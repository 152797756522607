import { ErrorHandler, Injectable } from '@angular/core'
import { KayttajaService } from '../_angular/service/kayttaja.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { MaksutFirestoreDataSource } from './maksut.firestore.datasource'
import { FirebaseLemonaid } from '../_angular/service/firebase-lemonaid.service'

@Injectable()
export class MaksutSharedStateService {
  constructor(
    private _errorHandler: ErrorHandler,
    private _kayttajaService: KayttajaService,
    private _lemonTranslationService: LemonTranslationService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {

  }

  private _dataSource: MaksutFirestoreDataSource
  getDataSource(): MaksutFirestoreDataSource {
    if (!this._dataSource) {
      this._dataSource = new MaksutFirestoreDataSource(this._errorHandler, this._kayttajaService, this._lemonTranslationService, this._firebaseLemonaid)
    }
    return this._dataSource
  }

}
