<div class="asetukset-page">
  <mat-tab-group>
    <mat-tab [label]="'asetukset.omat' | translate | async">
      <div class="sisalto">
        <app-perusasetukset></app-perusasetukset>
      </div>
    </mat-tab>
    <mat-tab [label]="'asetukset.lasku' | translate | async">
      <div class="sisalto">
        <app-laskuasetukset></app-laskuasetukset>
      </div>
    </mat-tab>
    <mat-tab [label]="'asetukset.spostipohja' | translate | async">
      <div class="sisalto">
        <app-emailpohja></app-emailpohja>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>