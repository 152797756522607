import * as Sentry from '@sentry/browser'

import { Injectable, ErrorHandler } from '@angular/core'

import { LaskuttavaAsiakasProvider } from '../../_jaettu-angular/service/lasku/laskuttava-asiakas.service'
import { TuettuKieli, ErrorResponse } from '../../_shared-core/model/common'

import { Kayttaja, KayttajanTiedot, Asiakas, PaivitaAsiointikieliRequest } from '../../_jaettu/model/kayttaja'

import { of, Observable, firstValueFrom } from 'rxjs'
import { switchMap, map } from 'rxjs/operators'
import { FirebaseLemonaid } from './firebase-lemonaid.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'

@Injectable()
export class LaskuttavaAsiakasLemonaidProvider extends LaskuttavaAsiakasProvider {

  constructor(
    private kayttajaService: KayttajaService
  ) {
    super()
  }

  public annaLaskuttavaAsiakasAvain(): Promise<string | null> {
    return this.kayttajaService.getKayttaja().then(kayttaja => {
      return kayttaja.asiakasAvain
    })
  }

  public onkoOssRekisterissa(): Observable<boolean> {
    return this.kayttajaService.nykyinenAsiakasObservable.pipe(
      map(asiakas => !!asiakas?.ossRekisterissa)
    )
  }

}

@Injectable()
export class KayttajaService {

  kayttajaObservable: Observable<Kayttaja>
  kayttajanTiedotObservable: Observable<KayttajanTiedot>
  nykyinenAsiakasObservable: Observable<Asiakas>

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private errorHandler: ErrorHandler
  ) {

    this.kayttajanTiedotObservable = this._firebaseLemonaid.authUserObservable.pipe(
      switchMap(lemonaidUser => {
        if (lemonaidUser) {
          const lemonaidTokenPromise = lemonaidUser.getIdTokenResult()
          return lemonaidTokenPromise.then(result => {
            if (
              result &&
              result.claims &&
              result.claims.clientid &&
              result.claims.asiakasavain
            ) {
              const tiedot: KayttajanTiedot = { uid: lemonaidUser.uid, asiakasAvain: result.claims.asiakasavain as string, asiakasId: result.claims.clientid as string }
              if (result.claims.euid) {
                tiedot.euid = result.claims.euid as string
              }
              this.asetaUserContext(tiedot, lemonaidUser.email)
              return tiedot
            } else {
              console.error('Claimeissa on jotakin vikana')
              return this._logoutImmediate().then(() => null).catch(er => this.errorHandler.handleError(er))
            }
          }).catch(err => {
            errorHandler.handleError(err)
            console.error('Error while logging in', err)
            return this._logoutImmediate().then(() => null).catch(er => this.errorHandler.handleError(er))
          })
        } else {
          // console.error('No user')
          return Promise.resolve(null)
        }
      }),
      lemonShare()
    )

    this.kayttajaObservable = this.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {
        if (kayttajanTiedot) {
          return this._firebaseLemonaid.firestoreDoc<Kayttaja>('/kayttajat/' + kayttajanTiedot.uid).listen().pipe(
            switchMap(kayttaja => {
              if (kayttaja && !kayttaja.aktiivinen) {
                return this.logout().then(() => null)
              }
              return of<Kayttaja>(kayttaja ?? null)
            })
          )
        }
        return of<Kayttaja>(null)
      })
    )

    this.nykyinenAsiakasObservable = this.kayttajanTiedotObservable.pipe(
      switchMap(kayttajanTiedot => {
        if (kayttajanTiedot) {
          return this._firebaseLemonaid.firestoreDoc<Asiakas>('/customers/' + kayttajanTiedot.asiakasAvain).listen()
        }
        return of<Asiakas>(null)
      })
    )

  }

  private asetaUserContext(kayttajanTiedot: KayttajanTiedot, email: string) {
    if (kayttajanTiedot) {
      Sentry.setUser({
        id: kayttajanTiedot.uid, // Your internal identifier for the user.
        username: email, // The username. Generally used as a better label than the internal ID.
        email: email // An alternative to a username (or addition). Sentry is aware of email addresses and can show things like Gravatars, unlock messaging capabilities, and more.
        // ip_address
      })
      Sentry.setExtra('asiakasId', kayttajanTiedot.asiakasId)
      Sentry.setExtra('asiakasAvain', kayttajanTiedot.asiakasAvain)
    } else {
      Sentry.setUser(null)
      Sentry.setExtra('asiakasId', null)
      Sentry.setExtra('asiakasAvain', null)
    }
  }

  private _logoutImmediate(): Promise<any> {
    return this._firebaseLemonaid.authSignOut()
  }

  public logout(): Promise<any> {
    return this.timeoutPromise(50).then(() => {
      return this.timeoutPromise(50)
    }).then(() => {
      return this.timeoutPromise(50)
    }).then(() => {
      return this.timeoutPromise(50)
    }).then(() => {
      return this.timeoutPromise(50)
    }).then(() => {
      return this.timeoutPromise(50)
    }).then(() => {
      return this.timeoutPromise(50)
    }).then(() => {
      return this.timeoutPromise(50)
    }).then(() => {
      return this.timeoutPromise(50)
    }).then(() => {
      return this.timeoutPromise(50)
    }).then(() => {
      return this._logoutImmediate()
    })
  }

  private timeoutPromise(millis: number): Promise<void> {
    return new Promise((resolve, reject) => {
      const id = setTimeout(() => {
        clearTimeout(id)
        resolve()
      }, millis)
    })
  }

  getKayttajanTiedot(): Promise<KayttajanTiedot> {
    return firstValueFrom(this.kayttajanTiedotObservable)
  }

  getKayttaja(): Promise<Kayttaja> {
    return firstValueFrom(this.kayttajaObservable)
  }
  tallennaKayttajanKieli(kieli: TuettuKieli): Promise<ErrorResponse> {
    return firstValueFrom(this.kayttajaObservable).then(kayttaja => {
      if (kayttaja) {
        const requestData: PaivitaAsiointikieliRequest = {
          kieli: kieli
        }
        return this._firebaseLemonaid.functionsCall<PaivitaAsiointikieliRequest, ErrorResponse>('kayttajaPaivitaAsiointikieli', requestData)
      } else {
        return Promise.reject('no-user')
      }
    }).then(resp => {
      if (!resp) {
        return Promise.reject('no-response')
      }
      if (resp.e) {
        return Promise.reject(resp.e)
      }
      return Promise.resolve(resp)
    })
  }
}
