import { DateService } from '../../_shared-core/service/date.service'
import { LocalDate } from '../../_shared-core/model/common'
import { Asiakas, KirjanpidonPeruste } from '../model/kayttaja'

type AsiakasType = Pick<Asiakas, 'kirjanpidonPerusteet'> & Partial<Pick<Asiakas, 'asiakasAvain'> & Pick<{ avain: string }, 'avain'>>
export class AsiakasJaettuLemonaidService {

  constructor(
    protected dateService: DateService
  ) { }

  public annaKirjanpidonPeruste(asiakas: AsiakasType, date: LocalDate): KirjanpidonPeruste {
    if (asiakas.kirjanpidonPerusteet === undefined || asiakas.kirjanpidonPerusteet === null) {
      return KirjanpidonPeruste.MAKSU
    }
    for (const peruste of asiakas.kirjanpidonPerusteet) {
      if (
        (!peruste.alkaa || this.dateService.compareLocalDates(peruste.alkaa, '<=', date)) &&
        (!peruste.loppuu || this.dateService.compareLocalDates(date, '<=', peruste.loppuu))
      ) {
        return peruste.peruste
      }
    }
    throw new Error('Kirjanpidon perustetta ei löytynyt asiakkaalle ' + asiakas.avain ?? asiakas.asiakasAvain)
  }

  public onkoKirjanpidonPerusteOllutVoimassaAikavalilla(asiakas: AsiakasType, alkaa: LocalDate, loppuu: LocalDate, peruste: KirjanpidonPeruste): boolean {
    if (asiakas.kirjanpidonPerusteet === undefined || asiakas.kirjanpidonPerusteet === null) {
      return peruste === KirjanpidonPeruste.MAKSU
    }
    for (const p of asiakas.kirjanpidonPerusteet) {
      if (
        peruste === p.peruste &&
        // See https://stackoverflow.com/a/325964/7740038 for an explanation
        (!p.loppuu || this.dateService.compareLocalDates(alkaa, '<=', p.loppuu)) &&
        (!p.alkaa || this.dateService.compareLocalDates(loppuu, '>=', p.alkaa))
      ) {
        return true
      }
    }
    return false
  }

}
