import { Injectable } from '@angular/core'

import { KayttajaService } from '../kayttaja.service'
import { TositeUriService } from '../../../_jaettu/service/tosite/tosite-uri.service'

import { ListausFirestoreKuitti } from './tosite-datasource.service'
import { FirestoreTositteenKuva } from '../../../_jaettu/model/tosite'
import { Yritysmuoto } from 'app/_jaettu/model/kayttaja'

import { Observable, of as observableOf, BehaviorSubject, combineLatest } from 'rxjs'
import { map, switchMap, tap } from 'rxjs/operators'
import { FirebaseLemonaid } from '../firebase-lemonaid.service'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'

export interface SelvitettavaListausFirestoreKuitti extends ListausFirestoreKuitti {
  naytaYksityisottoNappi: boolean
}

@Injectable()
export class SelvitettavatTositteetService {

  private lataaSubject = new BehaviorSubject<boolean>(true)
  lataaObservable: Observable<boolean> = this.lataaSubject.asObservable()

  private internalSelvitettavatObservable: Observable<SelvitettavaListausFirestoreKuitti[]>
  selvitettavatObservable: Observable<SelvitettavaListausFirestoreKuitti[]>

  constructor(
    private kayttajaService: KayttajaService,
    private firebaseLemonaid: FirebaseLemonaid,
    private tositeUriService: TositeUriService
  ) {

    this.internalSelvitettavatObservable = combineLatest([
      this.kayttajaService.kayttajanTiedotObservable,
      this.kayttajaService.nykyinenAsiakasObservable
    ]).pipe(
      tap(() => this.lataaSubject.next(true)),
      switchMap(([
        kayttajanTiedot,
        asiakas
      ]) => {
        if (!kayttajanTiedot || !asiakas) {
          return observableOf<SelvitettavaListausFirestoreKuitti[]>([])
        }
        const kuittienUri = this.tositeUriService.annaKuittienFirestoreCollectionUri(kayttajanTiedot.asiakasId)
        return this.firebaseLemonaid.firestoreCollection<SelvitettavaListausFirestoreKuitti>(kuittienUri)
          .where('x', '==', true)
          .where('poistettu', '==', false)
          .listen()
          .pipe(
            map(kuitit => {
              const sorted = kuitit.sort((a, b) => {
                if (a.localPvm.year !== b.localPvm.year) {
                  return b.localPvm.year - a.localPvm.year
                }
                if (a.localPvm.month !== b.localPvm.month) {
                  return b.localPvm.month - a.localPvm.month
                }
                if (a.localPvm.day !== b.localPvm.day) {
                  return b.localPvm.day - a.localPvm.day
                }
                return b.summa - a.summa
              })
              return sorted
            }),
            tap(kuitit => {
              if (kuitit) {
                for (const kuitti of kuitit) {

                  kuitti.naytaYksityisottoNappi = asiakas.yritysmuoto === Yritysmuoto.TOIMINIMI

                  let kuva: FirestoreTositteenKuva = null
                  for (const kuvanAvain of Object.keys(kuitti.kuvat)) {
                    const mahdollinen = kuitti.kuvat[kuvanAvain]
                    if (!mahdollinen.poistettu && (kuva === null || mahdollinen.jarjestys < kuva.jarjestys)) {
                      kuva = mahdollinen
                      if (kuva.jarjestys === 1) {
                        break
                      }
                    }
                  }

                  if (!kuva) {
                    kuitti.ladataan = false
                    kuitti.ensimmainenKuva = '/assets/noimage-blank.png'
                    continue
                  }

                  const kuvanUrl = this.tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, kuitti.kuvakansio, kuva.avain, kuva.type)
                  kuitti.ensimmainenKuva = '/api/1/kuitit/kuvat/thumb/' + kuvanUrl

                }
              }
              this.lataaSubject.next(false)
            })
          )
      }),
      lemonShare()
    )

    this.selvitettavatObservable = combineLatest([this.internalSelvitettavatObservable, this.lataaObservable]).pipe(
      map(([kuitit, lataa]) => {
        if (lataa) {
          return []
        }
        return kuitit
      })
    )
  }
  annaSelvitettavienMaaraJaSummaObservable(): Observable<{ maara: number, summa: number }> {
    return this.selvitettavatObservable.pipe(
      map(tositteet => {
        const summa = tositteet.reduce((a, b) => a + b.summa, 0)
        return { maara: tositteet?.length || 0, summa: summa || 0 }
      })
    )
  }

  // private async startSelvitettavatKuvatLoading(kuitit: ListausFirestoreKuitti[], kayttajanTiedot: KayttajanTiedot): Promise<void> {

  //   for (const kuitti of kuitit) {
  //     const lopullinenKuva = this.kuittiKuvaService.annaKuvaLopullisestaKakusta(kuitti)
  //     if (lopullinenKuva) {
  //       kuitti.ladataan = false
  //       kuitti.ensimmainenKuva = lopullinenKuva
  //     } else {
  //       kuitti.ladataan = true
  //       this.paivitaSelvitettavanKuva(kuitti, kayttajanTiedot)
  //     }
  //   }
  // }

  // private paivitaSelvitettavanKuva(kuitti: ListausFirestoreKuitti, kayttajanTiedot: KayttajanTiedot) {

  //   let kuva: FirestoreTositteenKuva = null
  //   for (const kuvanAvain of Object.keys(kuitti.kuvat)) {
  //     const mahdollinen = kuitti.kuvat[kuvanAvain]
  //     if (!mahdollinen.poistettu && (kuva === null || mahdollinen.jarjestys < kuva.jarjestys)) {
  //       kuva = mahdollinen
  //       if (kuva.jarjestys === 1) {
  //         break
  //       }
  //     }
  //   }

  //   if (!kuva) {
  //     kuitti.ladataan = false
  //     kuitti.ensimmainenKuva = '/assets/noimage-blank.png'
  //     return
  //   }

  //   const kuvaValimuistista = this.kuittiKuvaService.annaKuva(kuva)
  //   if (kuvaValimuistista &&
  //     kuvaValimuistista.kuva &&
  //     kuvaValimuistista.kuva.kuvanUrlObservable &&
  //     kuvaValimuistista.kuva.kuvanUrlObservable.value) {
  //     kuitti.ensimmainenKuva = kuvaValimuistista.kuva.kuvanUrlObservable.value
  //     kuitti.ladataan = false
  //     return
  //   }

  //   let lataa: boolean = true
  //   if (kuva.alkuperaisenAvain && kuitti.alkuperaiset) {
  //     const alkuperainen = kuitti.alkuperaiset[kuva.alkuperaisenAvain]
  //     lataa = alkuperainen?.kasitelty
  //   }

  //   if (lataa) {
  //     const kuvanUrl = this.tositeUriService.annaCloudStorageKuvaUri(kayttajanTiedot.asiakasId, kuitti.kuvakansio, kuva.avain, kuva.type)
  //     return this.httpService.getBinary('/api/1/kuitit/kuvat/thumb/' + kuvanUrl).then(blob => {
  //       // console.log('TÄÄLLÄ 3')
  //       const url = URL.createObjectURL(blob)
  //       return this.sanitizer.bypassSecurityTrustUrl(url)
  //     }).then(safeUrl => {
  //       // console.log('TÄÄLLÄ 3')
  //       // console.log('Returning', safeUrl)
  //       kuitti.ensimmainenKuva = safeUrl
  //       kuitti.ladataan = false
  //       this.kuittiKuvaService.lisaaKuvaLopulliseenKakkuun(kuitti, safeUrl)
  //     }).catch(error => {
  //       kuitti.ensimmainenKuva = '/assets/noimage-blank.png'
  //       kuitti.ladataan = false
  //       this.errorHandler.handleError(error)
  //     })
  //   }
  //   kuitti.ensimmainenKuva = '/assets/noimage-blank.png'
  //   kuitti.ladataan = false
  // }

}
