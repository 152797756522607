import { Injectable } from '@angular/core'

import { BicService } from '../_shared-core/service/bic.service'
import { CodeCheckService } from '../_shared-core/service/code-check.service'
import { IbanService } from '../_shared-core/service/iban.service'
import { ViitenumeroService } from '../_shared-core/service/viitenumero.service'
import { CurrencyService } from '../_shared-core/service/currency.service'
import { DateService } from '../_shared-core/service/date.service'
import { StringService } from '../_shared-core/service/string.service'
import { TimestampProviderBase } from '../_shared-core/service/timestamp-provider.interface'
import { CurrencyFormatService } from '../_shared-core/service/currency-format.service'

import { FirestoreIndeksoija } from '../_jaettu/service/firestore.indeksoija'
import { KopioijaPalvelu } from '../_jaettu/service/kopioija.service'
import { TranslationService } from '../_jaettu/service/translation.service'
import { LaskuKorkoService } from '../_jaettu/service/lasku/lasku-korko.service'
import { LaskuLahetystietojenLuoja } from '../_jaettu/service/lasku/lasku-lahetystietojen.luoja'
import { LaskuKopioija } from '../_jaettu/service/lasku/lasku.kopioija'
import { LaskuIndeksoija } from '../_jaettu/service/lasku/lasku.indeksoija'
import { LaskuUriService } from '../_jaettu/service/lasku/lasku-uri.service'
import { LaskuSpostiService } from '../_jaettu/service/lasku/lasku-sposti.service'
import { ReskontraService, LaskuSharedService } from '../_jaettu/service/lasku/lasku-shared.service'
import { SahkoisenLaskunValittajaService } from '../_jaettu/service/lasku/sahkoisen-laskun-valittaja.service'
import { TositeKopioija } from '../_jaettu/service/tosite/tosite.kopioija'
import { TositeIndeksoija } from '../_jaettu/service/tosite/tosite.indeksoija'
import { TositeUriService } from '../_jaettu/service/tosite/tosite-uri.service'
import { CountryLocalizationService } from '../_jaettu/service/translation.service'
import { KayttoehdotUriService } from '../_jaettu/service/kayttoehdot-uri.service'
import { KycUriService } from '../_jaettu/service/kyc-uri.service'
import { TilinpaatosUriService } from '../_jaettu/service/tilinpaatos-uri.service'
import { AjastettuTyoService } from '../_jaettu/eraajot/ajastettu/ajastetut-tyot.service'
import { TimestampService } from './service/timestamp-service'
import { BankConsentUriService } from 'app/_jaettu/service/bank-consent-uri.service'
import { PyhapaivatService } from 'app/_jaettu/service/pyhapaiva.service'
import { VatNumberValidationService } from 'app/_jaettu/service/vat-number-validation.service'
import { BankBalancePublicUriService } from 'app/_jaettu/service/bank-balance-public-uri.service'
import { AsiakasJaettuLemonaidService } from 'app/_jaettu/service/asiakas-jaettu-lemonaid.service'

@Injectable() export class AsiakasJaettuLemonaidServiceAngular extends AsiakasJaettuLemonaidService {
  constructor(dateService: DateService) {
    super(dateService)
  }
}

// Common
@Injectable() export class BicServiceAngular extends BicService {
  constructor() {
    super()
  }
}
@Injectable() export class CodeCheckServiceAngular extends CodeCheckService {
  constructor() {
    super()
  }
}
@Injectable() export class IbanServiceAngular extends IbanService {
  constructor(
    bicServic: BicService
  ) {
    super(bicServic)
  }
}
@Injectable() export class ViitenumeroServiceAngular extends ViitenumeroService {
  constructor(
    ibanServic: IbanService,
    bicServic: BicService,
    stringServic: StringService,
    dateServic: DateService,
    currencyServic: CurrencyService
  ) {
    super(ibanServic, bicServic, stringServic, dateServic, currencyServic)
  }
}
@Injectable() export class CurrencyServiceAngular extends CurrencyService {
  constructor() {
    super(new CurrencyFormatService())
  }
}
@Injectable() export class DateServiceAngular extends DateService {
  constructor() {
    super()
  }
}
@Injectable() export class StringServiceAngular extends StringService {
  constructor() {
    super()
  }
}
@Injectable() export class FirestoreIndeksoijaAngular extends FirestoreIndeksoija {
  constructor(
    dateServic: DateService
  ) { super(dateServic) }
}
@Injectable() export class KopioijaPalveluAngular extends KopioijaPalvelu {
  constructor(
    timestampProviderBas: TimestampProviderBase
  ) {
    super(timestampProviderBas)
  }
}
@Injectable() export class TranslationServiceAngular extends TranslationService {
  constructor() {
    super()
  }
}

@Injectable() export class KayttoehdotUriServiceAngular extends KayttoehdotUriService {
  constructor() {
    super()
  }
}

@Injectable() export class KycUriServiceAngular extends KycUriService {
  constructor(
  ) {
    super()
  }
}



// { provide: BicService, useClass: BicServiceAngular },
// { provide: IbanService, useClass: IbanServiceAngular },
// { provide: ViitenumeroService, useClass: ViitenumeroServiceAngular },
// { provide: CurrencyService, useClass: CurrencyServiceAngular },
// { provide: DateService, useClass: DateServiceAngular },
// { provide: FirestoreIndeksoija, useClass: FirestoreIndeksoijaAngular },
// { provide: KopioijaPalvelu, useClass: KopioijaPalveluAngular },
// { provide: CodeCheckService, useClass: CodeCheckServiceAngular },
// { provide: TranslationService, useClass: TranslationServiceAngular },
// { provide: StringService, useClass: StringServiceAngular },
// { provide: ViiaUriService, useClass: ViiaUriServiceAngular },

// Laskut
@Injectable() export class LaskuKorkoServiceAngular extends LaskuKorkoService {
  constructor(
    dateServic: DateService,
    currencyServic: CurrencyService,
    reskontraServic: ReskontraService
  ) {
    super(dateServic, currencyServic, reskontraServic)
  }
}
@Injectable() export class LaskuLahetystietojenLuojaAngular extends LaskuLahetystietojenLuoja {
  constructor(
    dateServic: DateService,
    currencyServic: CurrencyService,
    share: LaskuSharedService,
    timestampProvide: TimestampProviderBase
  ) {
    super(dateServic, currencyServic, share, timestampProvide)
  }
}
@Injectable() export class SahkoisenLaskunValittajaServiceAngular extends SahkoisenLaskunValittajaService {
  constructor() {
    super()
  }
}
@Injectable() export class LaskuKopioijaAngular extends LaskuKopioija {
  constructor(
    dateServic: DateService,
    timestampServic: TimestampProviderBase,
    kopioijaPalvel: KopioijaPalvelu,
    share: LaskuSharedService,
    korkoServic: LaskuKorkoService
  ) {
    super(dateServic, timestampServic, kopioijaPalvel, share, korkoServic)
  }
}
@Injectable() export class LaskuIndeksoijaAngular extends LaskuIndeksoija {
  constructor(
    firestoreIndeksoij: FirestoreIndeksoija,
    sharedLaskuServic: LaskuSharedService,
    reskontraServic: ReskontraService,
    currencyServic: CurrencyService
  ) {
    super(firestoreIndeksoij, sharedLaskuServic, reskontraServic, currencyServic)
  }
}
@Injectable() export class LaskuUriServiceAngular extends LaskuUriService {
  constructor() {
    super()
  }
}
@Injectable() export class LaskuSpostiServiceAngular extends LaskuSpostiService {
  constructor(
    share: LaskuSharedService,
    maaServic: CountryLocalizationService
  ) {
    super(share, maaServic)
  }
}
@Injectable() export class ReskontraServiceAngular extends ReskontraService {
  constructor(
    dateServic: DateService
  ) {
    super(dateServic)
  }
}
@Injectable() export class LaskuSharedServiceAngular extends LaskuSharedService {
  constructor(
    dateServic: DateService,
    translationServic: TranslationService,
    countryLocalizationServic: CountryLocalizationService,
    currencyServic: CurrencyService,
    reskontraServic: ReskontraService,
    timestampProvide: TimestampProviderBase,
    viitenumeroServic: ViitenumeroService
  ) {
    super(dateServic, translationServic, countryLocalizationServic, currencyServic, reskontraServic, timestampProvide, viitenumeroServic)
  }
}
@Injectable() export class BicAngularServiceAngular extends BicService {
  constructor() {
    super()
  }
}

// { provide: LaskuIndeksoija, useClass: LaskuIndeksoijaAngular },
// { provide: LaskuKopioija, useClass: LaskuKopioijaAngular },
// { provide: LaskuKorkoService, useClass: LaskuKorkoServiceAngular },
// { provide: LaskuSharedService, useClass: LaskuSharedServiceAngular },
// { provide: LaskuSpostiService, useClass: LaskuSpostiServiceAngular },
// { provide: LaskuUriService, useClass: LaskuUriServiceAngular },
// { provide: LaskuLahetystietojenLuoja, useClass: LaskuLahetystietojenLuojaAngular },
// { provide: ReskontraService, useClass: ReskontraServiceAngular },

// Tositteet
@Injectable() export class TositeKopioijaAngular extends TositeKopioija {
  constructor(
    kopioijaPalvel: KopioijaPalvelu
  ) {
    super(kopioijaPalvel)
  }
}
@Injectable() export class TositeUriServiceAngular extends TositeUriService {
  constructor() {
    super()
  }
}
@Injectable() export class TositeIndeksoijaAngular extends TositeIndeksoija {
  constructor(
    firestoreIndeksoij: FirestoreIndeksoija,
    currencyServic: CurrencyService,
    dateServic: DateService
  ) {
    super(firestoreIndeksoij, currencyServic, dateServic)
  }
}
@Injectable() export class TilinpaatosUriServiceAngular extends TilinpaatosUriService {
  constructor() {
    super()
  }
}

@Injectable() export class AjastettuTyoServiceAngular extends AjastettuTyoService {
  constructor(
    dateService: DateService,
    timestampService: TimestampService,
    pyhapaivatService: PyhapaivatService
  ) {
    super(dateService, timestampService, pyhapaivatService)
  }
}

@Injectable() export class VatNumberValidationServiceAngular extends VatNumberValidationService {
  constructor(
  ) {
    super()
  }
}


@Injectable() export class BankConsentUriServiceAngular extends BankConsentUriService {
  constructor() {
    super()
  }
}

@Injectable() export class BankBalancePublicUriServiceAngular extends BankBalancePublicUriService {
  constructor() {
    super()
  }
}
