import { Component, OnInit, Inject, ErrorHandler, OnDestroy } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'

import { LemonTranslationService } from 'app/_jaettu-angular/service/lemon-translation.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Subject, takeUntil } from 'rxjs'
import { FirestoreTosite, PaymentStatus } from 'app/_jaettu/model/tosite'
import { TositeKopioija } from 'app/_jaettu/service/tosite/tosite.kopioija'
import { DateService } from 'app/_shared-core/service/date.service'
import { TositeService } from 'app/_angular/service/tosite/tosite.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'

interface MaksettuMuuallaFormGroup {
  maksutapa: FormControl<number>
  tositteenPvm: FormControl<Date>
}

export interface MaksutMaksutapaDialogData {
  kuitti: FirestoreTosite
  milla: 'omalla' | 'yrityksen-tililta'
}

@Component({
  templateUrl: './maksut-maksutapa.dialog.html'
})
export class MaksutMaksutapaDialog implements OnInit, OnDestroy {

  private _ngUnsubscribe = new Subject<void>()

  voidaanMitatoida = false

  form: FormGroup<MaksettuMuuallaFormGroup> = null
  maksutapa: FormControl<number> = null
  tositteenPvm: FormControl<Date> = null

  maxDate: Date = new Date(2099, 11, 31)
  minDate: Date = new Date(2010, 0, 1)

  private _kuitti: FirestoreTosite = null

  namename = 'poiuyhgbvcdertyuj' + Math.random()

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: MaksutMaksutapaDialogData,
    private _dialogRef: MatDialogRef<MaksutMaksutapaDialog>,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _tositeKopioija: TositeKopioija,
    private _dateService: DateService,
    private _tositeService: TositeService,
    private _formValidationService: FormValidationService,
    private _kayttajaService: KayttajaService
  ) {
    this._kuitti = this._tositeKopioija.kopioiTosite(data.kuitti)
    this.maxDate = this._dateService.lisaaKuukausia(new Date(), 12)
    this.minDate = this._dateService.lisaaKuukausia(new Date(), -24)

    // combineLatest([this._tositeService.maksutavatObservable, this._kayttajaService.nykyinenAsiakasObservable]).pipe(
    //   filter(([kayttajanMaksutavat, asiakas]) => !!kayttajanMaksutavat && !!asiakas),
    //   take(1)
    // ).subscribe(([kayttajanMaksutavat, asiakas]) => {

    //   if (data.milla === 'omalla') {

    //     const generoitavia = asiakas.maksutavat.filter(m => m.)

    //     for () {

    //     }
    //   }



    //   if (maksutavat.filter(maksutapa => maksutapa.)) {

    //   }
    // })

  }

  ngOnInit() {

    this.maksutapa = new FormControl<number>(null, [Validators.required])
    this.tositteenPvm = new FormControl<Date>(null, [Validators.required])

    this.form = new FormGroup<MaksettuMuuallaFormGroup>({
      maksutapa: this.maksutapa,
      tositteenPvm: this.tositteenPvm
    })

    this.maksutapa.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      this._kuitti.paymentMaksutapa = value
    })

    this.tositteenPvm.valueChanges.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(value => {
      this._kuitti.paymentDate = this._dateService.dateToNumber(value)
    })

    this.tositteenPvm.setValue(new Date())

  }

  private _saveInFlight = false
  async tallenna() {

    this.form.updateValueAndValidity()
    if (this.form.invalid || !this.form.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.form)
      return
    }

    if (this._saveInFlight) {
      return
    }
    this._saveInFlight = true

    this._ladataanService.aloitaLataaminen()
    try {
      this._kuitti.paymentStatus = PaymentStatus.MAKSETTU_TOISAALLA
      await this._tositeService.tallennaKuitti(this._kuitti)
      this._dialogRef.close()
    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._saveInFlight = false
      this._ladataanService.lopetaLataaminen()
    }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

}
