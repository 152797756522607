// import * as Sentry from '@sentry/browser'

import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { map, Observable } from 'rxjs'
import { FirebaseLemonaid } from './firebase-lemonaid.service'

interface SovelluksenVersioTietokannassa {
  versio: string
}

export interface SovelluksenVersiotiedot {
  viimeisinversio: string
  binaarinversio: string
}

@Injectable()
export class VersionTarkistusPalvelu {

  sovelluksenVersioObservable: Observable<SovelluksenVersiotiedot | null>

  constructor(
    private firebaseLemonaid: FirebaseLemonaid
  ) {
    this.sovelluksenVersioObservable = this.firebaseLemonaid.firestoreDoc<SovelluksenVersioTietokannassa>('admin/sovelluksen-versio').listen().pipe(
      map(versio => {
        if (versio && versio.versio) {
          // Sentry.setExtra('versio', versio.versio) // Sentry already digs this up during the build or something.
          return {
            viimeisinversio: versio.versio,
            binaarinversio: environment.version
          }
        }
        return null
      })
    )
  }

  public annaVersio(): string {
    return environment.version
  }

  public tarkistaVersio(versiotiedot: SovelluksenVersiotiedot | null) {
    if (
      versiotiedot &&
      environment.enableVersionCheck &&
      versiotiedot.binaarinversio !== versiotiedot.viimeisinversio
    ) {
      location.reload()
    }
  }

}
