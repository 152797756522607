import { Component, OnInit, Inject } from '@angular/core'
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog'

import { LaskuReskontra } from '../../_jaettu/model/lasku'

@Component({
  templateUrl: './lasku.reskontra-tiedot.dialog.html'
})
export class LaskuReskontraTiedotDialog implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: LaskuReskontra,
    private dialogRef: MatDialogRef<LaskuReskontraTiedotDialog>
  ) {

  }

  ngOnInit() {

  }

}
