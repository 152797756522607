import * as Sentry from '@sentry/browser'

import { Injectable, ErrorHandler } from '@angular/core'
import { Platform } from '@angular/cdk/platform'

import { LemonDateAdapter } from './LemonDateAdapter'
import { DateService } from '../../_shared-core/service/date.service'
import { LemonTranslationService } from '../service/lemon-translation.service'

@Injectable()
export class LemonKuukausiDateAdapter extends LemonDateAdapter {

  constructor(
    errorHandler: ErrorHandler,
    dateService: DateService,
    platform: Platform,
    translationService: LemonTranslationService
  ) {
    super(errorHandler, dateService, platform, translationService)
  }

  parse(value: any): Date | null {

    if (this.isDateInstance(value)) {
      return new Date(value)
    }

    try {
      return this.dateService.parsiKuukausi(value, this.translationService.nykyinenKieli)
    } catch (error) {
      Sentry.captureException(error)
      return new Date('thisisinvaliddateithink')
    }

  }

  format(date: Date, displayFormat: Object): string {
    return this.dateService.muotoileKuukausiJaVuosi(date, this.translationService.nykyinenKieli)
  }

}
