import { Component, OnInit, OnDestroy, AfterContentChecked, ErrorHandler, ViewChild } from '@angular/core'
import { DomSanitizer, SafeUrl } from '@angular/platform-browser'
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms'

import { MatInput } from '@angular/material/input'
import { MatSnackBar } from '@angular/material/snack-bar'

import { FormValidationService, FieldErrors } from '../../_jaettu-angular/service/form-validation.service'
import { LaskuService } from '../../_angular/service/lasku/lasku.service'
import { AsetustenOletuksetService } from '../../_angular/service/asetusten-oletukset.service'
import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'
import { LEMONAID_CF_API, LemonHttpService } from '../../_angular/service/lemon-http.service'
import { TiedostojenLataamisService } from '../../_jaettu-angular/service/tiedostojen-lataamis.service'

import { BicService } from '../../_shared-core/service/bic.service'
import { IbanService } from '../../_shared-core/service/iban.service'
import { LaskuKopioija } from '../../_jaettu/service/lasku/lasku.kopioija'
import { Laskuasetukset } from '../../_jaettu/model/lasku'
import { CurrencyService } from '../../_shared-core/service/currency.service'
import { StringService } from 'app/_shared-core/service/string.service'
import { LemonaidValidators } from 'app/_angular/_validator/LemonaidValidators'

import { NgxFileDropEntry, FileSystemFileEntry } from 'ngx-file-drop'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'

import * as base64 from 'base64-js'

@Component({
  selector: 'app-laskuasetukset',
  templateUrl: './laskuasetukset.component.html'
})
export class LaskuasetuksetComponent implements OnInit, OnDestroy, AfterContentChecked {

  @ViewChild(MatInput, { static: true }) nimiInput: MatInput
  @ViewChild('fileInput', { static: true }) fileInput

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  form: UntypedFormGroup
  fieldErrors: FieldErrors = {}
  logo: SafeUrl = '/assets/noimage.png'
  kuvavirheAvain = ''
  kuvavirheParametrit = {}
  optimoidaanKuvaa = false
  asetukset: Laskuasetukset = null
  kuvanInfotekstinAvain = 'laskuasetukset.logo.info-teksti'

  constructor(
    private laskuService: LaskuService,
    private bicService: BicService,
    private ibanService: IbanService,
    private validationService: FormValidationService,
    private sanitizer: DomSanitizer,
    private laskuKopioija: LaskuKopioija,
    private asetuksetOletuksetService: AsetustenOletuksetService,
    private errorHandler: ErrorHandler,
    private ladataanService: LadataanService,
    private httpService: LemonHttpService,
    private snackbar: MatSnackBar,
    private currencyService: CurrencyService,
    private translationService: LemonTranslationService,
    private tiedostojenLataamisService: TiedostojenLataamisService,
    private stringService: StringService
  ) {

  }

  public async kasitteleLogo(entries: NgxFileDropEntry[]): Promise<void> {

    const tiedostonjenMaara = entries.length
    const fileEntrySuspect = tiedostonjenMaara > 0 ? entries[0] : null
    const fileSuspect = fileEntrySuspect && fileEntrySuspect.fileEntry && fileEntrySuspect.fileEntry.isFile ? fileEntrySuspect.fileEntry as FileSystemFileEntry : null
    const fileEnding = this.tiedostojenLataamisService.getFileEndingFromNgxFileDropEntry(fileEntrySuspect)
    const file: File = await this.tiedostojenLataamisService.getFile(fileSuspect)
    const fileSize = fileSuspect ? file.size : -1

    if (!this.tiedostoVoidaanLadata(fileSuspect ? tiedostonjenMaara : 0, fileEnding, fileSize)) {
      return
    }

    this.optimoiLogo(file, fileEntrySuspect.relativePath).catch(error => {
      this.optimoidaanKuvaa = false
      this.kuvavirheAvain = 'laskuasetukset.logo.virheet.tuntematon'
      setTimeout(() => {
        this.kuvavirheAvain = ''
      }, 10000)
      this.errorHandler.handleError(error)
    })

  }

  private optimoiLogo(file: File, relativePath: string): Promise<void> {
    this.optimoidaanKuvaa = true
    const formData = new FormData()
    formData.append('logo', file, relativePath)
    return this.httpService.postBinaryGetBinary('/asetuksetOptimoiLaskulogo', formData, LEMONAID_CF_API).then(kasiteltyKuva => {
      return this.tiedostojenLataamisService.getAsUint8Array(kasiteltyKuva)
    }).then(uint8Array => {
      const base64Img = base64.fromByteArray(uint8Array)
      this.asetukset.base64Logo = base64Img
      this.updateLogoSrc()
      this.kuvanInfotekstinAvain = 'laskuasetukset.logo.muista-tallentaa'
      this.optimoidaanKuvaa = false
    })
  }

  public fileOver(event) {
    // console.log(event)
  }

  public fileLeave(event) {
    // console.log(event)
  }

  public poistaKuva(event) {
    event.stopPropagation()
    this.asetukset.base64Logo = null
    this.kuvanInfotekstinAvain = 'laskuasetukset.logo.muista-tallentaa-poisto'
    this.paivitaPiilotaYrityksenNimiLaskullaCheckBox()
    this.updateLogoSrc()
  }

  public naytaPoista(): boolean {
    return this.asetukset.base64Logo && !this.kuvavirheAvain ? true : false
  }

  private paivitaPiilotaYrityksenNimiLaskullaCheckBox() {
    if (this.asetukset.base64Logo) {
      this.form.get('piilotaYrityksenNimiLaskulla').enable()
    } else {
      this.form.get('piilotaYrityksenNimiLaskulla').setValue(false)
      this.asetukset.piilotaYrityksenNimiLaskulla = false
      this.form.get('piilotaYrityksenNimiLaskulla').disable()
    }
  }

  ngAfterContentChecked() {
    this.fieldErrors = this.validationService.updateValidationStatus('laskuasetukset', this.form)
  }

  ngOnInit() {

    // Create form
    this.form = new UntypedFormGroup({
      'ohjeet': new UntypedFormControl('', []),
      'pankki': new UntypedFormControl('', [Validators.required]),
      'iban': new UntypedFormControl('', [Validators.required, LemonaidValidators.ibanValidator]),
      'bic': new UntypedFormControl('', [Validators.required, LemonaidValidators.bicValidator]),
      'aspaemail': new UntypedFormControl('', [Validators.required]),
      'aspapuh': new UntypedFormControl('', []),
      'piilotaYrityksenNimiLaskulla': new UntypedFormControl('', [])
    })

    // Bind to changes
    // Ohjeet
    this.form.get('ohjeet').valueChanges.subscribe(value => { this.asetukset.ohjeet = this.validationService.sanitizeWhitespace(value) })
    // Pankkiyhteys
    this.form.get('pankki').valueChanges.subscribe(value => { this.asetukset.pankki = this.validationService.processValue(value) })
    this.form.get('iban').valueChanges.subscribe(value => {
      this.asetukset.iban = this.stringService.removeAllWhiteSpaces(value) || null
      const pankinNimi = this.bicService.annaPankinNimi(this.asetukset.iban)
      const bic = this.bicService.annaBicKoodi(this.asetukset.iban)
      if (pankinNimi) {
        this.form.get('pankki').setValue(pankinNimi)
      }
      if (bic) {
        this.form.get('bic').setValue(bic)
      }
    })
    this.form.get('bic').valueChanges.subscribe(value => { this.asetukset.bic = this.validationService.processValue(value) })
    // Asiakaspalvelu
    this.form.get('aspaemail').valueChanges.subscribe(value => { this.asetukset.replyto = this.validationService.processValue(value) })
    this.form.get('aspapuh').valueChanges.subscribe(value => { this.asetukset.puhelin = this.validationService.processValue(value) })

    this.form.get('piilotaYrityksenNimiLaskulla').valueChanges.subscribe(value => { this.asetukset.piilotaYrityksenNimiLaskulla = value })

    // Load data
    this.laskuService.asetuksetObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(asetukset => {
      // this.form.reset()
      if (asetukset) {
        this.asetukset = this.laskuKopioija.copyAsetukset(asetukset)
        this.asetuksetOletuksetService.poistaOletuksetAsetuksista(this.asetukset)
      } else {
        this.asetukset = {
          nimi: null,
          ytunnus: null,
          katuosoite: null,
          postitmp: null,
          postinro: null,
          maakoodi: 'FIN',
          date: null,
          replyto: null,
          puhelin: null,
          bic: null,
          iban: null,
          pankki: null,
          base64Logo: null,
          ohjeet: null,
          slogan: null,
          piilotaYrityksenNimiLaskulla: false,
          summahuomautusPiilotettu: false,
          muistutusSpostiasetukset: {},
          spostiasetukset: {},
          tervetuloaNahty: true
        }
      }

      this.asetukset.tervetuloaNahty = true

      // Ohjeet
      this.form.get('ohjeet').setValue(this.asetukset.ohjeet)

      // Pankkiyhteys
      const trimmed = this.stringService.removeAllWhiteSpaces(this.asetukset.iban || '')
      this.form.get('iban').setValue(this.ibanService.formatoiIban(trimmed))
      this.form.get('pankki').setValue(this.asetukset.pankki)
      this.form.get('bic').setValue(this.asetukset.bic)

      // Asiakaspalvelu
      this.form.get('aspaemail').setValue(this.asetukset.replyto)
      this.form.get('aspapuh').setValue(this.asetukset.puhelin)

      this.form.get('piilotaYrityksenNimiLaskulla').setValue(this.asetukset.piilotaYrityksenNimiLaskulla)

      this.nimiInput.focus()
      this.updateLogoSrc()

      this.kuvanInfotekstinAvain = 'laskuasetukset.logo.info-teksti'

      this.paivitaPiilotaYrityksenNimiLaskullaCheckBox()

    }, err => {
      this.errorHandler.handleError(err)
    })

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  save() {
    if (this.validationService.onkoLomakkeessaVirheita(this.form)) {
      this.validationService.merkitseKokoLomakeKosketuksi(this.form)
    } else {
      this.ladataanService.aloitaLataaminen()
      this.laskuService.updateLaskuasetukset(this.asetukset).then(() => {
        const lokalisoitu = this.translationService.lokalisoi('laskuasetukset.tallennettu-onnistuneesti')
        this.snackbar.open(lokalisoitu, 'OK', { duration: 5000, verticalPosition: 'top' })
        this.ladataanService.lopetaLataaminen()
        this.kuvanInfotekstinAvain = 'laskuasetukset.logo.info-teksti'
      }).catch(err => {
        this.ladataanService.lopetaLataaminen()
        this.errorHandler.handleError(err)
      })
    }
  }

  private updateLogoSrc(): void {
    if (this.asetukset.base64Logo) {
      this.logo = this.sanitizer.bypassSecurityTrustUrl('data:;base64,' + this.asetukset.base64Logo)
    } else {
      this.logo = '/assets/noimage.png'
    }
    this.paivitaPiilotaYrityksenNimiLaskullaCheckBox()
  }

  naytaTiedostonValintaDialogi() {
    this.fileInput.nativeElement.click()
  }

  fileChanged(event) {

    const list: FileList = event.target.files

    const tiedostonjenMaara = list ? list.length : 0
    const fileSuspect = tiedostonjenMaara > 0 ? list.item(0) : null

    if (fileSuspect === null) {
      return
    }

    const fileEnding = this.tiedostojenLataamisService.getFileEndingFromFile(fileSuspect)
    const fileSize = fileSuspect.size ? fileSuspect.size : -1

    if (!this.tiedostoVoidaanLadata(tiedostonjenMaara, fileEnding, fileSize)) {
      return
    }

    return this.optimoiLogo(fileSuspect, './' + fileSuspect.name).catch(error => {
      this.optimoidaanKuvaa = false
      this.kuvavirheAvain = 'laskuasetukset.logo.virheet.tuntematon'
      setTimeout(() => {
        this.kuvavirheAvain = ''
      }, 10000)
      this.errorHandler.handleError(error)
    })

  }

  private tiedostoVoidaanLadata(kuvienMaara: number, tiedostonPaate: string, tiedostonKoko: number): boolean {

    this.kuvavirheAvain = ''
    this.kuvavirheParametrit = {}
    if (kuvienMaara > 1) {
      this.kuvavirheAvain = 'laskuasetukset.logo.virheet.vain-yksi-kerrallaan'
      setTimeout(() => {
        this.kuvavirheAvain = ''
      }, 10000)
      return false
    }

    if (kuvienMaara < 1) {
      this.kuvavirheAvain = 'laskuasetukset.logo.virheet.ei-tiedosto'
      setTimeout(() => {
        this.kuvavirheAvain = ''
      }, 10000)
      return false
    }

    const supportedImageTypes = ['jpeg', 'jpg', 'png', 'webp', 'tiff', 'gif', 'svg', 'heic', 'heif']
    if (!tiedostonPaate || supportedImageTypes.indexOf(tiedostonPaate.toLowerCase()) < 0) {
      this.kuvavirheParametrit = {
        tuetutMuodot: supportedImageTypes.join(', ')
      }
      this.kuvavirheAvain = 'laskuasetukset.logo.virheet.vaara-muoto'
      setTimeout(() => {
        this.kuvavirheAvain = ''
      }, 10000)
      return false
    }

    const maxKoko = 5 * 1024 * 1024
    if (tiedostonKoko > maxKoko) {
      const kokoMegatavuissaLokalisoitu = this.currencyService.formatoiDesimaali((tiedostonKoko / 1024 / 1024), 2, this.translationService.nykyinenKieli)
      const maxKokoLokalisoitu = this.currencyService.formatoiDesimaali((maxKoko / 1024 / 1024), 2, this.translationService.nykyinenKieli)
      this.kuvavirheParametrit = {
        kokoMax: maxKokoLokalisoitu,
        kokoNyt: kokoMegatavuissaLokalisoitu
      }
      this.kuvavirheAvain = 'laskuasetukset.logo.virheet.liian-suuri'
      setTimeout(() => {
        this.kuvavirheAvain = ''
      }, 10000)
      return false
    }

    return true

  }

}
