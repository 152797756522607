import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, HostListener, ErrorHandler } from '@angular/core'
import { Location } from '@angular/common'
import { Router } from '@angular/router'
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms'

import { MatDialog } from '@angular/material/dialog'
import { MatInput } from '@angular/material/input'
import { MatSnackBar } from '@angular/material/snack-bar'

import { KayttajaService } from '../_angular/service/kayttaja.service'
import { VersionTarkistusPalvelu } from '../_angular/service/version-tarkistus.palvelu'
import { LoginService, LoginInputData } from '../_angular/service/login.service'

import { FormValidationService } from '../_jaettu-angular/service/form-validation.service'
import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'

import { VaihdaKieliDialog, VaihdaKieliDialogData } from '../vaihda-kieli.dialog'

import { ErrorResponse } from '../_shared-core/model/common'
import { TwoFactorLoginRequest, TwoFactorLoginResponse, ResendSmsRequest } from '../_jaettu/model/login'

import { Subject } from 'rxjs'
import { takeUntil } from 'rxjs/operators'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  templateUrl: './second-factor.component.html',
  styleUrls: ['./login.component.css']
})
export class SecondFactorComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild(MatInput, { static: true }) codeInput: MatInput

  loginForm: UntypedFormGroup
  commonError: string
  online = true
  year = new Date().getFullYear()
  inputReceived: LoginInputData

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  constructor(
    private _kayttajaService: KayttajaService,
    private _dialog: MatDialog,
    private _router: Router,
    private _validationService: FormValidationService,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _versionTarkistaja: VersionTarkistusPalvelu,
    private _loginService: LoginService,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _versionTarkistusPalvelu: VersionTarkistusPalvelu,
    private _location: Location,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _currencyService: CurrencyService
  ) {
    // Preheat
    this._firebaseLemonaid.functionsCall<string, void>('loginTarkistaTwoFactorKoodiaJaTallennaTyojonoon', '')
  }

  ngOnInit(): void {

    this._loginService.currentLoginCredentialsObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(data => {
      this.inputReceived = data
      if (
        !this.inputReceived ||
        !this.inputReceived.email ||
        !this.inputReceived.password ||
        !this.inputReceived.loginCodeId
      ) {
        this.commonError = this._translationService.lokalisoi('login.login-out-of-date')
      }
    })

    this._versionTarkistaja.sovelluksenVersioObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(versiotiedot => {
      this._versionTarkistaja.tarkistaVersio(versiotiedot)
    })

    this._kayttajaService.kayttajanTiedotObservable.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(kayttajanTiedot => {
      if (kayttajanTiedot) {
        this.setLoginProcessEnded()
        this._router.navigate(['/'])
      }
    })
    this.loginForm = new UntypedFormGroup({
      'code': new UntypedFormControl(null, [Validators.required, this._containsNonNumbersValidator])
    })
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.codeInput.focus() }, 0)
  }

  public get loginFormControl(): AbstractControl {
    return this.loginForm.get('code')
  }

  setLoginProcessStarted() {
    this._ladataanService.aloitaLataaminen()
  }

  setLoginProcessEnded() {
    this._ladataanService.lopetaLataaminen()
  }

  back() {
    this._location.back()
  }
  sendCodeAgain() {
    this.setLoginProcessStarted()

    const resendRequest: ResendSmsRequest = {
      email: this.inputReceived.email,
      password: this.inputReceived.password,
      loginCodeId: this.inputReceived.loginCodeId,
      valittuKieli: this._translationService.nykyinenKieli,
      env: 'w',
      ver: this._versionTarkistusPalvelu.annaVersio(),
      device: navigator.platform,
      os: navigator.userAgent
    }
    const resend = this._firebaseLemonaid.functionsCall<ResendSmsRequest, ErrorResponse>('loginLahettaaUusiKoodi', resendRequest).then(resp => {

      if (!resp) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
        this._errorHandler.handleError('no-response-or-no-data')
        this.setLoginProcessEnded()
        return
      }

      if (resp.e) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
        this._errorHandler.handleError(resp.e)
      } else {
        this._snackbar.open(this._translationService.lokalisoi('login.send-success'), 'OK', { duration: 5000, verticalPosition: 'top' })
        setTimeout(() => { this.codeInput.focus() }, 0)
      }
      this.setLoginProcessEnded()

    })

  }
  @HostListener('document:keydown.enter')
  tryCode() {
    if (!this.loginForm.valid) {
      this._validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
      setTimeout(() => { this.codeInput.focus() }, 0)
      return
    }
    this.setLoginProcessStarted()
    const request: TwoFactorLoginRequest = {
      email: this.inputReceived.email,
      password: this.inputReceived.password,
      loginCodeId: this.inputReceived.loginCodeId,
      smsCode: '' + (this.loginFormControl.value || '').trim(),
      valittuKieli: this._translationService.nykyinenKieli,
      env: 'w',
      ver: this._versionTarkistusPalvelu.annaVersio(),
      device: navigator.platform,
      os: navigator.userAgent
    }
    const callForCodeCheck = this._firebaseLemonaid.functionsCall<TwoFactorLoginRequest, TwoFactorLoginResponse>('loginTarkistaTwoFactorKoodiaJaTallennaTyojonoon', request).then(data => {

      if (!data) {
        this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
        this._errorHandler.handleError('no-response-or-no-data')
        this.setLoginProcessEnded()
        return
      }

      const error = data.e
      if (error) {
        this.setLoginProcessEnded()
        switch (error) {
          case 'auth/invalid-code':
            this.loginForm.get('code').setErrors({ 'wrongCode': true })
            break
          case 'auth/no-valid-login':
            this.commonError = this._translationService.lokalisoi('login.login-out-of-date')
            break
          case 'auth/login-expired':
            this.commonError = this._translationService.lokalisoi('login.login-out-of-date')
            break
          case 'auth/user-disabled':
            this.commonError = this._translationService.lokalisoi('login.validation.email.user-disabled')
            break
          case 'auth/user-inactive':
            this.commonError = this._translationService.lokalisoi('login.validation.email.user-disabled')
            break
          default:
            this._errorHandler.handleError(error)
            this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
        }
        this._validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
      } else {
        this._firebaseLemonaid.authSignInWithCustomToken(data.tokens.lemonaidToken).then(() => {
          this.setLoginProcessEnded()
          this._loginService.clearCredentials()
          this._router.navigate(['/'])
        }).catch(err => {
          this._errorHandler.handleError(err)
          this.setLoginProcessEnded()
          const code = err['code']
          switch (code) {
            case 'auth/custom-token-mismatch':
              this.commonError = 'Tämä token kuuluu toiseen projektiin.'
              break
            case 'auth/invalid-custom-token':
              this.commonError = 'Token on virheellinen.'
              break
            case 'auth/user-disabled':
              this.commonError = this._translationService.lokalisoi('login.validation.email.user-disabled')
              break
            default:
              this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
          }
          this._validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
        })
      }
    }).catch(err => {
      this._errorHandler.handleError(err)
      this.setLoginProcessEnded()
      this.commonError = this._translationService.lokalisoi('yleiset.tuntematon-virhe')
      this._validationService.merkitseKokoLomakeKosketuksi(this.loginForm)
    })
  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: false
    }
    this._dialog.open(VaihdaKieliDialog, { data: data })
  }

  private _containsNonNumbersValidator: ValidatorFn = (control: UntypedFormControl): ValidationErrors | null => {
    if (control.value) {
      const trimmed = (control.value + '').trim()
      const containsNonNumbers = this._currencyService.poistaNumeroonKuulumattomatMerkit(trimmed, 0)?.length !== trimmed.length
      if (containsNonNumbers) {
        return { containsNonNums: true }
      }
    }
    return null
  }

}
