<mat-dialog-content>

  <!-- {{'maksut.vastaanotto-aloitettu-dialog.otsikko' | translate | async}} -->
  <lemon-dialog-header>
    <div>{{ 'maksut.luonti-onnistui' | translate |async}}
    </div>
  </lemon-dialog-header>

  <!-- {{'maksut.vastaanotto-aloitettu-dialog.teksti' | translate | async}} -->
  <p style="margin: 3em 3em 2em 3em;">
    {{ 'maksut.yrityksellesi-on-nyt-luotu' | translate |async}}
  </p>
</mat-dialog-content>

<mat-dialog-actions style="margin: 30px; margin-top: 12.5px; justify-content: center;">
  <button class="primarybutton" mat-button mat-dialog-close>{{'yleiset.jatka' | translate | async}}</button>
</mat-dialog-actions>