export class KycUriService {

  getCustomerKycDocUri(asiakasAvain: string) {
    return 'customers/' + asiakasAvain + '/customer-kyc/' + asiakasAvain
  }
  getCustomerKycHistoryDocUri(asiakasAvain: string, historyAvain: string) {
    return this.getCustomerKycDocUri(asiakasAvain) + '/customer-kyc-history/' + historyAvain
  }
  getUserKycCollection(asiakasAvain: string, kayttajaAvain: string) {
    return 'customers/' + asiakasAvain + '/customers-users/' + kayttajaAvain + '/user-kyc'
  }
  getUserKycUri(asiakasAvain: string, kayttajaAvain: string) {
    return this.getUserKycCollection(asiakasAvain, kayttajaAvain) + '/' + kayttajaAvain
  }
  getCustomerKycSeenLogDocUri(asiakasAvain: string) {
    return this.getCustomerKycDocUri(asiakasAvain) + '/customer-kyc-seen-log/' + asiakasAvain
  }
}
