import { Component, OnInit, OnDestroy, ErrorHandler, HostListener } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { SafeUrl } from '@angular/platform-browser'

import { MatDialog } from '@angular/material/dialog'

import { FirestoreTosite, FirestoreTositteenKuva, FirestoreTositteenAlkuperainenTiedosto, PaymentStatus } from '../_jaettu/model/tosite'
import { TositeKopioija } from '../_jaettu/service/tosite/tosite.kopioija'
import { DateService } from '../_shared-core/service/date.service'

import { LadataanService } from '../_jaettu-angular/service/ladataan.service'
import { TositeKuvaCacheService } from '../tositteet/kuvat/tosite-kuva-cache.service'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'
import { KayttajaService } from '../_angular/service/kayttaja.service'
import { TositeService } from '../_angular/service/tosite/tosite.service'
import { TositeKatseleComponentData } from '../_angular/_resolvers/tosite.resolve'

// import { TositePoistaDialog, TositePoistaDialogData } from '../poista-dialog/tosite.poista.dialog'
// import { TositeLukittuDialog } from '../tosite-lukittu.dialog'

import { BehaviorSubject, Observable, Subject, combineLatest, firstValueFrom, of } from 'rxjs'
import { map, startWith, switchMap, take, takeUntil, tap } from 'rxjs/operators'

import { MatSnackBar } from '@angular/material/snack-bar'
import { TimestampService } from 'app/_jaettu-angular/service/timestamp-service'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { FormValidationService } from 'app/_jaettu-angular/service/form-validation.service'
import { MaksutSharedStateService } from './maksut-shared-state.service'
import { MaksuKatseleComponentDataResolve, MaksuKatseleComponentExistingData } from 'app/_angular/_resolvers/maksu.resolve'
import { MaksutFirestoreDataSource } from './maksut.firestore.datasource'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { LaskunQrKoodinCharacterSet, LaskunQrKoodinOsat, ViitenumeroService, VirtuaaliviivakoodinOsat } from 'app/_shared-core/service/viitenumero.service'
import { Big } from 'big.js'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { MaksutMaksutapaDialog, MaksutMaksutapaDialogData } from './maksut-maksutapa.dialog'
import { LocalDate, NumberDate } from 'app/_shared-core/model/common'
import { KirjanpidonPeruste, UserSettings } from 'app/_jaettu/model/kayttaja'
import { VasenValikkoService } from 'app/_jaettu-angular/service/vasen-valikko.service'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { MaksutMaksupaivaDialog, MaksutMaksupaivaDialogData } from './maksut-maksupaiva.dialog'
import { AsiakasJaettuLemonaidService } from 'app/_jaettu/service/asiakas-jaettu-lemonaid.service'
import { UserSettingsService } from 'app/_angular/service/user-settings.service'

type ViewType = 'vastaanotto' | 'hyvaksynta' | 'maksaminen' | 'lukossa'
export interface FirestoreKuitinLatausKuva extends FirestoreTositteenKuva {
  ladataan: boolean
  safeUrl: SafeUrl
}

export interface MaksunTiedotFormInterface {
  erapvm: FormControl<Date>
  tililta: FormControl<string>
  maara: FormControl<number>
  lisatiedot: FormControl<string>
}

@Component({
  templateUrl: './maksut-katsele.component.html',
  styleUrls: ['./maksut-katsele.component.css']
})
export class MaksutKatseleComponent implements OnInit, OnDestroy {

  private _ngUnsubscribe = new Subject<void>()

  lahde: 'osto' | 'myynti' | 'palkka' | 'tiliote'

  // tosite: FirestoreTosite = null
  kuvat: string[] = null
  tositeObservable: Observable<FirestoreTosite>
  alkuperaiset: FirestoreTositteenAlkuperainenTiedosto[] = null
  maksuHyvaksyttyObservable: Observable<boolean>

  naytaHylkaaNappiObservable: Observable<boolean>
  naytaHylkaamisenPeruminenNappiObservable: Observable<boolean>
  naytaHyvaksyNappiObservable: Observable<boolean>
  naytaHyvaksynnanPeruminenNappiObservable: Observable<boolean>
  naytaMaksettuMuuallaNappiObservable: Observable<boolean>
  naytaMaksettuMuuallaPeruminenNappiObservable: Observable<boolean>
  naytaMuokkaaMaksuaNappiObservable: Observable<boolean>
  naytaKasiteltyNappiObservable: Observable<boolean>
  naytaKasittelynPeruminenNappiObservable: Observable<boolean>

  maksuHyvaksyttyTaiEiHyvaksyjaObservable: Observable<boolean>
  private _maksuaMuokataanSubject: BehaviorSubject<boolean> = new BehaviorSubject(false)
  maksuaMuokataanObservable: Observable<boolean> = this._maksuaMuokataanSubject.asObservable()
  tililtaIbanitObservable: Observable<string[]>
  logItemsObservable: Observable<string[]>
  kooditObservable: Observable<{ viiva: string, qr: string }>
  viewTypeObservable: Observable<ViewType>
  pagesObservable: Observable<{ curr: number, total: number }>

  showSumma: boolean = false
  logItems: string[] = []

  maksunTiedotForm: FormGroup<MaksunTiedotFormInterface> = new FormGroup({
    'erapvm': new FormControl<Date>(null, Validators.required),
    'tililta': new FormControl<string>(null, Validators.required),
    'maara': new FormControl<number>(null, Validators.required),
    'lisatiedot': new FormControl<string>(null),
  })

  activeTabIndex: number

  private _dataSource: MaksutFirestoreDataSource
  private _currentViewType: ViewType

  showLisatiedotCheckMark: boolean

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _errorHandler: ErrorHandler,
    private _snackbar: MatSnackBar,
    private _dialog: MatDialog,
    private _translationService: LemonTranslationService,
    private _ladataanService: LadataanService,
    private _dateService: DateService,
    private _tositeService: TositeService,
    private _tositeKopioija: TositeKopioija,
    private _tositeKuvaCacheService: TositeKuvaCacheService,
    private _kayttajaService: KayttajaService,
    private _timestampService: TimestampService,
    private _formValidationService: FormValidationService,
    private _maksutSharedStateService: MaksutSharedStateService,
    private _katseleComponentDataResolve: MaksuKatseleComponentDataResolve,
    private _viitenumeroService: ViitenumeroService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _vasenValikkoService: VasenValikkoService,
    private _breakpointObserver: BreakpointObserver,
    private _asiakasJaettuService: AsiakasJaettuLemonaidService,
    private _userSettingsService: UserSettingsService
  ) {
    this._dataSource = this._maksutSharedStateService.getDataSource()
  }

  @HostListener('document:keydown.arrowright')
  async navigoiSeuraavaan() {
    if (this._maksuaMuokataanSubject.value) {
      return
    }
    const seuraavaMaksu = await this._dataSource.siirrySeuraavaan()
    if (seuraavaMaksu) {
      const existing: MaksuKatseleComponentExistingData = {
        tosite: seuraavaMaksu
      }
      this._katseleComponentDataResolve.asetaOlemassaolevaData(existing)
      this._router.navigate(['/maksut/', seuraavaMaksu.avain])
    }
  }

  @HostListener('document:keydown.arrowleft')
  async navigoiEdelliseen() {
    if (this._maksuaMuokataanSubject.value) {
      return
    }
    const edellinenMaksu = await this._dataSource.siirryEdelliseen()
    if (edellinenMaksu) {
      const existing: MaksuKatseleComponentExistingData = {
        tosite: edellinenMaksu
      }
      this._katseleComponentDataResolve.asetaOlemassaolevaData(existing)
      this._router.navigate(['/maksut/', edellinenMaksu.avain])
    }
  }

  ngOnInit() {

    this._breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small]).pipe(
      takeUntil(this._ngUnsubscribe),
      map(br => br.matches)
    ).subscribe(isSmallScreen => this._vasenValikkoService.paivitaAuki(!isSmallScreen))

    this._userSettingsService.userSettingsObservable.pipe(
      take(1)
    ).subscribe(settings => {
      if (settings?.vm === 'viiva') {
        this.activeTabIndex = 1
      } else if (settings?.vm === 'virtuaaliviiva') {
        this.activeTabIndex = 2
      }
    })

    this.pagesObservable = combineLatest([
      this._dataSource.nykyinenIndeksiObservable,
      this._dataSource.valittujenRivienMaaraObservable])
      .pipe(
        map(([nykyinen, maara]) => {

          return { curr: nykyinen, total: maara }
        })
      )

    if (!this._dataSource.isInitialized()) {
      this.peruuta()
      return
    }

    this.tililtaIbanitObservable = this._kayttajaService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        if (asiakas?.paymentConfigs?.length) {
          return asiakas.paymentConfigs.map(gtw => gtw.iban)
        }
        return []
      })
    )

    this.tositeObservable = combineLatest([this._route.data, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      tap(async ([routeData, kayttaja]) => {
        await this._dataSource.asetaNykyisenKuitinIndex(routeData?.data?.tosite)
        // console.log('ASETA', routeData?.data?.tosite?.avain)
      }),
      switchMap(([routeData, kayttaja]) => {
        if (!routeData?.data?.tosite?.avain || !kayttaja?.asiakasId) {
          return of<FirestoreTosite>(null)
        }
        const componentData = routeData.data as TositeKatseleComponentData
        return this._firebaseLemonaid.firestoreDoc<FirestoreTosite>('kuitit/' + kayttaja.asiakasId + '/kuitit/' + componentData.tosite.avain).listen().pipe(
          startWith(componentData.tosite)
        )
      }),
      lemonShare()
    )

    this.viewTypeObservable = combineLatest([
      this._kayttajaService.nykyinenAsiakasObservable,
      this._kayttajaService.kayttajaObservable,
      this.tositeObservable
    ]).pipe(
      map(([asiakas, kayttaja, tosite]) => {
        if (asiakas?.paymentsNeedsApproval && kayttaja?.roolit?.MAKSUT_HYVAKSYJA) {
          return 'hyvaksynta'
        }
        if (kayttaja?.roolit?.MAKSUT_MAKSAJA) {
          return 'maksaminen'
        }
        if (
          tosite.paymentStatus === PaymentStatus.ASIAKAS_HYLKASI ||
          tosite.paymentStatus === PaymentStatus.MAKSETTU ||
          tosite.paymentStatus === PaymentStatus.MAKSETTU_TOISAALLA
        ) {
          return 'lukossa'
        }
        return 'vastaanotto'
      }),
      tap<ViewType>(viewType => this._currentViewType = viewType)
    )

    const virtuaaliviivakoodiObservable = this.tositeObservable.pipe(
      map(maksu => {
        const params: VirtuaaliviivakoodinOsat = {
          erapvm: this._getDateOrCurrentIfInPast(maksu.dueDate),
          iban: maksu.iban,
          summa: new Big(maksu.sum),
          viitenumero: maksu.viitenumero
        }
        return this._viitenumeroService.luoSuomalainenVirtuaaliviivakoodi(params)
      })
    )

    const qrkoodiObservable = this.tositeObservable.pipe(
      map(maksu => {
        const params: LaskunQrKoodinOsat = {
          serviceTag: 'BCD',
          version: '001',
          charset: LaskunQrKoodinCharacterSet.UTF8,
          identificationCode: 'SCT',
          bic: maksu.bic,
          iban: maksu.iban,
          maksunSaajaNimi: maksu.seller,
          valuutta: maksu.currency || 'EUR',
          summa: maksu.sum,
          purposeCode: null,
          viitenumero: maksu.viitenumero,
          erapaiva: this._getDateOrCurrentIfInPast(maksu.dueDate),
          remittanceInfo: null,
          lisatiedot: null
        }
        return this._viitenumeroService.luoSuomalainenQrKoodi(params)
      })
    )

    this.kooditObservable = combineLatest([virtuaaliviivakoodiObservable, qrkoodiObservable]).pipe(
      map(([viiva, qr]) => {
        return { viiva: viiva, qr: qr }
      })
    )

    this.maksuHyvaksyttyObservable = this.tositeObservable.pipe(
      map((tosite,) => {
        return Object.values(tosite?.einvoiceApprovals ?? {}).length > 0
      })
    )

    this.logItemsObservable = combineLatest([this.tositeObservable, this._translationService.currentLanguageObservable]).pipe(
      map(([tosite, kieli]) => {
        const approvals = Object.values(tosite.einvoiceApprovals ?? {}).sort((a, b) => a.approved.toMillis() - b.approved.toMillis())

        return approvals.map(approval => {
          return this._translationService.lokalisoiKielella('maksut.katsele.hyvaksytty', kieli) + ' ' + this._dateService.muotoilePaivaJaAikaDate(approval.approved.toDate(), kieli) + ' ' + approval.firstName + ' ' + approval.lastName
        }
        )

      })
    )

    combineLatest([this.maksuaMuokataanObservable, this.viewTypeObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([muokataan, viewType]) => {
      if (muokataan) {
        if (viewType === 'maksaminen') {
          this.erapvm.enable()
          this.tililta.enable()
          this.maara.enable()
        }
        // this.lisatiedot.enable()
      } else {
        this.erapvm.disable()
        this.tililta.disable()
        this.maara.disable()
        // this.lisatiedot.disable()
      }
    })

    this.maksuHyvaksyttyTaiEiHyvaksyjaObservable = combineLatest([
      this.viewTypeObservable,
      this.maksuHyvaksyttyObservable
    ]).pipe(
      map(([viewType, hyvaksytty]) => {
        return viewType !== 'hyvaksynta' && !hyvaksytty
      })
    )

    combineLatest([this._kayttajaService.kayttajanTiedotObservable, this.tositeObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(async ([kayttajanTiedot, tosite]) => {

      if (!tosite) {
        return
      }

      await this._startMaksunTiedotForm(tosite)

      this.kuvat = this._tositeKuvaCacheService.annaKuvienUrlit(tosite, kayttajanTiedot, 'full')
      this.alkuperaiset = this.annaAlkuperaiset(tosite)

    })

    this.naytaHyvaksyNappiObservable = combineLatest([
      this.viewTypeObservable,
      this.maksuHyvaksyttyObservable
    ]).pipe(
      map(([viewType, hyvaksytty]) => {
        return viewType === 'hyvaksynta' && !hyvaksytty
      })
    )
    this.naytaKasiteltyNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        return (tosite.paymentStatus === PaymentStatus.AVOIN || tosite.paymentStatus === PaymentStatus.ERAANTYNYT) && tosite.sum <= 0
      })
    )
    this.naytaKasittelynPeruminenNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        return tosite.paymentStatus === PaymentStatus.MAKSETTU_TOISAALLA && tosite.sum < 0
      })
    )
    this.naytaHylkaaNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        return (tosite.paymentStatus === PaymentStatus.AVOIN || tosite.paymentStatus === PaymentStatus.ERAANTYNYT)
      })
    )
    this.naytaHylkaamisenPeruminenNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        return tosite.paymentStatus === PaymentStatus.ASIAKAS_HYLKASI
      })
    )
    this.naytaHyvaksynnanPeruminenNappiObservable = combineLatest([
      this.tositeObservable,
      this._kayttajaService.kayttajanTiedotObservable
    ]).pipe(
      map(([tosite, kayttaja]) => {
        if (tosite?.einvoiceApprovals && kayttaja?.uid) {
          return !!tosite.einvoiceApprovals[kayttaja.uid]
        }
        return false
      })
    )
    this.naytaMaksettuMuuallaNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        return (tosite.paymentStatus === PaymentStatus.AVOIN || tosite.paymentStatus === PaymentStatus.ERAANTYNYT) && tosite.sum > 0
      })
    )

    this.naytaMaksettuMuuallaPeruminenNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        return tosite.paymentStatus === PaymentStatus.MAKSETTU_TOISAALLA && tosite.sum > 0
      })
    )

    this.naytaMuokkaaMaksuaNappiObservable = combineLatest([
      this.tositeObservable
    ]).pipe(
      map(([tosite]) => {
        return tosite.paymentStatus === PaymentStatus.AVOIN || tosite.paymentStatus === PaymentStatus.ERAANTYNYT
      })
    )

  }

  private _getDateOrCurrentIfInPast(date: NumberDate): LocalDate {
    if (this._dateService.compareNumberDates(date, '<', this._dateService.currentNumberDate())) {
      return this._dateService.currentLocalDate()
    }
    return this._dateService.numberToLocalDate(date)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }


  get erapvm() {
    return this.maksunTiedotForm.get('erapvm')
  }
  get tililta() {
    return this.maksunTiedotForm.get('tililta')
  }
  get maara() {
    return this.maksunTiedotForm.get('maara')
  }
  get lisatiedot() {
    return this.maksunTiedotForm.get('lisatiedot')
  }

  // lataaKuitti() {
  //   this._ladataanService.aloitaLataaminen()
  //   return this._httpService.getBinary('/kuittiLataaPdf?a=' + encodeURIComponent('/api/1/kuitit/lataa/pdf/' + this.tosite.avain), LEMONAID_CF_API).then(result => {
  //     const nimi = this._tositeService.annaPdfTiedostonNimi(this.tosite, this._translationService.nykyinenKieli, this.lahde)
  //     this._ladataanService.lopetaLataaminen()
  //     saveAs(result, nimi, { autoBom: false })
  //   }).catch(err => {
  //     this._ladataanService.lopetaLataaminen()
  //     this._errorHandler.handleError(err)
  //   })
  // }

  async peruuta() {
    const segments = await firstValueFrom(this._route.url)
    segments.pop()
    this._router.navigate(segments.map(segment => segment.path))
  }

  private annaAlkuperaiset(tosite: FirestoreTosite): FirestoreTositteenAlkuperainenTiedosto[] {
    if (tosite.alkuperaiset) {
      const alkuperaiset: FirestoreTositteenAlkuperainenTiedosto[] = []
      for (const alkuperaisenAvain of Object.keys(tosite.alkuperaiset)) {
        const alkuperainen = tosite.alkuperaiset[alkuperaisenAvain] as FirestoreTositteenAlkuperainenTiedosto
        alkuperaiset.push(alkuperainen)
      }
      return alkuperaiset
    }
    return null
  }

  async maksettuYrityksenTililta(tosite: FirestoreTosite) {

    try {
      const asiakas = await firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)
      const peruste = this._asiakasJaettuService.annaKirjanpidonPeruste(asiakas, this._dateService.currentLocalDate())

      /** Use copy since the save may be cancelled in the dialog */
      const copy = this._tositeKopioija.kopioiTosite(tosite)
      copy.paymentStatus = PaymentStatus.MAKSETTU_TOISAALLA
      copy.paymentMaksutapa = null
      const data: MaksutMaksupaivaDialogData = { kuitti: copy, kirjanpidonPeruste: peruste }

      this._dialog.open<MaksutMaksupaivaDialog, MaksutMaksupaivaDialogData>(MaksutMaksupaivaDialog, { data: data })

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    }
  }

  async maksettuOmallaRahalla(tosite: FirestoreTosite) {

    const data: MaksutMaksutapaDialogData = { kuitti: tosite, milla: 'omalla' }
    this._dialog.open(MaksutMaksutapaDialog, { data: data })

    // this._ladataanService.aloitaLataaminen()
    // try {

    //   tosite.paymentStatus = PaymentStatus.MAKSETTU_TOISAALLA
    //   await this._tositeService.tallennaKuitti(tosite)

    //   // this.navigoiSeuraavaan()

    // } catch (err) {
    //   this._errorHandler.handleError(err)
    //   this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    // } finally {
    //   this._ladataanService.lopetaLataaminen()
    // }
  }

  async maksettuToisaallaPeru(tosite: FirestoreTosite) {
    this._ladataanService.aloitaLataaminen()
    try {
      const asiakas = await firstValueFrom(this._kayttajaService.nykyinenAsiakasObservable)
      const peruste = this._asiakasJaettuService.annaKirjanpidonPeruste(asiakas, this._dateService.currentLocalDate())

      const eraantynyt = this._dateService.compareNumberDates(tosite.dueDate, '<', this._dateService.currentNumberDate())
      tosite.paymentStatus = eraantynyt ? PaymentStatus.ERAANTYNYT : PaymentStatus.AVOIN
      tosite.paymentMaksutapa = null
      tosite.paymentDate = null

      if (peruste === KirjanpidonPeruste.MAKSU) {
        const invoiceDateLocal = this._dateService.numberToLocalDate(tosite.invoiceDate)
        const invoiceDateTs = this._timestampService.localDateToTimestamp(invoiceDateLocal)

        tosite.p = tosite.invoiceDate
        tosite.pvm = invoiceDateTs
        tosite.localPvm = invoiceDateLocal
      }

      await this._tositeService.tallennaKuitti(tosite)

      // this.navigoiSeuraavaan()

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  @HostListener('document:keydown.enter', ['$event'])
  async approvePayment(event?: KeyboardEvent) {
    if (this._maksuaMuokataanSubject.value) {
      return
    }
    if (event?.target) {
      const asHtmlElement = event.target as HTMLElement
      if (asHtmlElement.tagName === 'TEXTAREA' || asHtmlElement.tagName === 'INPUT') {
        return
      }
    }

    this._ladataanService.aloitaLataaminen()

    try {

      if (this._currentViewType === 'hyvaksynta') {

        const tosite = await firstValueFrom(this.tositeObservable)
        tosite.paymentStatus = PaymentStatus.HYVAKSYTTY
        const kayttaja = await this._kayttajaService.getKayttaja()

        if (!tosite.einvoiceApprovals) {
          tosite.einvoiceApprovals = {}
        }

        tosite.einvoiceApprovals[kayttaja.avain] = {
          approved: this._timestampService.now(),
          firstName: kayttaja.etunimi,
          lastName: kayttaja.sukunimi
        }

        await this._tositeService.tallennaKuitti(tosite)

      }

      if (event) {
        this.navigoiSeuraavaan()
      }

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  async revokePaymentApproval(tosite: FirestoreTosite) {

    this._ladataanService.aloitaLataaminen()

    try {

      const kayttaja = await this._kayttajaService.getKayttaja()

      if (!tosite.einvoiceApprovals) {
        tosite.einvoiceApprovals = {}
      }

      if (tosite.einvoiceApprovals[kayttaja.avain]) {
        delete tosite.einvoiceApprovals[kayttaja.avain]
      }

      if (Object.keys(tosite.einvoiceApprovals ?? []).length < 1) {
        tosite.paymentStatus = tosite.dueDate < this._dateService.currentNumberDate() ? PaymentStatus.ERAANTYNYT : PaymentStatus.AVOIN
      }

      await this._tositeService.tallennaKuitti(tosite)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  async rejectPayment(tosite: FirestoreTosite) {
    this._ladataanService.aloitaLataaminen()

    try {

      tosite.paymentStatus = PaymentStatus.ASIAKAS_HYLKASI
      tosite.poistettu = true
      await this._tositeService.tallennaKuitti(tosite)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  async rejectPaymentPeru(tosite: FirestoreTosite) {
    this._ladataanService.aloitaLataaminen()

    try {

      if (Object.keys(tosite.einvoiceApprovals ?? []).length < 1) {
        tosite.paymentStatus = tosite.dueDate < this._dateService.currentNumberDate() ? PaymentStatus.ERAANTYNYT : PaymentStatus.AVOIN
      } else {
        tosite.paymentStatus = PaymentStatus.HYVAKSYTTY
      }
      tosite.poistettu = false
      await this._tositeService.tallennaKuitti(tosite)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  cancelPaymentDetailsChanges(tosite: FirestoreTosite) {
    this._maksuaMuokataanSubject.next(false)
    this.lisatiedot.setValue(tosite.selite)
  }

  async savePaymentDetailsChanges(tosite: FirestoreTosite) {

    if (!this.maksunTiedotForm.valid) {
      this._formValidationService.merkitseKokoLomakeKosketuksi(this.maksunTiedotForm)
      return
    }

    this._ladataanService.aloitaLataaminen()

    try {

      tosite.dueDate = this._dateService.dateToNumber(this.erapvm.value)

      const paymentSum = this.maara.value
      tosite.sum = paymentSum
      tosite.summa = paymentSum ? Math.abs(paymentSum) : null

      // tosite.selite = this.lisatiedot.value?.trim() ?? null

      await this._tositeService.tallennaKuitti(tosite)
      this.setMaksuaMuokataan(false)

    } catch (err) {
      this._errorHandler.handleError(err)
      this._snackbar.open(this._translationService.lokalisoi('yleiset.tuntematon-virhe'), 'OK', { duration: 10000 })
    } finally {
      this._ladataanService.lopetaLataaminen()
    }
  }

  private async _startMaksunTiedotForm(tosite: FirestoreTosite) {

    const ibans = await firstValueFrom(this.tililtaIbanitObservable)

    this.erapvm.setValue(this._dateService.numberToDate(tosite.dueDate))
    this.tililta.setValue(ibans?.length ? ibans[0] : null)
    this.maara.setValue(tosite.sum ?? null)
    this.lisatiedot.setValue(tosite.selite ?? null)

  }

  async tabGroupIndexChanged(event: number) {
    const valittuMaksukoodi: UserSettings['vm'] = this._mapTabsToMaksukoodit(event)

    /** Don't return, run in background  */
    firstValueFrom(this._userSettingsService.userSettingsObservable).then(settings => {
      if (settings?.vm === valittuMaksukoodi) {
        return
      }
      return this._kayttajaService.getKayttajanTiedot().then(kayttajanTiedot => {
        return this._userSettingsService.setValittuMaksukoodi(valittuMaksukoodi, kayttajanTiedot.asiakasAvain, kayttajanTiedot.uid, this._firebaseLemonaid)
      })
    })

  }

  private _mapTabsToMaksukoodit(idx: number): UserSettings['vm'] {
    if (idx === 0) { return 'qr' }
    if (idx === 1) { return 'viiva' }
    if (idx === 2) { return 'virtuaaliviiva' }
    return null
  }

  setMaksuaMuokataan(state: boolean) {
    this._maksuaMuokataanSubject.next(state)
  }

  async saveLisatiedotChange(tosite: FirestoreTosite) {

    try {
      const change = this.lisatiedot.value?.trim() ?? null
      if (change === (tosite.selite ?? null)) {
        return
      }

      tosite.selite = change

      await this._tositeService.tallennaKuitti(tosite)

      this.showLisatiedotCheckMark = true
      setTimeout(() => {
        this.showLisatiedotCheckMark = false
      }, 1500)

    } catch (err) {
      this._errorHandler.handleError(err)
    }
  }

}
