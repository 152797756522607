import { Component, OnInit, AfterViewInit, OnDestroy, ErrorHandler, ViewChild } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { DateAdapter } from '@angular/material/core'
import { MatDialog } from '@angular/material/dialog'
import { Platform } from '@angular/cdk/platform'

// import { LaskuKatseleComponentExistingData } from '../_angular/_resolvers/lasku.resolve'

import { LemonKuukausiDateAdapter } from '../_jaettu-angular/_material/LemonKuukausiDateAdapter'
import { LemonTranslationService } from '../_jaettu-angular/service/lemon-translation.service'

import { DateService } from '../_shared-core/service/date.service'
// import { LaskunAsiakas, LaskunListaustietorivi, Lasku } from '../_jaettu/model/lasku'

// import { LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialog, LaskuHaluatkoVarmastiPeruaMaksetuksiMerkitsemisenDialogData } from './dialogit/lasku.haluatko-varmasti-perua-maksetuksi-merkitsemisen.dialog'

import { BehaviorSubject, Observable, Subject, combineLatest, distinctUntilChanged, map, of as observableOf, switchMap, takeUntil } from 'rxjs'

import { MaksutFirestoreDataSource } from './maksut.firestore.datasource'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { CurrencyService } from 'app/_shared-core/service/currency.service'
import { FirestoreIndeksoija } from 'app/_jaettu/service/firestore.indeksoija'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'
import { VersionTarkistusPalvelu } from 'app/_angular/service/version-tarkistus.palvelu'
import { FirestoreTosite, PaymentStatus, SAHKOISET_LASKUT_MAKSUTAPA } from 'app/_jaettu/model/tosite'
import { LoadingConfig } from 'app/_jaettu-angular/_components/loading.component'
import { lemonShare } from 'app/_jaettu-angular/_rxjs/lemon-share.operator'
import { MatCheckboxChange } from '@angular/material/checkbox'
import { FileSystemFileEntry, NgxFileDropEntry } from 'ngx-file-drop'
import { TositeLuoUusiComponentData } from 'app/_angular/_resolvers/tosite.resolve'
import { TiedostojenLataamisService } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { MaksuKatseleComponentDataResolve, MaksuKatseleComponentExistingData, MaksuLuoUusiComponentDataResolve } from 'app/_angular/_resolvers/maksu.resolve'
import { BankBalancePublicUriService } from 'app/_jaettu/service/bank-balance-public-uri.service'
import { LatestBankBalance } from 'app/_jaettu/model/tiliote'
import { MaksutVahvistusData } from 'app/tunnistautuminen/nets/maksujen-vahvistus-pankit.component'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BicService } from 'app/_shared-core/service/bic.service'
import { ApixReceivedInvoiceConfig } from 'app/_jaettu/model/apix'
import { MaksutSharedStateService } from './maksut-shared-state.service'
import { MatPaginator } from '@angular/material/paginator'
import { AsiakasJaettuLemonaidService } from '../_jaettu/service/asiakas-jaettu-lemonaid.service'
import { KirjanpidonPeruste } from 'app/_jaettu/model/kayttaja'

// import { LaskuTaydennaAsetuksetDialog } from './dialogit/lasku-taydenna-asetukset.dialog'
// import { LaskuKommenttiDialog, LaskuKommenttiDialogData } from './dialogit/lasku-kommentti.dialog'

// export type TuoteProperties = 'nro' | 'asiakas' | 'pvm' | 'erapvm' | 'summa' | 'avoinna' | 'tila' | 'merkitse' | undefined


type ViewType = 'vastaanotto' | 'hyvaksynta' | 'maksaminen'
type ViewTab = 'avoimet' | 'maksetut'

interface MaksuFormGroup {
  config: FormControl<NaytettavaConfig>
}

interface YhteensaRivi {
  valuutta: string
  yhteensa: number
}

interface NaytettavaConfig {
  avain: string
  pankki: string
  iban: string
}

interface NakymanAsetukset {
  naytaListaus: boolean
}

@Component({
  templateUrl: './maksut.component.html',
  styleUrls: ['./maksut.component.css'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    { provide: DateAdapter, useClass: LemonKuukausiDateAdapter, deps: [ErrorHandler, DateService, Platform, LemonTranslationService] },
  ]
})
export class MaksutComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('fileInput', { static: true }) fileInput

  // @ViewChild('laskutaulu', { static: true }) laskutaulu: ElementRef
  @ViewChild('paginator', { static: false, read: MatPaginator })
  set paginator(p: MatPaginator) {
    if (p) {
      if (this.dataSource?.page) {
        p.pageIndex = this.dataSource.page.pageIndex
        p.pageSize = this.dataSource.page.pageSize
      }
      p.page.pipe(
        takeUntil(this._ngUnsubscribe)
      ).subscribe(page => {
        this.dataSource.page = page
      })
    }
  }
  // @ViewChild(MatSort, { static: true }) sort: MatSort

  private _ngUnsubscribe = new Subject<void>()
  // private _taydennaAsetuksetDialogi: MatDialogRef<LaskuTaydennaAsetuksetDialog> = null
  // private _kommenttiDialogi: MatDialogRef<LaskuKommenttiDialog> = null

  listFormGroup: FormGroup<MaksuFormGroup>
  configCtrl = new FormControl<NaytettavaConfig>(null, Validators.required)

  // propertiesToDisplay: TuoteProperties[] = []
  // laskunTilatObservable: Observable<LaskunLokalisoituTila[]>
  // tableViewOptionsObservable: Observable<{ showAddButton: boolean, showCommentsColumn: boolean }>

  nakyvatRivitObservable: Observable<FirestoreTosite[]>
  yhteensaRivitObservable: Observable<YhteensaRivi[]>
  kokonaismaaraObservable: Observable<{ maara: number }>
  nakymanAsetuksetObservable: Observable<NakymanAsetukset>

  private _valitutTositteetSubject: BehaviorSubject<FirestoreTosite[]> = new BehaviorSubject([])
  private _valitutTositteetObservable: Observable<FirestoreTosite[]> = this._valitutTositteetSubject.asObservable()
  valittujenTositteidenSummaObservable: Observable<number> = this._valitutTositteetObservable.pipe(
    map(tositteet => tositteet?.map(tosite => tosite.sum).reduce((partialSum, a) => partialSum + a, 0) || 0)
  )
  valittujenTositteidenMaaraObservable: Observable<number> = this._valitutTositteetObservable.pipe(
    map(tositteet => tositteet?.length || 0)
  )
  latestBankBalanceObservable: Observable<LatestBankBalance>

  naytaEiYhtaanObservable: Observable<boolean>
  lataaObservable: Observable<boolean>
  paymentConfigsObservable: Observable<NaytettavaConfig[]>
  naytaLisaaButtonObservable: Observable<boolean>
  // name = 'asdf' + Math.random()

  countToAvoinna: number = 0
  countToTilinSaldo: number = 0
  countToEraantynyt: number = 0
  avoinnaTo: number = 0
  avoinnaFrom: number = 0
  eraantynytTo: number = 0
  eraantynytFrom: number = 0
  tilinSaldoFrom: number = 0
  valuutta: string = 'EUR'
  selectedTabObservable: BehaviorSubject<ViewTab> = new BehaviorSubject('avoimet')

  // private _alustaSummat = true
  // private _pauser = new Subject<boolean>()

  loadingSettings: LoadingConfig = {
    fullScreenBackdrop: false,
    backdropBackgroundColour: 'rgba(0,0,0,0.15)',
    backdropBorderRadius: '4px',
    primaryColour: 'rgba(255, 255, 255, 1)',
    secondaryColour: 'rgba(255, 255, 255, 1)', // rgba(252, 238, 32, .15)
    tertiaryColour: 'rgba(255, 255, 255, 1)'
  }

  private _selectedMaksut: Map<string, FirestoreTosite> = new Map()
  private _dataSource: MaksutFirestoreDataSource
  viewTypeObservable: Observable<ViewType>

  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _errorHandler: ErrorHandler,
    private _dialog: MatDialog,
    // private _laskuSummatService: LaskuSummatService,
    // private _windowSizeService: WindowSizeService,
    // private _dateAdapter: DateAdapter<Date>,
    private _versionTarkistaja: VersionTarkistusPalvelu,
    private _tiedostojenLataamisService: TiedostojenLataamisService,
    private _kayttajaService: KayttajaService,
    private _lemonTranslationService: LemonTranslationService,
    private _currencyService: CurrencyService,
    private _dateService: DateService,
    private _firestoreIndeksoija: FirestoreIndeksoija,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _luoComponentDataResolve: MaksuLuoUusiComponentDataResolve,
    private _katseleComponentDataResolve: MaksuKatseleComponentDataResolve,
    private _bankBalancePublicUriService: BankBalancePublicUriService,
    private _bicService: BicService,
    private _maksutSharedStateService: MaksutSharedStateService,
    private _asiakasJaettuLemonaidService: AsiakasJaettuLemonaidService
  ) {
    this._dataSource = this._maksutSharedStateService.getDataSource()
    this._dataSource.unLockResultSet()
    this._dataSource.markAsInitialized()
    this._dataSource.tilat = this._annaAvoimetTilat()
    this.kokonaismaaraObservable = this._dataSource.rivienKokonaismaaraObservable.pipe(
      map(m => { return { maara: m } })
    )
    this.lataaObservable = this._dataSource.lataaObservable
    this.naytaEiYhtaanObservable = combineLatest([this._dataSource.rivienKokonaismaaraObservable, this._dataSource.lataaObservable]).pipe(
      map(([rivienMaara, lataa]) => {
        if (lataa) {
          return false
        }
        return rivienMaara < 1
      })
    )
  }

  private _annaAvoimetTilat(): PaymentStatus[] {
    return [PaymentStatus.AVOIN, PaymentStatus.ERAANTYNYT, PaymentStatus.MAKSUSSA, PaymentStatus.PANKKI_HYLKASI]
  }

  tabChanged(idx: number) {
    if (idx === 0) {
      this.vaihdaValilehtiAvoimiin()
    } else if (idx === 1) {
      this.vaihdaValilehtiMaksettuihin()
    }
  }

  vaihdaValilehtiAvoimiin() {
    if (this.selectedTabObservable.value !== 'avoimet') {
      this.selectedTabObservable.next('avoimet')
      this.dataSource.tilat = this._annaAvoimetTilat()
    }
  }

  vaihdaValilehtiMaksettuihin() {
    if (this.selectedTabObservable.value !== 'maksetut') {
      this.selectedTabObservable.next('maksetut')
      this.dataSource.tilat = [PaymentStatus.ASIAKAS_HYLKASI, PaymentStatus.MAKSETTU, PaymentStatus.MAKSETTU_TOISAALLA]
    }
  }

  ngOnInit() {

    this.viewTypeObservable = combineLatest([
      this._kayttajaService.nykyinenAsiakasObservable,
      this._kayttajaService.kayttajaObservable
    ]).pipe(
      map(([asiakas, kayttaja]) => {
        if (asiakas?.paymentsNeedsApproval && kayttaja?.roolit?.MAKSUT_HYVAKSYJA) {
          return 'hyvaksynta'
        }
        if (kayttaja?.roolit?.MAKSUT_MAKSAJA) {
          return 'maksaminen'
        }
        return 'vastaanotto'
      })
      // ,
      // tap<ViewType>(viewType => this._currentViewType = viewType)
    )

    this.naytaLisaaButtonObservable = combineLatest([
      this.viewTypeObservable,
      this.selectedTabObservable,
      this._kayttajaService.nykyinenAsiakasObservable
    ]).pipe(
      map(([viewType, selectedTab, asiakas]) => {
        if (!asiakas || !selectedTab) {
          return false
        }
        if (selectedTab === 'avoimet') {
          const peruste = this._asiakasJaettuLemonaidService.annaKirjanpidonPeruste(asiakas, this._dateService.currentLocalDate())
          if (peruste === KirjanpidonPeruste.LASKU) {
            return true
          }
          // if (viewType === 'maksaminen') {
          //   return true
          // }
        }
        return false
      })
    )

    const apixConfigObservable = this._kayttajaService.kayttajaObservable.pipe(
      switchMap(kayttaja => {
        if (!kayttaja) {
          return observableOf<ApixReceivedInvoiceConfig>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>('customers/' + kayttaja.asiakasAvain + '/apix-received-invoice-config/' + kayttaja.asiakasAvain).listen()
      })
    )

    this.nakymanAsetuksetObservable = apixConfigObservable.pipe(
      map(config => {
        const val: NakymanAsetukset = {
          naytaListaus: config && !!config.paymentReceiveIsActive
        }
        return val
      })
    )

    this.paymentConfigsObservable = this._kayttajaService.nykyinenAsiakasObservable.pipe(
      map(asiakas => {
        if (asiakas?.paymentConfigs) {
          return asiakas.paymentConfigs.map(cnfg => {
            const naytettava: NaytettavaConfig = {
              avain: cnfg.avain,
              iban: cnfg.iban,
              pankki: this._bicService.annaPankinNimi(cnfg.iban)
            }
            return naytettava
          })
        }
        return []
      })
    )

    this._versionTarkistaja.sovelluksenVersioObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(versiotiedot => {
      this._versionTarkistaja.tarkistaVersio(versiotiedot)
    })

    const asiakasObservable = this._kayttajaService.nykyinenAsiakasObservable.pipe(
      distinctUntilChanged((a, b) => a?.asiakasAvain === b?.asiakasAvain)
    )

    this.latestBankBalanceObservable = combineLatest([asiakasObservable, this.configCtrl.valueChanges]).pipe(
      switchMap(([asiakas, naytettavaConfig]) => {
        if (!asiakas || !naytettavaConfig) {
          return observableOf<LatestBankBalance>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<LatestBankBalance>(this._bankBalancePublicUriService.getLatestBankBalanceUri(asiakas.asiakasAvain, naytettavaConfig.iban)).listen()
      })

    )

    // this.asetaListauksenKolumnit()

    // this.translationService.currentLanguageObservable.pipe(
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(kieli => {
    //   this.dateAdapter.setLocale(kieli)
    // })

    // this.laskuService.asetuksetObservable.pipe(
    //   takeUntil(this.ngUnsubscribe)
    // ).subscribe(asetukset => {
    //   if (asetukset && !asetukset.summahuomautusPiilotettu) {
    //     this.naytaHuomautus = true
    //   } else {
    //     this.naytaHuomautus = false
    //   }
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    // this._pauser.pipe(
    //   switchMap(aktiivinen => {
    //     return iif(() => aktiivinen, this.laskuSummatService.summatObservable, EMPTY)
    //   }),
    //   throttleTime(1100, undefined, { leading: true, trailing: true }),
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(summat => {
    //   // console.log('summat', JSON.stringify(summat))
    //   if (summat) {

    //     // Mikä valuutta
    //     this.valuutta = summat.valuutta

    //     // Mistä rullataan
    //     this.avoinnaFrom = summat.avoinnaAlkuperainen
    //     this.eraantynytFrom = summat.eraantynytAlkuperainen

    //     // Mihin rullataan
    //     if (this._alustaSummat) {
    //       this._alustaSummat = false
    //       setTimeout(() => {
    //         this.avoinnaTo = summat.avoinna
    //         this.eraantynytTo = summat.eraantynyt
    //       }, 150)
    //     } else {
    //       this.avoinnaTo = summat.avoinna
    //       this.eraantynytTo = summat.eraantynyt
    //     }

    //   }
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })
    // this._pauser.next(true)

    // this.laskunTilatObservable = this.dataSource.laskunTilatObservable
    // this.tableViewOptionsObservable = this.kayttajaService.kayttajanTiedotObservable.pipe(
    //   map(kayttaja => {
    //     return { showAddButton: kayttaja?.asiakasId !== '9', showCommentsColumn: kayttaja?.asiakasId === '9' }
    //   }),
    //   startWith({ showAddButton: true, showCommentsColumn: false })
    // )

    this.listFormGroup = new FormGroup<MaksuFormGroup>({
      'config': this.configCtrl
    })

    this.paymentConfigsObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(configs => {
      if (!this.configCtrl.value && configs?.length > 0) {
        // console.log('SET', configs[0])
        this.configCtrl.setValue(configs[0])
      }
    })

    // this.form = new UntypedFormGroup({
    //   'nimiFirestore': new UntypedFormControl(null, []),
    //   'vuosikk': new UntypedFormControl(null, []),
    //   // 'vuosikkkohde': new FormControl( vuosiKk.kohde, [] ),
    //   'tila': new UntypedFormControl(null, [])
    // })

    // Jos hakutiedot muuttuvat, muuta käyttöliittymää sen mukaan
    // this.dataSource.hakutiedotObservable.pipe(
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(hakutiedot => {

    //   if (this.form.get('nimiFirestore').value !== hakutiedot.hakukriteerit.vapaahaku) {
    //     this.form.get('nimiFirestore').setValue(hakutiedot.hakukriteerit.vapaahaku, { emitModelToViewChange: true, emitViewToModelChange: false })
    //   }

    //   this._setKk(hakutiedot.hakukriteerit)
    //   // if (this.form.get('vuosikkkohde').value !== hakutiedot.hakukriteerit.vuosikk.kohde) {
    //   // this.form.get('vuosikkkohde'.setValue(vuosiKk.kohde, { emitModelToViewChange: true, emitViewToModelChange: false })
    //   // }
    //   if (this.form.get('tila').value !== hakutiedot.hakukriteerit.tila) {
    //     this.form.get('tila').setValue(hakutiedot.hakukriteerit.tila, { emitModelToViewChange: true, emitViewToModelChange: false })
    //   }
    // })

    // this.form.get('vuosikkkohde').valueChanges.subscribe((value: 'p' | 'e') => {
    //   this.dataSource.vuosiKkKohde = value
    // })
    // this.form.get('vuosikk').valueChanges.subscribe((value: Date) => {
    //   console.log('asetettu vuosikk; ', value)
    //   if (value) {
    //     this.dataSource.setVuosiKk(value.getFullYear(), value.getMonth())
    //   } else {
    //     this.dataSource.setVuosiKk(null, null)
    //   }
    // })
    // this.form.get('tila').valueChanges.subscribe((value: LaskunTila) => {
    //   this.dataSource.tila = value
    // })
    // this.form.get('nimiFirestore').valueChanges.pipe(
    //   debounceTime(500)
    // ).subscribe((value: string) => {
    //   if (value) {
    //     const val = this.firestoreIndeksoija.poistaValimerkitJaValilyonnit(value)
    //     if (val.length > 2) {
    //       this.dataSource.search = value
    //     } else {
    //       this.dataSource.search = null
    //     }
    //   } else {
    //     this.dataSource.search = null
    //   }
    // })

    // const currentSort = this.dataSource.sort
    // this.sort.sort({
    //   id: currentSort.active,
    //   disableClear: true,
    //   start: currentSort.direction === 'asc' ? 'asc' : 'desc'
    // })
    // this.sort.sortChange.subscribe(sort => {
    //   this.dataSource.sort = sort
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    // this.route.data.pipe(
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe((data: { data: LaskutComponentData }) => {
    //   if (data && data.data && data.data.nollaaHakuasetukset) {
    //     this.dataSource.resetSearchToDefaults()
    //   }
    // }, error => {
    //   this.errorHandler.handleError(error)
    // })

    this.nakyvatRivitObservable = this.dataSource.connect()
    this.yhteensaRivitObservable = this.nakyvatRivitObservable.pipe(
      map(tositteet => {
        const valuuttaMap: { [valuuttaKey: string]: YhteensaRivi } = {}
        for (const tosite of tositteet) {
          const curr = tosite.currency ?? 'EUR'
          let valuutanYhteensaRivi = valuuttaMap[curr]
          if (!valuutanYhteensaRivi) {
            valuutanYhteensaRivi = {
              yhteensa: 0,
              valuutta: curr
            }
            valuuttaMap[curr] = valuutanYhteensaRivi
          }
          valuutanYhteensaRivi.yhteensa += tosite.sum
        }
        return Object.keys(valuuttaMap).map(a => valuuttaMap[a]).sort((a, b) => { return a.yhteensa - b.yhteensa })
      }),
      lemonShare()
    )
    this.nakyvatRivitObservable.pipe(
      map(tositteet => {
        let sum = 0
        for (const tosite of tositteet) {
          if (tosite.paymentStatus === PaymentStatus.ERAANTYNYT) {
            sum += tosite.sum
          }
        }
        return sum
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(amount => this.eraantynytTo = amount)
    this.nakyvatRivitObservable.pipe(
      map(tositteet => {
        let sum = 0
        for (const tosite of tositteet) {
          if (tosite.paymentStatus === PaymentStatus.AVOIN || tosite.paymentStatus === PaymentStatus.MAKSUSSA) {
            sum += tosite.sum
          }
        }
        return sum
      }),
      takeUntil(this._ngUnsubscribe)
    ).subscribe(amount => this.avoinnaTo = amount)

  }

  compareConfigs(o1: NaytettavaConfig, o2: NaytettavaConfig): boolean {
    if (o1 && o2) {
      // console.log(o1, o2)
      return o1.avain === o2.avain
    }
    return false
  }

  maksuSelectionChanged(tosite: FirestoreTosite, event: MatCheckboxChange) {
    if (event.checked) {
      this._selectedMaksut.set(tosite.avain, tosite)
    } else {
      this._selectedMaksut.delete(tosite.avain)
    }
    this._valitutTositteetSubject.next(Array.from(this._selectedMaksut.values()))
  }

  // private _setKk(hakukriteerit: Hakukriteerit) {
  //   const vuosiKk2 = hakukriteerit.vuosikk
  //   if (
  //     vuosiKk2 &&
  //     vuosiKk2.kk !== null &&
  //     vuosiKk2.kk !== undefined &&
  //     vuosiKk2.vuosi !== null &&
  //     vuosiKk2.vuosi !== undefined
  //   ) {
  //     const date2 = new Date(vuosiKk2.vuosi, vuosiKk2.kk, 1)
  //     if (!this.form.get('vuosikk').value) {
  //       this.form.get('vuosikk').setValue(date2, { emitModelToViewChange: true, emitViewToModelChange: false })
  //     } else {
  //       const existingDate = this.form.get('vuosikk').value as Date
  //       if (existingDate.getFullYear() !== date2.getFullYear() || existingDate.getMonth() !== date2.getMonth()) {
  //         this.form.get('vuosikk').setValue(date2, { emitModelToViewChange: true, emitViewToModelChange: false })
  //       }
  //     }
  //   } else {
  //     if (this.form.get('vuosikk').value !== null) {
  //       this.form.get('vuosikk').setValue(null, { emitModelToViewChange: true, emitViewToModelChange: false })
  //     }
  //   }
  // }

  get dataSource(): MaksutFirestoreDataSource {
    return this._dataSource
  }

  // chosenMonthHandler(normalizedMonth: Date, datepicker: MatDatepicker<Date>) {
  //   this.form.get('vuosikk').setValue(normalizedMonth)
  //   datepicker.close()
  // }

  ngAfterViewInit() {
    // this.windowSizeService.sizeObservable.pipe(
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(koko => {
    //   setTimeout(() => {
    //     this.asetaListauksenKolumnit()
    //   }, 250)
    // })
  }

  private asetaListauksenKolumnit() {

    // this.naytaNro = true
    // this.naytaPvm = true
    // this.naytaSumma = true

    // const leveys = this.laskutaulu.nativeElement.offsetWidth
    // // if (tablenKoko < 500) {
    // //   this.propertiesToDisplay = [               'asiakas',         'erapvm',         'avoinna', 'merkitse']
    // // } else
    // if (leveys < 600) {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'erapvm', 'avoinna', 'merkitse']
    //   // this.naytaNro = false
    //   this.naytaPvm = false
    //   this.naytaSumma = false
    // } else if (leveys < 700) {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'erapvm', 'summa', 'avoinna', 'merkitse']
    //   // this.naytaNro = false
    //   this.naytaPvm = false
    // } else if (leveys < 800) {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'pvm', 'erapvm', 'summa', 'avoinna', 'merkitse']
    //   // this.naytaNro = false
    // } else {
    //   this.propertiesToDisplay = ['tila', 'nro', 'asiakas', 'pvm', 'erapvm', 'summa', 'avoinna', 'merkitse']
    // }
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  onCountToEnd() {
    // this.laskuSummatService.numerotRullattiin()
  }

  async katsele(rivi: FirestoreTosite) {

    // if (this.selectedTabObservable.value === 'avoimet') {
    await this.dataSource.lockResultSet()
    // }

    const existing: MaksuKatseleComponentExistingData = {
      tosite: rivi
    }
    this._katseleComponentDataResolve.asetaOlemassaolevaData(existing)
    this._router.navigate(['/maksut/', rivi.avain])

  }

  kuvavirheAvain: string = null
  kuvavirheParametrit: any = null
  private poistaFunktio: any = null
  private async tiedostoVoidaanLadata(tiedostot: NgxFileDropEntry[]): Promise<boolean> {

    this.kuvavirheAvain = ''
    this.kuvavirheParametrit = {}
    // if (kuvienMaara > 1) {
    //   this.kuvavirheAvain = 'laskuasetukset.logo.virheet.vain-yksi-kerrallaan'
    //   setTimeout(() => {
    //     this.kuvavirheAvain = ''
    //   }, 10000)
    //   return false
    // }

    if (tiedostot.length < 1) {
      if (this.poistaFunktio) {
        clearTimeout(this.poistaFunktio)
      }
      this.kuvavirheAvain = 'kuitit.lataaminen.virheet.ei-tiedosto'
      this.poistaFunktio = setTimeout(() => {
        this.kuvavirheAvain = ''
      }, 15000)
      return false
    }

    const supportedImageTypes = ['jpeg', 'jpg', 'png', 'webp', 'tiff', 'gif', 'svg', 'pdf', 'heic', 'heif']
    for (const tiedosto of tiedostot) {

      const file = tiedosto.fileEntry as FileSystemFileEntry

      const fileEnding = this._tiedostojenLataamisService.getFileEndingFromFileName(file.name)
      const fileSize = file ? await this._tiedostojenLataamisService.getFileSize(file) : -1

      // console.log(fileSize, file)

      if (!fileEnding || supportedImageTypes.indexOf(fileEnding.toLowerCase()) < 0) {
        if (this.poistaFunktio) {
          clearTimeout(this.poistaFunktio)
        }
        this.kuvavirheParametrit = {
          tuetutMuodot: supportedImageTypes.join(', ')
        }
        this.kuvavirheAvain = 'kuitit.lataaminen.virheet.vaara-muoto'
        this.poistaFunktio = setTimeout(() => {
          this.kuvavirheAvain = ''
        }, 15000)
        return false
      }

      const maxKoko = 25 * 1024 * 1024
      if (fileSize > maxKoko) {
        if (this.poistaFunktio) {
          clearTimeout(this.poistaFunktio)
        }
        const kokoMegatavuissaLokalisoitu = this._currencyService.formatoiDesimaali((fileSize / 1024 / 1024), 2, this._lemonTranslationService.nykyinenKieli)
        const maxKokoLokalisoitu = this._currencyService.formatoiDesimaali((maxKoko / 1024 / 1024), 2, this._lemonTranslationService.nykyinenKieli)
        this.kuvavirheParametrit = {
          kokoMax: maxKokoLokalisoitu,
          kokoNyt: kokoMegatavuissaLokalisoitu
        }
        this.kuvavirheAvain = 'kuitit.lataaminen.virheet.liian-suuri'
        this.poistaFunktio = setTimeout(() => {
          this.kuvavirheAvain = ''
        }, 15000)
        return false
      }

    }

    return true

  }

  naytaTiedostonValintaDialogi() {
    this.fileInput.nativeElement.click()
  }

  async fileChanged(event) {

    const list: FileList = event.target.files
    const tiedostot: NgxFileDropEntry[] = this._tiedostojenLataamisService.fileListToNgxFileDropEntries(list)

    const voidaanLadata = await this.tiedostoVoidaanLadata(tiedostot)
    if (!voidaanLadata) {
      return
    }
    this.siirryLuomiseen(tiedostot)
  }

  private siirryLuomiseen(tiedostot: NgxFileDropEntry[]) {
    const data: TositeLuoUusiComponentData = {
      tiedostot: tiedostot,
      maksutapa: SAHKOISET_LASKUT_MAKSUTAPA
    }
    this._luoComponentDataResolve.asetaOlemassaolevaData(data)
    this._router.navigate(['/maksut/uusi'])
  }

  // lisaa() {

  // this.laskuService.asetuksetObservable.pipe(
  //   take(1)
  // ).subscribe(asetukset => {
  //   if (asetukset) {
  //     if (this.asetustenOletuksetService.tarkistaPuuttuukoLaskuasetuksia(asetukset)) {
  //       this.naytaTaydennaAsetuksetDialogi()
  //     } else {
  //       // Sulje dialogi, jos sellainen oli
  //       if (this._taydennaAsetuksetDialogi) {
  //         this._taydennaAsetuksetDialogi.close()
  //         this._taydennaAsetuksetDialogi = null
  //       }
  //       this.router.navigate(['/laskutus/laskut/', 'uusi', 'uusi'])
  //     }
  //   }
  // })

  // }

  // private naytaTaydennaAsetuksetDialogi() {
  //   if (!this._taydennaAsetuksetDialogi) {
  //     const kaytettavaLeveys = window.innerWidth
  //     const kaytettavaKorkeus = window.innerHeight
  //     const leveys = kaytettavaLeveys > 900 ? '90vw' : '98vw'
  //     const korkeus = kaytettavaKorkeus > 1100 ? '80vh' : kaytettavaKorkeus > 750 ? '85vh' : '92vh'

  //     const settings: MatDialogConfig = {
  //       maxWidth: '900px',
  //       maxHeight: korkeus,
  //       width: leveys,
  //       data: {
  //         mobiili: kaytettavaLeveys < 670
  //       }
  //     }

  //     this._taydennaAsetuksetDialogi = this.dialog.open(LaskuTaydennaAsetuksetDialog, settings)
  //     this._taydennaAsetuksetDialogi.afterClosed().subscribe(result => {
  //       this._taydennaAsetuksetDialogi = null
  //       if (result === 'onnistui') {
  //         this.router.navigate(['/laskutus/laskut/', 'uusi', 'uusi'])
  //       }
  //     })
  //   }
  // }

  vertaaYhtaSuuret(yksi: number, kaksi: number): boolean {
    return Math.round(yksi * 100) === Math.round(kaksi * 100)
  }

  vertaaEriSuuret(yksi: number, kaksi: number): boolean {
    return Math.round(yksi * 100) !== Math.round(kaksi * 100)
  }

  vertaaEkaPienempiKuinToka(pienempi: number, suurempi: number): boolean {
    return Math.round(pienempi * 100) < Math.round(suurempi * 100)
  }

  /**
   * Maksut tallennetaan ensi FirestoreTositteiksi.
   * Käyttäjä valitsee haluamansa maksut ja aloittaa vahvan tunnistautumisen.
   * Aloittamisen yhteydessä kaikki valitut maksut kirjoitetaan tietokantaan.
   * (Vain näiden avaimet)
   *
   * Kun vahva tunnistautuminen on onnistunut, kirjoitetaan nämä maksut työjonoon,
   * joka tekee varsinaiset maksut payments-projektiin.
   */
  maksaHeti() {
    const data: MaksutVahvistusData = {
      maksut: Array.from(this._selectedMaksut.keys()),
      dueType: 'heti',
      paymentConfigId: 'asdf'
    }
    this._router.navigate(['maksut/vahvista'], { state: data, skipLocationChange: true })
  }

  maksaErapaivana() {
    const data: MaksutVahvistusData = {
      maksut: Array.from(this._selectedMaksut.keys()),
      dueType: 'erapaivana',
      paymentConfigId: 'asdfds'
    }
    this._router.navigate(['maksut/vahvista'], { state: data, skipLocationChange: true })
  }

}
