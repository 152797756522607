import { CurrencyService } from '../../_shared-core/service/currency.service'
import { LaskunTyypit, LaskunTyyppi, EuVatSpecMap, LaskunAlv } from '../../_jaettu/model/lasku'

export interface EuVatSpecMapProvider {
  provide(): Promise<EuVatSpecMap>
}

export abstract class AlvBaseService {

  constructor(
    protected _currencyService: CurrencyService
  ) { }

  public async annaLaskutyypinAlvt(laskunTyyppi: LaskunTyyppi, maaKoodi: string, euVatSpecMapProvider: EuVatSpecMapProvider): Promise<LaskunAlv[]> {
    if (laskunTyyppi.avain === LaskunTyypit.TAVALLINEN.avain) {
      return Promise.resolve<LaskunAlv[]>([
        { prosentti: 0, tunniste: 'suomi0' },
        { prosentti: 10, tunniste: 'suomi10' },
        { prosentti: 14, tunniste: 'suomi14' },
        { prosentti: 24, tunniste: 'suomi24', d: 1 }
      ])
    } else if (laskunTyyppi.avain === LaskunTyypit.EU_TAVARA.avain) {
      return Promise.resolve<LaskunAlv[]>([
        { prosentti: 0, tunniste: 'yht', d: 1 }
      ])
    } else if (laskunTyyppi.avain === LaskunTyypit.EU_PALVELU.avain) {
      return Promise.resolve<LaskunAlv[]>([
        { prosentti: 0, tunniste: 'yht', d: 1 }
      ])
    } else if (
      laskunTyyppi.avain === LaskunTyypit.EU_KULUTTAJA.avain ||
      laskunTyyppi.avain === LaskunTyypit.EU_KULUTTAJA_EI_REKISTEROITYNYT.avain
    ) {
      return Promise.resolve<LaskunAlv[]>([
        { prosentti: 0, tunniste: 'suomi0' },
        { prosentti: 10, tunniste: 'suomi10' },
        { prosentti: 14, tunniste: 'suomi14' },
        { prosentti: 24, tunniste: 'suomi24', d: 1 }
      ])
    } else if (
      laskunTyyppi.avain === LaskunTyypit.EU_KULUTTAJA_PALVELU.avain ||
      laskunTyyppi.avain === LaskunTyypit.EU_KULUTTAJA_TAVARA.avain
    ) {
      if (!maaKoodi) { return [] }
      const alvMap = await euVatSpecMapProvider.provide()
      const alvtForCountry = alvMap[maaKoodi]
      if (!alvtForCountry?.length) {
        console.error('No rates found for ' + maaKoodi)
        throw new Error('No rates found for ' + maaKoodi)
      }
      // console.log('alvtForCountry', alvtForCountry)
      const alvt: LaskunAlv[] = alvtForCountry.map(rate => {
        const ret: LaskunAlv = {
          prosentti: rate.p,
          tunniste: this._annaEtamyyntiTunniste(maaKoodi, rate.p)
        }
        if (rate.d) { ret.d = 1 }
        return ret
      })
      return Promise.resolve<LaskunAlv[]>(alvt)
    } else if (laskunTyyppi.avain === LaskunTyypit.RAKENNUSALA.avain) {
      return Promise.resolve<LaskunAlv[]>([{ prosentti: 0, tunniste: 'rak', d: 1 }])
    } else if (laskunTyyppi.avain === LaskunTyypit.MUU_MAAILMA.avain) {
      return Promise.resolve<LaskunAlv[]>([{ prosentti: 0, tunniste: 'eieu', d: 1 }])
    }
    throw new Error('Unknown laskun tyyppi ' + laskunTyyppi.avain)
  }

  protected _annaEtamyyntiTunniste(maaKoodi: string, prosentti: number) {
    return 'k_' + maaKoodi.toLowerCase() + '_' + this._currencyService.formatoiDesimaaliSailytaNollat(prosentti, 2, 'en')
  }

  puraEtamyyntiTunnisteTaiNull(tunniste: string): { maakoodi: string, prosentti: number } | null {
    if (tunniste?.startsWith('k_')) {
      const maakoodi = tunniste.substr(2, 3).toUpperCase()
      const prosenttiString = tunniste.substr(tunniste.lastIndexOf('_') + 1)
      return {
        maakoodi: maakoodi,
        prosentti: this._currencyService.muutaMerkkijonoNumeroksi(prosenttiString, 2)
      }
    }
    return null
  }

  puraEtamyyntiTunniste(tunniste: string): { maakoodi: string, prosentti: number } {
    if (!tunniste?.startsWith('k_')) { throw new Error('Annettu tunniste "' + tunniste + '" ei ole etämyyntitunniste!') }
    const maakoodi = tunniste.substr(2, 3).toUpperCase()
    const prosenttiString = tunniste.substr(tunniste.lastIndexOf('_') + 1)
    return {
      maakoodi: maakoodi,
      prosentti: this._currencyService.muutaMerkkijonoNumeroksi(prosenttiString, 2)
    }
  }

}
