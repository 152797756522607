import { CountryLocalizationService } from '../translation.service'
import { LaskuSharedService } from './lasku-shared.service'
import { TuettuKieli } from '../../../_shared-core/model/common'

import { Lasku, LaskuBase, Laskuasetukset, LaskunSahkopostipohja, LaskuasetuksetSpostille } from '../../model/lasku'

export class LaskuSpostiService {

  private templates = {}

  annaSahkopostipohjanPostmarkNumero(pohja: LaskunSahkopostipohja, kieli: TuettuKieli): number {
    const template = this.templates[pohja]
    if (template) {
      const id = template[kieli]
      if (id) {
        return id
      }
    }
    return 8372593
  }

  constructor(
    private shared: LaskuSharedService,
    private maaService: CountryLocalizationService
  ) {
    this.templates[LaskunSahkopostipohja.PERINTEINEN] = {}
    this.templates[LaskunSahkopostipohja.PERINTEINEN]['fi'] = 3935461
    this.templates[LaskunSahkopostipohja.PERINTEINEN]['en'] = 4686262
    this.templates[LaskunSahkopostipohja.PERINTEINEN]['sv'] = 0

    this.templates[LaskunSahkopostipohja.MUISTUTUS_PERINTEINEN] = {}
    this.templates[LaskunSahkopostipohja.MUISTUTUS_PERINTEINEN]['fi'] = 4593684
    this.templates[LaskunSahkopostipohja.MUISTUTUS_PERINTEINEN]['en'] = 4686261
    this.templates[LaskunSahkopostipohja.MUISTUTUS_PERINTEINEN]['sv'] = 0

    this.templates[LaskunSahkopostipohja.JATKUVALASKUTUS_PERINTEINEN] = {}
    this.templates[LaskunSahkopostipohja.JATKUVALASKUTUS_PERINTEINEN]['fi'] = 8372593
    this.templates[LaskunSahkopostipohja.JATKUVALASKUTUS_PERINTEINEN]['en'] = 8372593
    this.templates[LaskunSahkopostipohja.JATKUVALASKUTUS_PERINTEINEN]['sv'] = 8372593
  }

  korvaaMuuttujat(korvattava: string, asetukset: Laskuasetukset, juurilasku: Lasku, kasiteltava: LaskuBase): string {

    if (korvattava) {
      korvattava = korvattava.replace(/{{yrityksen_nimi}}/g, asetukset.nimi)
      korvattava = korvattava.replace(/{{ytunnus}}/g, asetukset.ytunnus)
      korvattava = korvattava.replace(/{{katuosoite}}/g, asetukset.katuosoite)
      korvattava = korvattava.replace(/{{postinumero}}/g, asetukset.postinro)
      korvattava = korvattava.replace(/{{postitoimipaikka}}/g, asetukset.postitmp)
      korvattava = korvattava.replace(/{{maa}}/g, this.maaService.getName(asetukset.maakoodi, kasiteltava.kieli))

      const viimeisinMuokattu = this.shared.annaViimeisinMuokattuLasku(juurilasku)
      korvattava = korvattava.replace(/{{laskunumero}}/g, this.shared.annaMuotoiltuLaskunumero(juurilasku, kasiteltava))
      korvattava = korvattava.replace(/{{erapaiva}}/g, this.shared.formatoiPvm(viimeisinMuokattu.erapvml, viimeisinMuokattu.erapvm, kasiteltava))

      return korvattava
    }

    return ''

  }

  annaSpostiAsetukset(kasiteltava: LaskuBase, onkoMuistutus: boolean, laskuasetukset: Laskuasetukset): LaskuasetuksetSpostille {

    if (onkoMuistutus && laskuasetukset && laskuasetukset.muistutusSpostiasetukset && laskuasetukset.muistutusSpostiasetukset[kasiteltava.kieli]) {
      return laskuasetukset.muistutusSpostiasetukset[kasiteltava.kieli]
    } else if (laskuasetukset && laskuasetukset.spostiasetukset && laskuasetukset.spostiasetukset[kasiteltava.kieli]) {
      return laskuasetukset.spostiasetukset[kasiteltava.kieli]
    }

    if (onkoMuistutus) {
      return {
        aihe: this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.muistutussposti.aihe', kasiteltava),
        kieli: kasiteltava.kieli,
        teksti: this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.muistutussposti.teksti', kasiteltava),
        otsikko: this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.muistutussposti.otsikko', kasiteltava),
        template: LaskunSahkopostipohja.MUISTUTUS_PERINTEINEN
      }
    } else {
      return {
        aihe: this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.aihe', kasiteltava),
        kieli: kasiteltava.kieli,
        teksti: this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.teksti', kasiteltava),
        otsikko: this.shared.annaLokalisoituMerkkijono('lasku.spostipohjat.oletukset.sposti.otsikko', kasiteltava),
        template: LaskunSahkopostipohja.PERINTEINEN
      }
    }

  }

}


