import { Injectable } from '@angular/core'

import { combineLatest, switchMap, of as observableOf, Observable } from 'rxjs'
import { TilinpaatosUserData } from 'app/_jaettu/model/tilinpaatos'
import { TilinpaatosUriService } from 'app/_jaettu/service/tilinpaatos-uri.service'
import { KayttajaService } from './kayttaja.service'
import { Yritysmuoto } from 'app/_jaettu/model/kayttaja'
import { FirebaseLemonaid } from './firebase-lemonaid.service'


@Injectable()
export class TilinpaatosUsersService {

  tilinpaatosUsersObservable: Observable<TilinpaatosUserData>

  constructor(
    private _firebaseLemonaid: FirebaseLemonaid,
    private _tilinpaatosUriService: TilinpaatosUriService,
    private _kayttajaService: KayttajaService
  ) {

    this.tilinpaatosUsersObservable = combineLatest([this._kayttajaService.nykyinenAsiakasObservable, this._kayttajaService.kayttajaObservable]).pipe(
      switchMap(([asiakas, kayttaja]) => {
        if (!kayttaja || !asiakas) {
          return observableOf<TilinpaatosUserData>(null)
        }
        if (asiakas.yritysmuoto === Yritysmuoto.TOIMINIMI) {
          return observableOf<TilinpaatosUserData>(null)
        }
        if (kayttaja.kayttajaTunnistettu === 'tunnistettu-nets') {
          return this._firebaseLemonaid.firestoreDoc<TilinpaatosUserData>(this._tilinpaatosUriService.getTilinpaatosUsersDocUri(asiakas.asiakasAvain)).listen()
        }
        return observableOf<TilinpaatosUserData>(null)
      })
    )

  }

}
