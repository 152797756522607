import { EnvironmentType, Environment } from '../app/app.environment'
import { LemonaidFirebaseConfig } from '../app/_jaettu-angular/base-firebase.service'

export const lemonaidFirebaseConfig: LemonaidFirebaseConfig = {
  apiKey: 'AIzaSyAQ6eSAtBdxY4kWpbM-j3T7wO7uTpbGlGs',
  authDomain: 'dev-lemonaid.firebaseapp.com',
  databaseURL: 'https://dev-lemonaid-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'dev-lemonaid',
  storageBucket: 'dev-lemonaid.appspot.com',
  messagingSenderId: '113515017142',
  appId: '1:113515017142:web:3b78992265c8cb8de10717',
  functionsRegion: 'europe-west1'
}

export const environment: Environment = {
  enableVersionCheck: false,
  environment: EnvironmentType.DEV,
  airbrakeEnabled: false,
  lemonaidFirebaseConfig: lemonaidFirebaseConfig,
  recaptchaSiteId: '6Ley3kcfAAAAACIe0hoWwsgPXl0yZxO3cFTGoMxA',
  version: 'DEV'
}
