<div [@sisaltoAuki]="sisallonAnimointitilaObservable | async" class="valikko-sisalto" [class.valikon-avaaja-nakyvissa]="valikonAvaajaNakyvissaObservable | async" [class.kapea]="onkoSisaltoKapeaObservable | async">
  <div *ngIf="naytaBeta" style="line-height: 40px; padding: 10px; text-align: center; font-size: 40px; color: red; font-weight: bold; width: 100%; overflow: hidden; text-overflow: ellipsis;">
    TESTIYMPÄRISTÖ
  </div>
  <div [style.height]="(valikonAvaajaNakyvissaObservable | async) ? '40px' : '0px'" *ngIf="(vasemmanValikonTilaObservable | async) === 'over'">
    <button *ngIf="valikonAvaajaNakyvissaObservable | async" mat-icon-button (click)="muutaNayta()">
      <mat-icon class="mat-24">menu</mat-icon>
    </button>
  </div>
  <!-- <div>TILA {{vasemmanValikonTilaObservable | async}}</div>
  <div>AUKI {{vasenValikkoAukiObservable | async}}</div>
  <div>NAPPULASTA {{avattuNappulasta | async}}</div> -->
  <div *ngIf="naytaInternetYhteysOngelmaObservable | async" class="mat-elevation-z3" style="line-height: 34px; padding: 10px; background-color: rgba(255, 255, 255, 1); border-radius: 10px; margin: 10px;">
    <i matListItemIcon class="icon ion-ios-wifi-outline flashit red-text" style="font-size: 28px; height: 28px; margin-right: 10px; vertical-align: middle;"></i>
    <span style="vertical-align: middle;">{{'app.no-internet-connection' | translate | async}}</span>
  </div>
  <app-unsupported-browser></app-unsupported-browser>
  <div app-browser-clock-check></div>
  <router-outlet #outletti></router-outlet>
</div>

<div *ngIf="onkoValikonVerhoNakyvissaObservable | async" [@tummaTausta]="(onkoValikonVerhoNakyvissaObservable | async) ? 'nakyvissa' : 'poissa'" class="valikko-underlay" (click)="muutaNayta()"></div>

<div [@valikkoAuki]="vasemmanValikonAnimointiTila | async" class="valikko">
  <app-menu (vasemmanValikonSulkupyynto)="suljeVasenValikko()" [kayttaja]="kirjautunutKayttajaObservable | async" [url]="urlObservable | async" [asetukset]="asetuksetObservable | async" [showReports]="showReportsIconObservable | async"></app-menu>
</div>

<!-- <mat-sidenav-container style="min-height: 100vh">
  <mat-sidenav #nav
    [fixedInViewport]="true"
    [mode]="vasemmanValikonTilaObservable | async"
    [opened]="vasenValikkoAukiObservable | async"
    (openedChange)="signalChange()"
    class="mat-elevation-z3">
    <app-menu
      (vasemmanValikonSulkupyynto)="suljeVasenValikko()"
      [kayttaja]="kirjautunutKayttajaObservable | async"
      [url]="urlObservable | async"
      [asetukset]="asetuksetObservable | async"
    ></app-menu>
  </mat-sidenav>
  <mat-sidenav-content style="min-height: 100vh">
    <button *ngIf="valikonAvaajaNakyvissaObservable | async" mat-icon-button (click)="nav.toggle()">
      <mat-icon class="mat-24">menu</mat-icon>
    </button>
    <div *ngIf="naytaInternetYhteysOngelmaObservable | async" class="mat-elevation-z3" style="line-height: 34px; padding: 10px; background-color: rgba(255, 255, 255, 1); border-radius: 10px; margin: 10px;">
      <i matListItemIcon class="icon ion-ios-wifi-outline flashit red-text" style="font-size: 28px; height: 28px; margin-right: 10px; vertical-align: middle;"></i>
      <span style="vertical-align: middle;">{{'app.no-internet-connection' | translate | async}}</span>
    </div>
    <router-outlet #outletti></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container> -->
<div lemon-loading class="kokosivu" [show]="ladataanObservable | async" [config]="ladataanOverlayConfig"></div>