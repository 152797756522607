import { Injectable, ErrorHandler } from '@angular/core'

import { LemonTranslationService } from '../../../_jaettu-angular/service/lemon-translation.service'
import { TimestampService } from '../../../_jaettu-angular/service/timestamp-service'

import { KayttajaService } from '../kayttaja.service'
import { PdfService } from '../pdf.service'

import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { DateService } from '../../../_shared-core/service/date.service'
import { TuettuKieli } from '../../../_shared-core/model/common'
import { TositeUriService } from '../../../_jaettu/service/tosite/tosite-uri.service'
import { TositeKopioija } from '../../../_jaettu/service/tosite/tosite.kopioija'
import { TositeIndeksoija } from '../../../_jaettu/service/tosite/tosite.indeksoija'

import { KuitinFirestoreTyojonoMerkinta } from '../../../_jaettu/model/kuitti-tyojonon-malli'
import { KayttajanTiedot, Roolit } from '../../../_jaettu/model/kayttaja'
import { Maksutapa, FirestoreTosite, UUDEN_KUITIN_AVAIN, RealtimeDbAvainToFirestoreAvainMappaus, TILIOTETOSITE_MAKSUTAPA, PALKKATOSITE_MAKSUTAPA, MYYNTITOSITE_MAKSUTAPA, MYYNTITOSITE_MAKSUTAPA_ID, TILIOTETOSITE_MAKSUTAPA_ID, PALKKATOSITE_MAKSUTAPA_ID, FirestoreTositteenAlkuperainenTiedosto, FirestoreTositteenKuva, SAHKOISET_LASKUT_MAKSUTAPA } from '../../../_jaettu/model/tosite'

import { Observable, of as observableOf, BehaviorSubject, firstValueFrom } from 'rxjs'
import { mergeMap, map, switchMap } from 'rxjs/operators'
import { NgxFileDropEntry } from 'ngx-file-drop'
import { TiedostojenLataamisService, UploadData } from 'app/_jaettu-angular/service/tiedostojen-lataamis.service'
import { UntypedFormGroup } from '@angular/forms'
import { TositeKuvaCacheService } from 'app/tositteet/kuvat/tosite-kuva-cache.service'
import { FirebaseLemonaid } from '../firebase-lemonaid.service'
import { IFirestoreBatch } from 'app/_jaettu-angular/base-firebase.service'
import { LEMONAID_CF_API, LemonHttpService } from '../lemon-http.service'
import { LemonBarcodeDetector, QrTaiViivakoodi } from '../barcode/barcode-detector.service'

export interface PurettuTiedosto {
  type: 'img' | 'pdf'
  koodi: Promise<QrTaiViivakoodi>
  kasitellytKuvat: FirestoreTositteenKuva[]
  virhe: string
}
export interface AlvPortaatJaoletus {
  portaat: number[]
  oletusProsentti: number
}
interface TallennuksenEstavatAsiat {
  tallennusEstetty: boolean
  lataaminenKesken: boolean
}

@Injectable()
export class TositeService {

  maksutavatObservable: Observable<Maksutapa[]>
  expandedMaksutavatObservable: Observable<Maksutapa[]>

  private previouslyUsedMaksutapaSubject = new BehaviorSubject<Maksutapa>(null)

  constructor(
    private errorHandler: ErrorHandler,
    private kayttajaService: KayttajaService,
    private lemonaidFirebase: FirebaseLemonaid,
    private tositeUriService: TositeUriService,
    private kuittiKopioija: TositeKopioija,
    private kuittiIndeksoija: TositeIndeksoija,
    private dateService: DateService,
    private timestampService: TimestampService,
    private currencyService: CurrencyService,
    private translationService: LemonTranslationService,
    private tiedostojenLataamisService: TiedostojenLataamisService,
    private pdfService: PdfService,
    private tositeKuvaCacheService: TositeKuvaCacheService,
    private httpService: LemonHttpService,
    private lemonBarcodeDetector: LemonBarcodeDetector
  ) {

    this.maksutavatObservable = this.kayttajaService.kayttajaObservable.pipe(
      map(kayttaja => {
        const tulos: Maksutapa[] = []
        const sahkoinenMaksutapa: Maksutapa = {
          a: false,
          d: 0,
          i: SAHKOISET_LASKUT_MAKSUTAPA.i,
          img: SAHKOISET_LASKUT_MAKSUTAPA.img,
          l: SAHKOISET_LASKUT_MAKSUTAPA.l,
          o: 1,
          v: SAHKOISET_LASKUT_MAKSUTAPA.v
        }
        tulos.push(sahkoinenMaksutapa)
        let o = 2
        for (const maksutapa of (kayttaja?.tositteenMaksutavat || [])) {
          const realtimeDbMaksutapa: Maksutapa = {
            a: maksutapa.aktiivinen,
            d: maksutapa.oletus ? 1 : 0,
            i: maksutapa.tunniste,
            img: maksutapa.base64Kuva,
            l: maksutapa.nimiListauksessa,
            o: o,
            v: maksutapa.nimiMuokkauksessa
          }
          tulos.push(realtimeDbMaksutapa)
          o++
        }
        return tulos
      })
    )

    this.expandedMaksutavatObservable = this.kayttajaService.kayttajaObservable.pipe(
      switchMap(kayttaja => {
        if (!kayttaja) {
          return observableOf<Maksutapa[]>([])
        }
        return this.maksutavatObservable.pipe(
          map(maksutavat => {
            const lisamaksutavat: Maksutapa[] = []
            if (kayttaja?.roolit[Roolit.TOSITTEET_MYYNTI]) {
              lisamaksutavat.push(MYYNTITOSITE_MAKSUTAPA)
            }
            if (kayttaja?.roolit[Roolit.TOSITTEET_TILI]) {
              lisamaksutavat.push(TILIOTETOSITE_MAKSUTAPA)
            }
            if (kayttaja?.roolit[Roolit.TOSITTEET_PALKKA]) {
              lisamaksutavat.push(PALKKATOSITE_MAKSUTAPA)
            }
            return maksutavat.concat(lisamaksutavat)
          })
        )
      })
    )
  }

  annaKuittiObservable(id: string): Observable<FirestoreTosite> {
    return this.kayttajaService.kayttajanTiedotObservable.pipe(
      mergeMap(kayttajanTiedot => {
        return this.annaKuittiObservableAsiakkaalle(kayttajanTiedot.asiakasId, id)
      })
    )
  }

  annaKuittiObservableAsiakkaalle(asiakasId: string, id: string): Observable<FirestoreTosite> {
    const kuittiUri = this.tositeUriService.annaKuitinFirestoreUri(asiakasId, id)
    return this.lemonaidFirebase.firestoreDoc<FirestoreTosite>(kuittiUri).listen().pipe(
      map(kuitti => {
        if (kuitti) {
          delete kuitti['haku']
        }
        return kuitti
      })
    )
  }

  annaUusiKuitti(maksutapa?: Maksutapa): FirestoreTosite {
    const nyt = this.timestampService.now()
    const localNyt = this.dateService.timestampToLocalDate(nyt)
    const kuitti: FirestoreTosite = {
      alvVahennysoikeus: null,
      avain: UUDEN_KUITIN_AVAIN,
      type: 'tosite',
      paivitetty: null,
      kuvakansio: this.lemonaidFirebase.databaseCreatePushId(),
      summa: null,
      sum: null,
      kayttajaUid: null,
      kuvat: {},
      lahde: null,
      maksutapa: maksutapa ? maksutapa.i : null,
      poistettu: false,
      pvm: nyt,
      localPvm: localNyt,
      p: this.dateService.localDateToNumber(localNyt),
      selite: null,
      tallennettuKannykassa: nyt,
      vastaanotettuPalvelimelle: nyt,
      alkuperaiset: {},
      paivitykset: {}
    }
    return kuitti
  }

  tallennaKuitit(kuitit: FirestoreTosite[]): Promise<void> {
    if (kuitit && kuitit.length > 0) {
      return this.kayttajaService.getKayttajanTiedot().then(kayttajanTiedot => {
        const batch = this.lemonaidFirebase.firestoreBatch()
        for (const kuitti of kuitit) {
          this.lisaaTallennettavaKuittiBatchiin(kuitti, kayttajanTiedot, batch)
        }
        return batch.commit()
      })
    }
    return Promise.resolve()
  }

  tallennaKuitti(kuitti: FirestoreTosite, einvoiceApproved?: boolean): Promise<void> {
    if (kuitti) {
      return this.kayttajaService.getKayttajanTiedot().then(kayttajanTiedot => {
        const batch = this.lemonaidFirebase.firestoreBatch()
        this.lisaaTallennettavaKuittiBatchiin(kuitti, kayttajanTiedot, batch, einvoiceApproved)
        return batch.commit()
      })
    }
    return Promise.resolve()
  }

  private lisaaTallennettavaKuittiBatchiin(kuitti: FirestoreTosite, kayttajanTiedot: KayttajanTiedot, batch: IFirestoreBatch, einvoiceApproved?: boolean): void {

    const tyojonoId = this.lemonaidFirebase.firestoreCreateId()
    const uusi = kuitti.avain === UUDEN_KUITIN_AVAIN

    // Aseta avaimet
    kuitti.avain = kuitti.avain && kuitti.avain !== UUDEN_KUITIN_AVAIN ? kuitti.avain : this.lemonaidFirebase.firestoreCreateId()

    // Aseta tallentaja
    kuitti.kayttajaUid = kayttajanTiedot.uid

    if (kuitti.x) {
      kuitti.l = true
    }

    delete kuitti.x // Remove selvitettava prop whenever a user saved the kuitti (even if no changes)
    delete kuitti.m // Remove accountant's comment whenever a user saved the kuitti (even if no changes)
    delete kuitti.w // Remove payee a user saved the kuitti (even if no changes)

    kuitti.lahde = 'web'
    kuitti.paivitetty = this.timestampService.now()

    const tallennettava = this.kuittiKopioija.kopioiTosite(kuitti)
    const historiaKopio = this.kuittiKopioija.kopioiTosite(kuitti)
    const avainMappaus: RealtimeDbAvainToFirestoreAvainMappaus = {
      firestoreKey: tallennettava.avain
    }

    // Urit
    const kuitinUri = this.tositeUriService.annaKuitinFirestoreUri(kayttajanTiedot.asiakasId, tallennettava.avain)
    const kuitinArkistoUri = this.tositeUriService.annaKuitinHistoriaFirestoreUri(kayttajanTiedot.asiakasId, tallennettava.avain, tyojonoId)
    const avainMappauksenUri = this.tositeUriService.annaRealtimeDbToFirestoreAvainMappausUri(kayttajanTiedot.asiakasAvain, tallennettava.kuvakansio) // Kuvakansiossa on itse asiassa realtimedb avain johtuen, well, legacy
    const tyojonoUri = this.tositeUriService.annaTyojonoWeb1AloitusUri(kayttajanTiedot.asiakasId, tyojonoId)

    const tyoData: KuitinFirestoreTyojonoMerkinta = {
      created: this.timestampService.now(),
      tosite: historiaKopio,
      uri: kuitinUri
    }

    if (einvoiceApproved) {
      tyoData.einvoiceApproved = true
    }

    // Luo hakutiedot
    const asany = tallennettava as any
    asany['haku'] = this.kuittiIndeksoija.luoHakutiedotKuitille(tallennettava)

    // Tallenna
    batch.set(kuitinUri, tallennettava)
    batch.set(kuitinArkistoUri, historiaKopio)
    if (uusi) {
      batch.set(avainMappauksenUri, avainMappaus)
    }
    batch.set(tyojonoUri, tyoData)

  }

  annaPdfTiedostonNimi(kuitti: FirestoreTosite, kieli: TuettuKieli, lahde: 'osto' | 'myynti' | 'tiliote' | 'palkka'): string {
    const nimi = this.translationService.lokalisoi('tositteet.' + lahde + '.katsele.lataa-tositteen-nimi', kieli)
    return this.annaPdfTiedostonNimiLadattaessa(nimi, true, kuitti, kieli)
  }

  annaPdfTiedostonNimiMyyntitositteelle(kuitti: FirestoreTosite, kieli: TuettuKieli): string {
    const nimi = this.translationService.lokalisoi('tositteet.myynti.katsele.lataa-tositteen-nimi', kieli)
    return this.annaPdfTiedostonNimiLadattaessa(nimi, true, kuitti, kieli)
  }

  annaPdfTiedostonNimiOstotositteelle(kuitti: FirestoreTosite, kieli: TuettuKieli): string {
    const nimi = this.translationService.lokalisoi('tositteet.osto.katsele.lataa-tositteen-nimi', kieli)
    return this.annaPdfTiedostonNimiLadattaessa(nimi, true, kuitti, kieli)
  }

  annaPdfTiedostonNimiPalkkatositteelle(kuitti: FirestoreTosite, kieli: TuettuKieli): string {
    const nimi = this.translationService.lokalisoi('tositteet.palkka.katsele.lataa-tositteen-nimi', kieli)
    return this.annaPdfTiedostonNimiLadattaessa(nimi, true, kuitti, kieli)
  }

  annaPdfTiedostonNimiTiliotetositteelle(kuitti: FirestoreTosite, kieli: TuettuKieli): string {
    const nimi = this.translationService.lokalisoi('tositteet.tiliote.katsele.lataa-tositteen-nimi', kieli)
    return this.annaPdfTiedostonNimiLadattaessa(nimi, true, kuitti, kieli)
  }

  private annaPdfTiedostonNimiLadattaessa(nimi: string, naytaSumma: boolean, kuitti: FirestoreTosite, kieli: TuettuKieli): string {

    if (kuitti && kuitti.localPvm) {
      nimi += ' - ' + this.dateService.muotoilePaikallinenPaiva(kuitti.localPvm, kieli)
    }

    if (kuitti && naytaSumma) {
      nimi += ' - ' + this.currencyService.formatoiRaha(kuitti.summa, 'EUR', kieli)
    }
    nimi += '.pdf'

    return nimi
  }

  getMaksutapaNameForRouter(id: number): 'osto' | 'myynti' | 'tiliote' | 'palkka' {
    if (id === MYYNTITOSITE_MAKSUTAPA_ID) {
      return 'myynti'
    }
    if (id === TILIOTETOSITE_MAKSUTAPA_ID) {
      return 'tiliote'
    }
    if (id === PALKKATOSITE_MAKSUTAPA_ID) {
      return 'palkka'
    }
    return 'osto'
  }

  public async setLastViewedKuitinMaksutapa(maksutapaId: number) {
    if (maksutapaId === null || maksutapaId === undefined) {
      this.previouslyUsedMaksutapaSubject.next(null)
      return
    }
    const maksutavat = await firstValueFrom(this.expandedMaksutavatObservable)
    const lastViewedMaksutapa = maksutavat.find(m => m.i === maksutapaId) ?? null
    this.previouslyUsedMaksutapaSubject.next(lastViewedMaksutapa)
  }
  public getLastViewedKuitinMaksutapa() {
    return this.previouslyUsedMaksutapaSubject.value
  }

  async puraYksiTiedosto(tosite: FirestoreTosite, file: File, tiedosto: NgxFileDropEntry, fileEnding: string, uploadUri: string, alkuperainen: FirestoreTositteenAlkuperainenTiedosto, kayttajanTiedot: KayttajanTiedot, etsiViivakooditiedot: boolean, koodiPromise: Promise<QrTaiViivakoodi>): Promise<PurettuTiedosto> {

    const paluuarvo: PurettuTiedosto = {
      type: null,
      kasitellytKuvat: [],
      koodi: koodiPromise,
      virhe: null
    }

    // Pura kuviksi
    if (fileEnding.toLowerCase() === 'pdf') {
      const fileAsUint8Array = await this.tiedostojenLataamisService.getAsUint8Array(file)
      const pdfDoc = await this.pdfService.yritaParsiaPdf(fileAsUint8Array)
      if (pdfDoc) {
        paluuarvo.type = 'pdf'
        const sivutResult = this.tositeKuvaCacheService.hoidaPdfnRajayttaminenKuviksi(tosite, alkuperainen, kayttajanTiedot, pdfDoc, 720, etsiViivakooditiedot ? 2 : 0, paluuarvo.koodi) // 2/3 1080p
        paluuarvo.kasitellytKuvat = sivutResult.kuvat
        if (etsiViivakooditiedot) {
          paluuarvo.koodi = sivutResult.koodi.then(result => {
            if (result.qr || result.viiva) {
              return result
            }
            return this.pdfService.etsiViivakoodiPdfsta(pdfDoc).then(code => {
              if (code) { result.viiva = code }
              return result
            })
          })
        }
      } else {

        const muutosKuva: FirestoreTositteenKuva = {
          avain: alkuperainen.avain + '_1',
          jarjestys: 1,
          poistettu: false,
          type: 'jpg',
          alkuperaisenAvain: alkuperainen.avain,
        }
        paluuarvo.kasitellytKuvat.push(muutosKuva)

        this.errorHandler.handleError(new Error('Tiedostoa "' + uploadUri + '" ei pystytä parsimaan.'))
        paluuarvo.virhe = this.translationService.lokalisoi('kuitit.muokkaa.validation.pdf-parse-error', { tiedostonNimi: tiedosto.relativePath })

      }
    } else if (fileEnding.toLowerCase() === 'heif' || fileEnding.toLowerCase() === 'heic') {

      const formData = new FormData()
      formData.append('img', file, file.name)
      const kuvaBlob = await this.httpService.postBinaryGetBinary('/imagesConvertHeif', formData, LEMONAID_CF_API)

      const kuva: FirestoreTositteenKuva = {
        avain: alkuperainen.avain + '_1',
        jarjestys: 1,
        poistettu: false,
        type: 'jpg',
        alkuperaisenAvain: alkuperainen.avain
      }

      if (etsiViivakooditiedot) {
        paluuarvo.koodi = paluuarvo.koodi.then(result => {
          if (result.qr || result.viiva) {
            return result
          }
          return this.lemonBarcodeDetector.detect(kuvaBlob).then(koodi => {
            // console.log('got koodi', koodi)
            if (koodi?.qr) {
              result.qr = koodi.qr
            } else if (koodi?.viiva) {
              result.viiva = koodi.viiva
            }
            return result
          })
        })
      }

      this.tositeKuvaCacheService.lisaaLisattyTiedostoCacheen(kayttajanTiedot, tosite, kuva, kuvaBlob)

      paluuarvo.kasitellytKuvat.push(kuva)
      paluuarvo.type = 'img'
    } else {

      const kuva: FirestoreTositteenKuva = {
        avain: alkuperainen.avain + '_1',
        jarjestys: 1,
        poistettu: false,
        type: 'jpg',
        alkuperaisenAvain: alkuperainen.avain
      }

      if (etsiViivakooditiedot) {
        paluuarvo.koodi = paluuarvo.koodi.then(result => {
          if (result.qr || result.viiva) {
            return result
          }
          return this.lemonBarcodeDetector.detect(file).then(koodi => {
            // console.log('got koodi', koodi)
            if (koodi?.qr) {
              result.qr = koodi.qr
            } else if (koodi?.viiva) {
              result.viiva = koodi.viiva
            }
            return result
          })
        })
      }

      this.tositeKuvaCacheService.lisaaLisattyTiedostoCacheen(kayttajanTiedot, tosite, kuva, file)

      paluuarvo.kasitellytKuvat.push(kuva)
      paluuarvo.type = 'img'
    }
    return paluuarvo
  }

  async saveIsPrevented(form: UntypedFormGroup, virheviestiPysyva: string, latausvirheetObservable: Observable<string[]>, uploadTasks: UploadData[]): Promise<TallennuksenEstavatAsiat> {

    if (!form || !latausvirheetObservable || !uploadTasks) {
      return {
        lataaminenKesken: false,
        tallennusEstetty: true
      }
    }

    const paluuarvo: TallennuksenEstavatAsiat = {
      lataaminenKesken: false,
      tallennusEstetty: false
    }

    if (virheviestiPysyva) {
      paluuarvo.tallennusEstetty = true
      return paluuarvo
    }

    const latausvirhe = await firstValueFrom(latausvirheetObservable).then(virheet => {
      return virheet && virheet.length > 0
    })
    if (latausvirhe) {
      return {
        lataaminenKesken: false,
        tallennusEstetty: true
      }
    }

    for (const uploadData of uploadTasks) {
      if (!uploadData.done) {
        paluuarvo.tallennusEstetty = true
        paluuarvo.lataaminenKesken = true
        return paluuarvo
      }
    }

    return {
      lataaminenKesken: false,
      tallennusEstetty: !form.valid
    }

  }
}
