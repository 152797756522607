
import { Injectable, ErrorHandler } from '@angular/core'

import { NativeDateAdapter } from '@angular/material/core'
import { Platform } from '@angular/cdk/platform'

import { DateService } from '../../_shared-core/service/date.service'
import { LemonTranslationService } from '../../_jaettu-angular/service/lemon-translation.service'

@Injectable()
export class LemonDateAdapter extends NativeDateAdapter {

  constructor(
    protected errorHandler: ErrorHandler,
    protected dateService: DateService,
    protected platform: Platform,
    protected translationService: LemonTranslationService
  ) {
    super(translationService.nykyinenKieli)
    this.setLocale(this.translationService.nykyinenKieli)
  }

  setLocale(locale: any): void {
    super.setLocale(this.translationService.getSupportedLocale(locale))
  }

  parse(value: any): Date | null {
    if (typeof value === 'string') {
      try {
        return this.dateService.parsiPaivamaara(value, this.translationService.nykyinenKieli)
      } catch (error) {
        return new Date('thisisinvaliddateithink')
      }
    }
    if (this.isDateInstance(value)) {
      return super.parse(value)
    }
    return new Date('thisisinvaliddateithink')
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    const kielenNimet = this.dateService.KUUKAUSIEN_NIMET[this.translationService.nykyinenKieli]
    if (kielenNimet) {
      return kielenNimet[style]
    }
    this.errorHandler.handleError('Ei löytynyt kuukausien nimiä kielelle ' + this.translationService.nykyinenKieli + ', tyylillä ' + style)
    return []
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    const paivienNimet = this.dateService.PAIVIEN_NIMET[this.translationService.nykyinenKieli]
    if (paivienNimet) {
      return paivienNimet[style]
    }
    this.errorHandler.handleError('Ei löytynyt päivien nimiä kielelle ' + this.translationService.nykyinenKieli + ', tyylillä ' + style)
    return []
  }

  format(date: Date, displayFormat: Object): string {
    return this.dateService.muotoilePaivaDate(date, this.translationService.nykyinenKieli)
  }

}
