<div class="kapea">
  <div class="center-container">

    <div style="height: 20px;"></div>

    <h2>{{'maksut.laskujen-vastaanotto' | translate | async}}</h2>
    <div class="text-part">
      <p>
        {{'maksut.mainos.vastaanota-verkkolaskuja-suoraan' | translate | async}}
      </p>
    </div>

    <div class="ikonit">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M384 80H128c-26 0-43 14-48 40L48 272v112a48.14 48.14 0 0048 48h320a48.14 48.14 0 0048-48V272l-32-152c-5-27-23-40-48-40z" fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="32" />
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M48 272h144M320 272h144M192 272a64 64 0 00128 0M144 144h224M128 208h256" />
        </svg>
        <div>{{'maksut.mainos.kaikki-laskut-samassa-paikassa' | translate | async}}</div>
      </div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
          <rect x="336" y="336" width="80" height="80" rx="8" ry="8" />
          <rect x="272" y="272" width="64" height="64" rx="8" ry="8" />
          <rect x="416" y="416" width="64" height="64" rx="8" ry="8" />
          <rect x="432" y="272" width="48" height="48" rx="8" ry="8" />
          <rect x="272" y="432" width="48" height="48" rx="8" ry="8" />
          <rect x="336" y="96" width="80" height="80" rx="8" ry="8" />
          <rect x="288" y="48" width="176" height="176" rx="16" ry="16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
          <rect x="96" y="96" width="80" height="80" rx="8" ry="8" />
          <rect x="48" y="48" width="176" height="176" rx="16" ry="16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
          <rect x="96" y="336" width="80" height="80" rx="8" ry="8" />
          <rect x="48" y="288" width="176" height="176" rx="16" ry="16" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
        </svg>
        <div>{{'maksut.mainos.helpottaa-laskujen-maksamista' | translate | async}}
        </div>
      </div>

      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
            d="M416.07 272a160 160 0 10-160 160 160 160 0 00160-160zM142.12 91.21A46.67 46.67 0 00112 80l-2.79.08C83.66 81.62 64 104 64.07 131c0 13.21 4.66 19.37 10.88 27.23a4.55 4.55 0 003.24 1.77h.88a3.23 3.23 0 002.54-1.31L142.38 99a5.38 5.38 0 001.55-4 5.26 5.26 0 00-1.81-3.79zM369.88 91.21A46.67 46.67 0 01400 80l2.79.08C428.34 81.62 448 104 447.93 131c0 13.21-4.66 19.37-10.88 27.23a4.55 4.55 0 01-3.24 1.76h-.88a3.23 3.23 0 01-2.54-1.31L369.62 99a5.38 5.38 0 01-1.55-4 5.26 5.26 0 011.81-3.79z"
            fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M256.07 160v112h-80M416.07 432l-40-40M96.07 432l40-40" />
        </svg>
        <div>{{'maksut.mainos.muistuttaa-eraantyneista-laskuista' | translate | async}}</div>
      </div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M315.27 33L96 304h128l-31.51 173.23a2.36 2.36 0 002.33 2.77h0a2.36 2.36 0 001.89-.95L416 208H288l31.66-173.25a2.45 2.45 0 00-2.44-2.75h0a2.42 2.42 0 00-1.95 1z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
        </svg>
        <div>{{'maksut.mainos.laskut-automaattisesti-kirjanpitoon' | translate | async}}</div>
      </div>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path d="M448 256c0-106-86-192-192-192S64 150 64 256s86 192 192 192 192-86 192-192z" fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="32" />
          <path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272" />
        </svg>
        <div>{{'maksut.mainos.ei-enaa-kadonneita-laskuja' | translate | async}}</div>
      </div>
    </div>

    <div class="mat-elevation-z4 mainos">
      <h3>{{'maksut.mainos.tutustumistarjous' | translate | async}}</h3>
      <p>{{'maksut.mainos.laskujen-vastaanotto-asti' | translate | async}}:</p>
      <p>
        <span style="font-size: 40px; padding-right: 10px; line-height: 65px;">0 €</span>
        <span style="line-height: 65px;">/{{'maksut.mainos.kk' | translate | async}}</span>
      </p>
      <button (click)="otaKayttoonVastaanotto($event)" mat-button class="primarybutton">{{'maksut.mainos.ota-kayttoon' | translate | async}}</button>
    </div>

    <p style="font-size: 80%;">
      {{'maksut.mainos.tutustumisjakson-jalkeen-hinta' | translate | async}}<br />
      {{'maksut.mainos.ei-sido-mihinkaan' | translate | async}}
    </p>

    <h2 style="margin-top: 70px;">{{'maksut.laskujen-maksaminen' | translate | async}}</h2>

    <div class="text-part">
      <p>
        {{'maksut.mainos.maksa-sahkoiset-ostolaskut' | translate | async}}
      </p>
      <p>
        {{'maksut.mainos.maksuominaisuuden-aktivointi' | translate | async}}
      </p>
      <p>
        {{'maksut.mainos.maksuominaisuus-hinta' | translate | async}}
      </p>
    </div>

    <div class="mat-elevation-z4 mainos" style="margin-top: 70px;">
      <h3>{{'maksut.laskujen-maksaminen' | translate | async}}</h3>
      <p>{{'maksut.mainos.tulossa' | translate | async}}</p>
      <!-- <p>
        <span style="font-size: 40px; padding-right: 10px; line-height: 65px;">0 €</span>
        <span style="line-height: 65px;">/kk</span>
      </p> -->
      <button disabled (click)="otaKayttoonMaksaminen($event)" mat-button class="primarybutton">{{'maksut.mainos.ota-kayttoon' | translate | async}}</button>
    </div>

    <!-- <p style="font-size: 80%;">
      Tutustumisjakson jälkeen hinta on 5,00 € /kk.<br />
      Käyttöönotto ei sido sinua mihinkään ja voit milloin tahansa lopettaa palvelun.
    </p> -->

    <!-- <div class="text-part">
      <p>Voit nyt vastaanottaa ja maksaa verkkolaskuja suoraan Lemonaidista. Laskujen maksaminen on helppoa ja tositteet siirtyvät automaattisesti kirjanpitoon. Näet myös yhdellä silmäyksellä kaikki avoinna olevat ostolaskusi ja niiden tilan.</p>
      <p>Laskujen vastaanottopalvelu voidaan ottaa käyttöön myös ilman maksuominaisuutta. Tällöin laskut saapuvat Lemonaidiin ja ne maksetaan normaalisti verkkopankissa tai pankin mobiilisovelluksella. Maksun jälkeen laskut siirtyvät kirjanpitoon yhdellä klikkauksella. Tämä on helppo tapa päästä hyötymään sähköisistä laskuista ilman suuria kuluja. Jos yritykselläsi on paljon osto- laskuja, tai
        haluat minimoida laskujen maksamiseen käytettävän ajan, suosittelemme ottamaan myös maksuominaisuuden käyttöön.</p>

      <div style="font-size: 120%; padding: 3em 0; margin-bottom: 1em;">

        <p>Palveluiden hinnat:</p>

        <table style="margin: 1em 0; width: 100%;">
          <tr>
            <td style="padding: 5px 0">Laskujen vastaanotto:</td>
            <td style="padding: 5px 0">5 € /kk</td>
            <td style="padding: 5px 0"><a href="#" (click)="otaKayttoonVastaanotto($event)">Ota käyttöön ></a></td>
          </tr>
          <tr>
            <td style="padding: 5px 0">Laskujen maksaminen:</td>
            <td style="padding: 5px 0">15 € /kk*</td>
            <td style="padding: 5px 0"><a href="#" (click)="otaKayttoonMaksaminen($event)">Ota käyttöön ></a></td>
          </tr>
        </table>

        <p style="font-size: 60%;">*Pankki veloittaa Web Service -yhteydestä ja sen käytöstä oman hinnastonsa mukaisesti.</p>

      </div>

      <img src="assets/maksut-promo.png" class="mat-elevation-z3" style="max-width: 100%;" />

    </div> -->

  </div>
</div>