import {
  Lasku,
  LaskuBase,
  LaskunTila,
  Laskuasetukset,
  LaskunumeroTyyppi,
  LaskunAlv,
  LaskunTyypit,
  EI_LASKUNUMEROA_NUMERO,
  LaskunTuote,
  LaskuReskontra,
  TuoteAvaimet
} from '../../model/lasku'
import { LocalDate, Timestamp } from '../../../_shared-core/model/common'

import { DateService } from '../../../_shared-core/service/date.service'
import { CurrencyService } from '../../../_shared-core/service/currency.service'
import { TranslationService, CountryLocalizationService } from '../translation.service'

import { Big } from 'big.js'

import { TimestampProviderBase } from '../../../_shared-core/service/timestamp-provider.interface'
import { LaskunQrKoodinCharacterSet, LaskunQrKoodinOsat, ViitenumeroService, VirtuaaliviivakoodinOsat } from '../../../_shared-core/service/viitenumero.service'


// TODO! TÄTÄ EI KÄYTETÄ MISSÄÄN??
// export enum PdfTemplate {
//   PERUS = 'perinteinen'
// }

export interface LaskuryppaanSummat {
  laskunSummat: LaskunSummat
  /**
   * Viimeisin tavallinen lasku + huomautuksien kulut ja korot - hyvitykset
   */
  rypasYhteensa: Big
  rypasYhteensaAlv0: Big
  huomautuskorot: Big
  huomautuskulut: Big
  hyvitykset: Big
  hyvityksetAlv0: Big
}

export interface LaskuryppaanSummatJaTila extends LaskuryppaanSummat {
  avoinna: Big
  reskontrasumma: Big
  tila: LaskunTila
}

export interface LaskunSummat {
  yhteensaIlmanAlv: Big
  yhteensaAlv: Big
  yhteensaKaikki: Big
  alennustaYhteensaIlmanAlv: Big
  alennustaYhteensaAlv: Big
  alvErittelyMap: { [key: string]: AlvErittelynOsa }
  alvErittely: AlvErittelynOsa[]
}

export interface AlvErittelynOsa {
  alennustaIlmanAlv: Big
  alennustaAlv: Big
  alvitonSumma: Big
  alvinSumma: Big
  alvKanta: LaskunAlv
}

export class ReskontraService {

  constructor(
    private dateService: DateService
  ) { }

  annaReskontranSummaPaivalle(reskontra: LaskuReskontra[], pvm: Date): Big {
    let maara = new Big('0')
    if (reskontra) {
      for (const reskontraEntry of reskontra) {
        const paiviaValissa = this.dateService.paiviaValissa(pvm, reskontraEntry.pvm.toDate())
        if (paiviaValissa >= 0) {
          maara = maara.plus(reskontraEntry.suoritus)
        }
      }
    }
    return maara.round(2, Big.roundHalfUp)
  }

  annaReskontranSummaHetkelle(reskontra: LaskuReskontra[], pvm: Timestamp): Big {
    let maara = new Big('0')
    if (reskontra) {
      for (const reskontraEntry of reskontra) {
        if (reskontraEntry.pvm.toMillis() <= pvm.toMillis()) {
          maara = maara.plus(reskontraEntry.suoritus)
        }
      }
    }
    return maara.round(2, Big.roundHalfUp)
  }

  annaReskontranSumma(reskontra: LaskuReskontra[]): Big {
    let maara = new Big('0')
    if (reskontra) {
      for (const reskontraEntry of reskontra) {
        maara = maara.plus(reskontraEntry.suoritus)
      }
    }
    return maara.round(2, Big.roundHalfUp)
  }

}

export class LaskuSharedService {

  constructor(
    private dateService: DateService,
    private translationService: TranslationService,
    private countryLocalizationService: CountryLocalizationService,
    private currencyService: CurrencyService,
    private reskontraService: ReskontraService,
    private timestampProvider: TimestampProviderBase,
    private viitenumeroService: ViitenumeroService
  ) { }

  valmisteleTeksti(valmisteltava: string): string {
    if (!valmisteltava) {
      return ''
    }
    return valmisteltava.replace(/[\n\r]/g, '<br />')
  }

  annaMuotoiltuLaskunumero(juurilasku: Lasku, kasiteltava: LaskuBase) {
    if (kasiteltava.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
      return this.annaLaskunumero(kasiteltava.nro, kasiteltava.nrotyyppi)
    }
    return this.annaLaskunumero(juurilasku.nro, juurilasku.nrotyyppi)
  }

  annaLaskunumero(numero: number, tyyppi: LaskunumeroTyyppi): string {
    if (numero && numero !== EI_LASKUNUMEROA_NUMERO) {
      if (tyyppi === LaskunumeroTyyppi.KORJAUS) {
        return 'K' + numero
      } else if (tyyppi === LaskunumeroTyyppi.MUISTUTUS) {
        return 'M' + numero
      }
      return '' + numero
    }
    return ''
  }

  formatoiPvm(paikallinen: LocalDate, date: Timestamp, kasiteltava: LaskuBase): string {
    const d = this.dateService.annaPaikallinenPvm(date, paikallinen)
    if (d && kasiteltava && kasiteltava.kieli) {
      return this.dateService.muotoilePaikallinenPaiva(d, kasiteltava.kieli)
    }
    return ''
  }

  laskeMaksuaikaa(kasiteltava: LaskuBase): number {
    if (kasiteltava) {
      const e = this.dateService.annaPaikallinenPvm(kasiteltava.erapvm, kasiteltava.erapvml)
      const p = this.dateService.annaPaikallinenPvm(kasiteltava.pvm, kasiteltava.pvml)
      if (e && p) {
        return this.dateService.paiviaValissaPaikallinen(e, p)
      }
    }
    return 0
  }

  formatoiBigRaha(amount: Big, lasku: LaskuBase): string {
    if (lasku) {
      return this.currencyService.formatoiBigRaha(amount, lasku.valuutta, lasku.kieli)
    }
    return ''
  }

  formatoiRaha(amount: number, lasku: LaskuBase): string {
    if (lasku) {
      return this.currencyService.formatoiRaha(amount, lasku.valuutta, lasku.kieli)
    }
    return ''
  }

  annaAlvNimi(lasku: LaskuBase, alv: LaskunAlv): string {
    if (alv && alv.prosentti > -0.01) {
      return this.currencyService.formatoiDesimaali(alv.prosentti, 2, lasku.kieli) + '%'
    }
    return 'Ei ALV:TÄ!'
  }

  annaLokalisoituMerkkijono(avain: string, kasiteltava: LaskuBase, parametrit?: any): string {
    if (!avain || !kasiteltava || !kasiteltava.kieli) {
      return ''
    }
    // console.log(avain)
    return this.translationService.lokalisoi(avain, kasiteltava.kieli, parametrit)
  }

  annaMuotoiltuViitenumero(juurilasku: Lasku): string {
    if (juurilasku) {
      if (juurilasku.viitenumero && juurilasku.viitenumero.length > 2) {
        return this.viitenumeroService.muotoileViitenumero(juurilasku.viitenumero)
      }
      if (juurilasku.nro && juurilasku.nro !== EI_LASKUNUMEROA_NUMERO) {
        const viitenumero = this.viitenumeroService.luoViitenumero(juurilasku.nro.toString())
        return this.viitenumeroService.muotoileViitenumero(viitenumero)
      }
    }
    return ''
  }

  private annaLaskunSummatJaTilaPaivamaaralle(juurilasku: Lasku, pvm: Date, viimeinenTavallinen: LaskuBase, muistutuksetJaHyvitykset: LaskuBase[], reskontra: LaskuReskontra[], laskeUudelleen: boolean = true): LaskuryppaanSummatJaTila {
    const reskontraSumma = this.reskontraService.annaReskontranSummaPaivalle(reskontra, pvm)
    return this.annaLaskunSummatJaTilaReskontrasummallaPaivamaaralle(juurilasku, reskontraSumma, viimeinenTavallinen, muistutuksetJaHyvitykset, laskeUudelleen, pvm)
  }

  private annaLaskunSummatJaTila(juurilasku: Lasku, viimeinenTavallinen: LaskuBase, muistutuksetJaHyvitykset: LaskuBase[], reskontra: LaskuReskontra[], laskeUudelleen: boolean = true): LaskuryppaanSummatJaTila {
    return this.annaLaskunSummatJaTilaReskontralle(juurilasku, viimeinenTavallinen, muistutuksetJaHyvitykset, reskontra, laskeUudelleen)
  }

  public annaLaskunSummatJaTilaReskontralle(juurilasku: Lasku, viimeinenTavallinen: LaskuBase, muistutuksetJaHyvitykset: LaskuBase[], reskontra: LaskuReskontra[], laskeUudelleen: boolean = true): LaskuryppaanSummatJaTila {
    const reskontraSumma = this.reskontraService.annaReskontranSumma(reskontra)
    return this.annaLaskunSummatJaTilaReskontrasummalla(juurilasku, reskontraSumma, viimeinenTavallinen, muistutuksetJaHyvitykset, laskeUudelleen)
  }

  private annaLaskuryppaanSummat(viimeinenTavallinen: LaskuBase, muistutuksetJaHyvitykset: LaskuBase[], laskeUudelleen: boolean): LaskuryppaanSummat {
    const summat = this.annaLaskunSummat(viimeinenTavallinen)

    let ryppaanSumma = laskeUudelleen ? summat.yhteensaKaikki : new Big(viimeinenTavallinen.summa)
    let ryppaanSummaAlv0 = summat.yhteensaIlmanAlv
    let huomautuskorot = new Big('0')
    let huomautuskulut = new Big('0')
    let hyvitykset = new Big('0')
    let hyvityksetAlv0 = new Big('0')

    for (const korvaustieto of muistutuksetJaHyvitykset) {
      if (korvaustieto.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
        const kulut = this.annaMuistutuslaskunKulut(korvaustieto)
        ryppaanSumma = ryppaanSumma.plus(kulut.korot)
        ryppaanSumma = ryppaanSumma.plus(kulut.kulut)
        ryppaanSummaAlv0 = ryppaanSumma.plus(kulut.korot)
        ryppaanSummaAlv0 = ryppaanSumma.plus(kulut.kulut)
        huomautuskorot = huomautuskorot.plus(kulut.korot)
        huomautuskulut = huomautuskorot.plus(kulut.kulut)
      } else if (korvaustieto.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
        const hyvityksenSummat = this.annaLaskunSummat(korvaustieto)
        ryppaanSumma = ryppaanSumma.plus(hyvityksenSummat.yhteensaKaikki)
        ryppaanSummaAlv0 = ryppaanSummaAlv0.plus(hyvityksenSummat.yhteensaIlmanAlv)
        hyvitykset = hyvitykset.plus(hyvityksenSummat.yhteensaKaikki)
        hyvityksetAlv0 = hyvityksetAlv0.plus(hyvityksenSummat.yhteensaIlmanAlv)
      }
    }

    return {
      rypasYhteensa: ryppaanSumma,
      rypasYhteensaAlv0: ryppaanSummaAlv0,
      laskunSummat: summat,
      huomautuskorot: huomautuskorot,
      huomautuskulut: huomautuskulut,
      hyvitykset: hyvitykset,
      hyvityksetAlv0: hyvityksetAlv0
    }

  }

  annaLaskuryppaanSummatLaskulle(juurilasku: Lasku, laskeUudelleen: boolean = true): LaskuryppaanSummat {
    const viimeinenTavallinen = this.annaViimeisinTavallinenLasku(juurilasku)
    const muistutuksetJaHyvitykset = this.annaLaskuryppaanViimeisinMuistutusJaHyvitykset(juurilasku)
    return this.annaLaskuryppaanSummat(viimeinenTavallinen, muistutuksetJaHyvitykset, laskeUudelleen)
  }

  private annaLaskunSummatJaTilaReskontrasummallaPaivamaaralle(juurilasku: Lasku, reskontraSumma: Big, viimeinenTavallinen: LaskuBase, muistutuksetJaHyvitykset: LaskuBase[], laskeUudelleen: boolean = true, pvm: Date = new Date()): LaskuryppaanSummatJaTila {
    const summat = this.annaLaskunSummat(viimeinenTavallinen)
    const kaytettavaSumma = laskeUudelleen ? summat.yhteensaKaikki : new Big(viimeinenTavallinen.summa)
    const laskuryppaanSummat = this.annaLaskuryppaanSummat(viimeinenTavallinen, muistutuksetJaHyvitykset, laskeUudelleen)
    const avoinna = laskuryppaanSummat.rypasYhteensa.minus(reskontraSumma)

    let tila = LaskunTila.avoin
    if (this.onkoKasiteltavaMitatoity(juurilasku, viimeinenTavallinen)) {
      tila = LaskunTila.mitatoity
    } else if (this.onkoKasiteltavaLuonnos(juurilasku, viimeinenTavallinen)) {
      tila = LaskunTila.luonnos
    } else if (juurilasku.tila === LaskunTila.luottotappio) {
      tila = LaskunTila.luottotappio
    } else {
      tila = this.annaLaskunTilaReskontranMukaan(juurilasku, kaytettavaSumma, avoinna, reskontraSumma, viimeinenTavallinen, pvm)
    }

    return {
      avoinna: avoinna,
      rypasYhteensa: laskuryppaanSummat.rypasYhteensa,
      rypasYhteensaAlv0: laskuryppaanSummat.rypasYhteensaAlv0,
      laskunSummat: laskuryppaanSummat.laskunSummat,
      reskontrasumma: reskontraSumma,
      tila: tila,
      huomautuskorot: laskuryppaanSummat.huomautuskorot,
      huomautuskulut: laskuryppaanSummat.huomautuskulut,
      hyvitykset: laskuryppaanSummat.hyvitykset,
      hyvityksetAlv0: laskuryppaanSummat.hyvityksetAlv0
    }

  }

  private annaLaskunSummatJaTilaReskontrasummalla(juurilasku: Lasku, reskontraSumma: Big, viimeinenTavallinen: LaskuBase, muistutuksetJaHyvitykset: LaskuBase[], laskeUudelleen: boolean = true): LaskuryppaanSummatJaTila {
    return this.annaLaskunSummatJaTilaReskontrasummallaPaivamaaralle(juurilasku, reskontraSumma, viimeinenTavallinen, muistutuksetJaHyvitykset, laskeUudelleen)
  }

  annaLaskunTilaReskontranMukaan(juurilasku: Lasku, kaytettavaSumma: Big, avoinna: Big, reskontraSumma: Big, viimeinenTavallinen: LaskuBase, pvm: Date = new Date()): LaskunTila {
    let tila = LaskunTila.avoin
    if (viimeinenTavallinen.avain === juurilasku.avain && kaytettavaSumma.lte(new Big('0')) && (!juurilasku.korvaus || juurilasku.korvaus.length < 1)) {
      tila = LaskunTila.hyvitetty
    } else if (avoinna.round(2, Big.roundHalfUp).eq('0')) {
      if (reskontraSumma.gt(new Big('0'))) {
        tila = LaskunTila.maksettu
      } else {
        tila = LaskunTila.hyvitetty
      }
    } else if (avoinna.round(2, Big.roundHalfUp).lt('0')) {
      tila = LaskunTila.maksettuLiikaa
    } else if (this.onkoLaskunErapaivaMennytPaivalle(viimeinenTavallinen, pvm)) {
      tila = LaskunTila.eraantynyt
    }
    return tila
  }

  // annaLaskunHyvityssummaHetkelle(juurilasku: Lasku, pvm: Timestamp): Big {
  //   const hyvityslaskut = this.annaLaskuryppaanHyvityslaskutHetkelle(juurilasku, pvm)
  //   let hyvitykset = new Big('0')
  //   for (const hyvityslasku of hyvityslaskut) {
  //     const hyvityksenSummat = this.annaLaskunSummat(hyvityslasku)
  //     hyvitykset = hyvitykset.plus(hyvityksenSummat.yhteensaKaikki)
  //   }
  //   return hyvitykset
  // }

  // annaLaskunSummatLaskulle(juurilasku: Lasku): LaskuryppaanSummat {
  //   const viimeinenTavallinen = this.annaViimeisinTavallinenLasku(juurilasku)
  //   const muistutuksetJaHyvitykset = this.annaLaskuryppaanViimeisinMuistutusJaHyvitykset(juurilasku)
  //   return this.annaLaskuryppaanSummat(viimeinenTavallinen, muistutuksetJaHyvitykset)
  // }

  annaLaskunSummatJaTilaLaskulle(juurilasku: Lasku, reskontra: LaskuReskontra[], laskeUudelleen: boolean = true): LaskuryppaanSummatJaTila {
    const viimeinenTavallinen = this.annaViimeisinTavallinenLasku(juurilasku)
    const muistutuksetJaHyvitykset = this.annaLaskuryppaanViimeisinMuistutusJaHyvitykset(juurilasku)
    return this.annaLaskunSummatJaTila(juurilasku, viimeinenTavallinen, muistutuksetJaHyvitykset, reskontra, laskeUudelleen)
  }

  annaLaskunReskontraJaHyvityssummaHetkelle(juurilasku: Lasku, pvm: Timestamp, reskontra: LaskuReskontra[]): { reskontrasumma: Big, hyvityssumma: Big } {
    const reskontrasumma = this.reskontraService.annaReskontranSummaHetkelle(reskontra, pvm)
    const hyvityslaskut = this.annaLaskuryppaanHyvityslaskutHetkelle(juurilasku, pvm)
    let hyvitykset = new Big('0')
    for (const hyvityslasku of hyvityslaskut) {
      const hyvityksenSummat = this.annaLaskunSummat(hyvityslasku)
      hyvitykset = hyvitykset.plus(hyvityksenSummat.yhteensaKaikki)
    }
    return {
      hyvityssumma: hyvitykset,
      reskontrasumma: reskontrasumma
    }
  }

  // OTETAANKO HUOMIOON KAIKKI MUIKKARIT?
  annaLaskunSummatJaTilaLaskulleTietyllaPaivamaaralla(juurilasku: Lasku, pvm: Date, reskontra: LaskuReskontra[]): LaskuryppaanSummatJaTila {
    const viimeinenTavallinen = this.annaViimeisinTavallinenLaskuPaivamaaralle(juurilasku, pvm)
    const muistutuksetJaHyvitykset = this.annaLaskuryppaanViimeisinMuistutusJaHyvityksetPaivamaaralle(juurilasku, pvm)
    return this.annaLaskunSummatJaTilaPaivamaaralle(juurilasku, pvm, viimeinenTavallinen, muistutuksetJaHyvitykset, reskontra, true)
  }

  // annaLaskunSummatJaTilaLaskulle(juurilasku: Lasku, laskeUudelleen: boolean = true): LaskuryppaanSummatJaTila {
  //   const viimeinenTavallinen = this.annaViimeisinTavallinenLasku(juurilasku)
  //   const muistutuksetJaHyvitykset = this.annaLaskuryppaanViimeisinMuistutusJaHyvitykset(juurilasku)
  //   return this.annaLaskunSummatJaTila(juurilasku, viimeinenTavallinen, muistutuksetJaHyvitykset, juurilasku.reskontra, laskeUudelleen)
  // }

  annaLaskunSummatJaTilaLaskulleReskontralla(juurilasku: Lasku, reskontra: LaskuReskontra[], laskeUudelleen: boolean = true): LaskuryppaanSummatJaTila {
    const viimeinenTavallinen = this.annaViimeisinTavallinenLasku(juurilasku)
    const muistutuksetJaHyvitykset = this.annaLaskuryppaanViimeisinMuistutusJaHyvitykset(juurilasku)
    return this.annaLaskunSummatJaTilaReskontralle(juurilasku, viimeinenTavallinen, muistutuksetJaHyvitykset, reskontra, laskeUudelleen)
  }

  paivitaLaskunTila(juurilasku: Lasku, reskontra: LaskuReskontra[]): void {
    juurilasku.tila = null
    juurilasku.tila = this.annaLaskunSummatJaTilaLaskulleReskontralla(juurilasku, reskontra).tila
  }

  annaMuistutuslaskunKulutTalleLaskulle(juurilasku: Lasku, kasiteltava: LaskuBase): { korot: Big, kulut: Big } {

    let edellinenMuikkari: LaskuBase = null
    for (const korvaava of juurilasku.korvaus) {
      if (korvaava.avain === kasiteltava.avain) {
        break
      }
      if (korvaava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
        edellinenMuikkari = korvaava
      } else if (korvaava.nrotyyppi === LaskunumeroTyyppi.KORJAUS || korvaava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN) {
        edellinenMuikkari = null
      }
    }

    const nykyisenKulut = this.annaMuistutuslaskunKulut(kasiteltava)
    if (edellinenMuikkari) {
      const edellisenKulut = this.annaMuistutuslaskunKulut(edellinenMuikkari)
      nykyisenKulut.kulut = nykyisenKulut.kulut.minus(edellisenKulut.kulut)
      nykyisenKulut.korot = nykyisenKulut.korot.minus(edellisenKulut.korot)
    }

    return nykyisenKulut
  }

  annaMuistutuslaskunKulut(kasiteltava: LaskuBase): { korot: Big, kulut: Big } {
    const paluuarvo = { korot: new Big('0'), kulut: new Big('0') }
    // return (tuote.tuote.avain !== TuoteAvaimet.HUOMAUTUSKULU && tuote.tuote.avain !== TuoteAvaimet.KORKOKULU) || Math.floor(tuote.hinta * 100) !== 0
    if (kasiteltava && kasiteltava.tuotteet) {
      for (const tuote of kasiteltava.tuotteet) {
        if (tuote && tuote.tuote) {
          if (tuote.tuote.avain === TuoteAvaimet.HUOMAUTUSKULU) {
            paluuarvo.kulut = paluuarvo.kulut.add(this.annaLaskurivinYhteensaLaskunTuotteelle(tuote))
          }
          if (tuote.tuote.avain === TuoteAvaimet.KORKOKULU) {
            paluuarvo.korot = paluuarvo.korot.add(this.annaLaskurivinYhteensaLaskunTuotteelle(tuote))
          }
        }
      }
    }
    return paluuarvo
  }

  private annaLaskuryppaanViimeisinMuistutusJaHyvityksetPaivamaaralle(juurilasku: Lasku, pvm: Date): LaskuBase[] {
    if (juurilasku.korvaus) {
      const kaikki = this.annaLaskuryppaanHyvityslaskutPaivamaaralle(juurilasku, pvm)
      const vikaMuikkari = this.annaLaskuryppaanViimeisinMuistutuslaskuPaivamaaralle(juurilasku, pvm)
      if (vikaMuikkari) {
        kaikki.push(vikaMuikkari)
      }
      return kaikki
    }
    return []
  }

  annaLaskuryppaanViimeisinMuistutusJaHyvitykset(juurilasku: Lasku): LaskuBase[] {
    if (juurilasku.korvaus) {
      const kaikki = this.annaLaskuryppaanHyvityslaskut(juurilasku)
      const vikaMuikkari = this.annaLaskuryppaanViimeisinMuistutuslasku(juurilasku)
      if (vikaMuikkari) {
        kaikki.push(vikaMuikkari)
      }
      return kaikki
    }
    return []
  }

  annaLaskuryppaanMuistutuksetJaHyvitykset(juurilasku: Lasku): LaskuBase[] {
    if (juurilasku.korvaus) {
      const muistutukset = this.annaLaskuryppaanMuistutuslaskut(juurilasku)
      muistutukset.push(...this.annaLaskuryppaanHyvityslaskut(juurilasku))
      return muistutukset
    }
    return []
  }

  private annaLaskuryppaanViimeisinMuistutuslaskuPaivamaaralle(juurilasku: Lasku, pvm: Date): LaskuBase | null {
    const muikkarit = this.annaLaskuryppaanMuistutuslaskut(juurilasku).filter(a => {
      return this.dateService.paiviaValissa(pvm, a.pvm.toDate()) > -1
    })
    return muikkarit.length > 0 ? muikkarit[muikkarit.length - 1] : null
  }

  annaLaskuryppaanViimeisinMuistutuslasku(juurilasku: Lasku): LaskuBase | null {
    const muikkarit = this.annaLaskuryppaanMuistutuslaskut(juurilasku)
    return muikkarit.length > 0 ? muikkarit[muikkarit.length - 1] : null
  }

  annaLaskuryppaanMuistutuslaskut(juurilasku: Lasku): LaskuBase[] {
    // HUOM! Jos ehtoja muutetaan, katso myös this.annaMuistutuslaskunNumero
    let muistutukset: LaskuBase[] = []
    if (juurilasku.korvaus) {
      for (const korvaava of juurilasku.korvaus) {
        if (korvaava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
          muistutukset.push(korvaava)
        } else if (korvaava.nrotyyppi === LaskunumeroTyyppi.KORJAUS || korvaava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN) {
          muistutukset = []
        }
      }
    }
    return muistutukset
  }

  annaLaskuryppaanHyvityslaskutHetkelle(juurilasku: Lasku, pvm: Timestamp): LaskuBase[] {
    const hyvitykset: LaskuBase[] = []
    if (juurilasku.korvaus) {
      for (const korvaava of juurilasku.korvaus) {
        if (korvaava.nrotyyppi === LaskunumeroTyyppi.HYVITYS && korvaava.pvm.toMillis() < pvm.toMillis()) {
          hyvitykset.push(korvaava)
        }
      }
    }
    return hyvitykset
  }

  annaLaskuryppaanPaalaskunEriVersiot(juurilasku: Lasku): LaskuBase[] {
    const hyvitykset: LaskuBase[] = [juurilasku]
    if (juurilasku.korvaus) {
      for (const korvaava of juurilasku.korvaus) {
        if (korvaava.nrotyyppi === LaskunumeroTyyppi.KORJAUS || korvaava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN) {
          hyvitykset.push(korvaava)
        }
      }
    }
    return hyvitykset
  }

  annaLaskuryppaanViimeisinHyvityslasku(juurilasku: Lasku): LaskuBase | null {
    const hyvitykset = this.annaLaskuryppaanHyvityslaskut(juurilasku)
    return hyvitykset.length > 0 ? hyvitykset[hyvitykset.length - 1] : null
  }

  annaLaskuryppaanHyvityslaskut(juurilasku: Lasku): LaskuBase[] {
    const hyvitykset: LaskuBase[] = []
    if (juurilasku.korvaus) {
      for (const korvaava of juurilasku.korvaus) {
        if (korvaava.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
          hyvitykset.push(korvaava)
        }
      }
    }
    return hyvitykset
  }

  private annaLaskuryppaanHyvityslaskutPaivamaaralle(juurilasku: Lasku, pvm: Date): LaskuBase[] {
    const hyvitykset: LaskuBase[] = []
    if (juurilasku.korvaus) {
      for (const korvaava of juurilasku.korvaus) {
        if (korvaava.nrotyyppi === LaskunumeroTyyppi.HYVITYS && this.dateService.paiviaValissa(pvm, korvaava.pvm.toDate()) > -1) {
          hyvitykset.push(korvaava)
        }
      }
    }
    return hyvitykset
  }

  paivitaRyppaanJaKasiteltavanSummat(juurilasku: Lasku, kasiteltava: LaskuBase, reskontra: LaskuReskontra[]): void {
    const summat = this.annaLaskunSummat(kasiteltava)

    const reskontraJaHyvitykset = this.annaLaskunReskontraJaHyvityssummaHetkelle(juurilasku, kasiteltava.pvm, reskontra)
    const reskontranSumma = this.reskontraService.annaReskontranSumma(reskontra)
    kasiteltava.avoinna = this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaKaikki.minus(reskontranSumma)) // .minus(hyvitystenSummat.yhteensaKaikki)
    kasiteltava.summa = this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaKaikki)
    kasiteltava.summaAlv0 = this.currencyService.muutaBigDecimalRahaksi(summat.yhteensaIlmanAlv)
    kasiteltava.summaHyvityksetLaskunPvm = this.currencyService.muutaBigDecimalRahaksi(reskontraJaHyvitykset.hyvityssumma)
    kasiteltava.summaReskontraLaskunPvm = this.currencyService.muutaBigDecimalRahaksi(reskontraJaHyvitykset.reskontrasumma)

    if (kasiteltava.summa < 0) {
      kasiteltava.nrotyyppi = LaskunumeroTyyppi.HYVITYS
    }

    const viimeisinTavallinen = this.annaViimeisinTavallinenLasku(juurilasku)
    if (viimeisinTavallinen.avain !== kasiteltava.avain) {
      const summatViimeisinTavallinen = this.annaLaskunSummat(viimeisinTavallinen)
      const reskontraJaHyvityksetViimeisinTavallinen = this.annaLaskunReskontraJaHyvityssummaHetkelle(juurilasku, viimeisinTavallinen.pvm, reskontra)
      viimeisinTavallinen.avoinna = this.currencyService.muutaBigDecimalRahaksi(summatViimeisinTavallinen.yhteensaKaikki.minus(reskontranSumma)) // .minus(hyvitystenSummat.yhteensaKaikki)
      viimeisinTavallinen.summa = this.currencyService.muutaBigDecimalRahaksi(summatViimeisinTavallinen.yhteensaKaikki)
      viimeisinTavallinen.summaAlv0 = this.currencyService.muutaBigDecimalRahaksi(summatViimeisinTavallinen.yhteensaIlmanAlv)
      viimeisinTavallinen.summaHyvityksetLaskunPvm = this.currencyService.muutaBigDecimalRahaksi(reskontraJaHyvityksetViimeisinTavallinen.hyvityssumma)
      viimeisinTavallinen.summaReskontraLaskunPvm = this.currencyService.muutaBigDecimalRahaksi(reskontraJaHyvityksetViimeisinTavallinen.reskontrasumma)

      if (viimeisinTavallinen.summa < 0) {
        viimeisinTavallinen.nrotyyppi = LaskunumeroTyyppi.HYVITYS
      }
    }

    const muistutusJaHyvitykset = this.annaLaskuryppaanViimeisinMuistutusJaHyvitykset(juurilasku)
    const laskuryppaanSummat = this.annaLaskuryppaanSummat(viimeisinTavallinen, muistutusJaHyvitykset, true)

    const summaYhteensaBig = new Big(viimeisinTavallinen.summa).plus(laskuryppaanSummat.huomautuskorot).plus(laskuryppaanSummat.huomautuskulut)
    const summaYhteensaBigAlv0 = new Big(viimeisinTavallinen.summaAlv0).plus(laskuryppaanSummat.huomautuskorot).plus(laskuryppaanSummat.huomautuskulut)
    juurilasku.summaRypas = this.currencyService.muutaBigDecimalRahaksi(summaYhteensaBig)
    juurilasku.summaRypasAlv0 = this.currencyService.muutaBigDecimalRahaksi(summaYhteensaBigAlv0)

    const avoinnaBig = summaYhteensaBig.plus(laskuryppaanSummat.hyvitykset).minus(reskontranSumma)

    juurilasku.avoinnaRypas = this.currencyService.muutaBigDecimalRahaksi(avoinnaBig)

  }

  annaMuotoiltuVirtuaaliviivakoodi(juurilasku: Lasku, kasiteltava: LaskuBase, asetukset: Laskuasetukset): string {
    if (!juurilasku || !kasiteltava || !asetukset) {
      return ''
    }

    const maksettavaa = this.annaMaksettavaa(kasiteltava)
    const viite = this.annaMuotoiltuViitenumero(juurilasku)
    const params: VirtuaaliviivakoodinOsat = {
      iban: asetukset.iban,
      summa: maksettavaa,
      viitenumero: viite,
      erapvm: kasiteltava.erapvml
    }
    return this.viitenumeroService.luoSuomalainenVirtuaaliviivakoodi(params)
  }

  annaMuotoiltuQrkoodi(juurilasku: Lasku, kasiteltava: LaskuBase, asetukset: Laskuasetukset): string {
    if (!juurilasku || !kasiteltava || !asetukset) {
      return ''
    }

    const maksettavaa = this.annaMaksettavaa(kasiteltava)
    const viite = this.annaMuotoiltuViitenumero(juurilasku)
    const params: LaskunQrKoodinOsat = {
      serviceTag: 'BCD',
      version: '001',
      charset: LaskunQrKoodinCharacterSet.UTF8,
      identificationCode: 'SCT',
      bic: asetukset.bic,
      iban: asetukset.iban,
      maksunSaajaNimi: asetukset.nimi,
      valuutta: kasiteltava.valuutta,
      summa: this.currencyService.muutaBigDecimalRahaksi(maksettavaa),
      purposeCode: null,
      viitenumero: viite,
      erapaiva: kasiteltava.erapvml,
      remittanceInfo: null,
      lisatiedot: null
    }
    return this.viitenumeroService.luoSuomalainenQrKoodi(params)
  }

  annaViimeisinTavallinenLaskuPaivamaaralle(juurilasku: Lasku, pvm: Date): LaskuBase {
    let viimeinenTavallinen: LaskuBase = juurilasku
    if (juurilasku.korvaus) {
      for (const korvaava of juurilasku.korvaus) {
        if (
          korvaava &&
          (korvaava.nrotyyppi === LaskunumeroTyyppi.KORJAUS || korvaava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN) &&
          this.dateService.paiviaValissa(pvm, korvaava.pvm.toDate()) > -1
        ) {
          viimeinenTavallinen = korvaava
        }
      }
    }
    return viimeinenTavallinen
  }

  annaViimeisinTavallinenLasku(juurilasku: Lasku): LaskuBase {
    let viimeinenTavallinen: LaskuBase = juurilasku
    if (juurilasku.korvaus) {
      for (const korvaava of juurilasku.korvaus) {
        if (korvaava && (korvaava.nrotyyppi === LaskunumeroTyyppi.KORJAUS || korvaava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN)) {
          viimeinenTavallinen = korvaava
        }
      }
    }
    return viimeinenTavallinen
  }

  annaKasiteltavaLasku(juurilasku: Lasku, kasiteltavanAvain: string): LaskuBase {
    if (juurilasku.avain === kasiteltavanAvain) {
      return juurilasku
    } else {
      for (const kasiteltava of juurilasku.korvaus) {
        if (kasiteltava.avain === kasiteltavanAvain) {
          return kasiteltava
        }
      }
    }
    throw new Error('Laskusta ' + juurilasku.avain + ' ei löydy käsiteltävää laskua ' + kasiteltavanAvain)
  }

  annaViimeisinKasiteltavaLasku(juurilasku: Lasku): LaskuBase {
    if (juurilasku.korvaus && juurilasku.korvaus.length > 0) {
      return juurilasku.korvaus[juurilasku.korvaus.length - 1]
    }
    return juurilasku
  }

  annaLaskunKorvaustila(tiedot: LaskuBase): LaskunTila {
    if (tiedot.nrotyyppi === LaskunumeroTyyppi.KORJAUS) {
      return LaskunTila.korvattuLaskulla
    }
    return LaskunTila.korvattuMuistutusLaskulla
  }

  annaLokalisoituMaa(koodi: string, kasiteltava: LaskuBase) {
    if (koodi && kasiteltava && kasiteltava.kieli) {
      return this.countryLocalizationService.getNameTranslateTaxAreaToCountryName(koodi, kasiteltava.kieli)
    }
    return ''
  }

  onkoKasiteltavaMuuKuinLuonnos(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return !this.onkoKasiteltavaLuonnos(juurilasku, kasiteltava)
  }

  onkoKasiteltavaLuonnos(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {

    if (juurilasku.tila !== LaskunTila.luonnos) {
      return false
    }

    let kas: LaskuBase = juurilasku
    if (juurilasku.korvaus && juurilasku.korvaus.length > 0) {
      kas = juurilasku.korvaus[juurilasku.korvaus.length - 1]
    }

    if (kasiteltava.avain === kas.avain) {
      return true
    }

    return false

  }

  onkoKasiteltavaMuuKuinMitatoity(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {
    return !this.onkoKasiteltavaMitatoity(juurilasku, kasiteltava)
  }

  onkoKasiteltavaMitatoity(juurilasku: Lasku, kasiteltava: LaskuBase): boolean {

    if (!juurilasku || !kasiteltava) {
      return false
    }

    if (juurilasku.tila !== LaskunTila.mitatoity) {
      return false
    }

    let kas: LaskuBase = juurilasku
    if (juurilasku.korvaus && juurilasku.korvaus.length > 0) {
      kas = juurilasku.korvaus[juurilasku.korvaus.length - 1]
    }

    if (kasiteltava.avain === kas.avain) {
      return true
    }

    return false

  }

  public annaLaskurivinYhteensaLaskunTuotteelle(tuote: LaskunTuote): number {

    if (!tuote) {
      return null
    }

    if (tuote.hinnanTyyppi === 'yhteensa' && this.currencyService.onkoNumero(tuote.yhteensa)) {
      return this.currencyService.muutaBigDecimalRahaksi(new Big(tuote.yhteensa))
    } else {
      const rivinSummat = this.annaRivinSummatLaskettunaKappalehinnasta(tuote)
      return this.currencyService.muutaBigDecimalRahaksi(rivinSummat.yhteensaAlvnKanssa)
    }

  }

  // private annaUseanLaskunSummat(kasiteltavat: LaskuBase[]): LaskunSummat {
  //   const erittely: { [key: string]: AlvErittelynOsa } = {}
  //   const summat: LaskunSummat = {
  //     alvErittely: [],
  //     yhteensaAlv: new Big('0'),
  //     yhteensaIlmanAlv: new Big('0'),
  //     yhteensaKaikki: new Big('0'),
  //     alennustaYhteensa: new Big('0'),
  //     alvErittelyMap: erittely
  //   }

  //   for (const kasiteltava of kasiteltavat) {
  //     const kasiteltavanSummat = this.annaLaskunSummat(kasiteltava)
  //     this.lisaaAlvErittelynSummatToiseen(summat, erittely, kasiteltavanSummat.alvErittelyMap)
  //     summat.yhteensaAlv = summat.yhteensaAlv.plus(kasiteltavanSummat.yhteensaAlv)
  //     summat.yhteensaIlmanAlv = summat.yhteensaIlmanAlv.plus(kasiteltavanSummat.yhteensaIlmanAlv)
  //     summat.yhteensaKaikki = summat.yhteensaKaikki.plus(kasiteltavanSummat.yhteensaKaikki)
  //     summat.alennustaYhteensa = summat.yhteensaAlv.plus(kasiteltavanSummat.alennustaYhteensa)
  //   }

  //   return summat
  // }

  // private lisaaAlvErittelynSummatToiseen(summat: LaskunSummat, erittely: { [key: string]: AlvErittelynOsa }, tastaLisataan: { [key: string]: AlvErittelynOsa }) {
  //   for (const key of Object.keys(tastaLisataan)) {
  //     const erittelynOsa: AlvErittelynOsa = tastaLisataan[key]
  //     if (erittelynOsa) {
  //       let osa: AlvErittelynOsa = erittely[key]
  //       if (!osa) {
  //         osa = {
  //           alvitonSumma: new Big('0'),
  //           alvinSumma: new Big('0'),
  //           alvKanta: erittelynOsa.alvKanta
  //         }
  //         erittely[key] = osa
  //         summat.alvErittely.push(osa)
  //       }
  //       osa.alvitonSumma = osa.alvitonSumma.plus(erittelynOsa.alvitonSumma)
  //       osa.alvinSumma =  osa.alvinSumma.plus(erittelynOsa.alvinSumma)
  //     }
  //   }
  // }

  private _lisaaLaskurivinAlvSummat(tuote: LaskunTuote, summat: LaskunSummat, erittely: { [key: string]: AlvErittelynOsa }) {
    if (tuote.hinnanTyyppi === 'yhteensa' && this.currencyService.onkoNumero(tuote.yhteensa)) {

      const alvKerroin = this.annaAlvKerroin(tuote)
      const aleKerroin = this.annaAleKerroin(tuote)
      const alennusta = !aleKerroin.eq('1')
      const riviYhteensa = new Big(tuote.yhteensa).round(2, Big.roundHalfUp)
      const yhteensaIlmanAlv = riviYhteensa.div(alvKerroin) // .round(2, Big.roundHalfUp)
      const alvinOsuus = riviYhteensa.minus(yhteensaIlmanAlv) // .round(2, Big.roundHalfUp)
      const alennusIlmanAlv = alennusta ? yhteensaIlmanAlv.div(aleKerroin).minus(yhteensaIlmanAlv) : new Big('0')
      const alennusAlvinOsuus = alennusta ? alvinOsuus.div(aleKerroin).minus(alvinOsuus) : new Big('0')

      this.lisaaAlvErittelynSummat(tuote, erittely, summat, yhteensaIlmanAlv, alvinOsuus, alennusIlmanAlv, alennusAlvinOsuus)

    } else {

      const rivinSummat = this.annaRivinSummatLaskettunaKappalehinnasta(tuote)
      // Yhteensä ilman alvia
      const yhteensaIlmanAlv = rivinSummat.yhteensaAlvnKanssa.minus(rivinSummat.alvta) // .round(2, Big.roundHalfUp)
      const yhteensaIlmanAlvAle = rivinSummat.aleAlvnKanssa.minus(rivinSummat.aleAlvta) // .round(2, Big.roundHalfUp)
      this.lisaaAlvErittelynSummat(tuote, erittely, summat, yhteensaIlmanAlv, rivinSummat.alvta, yhteensaIlmanAlvAle, rivinSummat.aleAlvta)

    }
  }

  annaYhdenLaskunTuotteenSummat(tuote: LaskunTuote): LaskunSummat {

    const erittely: { [key: string]: AlvErittelynOsa } = {}
    const summat: LaskunSummat = {
      alvErittely: [],
      yhteensaAlv: new Big('0'),
      yhteensaIlmanAlv: new Big('0'),
      yhteensaKaikki: new Big('0'),
      alennustaYhteensaIlmanAlv: new Big('0'),
      alennustaYhteensaAlv: new Big('0'),
      alvErittelyMap: erittely
    }

    this._lisaaLaskurivinAlvSummat(tuote, summat, erittely)
    summat.yhteensaKaikki = summat.yhteensaIlmanAlv.plus(summat.yhteensaAlv) // .round(2, Big.roundHalfUp)

    summat.yhteensaAlv = summat.yhteensaAlv.round(2, Big.roundHalfUp)
    summat.yhteensaIlmanAlv = summat.yhteensaIlmanAlv.round(2, Big.roundHalfUp)
    summat.yhteensaKaikki = summat.yhteensaKaikki.round(2, Big.roundHalfUp)
    summat.alennustaYhteensaIlmanAlv = summat.alennustaYhteensaIlmanAlv.round(2, Big.roundHalfUp)
    summat.alennustaYhteensaAlv = summat.alennustaYhteensaAlv.round(2, Big.roundHalfUp)

    return summat
  }

  annaMaksettavaa(kasiteltava: LaskuBase): Big {
    const summat = this.annaLaskunSummat(kasiteltava)
    if (kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
      return summat.yhteensaKaikki.plus(kasiteltava.summaHyvityksetLaskunPvm).minus(kasiteltava.summaReskontraLaskunPvm)
    }
    return summat.yhteensaKaikki
  }

  annaLaskunSummat(kasiteltava: LaskuBase): LaskunSummat {

    const erittely: { [key: string]: AlvErittelynOsa } = {}
    const summat: LaskunSummat = {
      alvErittely: [],
      yhteensaAlv: new Big('0'),
      yhteensaIlmanAlv: new Big('0'),
      yhteensaKaikki: new Big('0'),
      alennustaYhteensaIlmanAlv: new Big('0'),
      alennustaYhteensaAlv: new Big('0'),
      alvErittelyMap: erittely
    }

    if (kasiteltava && kasiteltava.tuotteet) {
      for (const tuote of kasiteltava.tuotteet) {
        this._lisaaLaskurivinAlvSummat(tuote, summat, erittely)
      }
      summat.yhteensaKaikki = summat.yhteensaIlmanAlv.plus(summat.yhteensaAlv) // .round(2, Big.roundHalfUp)
    }

    summat.yhteensaAlv = summat.yhteensaAlv.round(2, Big.roundHalfUp)
    summat.yhteensaIlmanAlv = summat.yhteensaIlmanAlv.round(2, Big.roundHalfUp)
    summat.alennustaYhteensaAlv = summat.alennustaYhteensaAlv.round(2, Big.roundHalfUp)
    summat.alennustaYhteensaIlmanAlv = summat.alennustaYhteensaIlmanAlv.round(2, Big.roundHalfUp)
    summat.yhteensaKaikki = summat.yhteensaKaikki.round(2, Big.roundHalfUp)

    return summat
  }

  public annaAlvKerroin(tuote: LaskunTuote): Big {
    const alv = ((tuote.alv || {}) as LaskunAlv).prosentti || 0
    return new Big(alv ? 1 + alv / 100 : 1).round(2, Big.roundHalfUp)
  }

  public annaAleKerroin(tuote: LaskunTuote) {
    const aleKerroin = new Big(1 - (tuote.ale ? tuote.ale / 100 : 0)).round(4, Big.roundHalfUp)
    if (aleKerroin.eq('0')) {
      return new Big('1')
    }
    return aleKerroin
  }

  public annaRivinSummatLaskettunaKappalehinnasta(tuote: LaskunTuote): { alvta: Big, yhteensaAlvnKanssa: Big, aleAlvta: Big, aleAlvnKanssa: Big } {

    const hinta = new Big(tuote.hinta || 0).round(2, Big.roundHalfUp)
    const maara = new Big(tuote.maara || 0).round(4, Big.roundHalfUp)

    // Alv ja ale
    const alvKerroin = this.annaAlvKerroin(tuote)
    const aleKerroin = this.annaAleKerroin(tuote)
    const alennusta = !aleKerroin.eq('1')

    // Laske ensin veroton alennettu hinta
    const alennettuYksikkohinta = hinta.times(aleKerroin) // .round(2, Big.roundHalfUp)

    // Sitten alennettu hinta alvin kanssa
    const yksikkoHintaAlvnKanssa = alennettuYksikkohinta.times(alvKerroin) // .round(2, Big.roundHalfUp)

    // Alvia per tuote
    const alvPerTuote = yksikkoHintaAlvnKanssa.minus(alennettuYksikkohinta) // .round(2, Big.roundHalfUp)

    // Yhteensä rivillä alvia
    const alvinOsuus = alvPerTuote.times(maara) // .round(2, Big.roundHalfUp)

    // Rivi yhteensä
    const riviYhteensa = yksikkoHintaAlvnKanssa.times(maara) // .round(2, Big.roundHalfUp)

    const tuotteetIlmanAlennustaAlv0 = hinta.times(maara)
    const tuotteetIlmanAlennusta = hinta.times(alvKerroin).times(maara)

    const alennuksenMaaraAlv0 = alennusta ? tuotteetIlmanAlennustaAlv0.minus(riviYhteensa.minus(alvinOsuus)) : new Big('0')
    const alennuksenMaara = alennusta ? tuotteetIlmanAlennusta.minus(riviYhteensa) : new Big('0')
    const aleAlvnMaara = alennuksenMaara.minus(alennuksenMaaraAlv0)

    return {
      alvta: alvinOsuus,
      yhteensaAlvnKanssa: riviYhteensa,
      aleAlvta: aleAlvnMaara,
      aleAlvnKanssa: alennuksenMaara
    }

  }

  private lisaaAlvErittelynSummat(
    tuote: LaskunTuote,
    erittely: { [key: string]: AlvErittelynOsa },
    summat: LaskunSummat,
    yhteensaIlmanAlv: Big,
    alvinOsuus: Big,
    alennusIlmanAlv: Big,
    alennusAlvinOsuus: Big
  ) {
    if (tuote.alv) {
      let osa: AlvErittelynOsa = erittely[tuote.alv.tunniste]
      if (!osa) {
        osa = {
          alvitonSumma: new Big('0'),
          alvinSumma: new Big('0'),
          alvKanta: tuote.alv,
          alennustaIlmanAlv: new Big('0'),
          alennustaAlv: new Big('0')
        }
        erittely[tuote.alv.tunniste] = osa
        summat.alvErittely.push(osa)
      }
      osa.alvitonSumma = osa.alvitonSumma.plus(yhteensaIlmanAlv)
      osa.alvinSumma = osa.alvinSumma.plus(alvinOsuus)
      osa.alennustaIlmanAlv = osa.alennustaIlmanAlv.plus(alennusIlmanAlv)
      osa.alennustaAlv = osa.alennustaAlv.plus(alennusAlvinOsuus)
      summat.yhteensaAlv = summat.yhteensaAlv.plus(alvinOsuus)
      summat.alennustaYhteensaAlv = summat.alennustaYhteensaAlv.plus(alennusAlvinOsuus)
    }
    summat.yhteensaIlmanAlv = summat.yhteensaIlmanAlv.plus(yhteensaIlmanAlv)
    summat.alennustaYhteensaIlmanAlv = summat.alennustaYhteensaIlmanAlv.plus(alennusIlmanAlv)
  }

  public annaAlvHuomautus(kasiteltava: LaskuBase): string | null {
    if (!kasiteltava) {
      return null
    }
    if (
      kasiteltava.tyyppi === LaskunTyypit.RAKENNUSALA.avain ||
      kasiteltava.tyyppi === LaskunTyypit.EU_TAVARA.avain ||
      kasiteltava.tyyppi === LaskunTyypit.EU_PALVELU.avain ||
      kasiteltava.tyyppi === LaskunTyypit.MUU_MAAILMA.avain
    ) {
      return this.annaLokalisoituMerkkijono('alv-huomautus.' + kasiteltava.tyyppi, kasiteltava)
    }
    return null
  }

  public annaViimeisinEiLuonnosLasku(juurilasku: Lasku): LaskuBase {
    let kasiteltava: LaskuBase = juurilasku
    if (juurilasku.korvaus) {
      const indeksi = juurilasku.tila === LaskunTila.luonnos ? juurilasku.korvaus.length - 2 : juurilasku.korvaus.length - 1
      if (indeksi > -1) {
        kasiteltava = juurilasku.korvaus[indeksi]
      }
    }
    return kasiteltava
  }

  public annaViimeisinEiLuonnosLaskuTaiNullJosVainLuonnos(juurilasku: Lasku): LaskuBase {
    if (juurilasku.tila === LaskunTila.luonnos && (!juurilasku.korvaus || juurilasku.korvaus.length < 1)) {
      return null
    }
    return this.annaViimeisinEiLuonnosLasku(juurilasku)
  }

  public annaViimeisinMuistutuslasku(juurilasku: Lasku): LaskuBase | null {
    if (juurilasku.korvaus) {
      let viimeisinMuistutus = null
      for (const korvaava of juurilasku.korvaus) {
        if (korvaava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
          viimeisinMuistutus = korvaava
        }
      }
      return viimeisinMuistutus
    }
    return null
  }

  public annaViimeisinMuokattuLasku(juurilasku: Lasku): LaskuBase {
    let viimeisinMuokattu: LaskuBase = juurilasku
    if (juurilasku.korvaus) {
      for (const korvaava of juurilasku.korvaus) {
        if (korvaava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN || korvaava.nrotyyppi === LaskunumeroTyyppi.KORJAUS) {
          viimeisinMuokattu = korvaava
        }
      }
    }
    return viimeisinMuokattu
  }

  // public annaLaskunTila(reskontraSumma: Big, laskunSumma: Big, juurilasku: Lasku, kasiteltava: LaskuBase): LaskunTila {
  //   const avoinna = laskunSumma.minus(reskontraSumma)
  //   if (avoinna.round(2, Big.roundHalfUp).eq('0')) {
  //     return LaskunTila.maksettu
  //   } else if (avoinna.round(2, Big.roundHalfUp).lt('0')) {
  //     return LaskunTila.maksettuLiikaa
  //   } else if (this.onkoLaskunErapaivaMennyt(kasiteltava)) {
  //     return LaskunTila.eraantynyt
  //   }
  //   return LaskunTila.avoin
  // }

  onkoLaskunErapaivaMennytPaivalle(kasiteltava: LaskuBase, pvm: Date): boolean {
    return this.dateService.paiviaValissaTimestamp(kasiteltava.erapvm, this.timestampProvider.dateToTimestamp(pvm)) < 0
  }

  onkoLaskunErapaivaMennyt(kasiteltava: LaskuBase): boolean {
    return this.dateService.paiviaValissaTimestamp(kasiteltava.erapvm, this.timestampProvider.now()) < 0
  }

  public annaLokalisoituMuistutus(juurilasku: Lasku, kasiteltava: LaskuBase): string {

    const viimeisinMuokattu = this.annaViimeisinMuokattuLasku(juurilasku)

    const erapaiva = this.formatoiPvm(viimeisinMuokattu.erapvml, viimeisinMuokattu.erapvm, kasiteltava)
    const laskunumero = this.annaMuotoiltuLaskunumero(juurilasku, kasiteltava)

    return this.annaLokalisoituMerkkijono('pdf-perinteinen.muistutus', kasiteltava, { laskunumero: laskunumero, erapaiva: erapaiva })
  }

  public annaLokalisoituLaskunOtsikko(juurilasku: Lasku, kasiteltava: LaskuBase, maksettavaaYhteensa: Big): string {

    if (this.onkoKasiteltavaMitatoity(juurilasku, kasiteltava)) {
      return this.annaLokalisoituMerkkijono('pdf-perinteinen.mitatoity', kasiteltava)
    }

    if (this.onkoKasiteltavaLuonnos(juurilasku, kasiteltava)) {
      return this.annaLokalisoituMerkkijono('pdf-perinteinen.luonnos', kasiteltava)
    }

    if (maksettavaaYhteensa && maksettavaaYhteensa.lt('0')) {
      return this.annaLokalisoituMerkkijono('pdf-perinteinen.hyvityslasku', kasiteltava)
    }

    const laskunAvain = 'pdf-perinteinen.' + (kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS ? 'muistutuslasku' : 'lasku')
    return this.annaLokalisoituMerkkijono(laskunAvain, kasiteltava)
  }

  public annaLokalisoituValuutanNimi(kasiteltava: LaskuBase): string {
    if (kasiteltava.valuutta === 'EUR') {
      return this.annaLokalisoituMerkkijono('pdf-perinteinen.euro', kasiteltava)
    }
    return kasiteltava.valuutta
  }

  /**
   * Palauttaa laskun jonka kasiteltava on korvannut. (edellinen lasku)
   * @param juurilasku
   * @param kasiteltava
   */
  public annaKorvattuLasku(juurilasku: Lasku, kasiteltava: LaskuBase): LaskuBase | null {
    if (!juurilasku || !kasiteltava) {
      return null
    }
    if (juurilasku.avain === kasiteltava.avain) {
      return null
    }
    if (juurilasku.korvaus && juurilasku.korvaus.length > 0) {
      let korvattu: LaskuBase = juurilasku
      for (const korvaava of juurilasku.korvaus) {
        if (korvaava.avain === kasiteltava.avain) {
          return korvattu
        }
        korvattu = korvaava
      }
    }
    return null
  }

  /**
   * Palauttaa laskun, joka on korvannut tämän (ei viimeisin, vaan seuraava)
   * @param juurilasku
   * @param kasiteltava
   */
  public annaKorvaavaLasku(juurilasku: Lasku, kasiteltava: LaskuBase): LaskuBase | null {
    if (!juurilasku || !kasiteltava) {
      return null
    }
    let palautaSeuraava = false
    if (juurilasku.avain === kasiteltava.avain) {
      palautaSeuraava = true
    }
    if (juurilasku.korvaus && juurilasku.korvaus.length > 0) {
      for (const korvaava of juurilasku.korvaus) {
        if (palautaSeuraava && korvaava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN || LaskunumeroTyyppi.KORJAUS) {
          return korvaava
        }
        if (korvaava.avain === kasiteltava.avain) {
          palautaSeuraava = true
        }
      }
    }
    return null
  }

  public annaLisatiedot(juurilasku: Lasku, kasiteltava: LaskuBase): string {

    if (!juurilasku || !kasiteltava) {
      return null
    }

    let lisatiedot = ''
    if (kasiteltava.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
      const muotoiltuKorvaava = this.annaMuotoiltuLaskunumero(juurilasku, juurilasku)
      lisatiedot = this.annaLokalisoituMerkkijono('pdf-perinteinen.hyvittaa-laskun', kasiteltava, { korvatunLaskunNumero: muotoiltuKorvaava })
    } else if (kasiteltava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {

      const maara = this.annaMuistutuslaskunNumero(juurilasku, kasiteltava)
      if (maara > 6) {
        lisatiedot = this.annaLokalisoituMerkkijono('pdf-perinteinen.monesko-muistutuslasku', kasiteltava, { muistutuslaskujenMaara: maara })
      } else {
        lisatiedot = this.annaLokalisoituMerkkijono('pdf-perinteinen.monesko-muistutuslasku-' + maara, kasiteltava, { muistutuslaskujenMaara: maara })
      }

    } else if (kasiteltava.nrotyyppi === LaskunumeroTyyppi.KORJAUS) {
      const muotoiltuKorvaava = this.annaMuotoiltuLaskunumero(juurilasku, juurilasku)
      lisatiedot = this.annaLokalisoituMerkkijono('pdf-perinteinen.korvaa-laskun', kasiteltava, { korvatunLaskunNumero: muotoiltuKorvaava })
    }

    lisatiedot = lisatiedot.trim()

    if (kasiteltava.lisatiedot) {
      if (lisatiedot) {
        lisatiedot += ' '
      }
      lisatiedot += kasiteltava.lisatiedot.trim()
    }

    if (lisatiedot === '') {
      return null
    }

    return lisatiedot
  }

  annaHyvityslaskunNumero(juurilasku: Lasku, kasiteltava: LaskuBase): number {
    if (kasiteltava.nrotyyppi !== LaskunumeroTyyppi.HYVITYS) {
      throw new Error('kasiteltava EI ole hyvitys, ' + kasiteltava.nrotyyppi)
    }
    let maara = 1
    if (juurilasku.korvaus && juurilasku.korvaus.length > 0) {
      for (const korvaava of juurilasku.korvaus) {
        if (korvaava.avain === kasiteltava.avain) {
          break
        }
        if (korvaava.nrotyyppi === LaskunumeroTyyppi.HYVITYS) {
          maara += 1
        }
      }
    }
    return maara
  }

  annaMuistutuslaskunNumero(juurilasku: Lasku, kasiteltava: LaskuBase): number {

    // HUOM! Jos ehtoja muutetaan, katso myös this.annaLaskuryppaanMuistutuslaskut

    if (kasiteltava.nrotyyppi !== LaskunumeroTyyppi.MUISTUTUS) {
      throw new Error('kasiteltava EI ole muistutus, ' + kasiteltava.nrotyyppi)
    }

    let maara = 0
    if (juurilasku.korvaus) {
      for (const korvaava of juurilasku.korvaus) {
        if (korvaava.avain === kasiteltava.avain) {
          // console.log('Löytyi', korvaava.avain)
          maara++
          break
        }
        if (korvaava.nrotyyppi === LaskunumeroTyyppi.MUISTUTUS) {
          // console.log('MUISTUTUS', korvaava.avain, korvaava.date, korvaava.nrotyyppi)
          maara++
        } else if (korvaava.nrotyyppi === LaskunumeroTyyppi.KORJAUS || korvaava.nrotyyppi === LaskunumeroTyyppi.TAVALLINEN) {
          // KATSO MYÖS annaMuistutukset metodi!
          maara = 0
        }
      }
    }
    // console.log('maara', maara)
    return maara
  }

}
