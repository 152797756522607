import { Component, OnDestroy, ErrorHandler } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { Router, ActivatedRoute } from '@angular/router'

import { Subject } from 'rxjs'

import { LadataanService } from '../../_jaettu-angular/service/ladataan.service'
import { environment } from 'environments/environment'
import { VaihdaKieliDialog, VaihdaKieliDialogData } from 'app/vaihda-kieli.dialog'
import { ActionAfterAuthentication, NetsTunnistauduRequest, NetsTunnistauduResponse } from 'app/_jaettu/model/tunnistaminen'
import { TunnistautuminenService } from 'app/_angular/service/tunnistautuminen.service'
import { AllowedDialogsService } from 'app/_angular/service/allowed-dialogs.service'
import { KayttajaService } from 'app/_angular/service/kayttaja.service'
import { FirebaseLemonaid } from 'app/_angular/service/firebase-lemonaid.service'

@Component({
  templateUrl: './nets-return.component.html',
  styleUrls: ['./nets-return.component.css']
})
export class TunnistautuminenNetsReturnComponent implements OnDestroy {

  private ngUnsubscribe: Subject<void> = new Subject<void>()

  virhe: string = null
  year = new Date().getFullYear()

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _errorHandler: ErrorHandler,
    private _dialog: MatDialog,
    private _ladataanService: LadataanService,
    private _router: Router,
    private _tunnistautuminenService: TunnistautuminenService,
    private _allowedDialogsService: AllowedDialogsService,
    private _kayttajaService: KayttajaService,
    private _firebaseLemonaid: FirebaseLemonaid
  ) {
    this._allowedDialogsService.disableAllDialogs()
    this._ladataanService.aloitaLataaminen()

    const errorCode = this._activatedRoute.snapshot.queryParamMap.get('error')
    const errorDescription = this._activatedRoute.snapshot.queryParamMap.get('error_description')
    const errorURIString = this._activatedRoute.snapshot.queryParamMap.get('error_uri')
    const code = this._activatedRoute.snapshot.queryParamMap.get('code')
    const stateHash = this._activatedRoute.snapshot.queryParamMap.get('state')

    const clientSession = this._tunnistautuminenService.getClientSideAuthenticationSession()

    this._kayttajaService.getKayttaja().then(kayttaja => {
      const netsEnv = this._tunnistautuminenService.getNetsEnvironment(environment.environment)
      return this._tunnistautuminenService.saveHistory(kayttaja, 'returned', 'Includes code: ' + !!code + (errorCode ? 'Error: ' + errorCode : ''), netsEnv)
    }).catch(err => {
      this._errorHandler.handleError(new Error('Strong auth log 1 save failed ' + err?.message || ''))
    })

    if (errorCode || errorDescription || errorURIString) {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(new Error('Error in strong auth return component: ' + errorCode + ' ,__, ' + errorDescription + ' ,__, ' + errorURIString))
      this._allowedDialogsService.onlyAllowTervetuloaOrTunnistaminen()
      this._tunnistautuminenService.setTunnistautuminenState(code === 'cancel' ? 'error-cancel' : 'error')
      this._tunnistautuminenService.navigateByAction(clientSession?.action ?? 'continue-welcome')
    } else if (code && stateHash) {
      const pyynto: NetsTunnistauduRequest = {
        avain: clientSession.sessionAvain,
        code: code,
        state: clientSession.state,
        stateHash: stateHash,
        env: this._tunnistautuminenService.getNetsEnvironment(environment.environment)
      }
      const func = this._getFunctionByAction(clientSession.action)
      this._firebaseLemonaid.functionsCall<NetsTunnistauduRequest, NetsTunnistauduResponse>(func, pyynto).then(vastaus => {

        if (vastaus.result === 'success') {
          this._kayttajaService.getKayttaja().then(kayttaja => {
            const netsEnv = this._tunnistautuminenService.getNetsEnvironment(environment.environment)
            return this._tunnistautuminenService.saveHistory(kayttaja, 'success', null, netsEnv)
          }).catch(err => {
            this._errorHandler.handleError(new Error('Strong auth log 2 save failed ' + err?.message || ''))
          })

          this._ladataanService.lopetaLataaminen()
          this._tunnistautuminenService.setTunnistautuminenState('success')
          this._allowedDialogsService.onlyAllowTervetuloaOrTunnistaminen()
          this._tunnistautuminenService.navigateByAction(clientSession.action ?? 'continue-welcome')
        } else {
          this._kayttajaService.getKayttaja().then(kayttaja => {
            const netsEnv = this._tunnistautuminenService.getNetsEnvironment(environment.environment)
            return this._tunnistautuminenService.saveHistory(kayttaja, 'error', 'unknown error', netsEnv)
          }).catch(loginErr => {
            this._errorHandler.handleError(new Error('Strong auth log 3 save failed ' + loginErr?.message || ''))
          })
          this._ladataanService.lopetaLataaminen()
          this._errorHandler.handleError('Backend error while checking NETS authentication.')
          this._allowedDialogsService.onlyAllowTervetuloaOrTunnistaminen()
          this._tunnistautuminenService.setTunnistautuminenState('error')
          this._tunnistautuminenService.navigateByAction(clientSession.action ?? 'continue-welcome')
        }

      }).catch(err => {
        this._kayttajaService.getKayttaja().then(kayttaja => {
          const netsEnv = this._tunnistautuminenService.getNetsEnvironment(environment.environment)
          return this._tunnistautuminenService.saveHistory(kayttaja, 'error', err ? JSON.stringify(err) : 'unknown error', netsEnv)
        }).catch(loginErr => {
          this._errorHandler.handleError(new Error('Strong auth log 3 save failed ' + loginErr?.message || ''))
        })
        this._ladataanService.lopetaLataaminen()
        this._errorHandler.handleError(err)
        this._allowedDialogsService.onlyAllowTervetuloaOrTunnistaminen()
        this._tunnistautuminenService.setTunnistautuminenState('error')
        this._tunnistautuminenService.navigateByAction(clientSession.action ?? 'continue-welcome')
      })
    } else {
      this._ladataanService.lopetaLataaminen()
      this._errorHandler.handleError(new Error('Error in strong auth return component: Missing code or state. Code: ' + code + ', state: ' + stateHash))
      this._allowedDialogsService.onlyAllowTervetuloaOrTunnistaminen()
      this._tunnistautuminenService.setTunnistautuminenState('error')
      this._tunnistautuminenService.navigateByAction(clientSession?.action ?? 'continue-welcome')
    }
  }

  private _getFunctionByAction(action: ActionAfterAuthentication): string {
    if (action === 'continue-payment-approval') {
      return 'tunnistautuminenNetsTarkistaMaksut'
    }
    return 'tunnistautuminenNetsTarkista'
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next()
    this.ngUnsubscribe.complete()
  }

  vaihdaKieli() {
    const data: VaihdaKieliDialogData = {
      tallenna: false
    }
    this._dialog.open(VaihdaKieliDialog, { data: data })
  }

}
