<div class="tab-container no-hidden-overflow-tabs">

  <mat-tab-group *ngIf="{ kieli: (valittuKieliObservable | async)}  as valittuKieli" #tabGroup mat-stretch-tabs animationDuration="0ms" style="margin: 2em; overflow: hidden;">

    <mat-tab label="{{'sopimus-ja-ehdot.sopimus' | translate | async}}">
      <div class="text-box" [innerHtml]="sopimusTekstiObservable | async">
      </div>

      <ng-container *ngIf="sopimuksenAllekirjoitanutObservable | async; let sopimuksenAllekirjoitanut" style="margin-left: 25px;">
        <ng-container *ngIf="sopimuksenAllekirjoitanut?.length; else eiViela">
          <div style="font-weight: bold;">{{'sopimus-ja-ehdot.allekirjoitettu-sahkoisesti' | translate | async}}</div>
          <ng-container *ngFor="let allekirjoittaja of sopimuksenAllekirjoitanut">
            <div>{{ allekirjoittaja.nimi }} ({{ allekirjoittaja.aika | lnumberpvmjaaika | async }})</div>
          </ng-container>
        </ng-container>
        <ng-template #eiViela>
          <div style="font-weight: bold;">{{'sopimus-ja-ehdot.ei-viela-allekirjoitettu' | translate | async}}</div>
        </ng-template>
      </ng-container>

      <button *ngIf="sopimusExists" mat-button class="secondarybutton" style="margin: 25px" (click)="downloadSopimus()">{{'sopimus-ja-ehdot.lataa-pdf' | translate | async}}</button>
      <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>

    </mat-tab>

    <mat-tab label="{{'sopimus-ja-ehdot.palvelukuvaus' | translate | async}}">
      <div class="text-box" [innerHtml]="palvelukuvausTekstiObservable | async"></div>

      <button mat-button class="secondarybutton" style="margin: 25px" (click)="downloadPalvelukuvaus()">{{'sopimus-ja-ehdot.lataa-pdf' | translate | async}}</button>
      <div class="lemon-error" *ngIf="commonError">{{commonError}}</div>

    </mat-tab>

    <mat-tab label="{{'sopimus-ja-ehdot.yleiset-palveluehdot' | translate | async}} TAL 2018">

      <div *ngIf="valittuKieli.kieli === 'en'; else sopimusehdotFi">
        <div class="pages-container">
          <div class="page">
            <div class="title">GENERAL CONDITIONS OF CONTRACT TAL2018</div>
            <div class="column-box">
              <div class="column">
                <p>These conditions of contract shall be applied to services, offers, order confirmations and services contracts of the accounting firm.
                </p>
                <b>Offer</b>
                <p><b>1.</b> An offer of an accounting firm shall be valid for a period of 30 days from the date of the offer, unless otherwise stated in the offer.</p>

                <b>Entry into force of the contract</b>
                <p><b>2.</b> The contract enters into force by signing of the accounting services contract by both parties, when the client has approved the accounting firm's offer, or when the accounting firm has confirmed the order of the client.
                </p>
                <b>Services</b>
                <p>
                  <b>3.</b> The tasks to be performed by the accounting firm are the ones jointly agreed upon in the service specification or other documents attached to the contract (hereinafter Services). If the accounting firm agrees to perform on the basis of the client's order other than the services mentioned in the attachment, these terms apply to them as well.
                </p>
                <p>
                  The accounting firm starts to provide the Service forthwith after the client has given the accounting firm necessary information and material and paid a possible advance payment. Accounting firm shall provide the Services carefully and professionally in accordance with the working and reporting methods of the accounting firm.
                </p>
                <b>Basic Information</b>
                <p>
                  <b>4.</b> The client shall see that the accounting firm always has up to date basic information on the client (hereinafter Basic Information). Such information include contact, personnel, financial year, trade register information, industry, domicile, nationality, members of the board of directors and other necessary information and instructions on the measures to be taken. The
                  client shall name a contact person, to whom the accounting firm can turn if needed to obtain information or decisions in relation to the Services. A party shall inform the other party if the contact person is changed.
                </p>
                <b>Giving information and instructions on measures to be taken</b>
                <p>
                  <b>5.</b> The client shall supply the accounting firm with the information and material necessary to perform the Services well enough in advance in order to enable the accounting firm to carry out its tasks properly within normal working hours. Unless otherwise agreed, the material must be available to the accounting firm as follows:
                  - entire accounting material by the 10th day of the month following the end of the month in question
                  - material relating to payroll five (5) business days
                  before the date the parties have agreed the
                  calculation of pay shall be ready
                  - material relating to financial statements and taxation
                  no later than within 30 days from the end of the financial year
                </p>
              </div>


              <div class="column">
                <p>
                  - other material no later than 10 working days before the due date. The client contributes to the producing of the Services and carefully fulfills its own tasks in accordance with the contractual terms and instructions and recommendations of the authorities and the accounting firm. Enquiries and requests of the accounting firm shall be responded to without delay.
                </p>
                <b>Confidentiality</b>
                <p>
                  <b>6.</b> The party shall keep confidential the other party's trade secrets and other confidential information brought to his knowledge. They cannot be used for any other purpose than to fulfill the contract. The offer, order confirmation and contract with its terms and thereto included plans and schemes and other material are regarded as confidential. The confidentiality
                  obligation
                  shall survive the termination of the contract. The parties shall ensure that also their employees and sub- contractors undertake to observe the confidentiality obligation.
                </p>
                <b>Validity of the contract</b>
                <p>
                  <b>7.</b> The contract is valid until further notice with a two months' notice period, unless otherwise agreed. If neither of the parties gives a notice on a fixed term contract at least two months before the end of the agreed fixed term, the contract is valid until further notice with a two months' notice period.
                </p>
                <p>
                  When the contract is terminated, the Service may - in accordance with the nature of the Service - be unfinished as regards to a past or ongoing Service processing period. This has been dealt with in section 11 below.
                </p>

                <b>Interruption of the Services and termination of a contract with immediate effect</b>
                <p>
                  <b>8.</b> The accounting firm has the right to interrupt the Services, if
                  a) the payment to the accounting firm is delayed with
                  more than seven (7) days,
                  b) the client does not provide the necessary
                  information or material in due time or does not otherwise properly contribute to the provision of the Services,
                  c) client breaches the contract in some other manner or breaks the laws, orders, instructions or recommendations of the authorities significant to the provision of the Services, or
                  d) the client refuses to follow the accounting firm's entry instructions in a situation that might lead to criminal or civil sanctions for the parties. This condition is applied even if it is only a question of the parties' different interpretations of the content or meaning of the law, an order, a recommendation or instructions, for example different interpretations of the
                  spreading
                  of income and related expenses or the valuation of assets and liabilities in the accounting or in the financial statements.
                </p>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>1 (7)</div>
            </div>
          </div>

          <div class="page">
            <div class="column-box">
              <div class="column">
                <p>
                  If the client does not correct the situation within seven (7) days from the written remark of the accounting firm, the accounting firm has the right to terminate the contract with immediate effect.
                </p>

                <p>
                  <b>9.</b> The client has the right to terminate the contract with immediate effect, if the accounting firm essentially breaches the contract and does not begin to correct the situation within seven (7) days from the written remark of the client.
                </p>
                <p>
                  <b>10.</b> A party may terminate the contract with immediate effect, if the other party applies for composition with creditors, the other party's property is applied to be transferred to a bankruptcy, or it is applied for company reorganization or arrangement of debts.
                </p>
                <b>Services upon the termination of the contract</b>
                <p>
                  <b>11.</b> If the contract terminates before the closing of the accounts of the financial year ended during the validity of the contract is made, the accounting firm will close the accounts, if the client pays an advance payment and provides the accounting firm with information and material needed for the closing of the accounts. If the contract terminates in the middle of the
                  financial year, having received the advance payment the accounting firm prepares the itemization of the balance sheet ac- counts based on the information available to it. Equally, the accounting firm shall prepare the necessary itemizations of the calculation of salaries and payroll accounting. The accounting firm charges the costs and a fee of the aforementioned Services
                  according
                  to its practice.
                </p>
                <b>Rights to the material, right of retention to the material, transfer of the material to the client</b>
                <p>
                  <b>12.</b> All rights to the material and the databases and the automatic solutions produced by the accounting firm belong to the accounting firm, when the Services have been produced with software that the accounting firm has acquired for its use or owns. This condition has no effect on the rights the client has to the material it has submitted to the accounting firm. Others
                  than
                  the accounting firm are not without the accounting firm's authorization allowed to commercially utilize the databases. Legal obligations, such as executing audits or auditing under the General Data Protection Regulation, are not considered commercial utilization. When the Service is produced with software that the client has acquired for its own use or own, and to which it has
                  provided the accounting firm with user rights, the rights in the material and databases belong to the client. Regardless of which of the parties the rights in the databases belong to, the accounting firm has the right to produce and publish various sector specific, company form specific or other similar numbers by combining different clients' data so that a specific client's data
                  cannot be identified, and trade secrets are kept. The accounting firm produces and publishes data only in accordance with competition law.
                </p>
                <p>Kun palvelu tuotetaan asiakkaan käyttöönsä hankkimalla tai
                  sen omalla ohjelmistolla, johon se on antanut tilitoimistolle
                  käyttöoikeuden palvelun tuottamiseksi, kuuluvat aineiston ja
                  tietokantojen oikeudet asiakkaalle.
                </p>
                <p>Tilitoimistolla on oikeus tuottaa ja julkaista erilaisia toimialakohtaisia, yritysmuotokohtaisia tai muita vastaavia tunnuslukuja, yhdistämällä eri asiakkaiden tietoja, siten että yksittäisen
                  asiakkaan, asiakkaan henkilökunnan ja asiakkaan yhteistyökumppaneiden tietoja ei voida tunnistaa ja liikesalaisuudet
                  säilytetään, riippumatta siitä kummalle osapuolelle tietokantojen oikeudet kuuluvat. Tilitoimisto tuottaa ja julkaisee tietoja
                  vain kilpailulainsäädännön sallimissa tilanteissa.
                </p>
                <p>The accounting firm has the right to have in its possession the material prepared for the client on the basis of the material the client has submitted to the accounting firm or otherwise until all the receivables of
                </p>
              </div>

              <div class="column">
                <p>
                  the accounting firm from the client have been paid, unless nothing else is regulated in mandatory bankruptcy or other legislation. The accounting firm shall transfer the material to the client forthwith upon payment. The client shall take care of the picking up of the material at its own expense. If the client does not come to pick up the material on a due date given by the
                  accounting firm at the latest, the accounting firm will send it as registered collect on delivery, unless otherwise agreed.
                </p>
                <p>The accounting firm has the right to collect a fee for the keeping of the client's material.</p>
                <p>The accounting firm has the right to keep the copies of the material it has produced also after the termination of the contract and it is not required to delete the client's data from the database, unless otherwise provided by mandatory law. The accounting firm keeps the Basic Information at least five years from the termination of the contract.
                </p>
                <b>Charges</b>
                <p>
                  <b>13.</b> The accounting firm's <b>fees</b> are determined in accordance with the selection and nature of supplied services in each assignment. The accounting firm has the right to demand an <b>advance payment</b>
                  from the client, if that has been agreed upon with the client or if it can otherwise be considered justified taking into account the circumstances of the case. In addition to the fee, the accounting firm has the right to charge for necessary and reasonable direct travel and other <b>costs</b>. Unless otherwise agreed, the accounting firm has the right to charge for <b>additional
                    work</b>
                  in accordance with the accounting firm's practice. Payable additional work is e.g. processing of delayed material and amendment work of the account scheme or other material caused by amendment of law or orders of the authorities or recommendation of the branch to be taken into use as well as supplementary work due to a request or a notification of authorities or other additional
                  work
                  which is not due to the accounting firm, such as work due to data protection legislation and its changes. If the request or a notification of authorities is resulting from an error or negligence due to the accounting firm, the additional work performed due to the request is not chargeable.
                </p>
                <p>The charges do not contain VAT, which, as valid in each case, is added itemized to the charges together with all the other indirect taxes and charges required by law or ordered by the authorities.
                </p>
                <p>
                  The accounting firm has prepared for and invested in producing the Services until the end of the contract. The accounting firm has the right to receive fees and possible direct costs also under the notice period, even if no Services have been ordered and performed during the notice period.
                </p>
                <b>
                  Invoice itemization, terms of payment, consequences for default</b>
                <p>
                  <b>14.</b>The accounting firm charges for its Services in accordance with the contract and its price list. The
                </p>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>2 (7)</div>
            </div>

          </div>
          <div class="page">
            <div class="column-box">
              <div class="column">
                <p>Service and cost charges shall be itemized in accordance with the agreed pricing principle.</p>
                <p>Unless otherwise appears in the price list of the accounting firm or otherwise agreed, the term of payment is fourteen (14) days from the date of the invoice and interest on overdue payments as set forth in the Act on Interest. The accounting firm has the right to charge the collecting costs of a delayed payment.
                </p>
                <b>Complaints regarding the invoices</b>
                <p>
                  <b>15.</b> Complaints regarding the invoices shall be made in writing within ten (10) days of the date of the invoice.
                </p>
                <b>Changes of the payments and the General Conditions of Contract</b>
                <p>
                  <b>16.</b> In case of changes in labor costs or in other bases for determining charges, the accounting firm has the right to adjust its charges accordingly.
                </p>
                <p>
                  <b>17.</b> If these General Conditions of Contract applied in the contract change, the accounting firm shall have the right to change the contractual terms by replacing the General Conditions of Contract with the amended General Conditions of Contract.
                </p>
                <p>
                  <b>18.</b> The accounting firm shall notify the client of the aforementioned amendments in writing, no later than thirty (30) days before the adjustment enters into force. Then the client shall have the right to give a notice in writing within fourteen (14) days of the date of the notification to terminate the contract on the effective date of the adjustment.
                </p>
                <p>
                  <b>19.</b> In case the charges or the bases for determining the charges change due to amendment of law or orders of the authorities, the accounting firm shall have the right to amend its charges accordingly. The changes shall be notified fourteen (14) days before they become effective.
                </p>
                <b>Responsibilities of the parties</b>
                <p>
                  <b>20.</b> The client, having a legal obligation to keep books, is responsible for its bookkeeping, as a taxpayer for paying the taxes and as an employer for the obligations related to the employer status and as a controller for its controller obligations. The client is as an employer responsible for interpreting laws, collective agreements and other agreements and for any
                  decision-making related thereto. The client is responsible for working time records. The client is responsible for properly making all the surveillance and official notifications and obtaining the necessary permits. These responsibilities do not transfer to the accounting firm. The client shall see that the appropriate material describing business transactions is collected, kept
                  and
                  delivered to the accounting firm. The responsibility for the timeliness, sufficiency, correctness, completeness and pertinence for bookkeeping purposes of the information and material of the accounting period and information and material describing subsequent events supplied to the accounting firm rests with the client. The client decides on what calculations and reports shall be
                  prepared and how they are exploited.
                </p>
              </div>

              <div class="column">
                <p>
                  <b>21.</b> The accounting firm shall place the Services and its expertise at the client's disposal in accordance with the contract and these General Conditions of Contract. The accounting firm shall notify the client of errors in the material supplied by the client. Unless otherwise agreed, the accounting firm shall, however, not be responsible for controlling or correcting any
                  computational or other errors in the material provided by the client.
                </p>
                <p>
                  <b>22.</b> The accounting firm is liable for its breaches of contract with the limitation included in these General Conditions of Contract, error in its Services and negligence causing damages to the client. The accounting firm has the duty to compensate the damage only and to the extent that the client proves that due action according to the contract, carried out by the
                  accounting
                  firm on time, would actually have prevented the damage or limited it.
                </p>
                <b>Notification of the error of the accounting firm and correction of it</b>
                <p>
                  <b>23.</b>
                  If an error is discovered in the Service performed for the client by the accounting firm, the client must inform the accounting firm thereof without delay. The accounting firm shall then have the right and the obligation to correct the error it has caused without a charge, as soon as the circumstances permit. If the client neglects to report an error which it has detected, or
                  which
                  it should reasonably have detected, the client shall not have any right to make claims based on the error.
                </p>
                <p>
                  If the client issues a complaint and the accounting firm no longer can access the client's data system, the client must provide the accounting firm with access to the system on the client's expense so that the accounting firm can address the complaint. If the accounting firm uses its right or fulfils its obligations to correct its mistake, the accounting firm shall bear the costs
                  for
                  granting the accounting firm access to the data system.
                </p>
                <b>Accounting firm is responsible only for its own activities</b>
                <p>
                  <b>24.</b> The accounting firm is not responsible for the taxes of the client, or for damages other than those mentioned in Section 22 above. The accounting firm is not liable for the damages caused by the inadequateness, incorrectness or tardiness of the information or instructions or of the material provided by the client, or damages attributable to the client, or if the damage
                  has
                  been caused by a person, other than an employee of the accounting firm, acting on behalf of the client. Nor shall the accounting firm be liable for damages caused by the fact that the client or somebody acting on its behalf, other than an employee of the accounting firm, has not followed the relevant laws or regulations of the authorities or the terms and conditions agreed. The
                  accounting firm is never responsible for commercial or managerial decisions. They are decided upon and answered for by the client.
                </p>
                <b>Limitation of liability of the accounting firm to direct damages and to maximum amount</b>
                <p>
                  <b>25.</b> The accounting firm is not liable for indirect damages, like the loss of income, turnover or markets,
                </p>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>3 (7)</div>
            </div>

          </div>

          <div class="page">
            <div class="column-box">
              <div class="column">
                <p>
                  interruption of production or service, lost profits or other thereto comparable damages.
                </p>
                <p>
                  The accounting firm will be responsible only for direct damage, which has been caused through its negligence. <b>The maximum amount of liability of the accounting firm is however always Euros 10.000 per one damage event and for all damage events appearing during the same financial year maximum Euros 20.000, unless another maximum amount of liability has been specifically agreed
                    upon.</b> The damage shall be regarded as one event of damage even though the recurrence of the same defect had had influence in it and even though it would influence during several financial years. The damage is considered to have appeared in its entirety during that financial year when it appeared to its essential part, even though a part of the damage would appear during
                  some
                  other financial year.
                </p>
                <p>
                  Breach of a contract, defect or negligence in performing the Services does not cause any other consequences to the accounting firm than those aforementioned.
                </p>
                <b>Deadline for presenting claims</b>
                <p>
                  <b>26.</b> The client shall notify the accounting firm, without delay and in writing, of any claim it may have. If the matter involves an error or deficiency which is detected or can be detected immediately, the notification must be made immediately and in no case later than within fourteen (14) days. Unless an itemized claim is presented to the accounting firm within six (6)
                  months
                  of the detection of the damage, no compensation shall be paid. Furthermore, no compensation will be paid if a claim is presented when more than three (3) years has lapsed after the performing of the Service in question. Nonetheless, if the damage is caused by an error committed by the accounting firm in
                  the client's bookkeeping subject to residual tax review or a Service directly based thereon, such as the tax return or other calculation directly related to the client's taxation, the time for presenting the claim is six years from the performing of the Service in question instead of the aforementioned three years.
                </p>

                <b>Third party claims</b>
                <p>
                  <b>27.</b> Should a third party present one of the parties with a claim for damages based on the Services or the assignment contract, the other party must be notified of this without delay. Should the accounting firm pay damages to a third party, the client shall indemnify the accounting firm for the loss the accounting firm has incurred so far as it is not resulting from the
                  error
                  or omission of the accounting firm in observing of the contract terms. The accounting firm's limitations of liability are also applicable to third party claims in the relationship between the accounting firm and the client.
                </p>
                <b>Subcontractors and the personnel</b>
                <p>
                  <b>28.</b> Unless otherwise agreed or prescribed by mandatory law, the parties may use sub-contractors. The party is responsible for the work performed by its subcontractors like it is responsible for its own work. The client's obligations towards the accounting firm and the terms on the limitation of liability are in force also for the
                </p>
              </div>

              <div class="column">
                <p>benefit of the subcontractors, personnel, shareholders and management of the accounting firm.</p>
                <b>Liability insurance of the accounting firm</b>
                <p>
                  <b>29.</b> The accounting firm keeps in force at its own expense proper liability insurance. Upon the occurrence of an event of damage the client shall for its part submit the insurance company with necessary reports and provide the insurance company with a possibility to assess the quantity and quality of the damage.
                </p>
                <b>Force majeure; grounds for release</b>
                <p>
                  <b>30.</b> If the fulfilment of either party's contractual obligations is prevented, impeded or delayed owing to a circumstance beyond the party's control, such as a strike, work boycott, lockout or other labour conflict, a fire or other accident or crisis situation, lightning damage or other act of nature, or due to a disturbance in the communications connections or electricity,
                  the
                  party concerned shall be released from the performance of its contractual obligations and from the sanctions as long as the circumstances require. Error or delay of the authorities or of a bank, a defect in equipment or software, the breaking off, interruption, or disturbance of data communications or links, and an action taken by a network operator shall be deemed grounds for
                  release.
                </p>
                <p>
                  <b>31.</b> The other party shall immediately be notified of the force majeure and the ending of it.
                </p>
                <b>Notifications</b>
                <p>
                  <b>32.</b> Termination or early termination of the contract, as well as other remarks and notifications according to the contract must be made demonstrably. Unless otherwise agreed, the notifications deem to have been made and come to the knowledge of the receiver by the latest the following working day of the date when,
                  - they are left for the post to be delivered and
                  addressed to the address mentioned in the contract or notified later by the party in writing. However, if mail is not delivered on a business day in Finland, arrival shall be considered to have taken place on the following business day, or
                  - they have been sent to the e-mail address mentioned in the contract or notified later in writing by the party.
                </p>
                <b>Electronic communication</b>
                <p>
                  <b>33.</b> The parties are each responsible for information security and antivirus and other protection software condition and updating. The parties know and accept that despite of this electronic communication may be disturbed. The parties may send each other emails and attachment files without encrypting them, unless otherwise agreed. The party is not responsible for the email
                  or
                  attachment file sent through the electronic communication system has been received unchanged or without delay.
                </p>
                <b>Use of data systems used jointly for producing the Services</b>
                <p><b>34.</b> The user rights in the application software used to produce the Services can be a) user rights provided by the client to the accounting firm to the client's own or acquired software or b) restricted remote access
                </p>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>4 (7)</div>
            </div>

          </div>
          <div class="page">
            <div class="column-box">
              <div class="column">
                <p>
                  provided by the accounting firm to the client to software either licensed to or owned by the accounting firm. The terms of situation a) are described in section 34a. The terms of situation b) are described in section 34b.
                </p>
                <p>
                  <b>34a.</b> If the accounting firm produces part of or all agreed accounting, calculation or other Services by using the software application acquired by the client for example via an information network, the following is applied to such use, unless otherwise agreed:
                </p>
                <i><b>The user rights of the accounting firm</b></i>
                <p>
                  The client grants or acquires on its expense free user rights to the accounting firm and its sub-contractors to the software application it uses. The accounting firm shall comply with the client's and/or the third party's, such as the manufacturer of the software, instructions and terms of use, and use its user rights only for the agreed purpose. The accounting firm is not
                  allowed
                  to
                  transfer its user rights without the client's written consent.
                </p>
                <p>
                  The client shall provide the accounting firm with any necessary user names. The accounting firm shall store the user names it receives carefully, and the accounting firm shall make sure that only a user (or users) authorized by the accounting firm may use the user names. The accounting firm shall use an appropriate virus protection, firewall and other necessary data security
                  solutions.
                </p>
                <i><b>Client's liability</b></i>
                <p>
                  The software application can be found on the workstation or server designated by the client or its licensor. The client is responsible for the maintenance of the software and for making sure that the software application can be contacted through customary technical measures. The client is responsible for any costs related to the construction of the telecommunications connection
                  and
                  transfer of data, except for costs related to the accounting firm's own internet connection.
                </p>
                <p>
                  The client is responsible for its own use of the software and for the content, correctness and appropriateness of any material created by it using the software. Material produced by the client in this way is considered to be accounting data provided by the client to the accounting firm for the supply of the Service.
                </p>
                <i><b>Infringement of intellectual property rights</b></i>
                <p>
                  The client is responsible for ensuring that the accounting firm's use of the software does not infringe valid third party intellectual property rights. The client is not responsible for infringements that originated from the fact that the accounting firm has not followed the appropriate instructions provided by the client.
                </p>
                <i><b>Procedure in case of intellectual property right infringement</b></i>
                <p>
                  If a third party considers that the accounting firm infringes its intellectual property rights by using the software, the accounting firm must inform the client of the demand in writing without delay. If the accounting firm authorizes the client to reply to the third party
                </p>
              </div>

              <div class="column">
                <p>
                  demands on its behalf and provides the client with necessary data and help required for handling the matters, the client shall take care of it as it best sees fit on its own expense and shall be responsible for any compensation the accounting firm is liable to pay.
                </p>
                <p>
                  If the demand has received binding legal force or the client considers the demand to justified, the client may in its own discretion
                  - acquire on its expense the right to continue the use
                  or part of it
                  - change the user rights so that third party rights are
                  not infringed or
                  - replace the software with another functionally equal.
                </p>

                <i><b>Faults and changes in the availability of the software application</b></i>
                <p>
                  The possibility to use an appropriate software is a requirement for the accounting firm to be able to provide the Services. The client is responsible for any additional work expenses of the accounting firm deriving from any faults, interruptions, or loss of data, unless these derive from the accounting firm's actions or interruptions in the accounting firm's own internet
                  connection.
                  The client shall strive to solve the problem as soon as reasonably possible, taking into account the nature of the fault or situation.
                </p>
                <p>
                  The accounting firm is not responsible for any harm or delay the client has been caused due to any possible interruptions, termination or delay of the software's user rights.
                </p>
                <p>
                  If the interruption, essential change or cessation of use or the user rights, or a change in the operations model leads to changes in the supply of Services (for example the accounting or payroll Service), the amount of work and costs, the accounting firm has the right to change the fees it charges the client accordingly.
                </p>
                <i><b>Term and termination of user rights</b></i>
                <p>
                  The remote access is only valid for as long as the client holds user rights to the software application. The remote access is terminated when the accounting services contract is terminated by the latest.
                </p>
                <p>
                  <b>34b.</b> If it is agreed that the client produces part of the bookkeeping, accounting, or other material related to the Services through remote access of an application software licensed to or owned by the accounting firm through an information network, the following shall be applied to such remote access, unless otherwise agreed:
                </p>
                <i><b>The client's user rights</b></i>
                <p>
                  Accounting firm grants/acquires, subject to an extra charge, for use of the client a limited right to remote access to the application software the accounting firm is using. The client shall comply with the accounting firm's and/or the third party's, such as the manufacturer of the software, instructions and terms of use and use its user rights only for the agreed purpose. The
                  client
                  is not allowed to transfer its user rights without the accounting firm's written consent, unless otherwise prescribed by mandatory law. Typical transfer situations under
                </p>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>5 (7)</div>
            </div>

          </div>
          <div class="page">
            <div class="column-box">
              <div class="column">
                <p>mandatory law are, for example, transfer for audits or official inspections prescribed by law. The client is responsible for any costs caused by transfers of remote access rights under mandatory law.</p>
                <p>
                  The accounting firm shall provide the client with user specific user names. The client shall store the user names it receives carefully, and unless otherwise agreed, only an authorized user (or users) belonging to the client's personnel may use the user names. The client is always responsible for all actions made with the user names that has been provided to the client.
                </p>
                <p>
                  The application software is on the accounting firm's or the accounting firm's sub-contractor's server or on the server of the software supplier, which has assigned the access right to the accounting firm. The client is responsible for the costs of acquiring its own data communication connection and the costs for transfer of information, as well as for the compatibility,
                  functioning
                  and maintenance of the user terminals and computers and the software it is using in relation to the application software. The client shall use an appropriate virus protection, firewall and other necessary data security solutions. Unless otherwise separately agreed, the accounting firm is not responsible for the performance of the software in the operating environment of the
                  client
                  and not for the need for changes in the client's operating environment caused by possible repairs, updates or maintenance of the software.
                </p>
                <p>
                  The client is responsible for its own use of the software and the contents, accuracy and the due form of the material created by the client with the help of the software. The material so produced by the client is considered to be accounting material assigned by the client to the accounting firm.
                </p>
                <i><b>Infringement of intellectual property rights</b></i>
                <p>
                  The accounting firm is liable for the fact that the use of the remote access does not upon conclusion of the contract infringe intellectual property rights of a third party in force in Finland. The accounting firm is not responsible for any infringements of rights that derive from the fact that the client has not followed the instructions it has been provided with during the
                  remote
                  access, or from the fact that the client has used the application software for other than agreed purposes.
                </p>
                <i><b>Procedure in case of intellectual property right infringement</b></i>
                <p>
                  In case a third party deems that the client infringes its intellectual property right by using the remote access, the client shall forthwith notify the accounting firm in writing of such a claim. If the client authorizes the accounting firm to respond on its behalf to the claim of the third party and gives the accounting firm necessary information and help needed for the handling
                  of
                  the matter, the accounting firm handles it the way it best sees fit at its own expense and is liable for the compen- sation possibly payable by the client. The payment of the compensation requires that the liability for infringement belongs to the accounting firm in accordance with these contractual terms.
                </p>
              </div>

              <div class="column">
                <p>
                  If the claim is accepted and legally valid or the accounting firm considers the claim justified, the accounting firm has the right at its discretion to
                  - acquire at its own expense the right to continue the
                  use or part of it,
                  - change the remote access right so that the rights of
                  a third party are not infringed,
                  - replace the remote access with remote access to
                  another functionally comparable software, or
                  - end the remote access right without notice period.
                </p>
                <p>
                  The infringement of a right is not deemed the fault or delay of the accounting firm. The liability of the accounting firm for the infringement of a right is limited to measures appearing in this section.
                </p>

                <i><b>Faults and changes in the availability of the software application</b></i>
                <p>
                  The accounting firm does not guarantee that the application software will work flawlessly or continuously, unless separately agreed otherwise. The accounting firm is not responsible for harm deriving from any faults, interruptions, or loss of data. The client shall inform the accounting firm of the fault individually and the accounting firm shall strive to solve the problem as
                  soon
                  as reasonably required by the nature of the problem and it is possible to solve the problem. The problem can be solved for example by providing instructions on how to go around the problem. If the fault derives from circumstances the accounting firm is not responsible for, the accounting firm has the right to receive compensation for its research work.
                </p>
                <i><b>Term and termination of the remote access right</b></i>
                <p>
                  The remote access right is in force only as long as the accounting firm has the right to use the application software. The remote access right ends at the latest when the accounting services contract is terminated.
                </p>
                <i><b>Changes</b></i>
                <p>
                  If the termination of the remote access or a change in the operations model leads to changes in the amount of work related to supply of Services (for example the accounting or calculation Service), the accounting firm has the right to change the fees it charges the client.
                </p>
                <b>Recruitment limitation</b>
                <p>
                  <b>35.</b> Neither party may without the consent of the other party engage a person, who has performed tasks meant by and essential to the Services, employed at that moment or previously by the other party, or agree on any other arrangement in order to acquire the work input of such a person, before six months has passed from the termination of the accounting Services contract.
                </p>
                <p>
                  Unless otherwise agreed, the party breaking the recruitment limitation shall pay the other party a compensation amounting to six (6) months' gross salary of the employee in question.
                </p>
                <p>
                  The recruitment limitation is not in force, if the employment relationship has ended for a reason attributable to the employer.
                </p>
                <b>Transfer of contract</b>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>6 (7)</div>
            </div>
          </div>

          <div class="page">
            <div class="column-box">
              <div class="column">
                <p>
                  <b>36.</b> The transfer of contract is subject to a written consent of the other party. However, the accounting firm is entitled to transfer this contract, including rights and obligations related thereto, as part of a transfer of its business, to which the Services belong, to the transferee, unless otherwise prescribed under mandatory law.
                </p>
                <b>Amending of contract</b>
                <p>
                  <b>37.</b> Other amendments to the contract than those mentioned in sections 16-19 and 34a and 34b shall be agreed upon in writing.
                </p>
                <b>Previous contracts</b>
                <p>
                  <b>38. Contract, which includes these terms of contract,
                    supersedes all the previous contracts</b> regarding the
                  Services made between the parties and oral and written
                  notifications.
                </p>
                <b>Dispute resolution</b>
                <p>
                  <b>39.</b> The parties pursue to solve their disputes through
                  negotiations. Any disputes arising from this contract are
                  to be brought primarily for settlement by mediation in
                </p>
              </div>

              <div class="column">
                <p>accordance with the mediation rules of the Finnish Bar Association or in a court of law. If necessary, either party may bring a settlement to be confirmed by an arbitrator. Otherwise the disputes shall be settled by the district court of the domicile of the defendant.</p>
                <b>Other terms</b>
                <p>
                  <b>40.</b> These General Conditions of Contract and other documents mentioned in the contract shall be applied to this contract in accordance with the Laws of Finland. Information contained in the brochures, price lists and materials of the accounting firm are part of the contract only, if it is separately and explicitly stated so in the offer, order confirmation or contract.
                </p>
                <b>Order of priority</b>
                <p>
                  <b>41.</b> If the contractual instruments contradict, they are applied in the following order of priority:
                  <br />
                  1. Accounting services contract
                  <br />
                  2. Service specification and other attachments to the contract in the numberical order
                  <br />
                  3. These General Conditions of the Contract.
                </p>
              </div>
            </div>
            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>7 (7)</div>
            </div>
          </div>
        </div>
      </div>

      <ng-template #sopimusehdotFi>
        <div class="pages-container">

          <div class="page">
            <div class="title">YLEISET SOPIMUS&shy;EHDOT TAL 2018</div>
            <p style="text-align: center;">Tilitoimiston palveluihin, tarjouksiin, tilausvahvistuksiin ja palveluiden toimitussopimuksiin sovelletaan näitä sopimusehtoja.</p>

            <div class="column-box">
              <div class="column">
                <b>Tarjous</b>
                <p><b>1.</b> Tilitoimiston tarjous on voimassa 30 päivää tarjouksen
                  päiväyksestä, jos tarjouksessa ei muuta mainita.</p>

                <b>Sopimuksen voimaantulo</b>
                <p><b>2.</b> Sopimus tulee voimaan, kun osapuolet ovat allekirjoittaneet
                  toimeksiantosopimuksen, asiakas on hyväksynyt tilitoimiston
                  tarjouksen tai tilitoimisto on vahvistanut asiakkaan tilauksen.
                </p>
                <b>Palvelut</b>
                <p>
                  <b>3.</b> Tilitoimiston tehtäväksi sovitut tehtävät ovat sopimuksen
                  liitteenä olevassa Palveluerittelyssä tai muussa asiakirjassa
                  tehtäväksi yhdessä sovitut palvelut (jäljempänä Palvelut).
                  Mikäli tilitoimisto hyväksyy asiakkaan tilauksesta tehtäväkseen muitakin kuin, liitteessä mainittuja palveluja, myös niihin
                  sovelletaan näitä ehtoja.
                </p>
                <p>
                  Tilitoimisto aloittaa Palvelun viipymättä sen jälkeen, kun
                  asiakas on antanut tilitoimistolle tarvittavat tiedot ja aineiston
                  sekä maksanut mahdollisesti sovitun ennakkomaksun.
                  Tilitoimiston tulee huolehtia Palveluista huolellisesti ja ammattitaitoisesti tilitoimiston työ- ja raportointimenetelmien mukaan.
                </p>
                <b>Perustiedot</b>
                <p>
                  <b>4.</b> Asiakkaan tulee huolehtia siitä, että tilitoimistolla on
                  asiakkaasta aina ajan tasalla olevat perustiedot (jäljempänä
                  Perustiedot). Tällaisia tietoja ovat yhteys-, henkilö-, tilikausi-,
                  ja kaupparekisteritiedot, toimiala, kotipaikka, kansallisuus, hallituksen jäsenet sekä muut tarvittavat tiedot ja toimenpideohjeet. Asiakas nimeää ja valtuuttaa yhteyshenkilön antamaan
                  tilitoimistolle tarvittavat Palveluihin liittyvät tiedot ja päätökset.
                  Osapuolet ilmoittavat toisilleen yhteyshenkilön vaihtumisesta.
                </p>
                <b>Tietojen ja toimenpideohjeiden antaminen</b>
                <p>
                  <b>5.</b> Palvelujen suorittamiseksi tarvittavat tiedot ja aineisto on
                  annettava tilitoimistolle niin hyvissä ajoin, että tilitoimisto voi
                  tehdä tehtävänsä asianmukaisesti normaalina työaikana. Jos
                  muuta ei ole sovittu, aineiston tulee olla tilitoimiston käytettävissä seuraavasti:
                  - koko kirjanpitoaineisto kohdekuukauden päättymistä seuraavan kuukauden 10. päivään mennessä
                  - palkanlaskenta-aineisto viisi (5) arkipäivää ennen palkanlaskennan sovittua valmistumispäivää
                  - tilinpäätös- ja verotukseen liittyvä aineisto viimeistään 30
                  päivän kuluessa tilikauden päättymisestä
                  - muu aineisto viimeistään kymmenen (10) arkipäivää ennen
                  määräpäivää.
                </p>
                <p>
                  Asiakas myötävaikuttaa Palvelujen tuottamiseen ja täyttää
                  omat tehtävänsä huolellisesti sopimuksen ehtojen sekä viranomaisten ja tilitoimiston ohjeiden ja suositusten mukaisesti.
                  Tilitoimiston tiedusteluihin ja tarkistuspyyntöihin on vastattava
                  viipymättä.
                </p>
                <b>Luottamuksellisuus</b>
                <p>
                  <b>6.</b> Osapuolen on pidettävä salassa tietoon saamansa toisen
                  osapuolen liikesalaisuudet ja muut luottamukselliset tiedot.
                  Niitä ei saa käyttää muutoin kuin sopimuksen täyttämiseksi.
                  Tilitoimiston tarjous, tilausvahvistus ja sopimus ehtoineen
                  sekä niihin sisältyvät suunnitelmat ja muu aineisto ovat luottamuksellisia tietoja. Salassapitovelvollisuus jatkuu myös sopi-
                </p>
              </div>


              <div class="column">
                <p>
                  muksen päätyttyä. Osapuolet huolehtivat siitä, että myös heidän
                  henkilöstönsä ja alihankkijansa sitoutuvat salassapitoon.
                </p>
                <b>Sopimuksen voimassaolo</b>
                <p>
                  <b>7.</b> Sopimus on voimassa toistaiseksi kahden (2) kuukauden
                  irtisanomisajoin, ellei muuta ole sovittu. Jos kumpikaan osapuoli
                  ei irtisano määräaikaista sopimusta vähintään kaksi (2)
                  kuukautta ennen sovitun määräajan päättymistä, sopimuksen
                  voimassaolo jatkuu toistaiseksi kahden (2) kuukauden irtisanomisajoin.
                </p>
                <p>
                  Sopimuksen päättyessä palvelu saattaa olla palvelun luonteen
                  mukaisesti kesken sopimuksen aikana kuluneelta tai kulumassa
                  olleelta palveluiden käsittelykaudelta. Tätä on käsitelty jäljempänä
                  11. kohdassa.
                </p>

                <b>Palveluiden keskeyttäminen ja sopimuksen purku</b>
                <p>
                  <b>8.</b> Tilitoimistolla on oikeus keskeyttää Palvelut, jos
                  a) asiakkaan maksu tilitoimistolle viivästyy yli seitsemän (7)
                  päivää,
                  b) asiakas ei toimita tarvittavia tietoja tai aineistoa ajallaan tai
                  ei muutoin asianmukaisesti myötävaikuta Palvelujen tuottamiseen,
                  c) asiakas rikkoo sopimusta muutoin tai palvelujen tuottamiselle
                  merkityksellisiä lakeja tai viranomaisten määräyksiä,
                  ohjeita tai suosituksia, tai
                  d) asiakas kieltäytyy noudattamasta tilitoimiston kirjausohjeita
                  tilanteessa, josta voi seurata rikos- tai siviilioikeudellisia seuraamuksia
                  osapuolille. Tätä ehtoa sovelletaan, vaikka kyse olisi
                  vain osapuolten erilaisesta tulkinnasta lain, määräyksen,
                  suosituksen tai ohjeen sisällöstä tai merkityksestä, kuten esimerkiksi
                  tulojen ja niihin kohdistuvien menojen jaksottamisesta
                  tai varojen ja velkojen arvostamisesta kirjanpidossa tai tilinpäätöksessä.
                </p>
                <p>
                  Jos asiakas ei oikaise tilannetta seitsemän (7) päivän kuluessa
                  tilitoimiston kirjallisesta huomautuksesta, tilitoimistolla on
                  oikeus purkaa sopimus.
                </p>

                <p>
                  <b>9.</b> Asiakkaalla on oikeus purkaa sopimus, jos tilitoimisto rikkoo
                  sopimusta olennaisesti eikä ryhdy oikaisemaan tilannetta seitsemän
                  (7) päivän kuluessa asiakkaan kirjallisesta huomautuksesta.
                </p>
                <p>
                  <b>10.</b> Osapuoli saa purkaa sopimuksen, jos toinen osapuoli hakee
                  velkojiltaan akordia, toisen osapuolen omaisuutta on haettu
                  konkurssiin luovutettavaksi tai sitä haetaan yrityssaneeraus- tai velkajärjestelymenettelyyn.
                </p>
                <b>Palvelut sopimuksen päättyessä</b>
                <p>
                  <b>11.</b> Jos sopimus päättyy ennen kuin sopimuksen aikana päättyneen
                  tilikauden tilinpäätös on tehty, tilitoimisto laatii tilinpäätöksen,
                  jos asiakas maksaa siitä ennakkomaksun ja toimittaa
                  tilitoimistolle tilinpäätöstä varten tarvittavat tiedot ja aineiston.
                  Jos sopimus päättyy kesken tilikauden, ennakkomaksun saatuaan
                  tilitoimisto laatii käytettävissään olevien tietojen perusteella
                  tasetilien erittelyt. Vastaavasti tilitoimisto huolehtii
                  palkanlaskennan ja palkkakirjanpidon kannalta tarpeelliset
                  erittelyt. Tilitoimisto veloittaa edellä mainituista Palveluista
                  kustannukset ja palkkion käytäntönsä mukaan.
                </p>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>1 (6)</div>
            </div>
          </div>

          <div class="page">
            <div class="column-box">
              <div class="column">
                <b>Oikeudet aineistoon, aineiston pidätysoikeus ja aineiston
                  luovuttaminen asiakkaalle</b>
                <p>
                  <b>12.</b> Tilitoimiston tuottaman aineiston ja tietokantojen ja automaatioratkaisujen kaikki oikeudet kuuluvat tilitoimistolle, kun
                  palvelut on tuotettu tilitoimiston käyttöönsä hankkimalla tai sen
                  omalla ohjelmistolla. Tällä ehdolla ei ole vaikutusta niihin
                  oikeuksiin, jotka asiakkaalla on tilitoimistolle antamaansa
                  aineistoon. Muilla kuin tilitoimistolla ei ole ilman tilitoimiston lupaa oikeutta hyödyntää kaupallisesti tietokantoja. Kaupallisena hyödyntämisenä ei pidetä lainsäädännöllisten velvoitteiden
                  kuten tilintarkastuksen tai tietosuoja-asetuksen edellyttämän
                  auditoinnin toteuttamista.
                </p>
                <p>Kun palvelu tuotetaan asiakkaan käyttöönsä hankkimalla tai
                  sen omalla ohjelmistolla, johon se on antanut tilitoimistolle
                  käyttöoikeuden palvelun tuottamiseksi, kuuluvat aineiston ja
                  tietokantojen oikeudet asiakkaalle.
                </p>
                <p>Tilitoimistolla on oikeus tuottaa ja julkaista erilaisia toimialakohtaisia, yritysmuotokohtaisia tai muita vastaavia tunnuslukuja, yhdistämällä eri asiakkaiden tietoja, siten että yksittäisen
                  asiakkaan, asiakkaan henkilökunnan ja asiakkaan yhteistyökumppaneiden tietoja ei voida tunnistaa ja liikesalaisuudet
                  säilytetään, riippumatta siitä kummalle osapuolelle tietokantojen oikeudet kuuluvat. Tilitoimisto tuottaa ja julkaisee tietoja
                  vain kilpailulainsäädännön sallimissa tilanteissa.
                </p>
                <p>Tilitoimistolla on oikeus pitää hallussaan olevat asiakkaan
                  toimittaman aineiston perusteella tai muutoin asiakasta varten
                  laatimansa aineistot kunnes kaikki tilitoimiston asiakkaalta
                  olevat saatavat on maksettu, ellei konkurssi- tai muusta
                  pakottavasta lainsäädännöstä muuta johdu. Tilitoimisto
                  luovuttaa maksun saatuaan asiakkaan aineiston viipymättä
                  asiakkaalle. Asiakas huolehtii kustannuksellaan aineiston
                  noutamisesta. Jos asiakas ei nouda aineistoa tilitoimiston
                  ilmoittamaan määräaikaan mennessä, tilitoimisto lähettää sen
                  kirjattuna postiennakkolähetyksenä, ellei toisin sovita. Tilitoimistolla on oikeus periä asiakkaan aineiston säilyttämisestä
                  eri maksu.
                </p>
                <p>Tilitoimistolla on oikeus säilyttää kopiot laatimastaan aineistosta myös sopimuksen päätyttyä, eikä se ole velvollinen poistamaan asiakkaan tietoja tietokannastaan, ellei pakottavasta
                  lainsäädännöstä muuta johdu. Perustiedot tilitoimisto säilyttää
                  vähintään viisi vuotta sopimuksen päättymisestä.
                </p>
                <b>Maksut</b>
                <p>
                  <b>13.</b> Tilitoimiston <b>palkkio</b> määräytyy kussakin toimeksiannossa
                  toimitettavien palvelujen valikoiman ja niiden laadun mukaan.
                </p>
                <p>Tilitoimistolla on oikeus vaatia asiakkaalta <b>ennakkomaksu</b>,
                  jos siitä on asiakkaan kanssa sovittu taikka, jos se voidaan
                  tapauksen olosuhteissa muutoin katsoa perustelluksi.
                </p>
                <p>Palkkion lisäksi tilitoimistolla on oikeus veloittaa tarpeelliset ja
                  kohtuulliset suoranaiset matka- ja muut <b>kustannukset</b>.
                </p>
                <p>Tilitoimistolla on oikeus veloittaa tilitoimiston käytännön mukaiset eri korvaukset <b>lisätöistä</b>, ellei toisin sovita. Eri maksullista lisätyötä on esimerkiksi viivästyneen aineiston käsittely,
                  lainmuutoksesta tai viranomaismääräyksestä tai käyttöön
                  otettavasta alan suosituksesta johtuva tilikarttojen tai muun
                  aineiston muutostyö sekä viranomaisen tiedonanto- tai muusta kehotuksesta johtuva tai muu tilitoimistosta riippumaton li-
                </p>
              </div>
              <div class="column">
                <p>sätyö kuten esimerkiksi tietosuojalainsäädännöstä ja sen
                  muutoksista johtuva työ. Jos viranomaisen tiedonanto- tai muu
                  kehotus johtuu tilitoimiston virheestä tai laiminlyönnistä, kehotuksesta johtuvasta lisätyöstä ei kuitenkaan ole oikeutta periä
                  eri maksua.
                </p>
                <p>Maksut eivät sisällä <b>arvonlisävero</b>, joka kulloinkin voimassaolevana samoin kuin muut välilliset verot ja lain edellyttämät
                  sekä viranomaisten määräämät maksut lisätään eriteltyinä
                  maksuihin.
                </p>
                <p>Tilitoimisto on varautunut ja panostanut palveluiden tuottamiseen sopimuksen päättymiseen saakka. Tilitoimistolla on oikeus saada edelleen palkkiota ja mahdolliset suoranaiset kustannukset myös <b>irtisanomisajalta</b>, vaikka tilitoimistolla ei olisi
                  teetetty Palveluja irtisanomisaikana.
                </p>
                <b>Laskuerittelyt, maksuehto, viivästysseuraamukset</b>
                <p>
                  <b>14.</b> Tilitoimisto veloittaa palveluistaan tehdyn sopimuksen ja
                  hinnastonsa mukaisesti. Palvelu- ja kuluveloitukset tulee eritellä sovitun hinnoitteluperusteen mukaisesti.
                </p>
                <p>Jos muuta ei ilmene tilitoimiston hinnastosta taikka ei ole muutoin sovittu toisin, maksuehto on 14 päivää laskun päiväyksestä ja viivästyskorkoa on maksettava korkolain mukaan. Tilitoimistolla on oikeus veloittaa viivästyneen maksun perimiskulut.
                </p>
                <b>Huomautukset laskuista</b>
                <p>
                  <b>15.</b> Huomautukset laskuista on tehtävä kirjallisesti kymmenen
                  (10) päivän kuluessa laskun päiväyksestä.
                </p>
                <b>Maksujen ja yleisten sopimusehtojen muutokset</b>
                <p>
                  <b>16.</b> Mikäli työvoimakustannukset tai muut maksujen määräytymisperusteet muuttuvat, tilitoimistolla on oikeus muuttaa
                  maksuja vastaavasti.
                </p>
                <p>
                  <b>17.</b> Jos nämä sopimuksessa noudatetut yleiset sopimusehdot
                  muuttuvat, tilitoimistolla on oikeus muuttaa sopimusehtoja korvaamalla yleiset ehdot muutetuilla yleisillä sopimusehdoilla.
                </p>
                <p>
                  <b>18.</b> Tilitoimiston on ilmoitettava maksujen ja sopimusehtojen
                  muutoksista asiakkaalle kirjallisesti viimeistään kolmekymmentä (30) päivää ennen muutoksen voimaantuloa. Tällöin
                  asiakkaalla on oikeus neljäntoista (14) päivän kuluessa ilmoituksen päiväyksestä kirjallisesti irtisanoa sopimus päättymään
                  muutoksen voimaantulopäivänä.
                </p>
                <p>
                  <b>19.</b> Mikäli lainmuutoksen tai viranomaismääräyksen vuoksi
                  maksut tai maksujen perusteet muuttuvat, tilitoimistolla on oikeus muuttaa maksuja vastaavasti. Muutoksista on ilmoitettava viimeistään 14 päivää ennen muutoksen voimaantuloa.
                </p>
                <b>Osapuolten vastuista</b>
                <p>
                  <b>20.</b> Asiakas on kirjanpitovelvollisena vastuussa kirjanpidostaan, verovelvollisena veroistaan ja henkilöstönsä työnantajana työantajavelvoitteistaan sekä rekisterinpitäjänä rekisterinpitäjän velvollisuuksistaan. Asiakas vastaa työnantajana lakien, työehtosopimusten ja muiden
                  sopimusten tulkinnasta sekä niihin liittyvästä päätöksenteosta. Asiakas vastaa työaikakirjanpidosta. Asiakas on vastuussa asianmukaisten valvonta- ja viranomaisilmoitusten tekemisestä ja tarpeellisten lupien
                  hankkimisesta. Nämä vastuut eivät siirry tilitoimistolle.
                </p>
                <p>Asiakas huolehtii siitä, että liiketapahtumia kuvaava asianmukainen aineisto kootaan, säilytetään ja toimitetaan tilitoimis-
                </p>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>2 (6)</div>
            </div>

          </div>
          <div class="page">
            <div class="column-box">
              <div class="column">
                <p>
                  Vastuu tilitoimistolle toimitettujen tilikauden ja sen
                  jälkeisiä tapahtumia kuvaavien tietojen ja aineiston
                  ajantasaisuudesta, riittävyydestä, oikeellisuudesta,
                  täydellisyydestä ja kuu- lumisesta kirjanpitoon on
                  asiakkaalla. Asiakas päättää, mitä laskelmia ja raportteja
                  tehdään ja miten niitä hyödynnetään.
                </p>
                <p>
                  <b>21.</b> Tilitoimisto antaa Palvelut ja asiantuntemuksensa asiakkaan
                  käytettäväksi sopimuksen ja näiden sopimusehto- jen
                  mukaisesti. Tilitoimiston on ilmoitettava asiakkaalle tämän
                  toimittamassa aineistossa havaitsemistaan virheistä. Tilitoimisto ei kuitenkaan ole velvollinen tarkkailemaan tai korjaamaan asiakkaan toimittaman aineiston mahdollisia lasku- tai
                  muita virheitä tai tarkastamaan asiakkaan antamia tietoja,
                  ellei toisin ole sovittu.
                </p>
                <p>
                  <b>22.</b> Tilitoimisto vastaa näiden yleisten sopimusehtojen mukaisin rajoituksin sopimusrikkomuksestaan, virheestä Palvelussaan ja laiminlyönnistään asiakkaalle aiheutuneesta vahingosta. Tilitoimisto on velvollinen korvaamaan vahingon vain sikäli ja siltä osin kuin asiakas osoittaa, että
                  tilitoimiston
                  asian- mukaisesti ja ajallaan suorittama sopimuksen mukainen
                  toimenpide olisi estänyt vahingon tai rajoittanut sitä.
                </p>
                <b>Tilitoimiston virheestä ilmoittaminen ja sen korjaaminen </b>
                <p>
                  <b>23.</b>
                  Jos tilitoimiston asiakkaalle toimittamassa Palvelussa ilmenee
                  virhe, asiakkaan on viipymättä ilmoitettava siitä tilitoimistolle. Tilitoimistolla on tällöin oikeus ja velvollisuus
                  korjata aiheuttamansa virhe veloituksetta niin pian kuin
                  olosuhteet sallivat. Jos asiakas laiminlyö ilmoittaa
                  virheestä, jonka tämä on havainnut tai tämän olisi
                  pitänyt kohtuudella havaita, asiak- kaalla ei ole oikeutta
                  esittää virheen perusteella vaatimuksia.
                </p>
                <p>
                  Mikäli tilitoimistolla ei asiakkaan reklamoidessa ole enää
                  pää- syä asiakkaan tietojärjestelmään asiakkaan tulee
                  avata omal- la kustannuksellaan tilitoimistolle pääsy
                  tietojärjestelmään, jot- ta tämä voi vastata
                  reklamaatioon. Jos tilitoimisto käyttää oikeuttaan tai
                  täyttää velvollisuuttaan korjata aiheuttamansa virhe,
                  tilitoimisto vastaa tietojärjestelmään pääsyn avaamisen
                  kustannuksista.
                </p>
                <b>Tilitoimisto vastaa vain omasta toiminnastaan</b>
                <p>
                  <b>24.</b> Tilitoimisto ei vastaa asiakkaan veroista eikä muista kuin
                  edellä 22. kohdassa mainituista vahingoista. Tilitoimisto ei
                  ole korvausvelvollinen, jos vahinko on aiheutunut asiakkaan
                  anta- mien tietojen tai ohjeiden taikka toimitetun aineiston
                  puutteellisuudesta, virheellisyydestä tai viivästymisestä
                  tai muutoin asiakkaan syystä tai mikäli vahingon on
                  aiheuttanut asiakkaan puolesta tai lukuun toiminut muu kuin
                  tilitoimiston henkilö. Tilitoimisto ei vastaa vahingosta, joka
                  johtuu siitä, että asiakas tai tämän lukuun toiminut muu
                  kuin tilitoimiston henkilö ei ole noudattanut Palvelulle
                  suoraan tai välillisesti merkityksellisiä lakeja tai
                  viranomaismääräyksiä taikka sovittuja ehtoja.
                </p>
                <p>
                  Tilitoimisto ei milloinkaan vastaa liiketoiminnallisista tai
                  liik- keenjohdollisista ratkaisuista, vaan niistä päättää
                  ja vastaa asiakas.
                </p>
                <b>Tilitoimiston vastuun rajoitus välittömiin vahinkoihin ja
                  enimmäismäärään</b>
                <p>
                  <b>25.</b> Tilitoimisto ei vastaa välillisistä vahingoista, kuten
                  tulon, liikevaihdon tai markkinoiden menetyksestä, tuotannon
                  tai
                </p>
              </div>

              <div class="column">
                <p>
                  palvelun keskeytymisestä, saamatta jääneestä voitosta
                  taikka muusta niihin verrattavasta vahingosta.
                </p>
                <p>
                  Tilitoimisto vastaa vain huolimattomuudestaan johtuvista
                  välit- tömistä vahingoista. Tilitoimiston vastuun
                  enimmäismäärä on kuitenkin aina enintään <b>10.000 euroa
                    yhdessä vahinkota- pahtumassa ja saman tilikauden aikana
                    ilmenneistä va- hinkotapahtumista yhteensä enintään 20.000
                    euroa, ellei muusta enimmäismäärästä ole nimenomaisesti
                    sopimuk- sessa sovittu.</b> Vahinko katsotaan yhdeksi
                  vahinkotapahtu- maksi, vaikka siihen olisi vaikuttanut saman
                  virheen toistumi- nen ja vaikka se vaikuttaisi useampaan
                  tilikauteen. Vahingon katsotaan ilmenneen kokonaan sen
                  tilikauden aikana, jolloin se olennaisilta osiltaan ilmeni,
                  vaikka jokin vahingon osa ilmenisi muuna tilikautena.
                </p>
                <p>
                  Sopimusrikkomus, virhe tai laiminlyönti Palvelujen suorittamisessa eivät aiheuta tilitoimistolle muuta seuraamusta kuin
                  mitä edellä on todettu.
                </p>
                <b>Vaatimusten esittämisen määräaika</b>
                <p>
                  <b>26.</b> Vaatimukset tilitoimistolle on tehtävä kirjallisesti
                  viipymät- tä. Jos virhe tai puute havaitaan tai on
                  havaittavissa välittö- mästi, huomautus on tehtävä heti ja
                  viimeistään neljäntoista (14) päivän kuluessa. Jos
                  eriteltyä vaatimusta ei ole tehty tili- toimistolle kuuden (6)
                  kuukauden kuluessa vahingon toteami- sesta, ei korvausta
                  makseta. Korvausta ei myöskään makse- ta, jos vaatimus
                  tehdään, kun on kulunut yli kolme (3) vuotta kyseisen
                  Palvelun toimittamisesta. Jos vahinko kuitenkin joh- tuu
                  sellaisesta tilitoimiston vastuulle kuuluvasta virheestä, joka
                  on tehty asiakkaan jälkiverotarkastettavissa olevassa
                  kirjanpi- dossa tai suoraan siihen perustuvassa Palvelussa
                  kuten vero- ilmoituksessa tai muussa suoraan asiakkaan
                  verotukseen vai- kuttavassa laskelmassa, edellä mainitun
                  kolmen vuoden sijas- ta noudatetaan kuuden (6) vuoden
                  määräaikaa kyseisen Pal- velun toimittamisesta.
                </p>

                <b>Kolmansien osapuolten vaatimukset</b>
                <p>
                  <b>27.</b> Jos kolmas osapuoli tekee osapuolelle Palveluiden tai toimeksiantosopimuksen perusteella korvausvaatimuksen, siitä on
                  ilmoitettava toiselle osapuolelle viipymättä. Jos
                  tilitoimisto joutuu maksamaan kolmannelle osapuolelle
                  vahingonkor- vausta, asiakkaan on hyvitettävä tilitoimistolle
                  tästä aiheutunut menetys sikäli kuin se ei johdu
                  tilitoimiston virheestä tai laimin- lyönnistä sopimusehtojen
                  noudattamisessa. Tilitoimiston vas- tuunrajoitukset soveltuvat
                  myös kolmansien vaatimuksiin tili- toimiston ja asiakkaan
                  välisessä suhteessa.
                </p>
                <b>Alihankkijat ja henkilöstö</b>
                <p>
                  <b>28.</b> Jos toisin ei ole sovittu eikä pakottavasta
                  lainsäädännöstä muuta johdu, osapuolet saavat käyttää
                  alihankkijoita. Osapuo- li vastaa alihankkijansa suorituksesta
                  kuin omastaan. Asiak- kaan velvoitteet tilitoimistolle ja ehdot
                  tilitoimiston vastuunra- joituksista ovat voimassa myös
                  tilitoimiston alihankkijan, hen- kilöstön, osakkaiden ja
                  johtohenkilöiden hyväksi.
                </p>
                <b>Tilitoimiston vastuuvakuutus</b>
                <p>
                  <b>29.</b> Tilitoimisto ylläpitää kustannuksellaan asianmukaisen
                  vas- tuuvakuutuksen. Vahinkotapahtuman sattuessa asiakkaan tulee osaltaan antaa vakuutusyhtiölle tarpeelliset selvitykset
                  ja varata tälle mahdollisuus vahingon määrän ja laadun
                  arvioimi- seen.
                </p>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>3 (6)</div>
            </div>

          </div>
          <div class="page">
            <div class="column-box">
              <div class="column">
                <b>Ylivoimainen este; vapauttamisperusteet</b>
                <p>
                  <b>30.</b> Mikäli osapuolen sopimusvelvoitteiden täyttäminen estyy,
                  vaikeutuu tai viivästyy tämän vaikutuspiirin ulkopuolisesta
                  sei- kasta (ylivoimainen este), kuten lakosta, työsulusta tai
                  muusta työselkkauksesta, tulipalosta, muusta onnettomuudesta
                  tai kriisitilanteesta, ukkosvauriosta tai muusta
                  luonnontapahtu- masta taikka viestintäyhteys- tai
                  sähköhäiriön vuoksi, osapuoli vapautuu
                  sopimusvelvoitteidensa täyttämisestä ja seuraa- muksista
                  niin pitkäksi aikaa kuin olosuhteet edellyttävät.
                  Vapauttamisperusteeksi katsotaan myös
                  - viranomaisen tai pankin virhe tai viivästys,
                  - laitteisto- ja ohjelmistovika-, tietoliikenteen tai
                  -yhteyksien katkeaminen, keskeytys ja häiriö sekä
                  - verkkolaskuoperaattorin toimenpide.
                </p>
                <p>
                  <b>31.</b> Ylivoimaisesta esteestä ja sen päättymisestä on
                  viipymät- tä ilmoitettava toiselle osapuolelle.
                </p>
                <b>Ilmoitukset</b>
                <p>
                  <b>32.</b> Sopimuksen irtisanominen tai purkaminen samoin kuin muut
                  sopimuksen mukaiset huomautukset ja ilmoitukset on tehtävä
                  todisteellisesti. Ellei toisin ole sovittu, ilmoitukset katsotaan tehdyiksi ja tulleen vastaanottajan tietoon
                  viimeistään sitä päivää ensiksi seuraavana
                  arkipäivänä, jolloin
                  - ne on jätetty postin kuljetettavaksi osoitettuna
                  sopimuksessa mainittuun tai osapuolen myöhemmin kirjallisesti
                  ilmoittamaan osoitteeseen, mutta mikäli postia ei jonain
                  arkipäivänä lain- kaan jaeta Suomessa, saapumisen katsotaan
                  tapahtuneen sii- tä seuraavana arkipäivänä, tai
                  - ne on lähetetty sopimuksessa mainittuun tai osapuolen kirjallisesti myöhemmin ilmoittamaan sähköpostiosoitteeseen.
                </p>
                <b>Sähköinen viestintä</b>
                <p>
                  <b>33.</b> Osapuolet vastaavat kumpikin osaltaan tietoturvallisuudesta ja huolehtivat viruksentorjunta- ja muiden
                  suojausjärjes- telmiensä kunnosta ja ajanmukaisuudesta.
                  Osapuolet tietävät ja hyväksyvät, että tästä huolimatta
                  sähköinen viestintä voi häiriytyä. Osapuolet saavat
                  lähettää toisilleen sähköpostitse viestejä ja
                  liitetiedostoja niitä salaamatta, jos muuta ei ole so- vittu.
                  Osapuoli ei vastaa siitä, että hänelle sähköisen
                  viestintä- järjestelmän kautta lähetetty viesti tai
                  liiteaineisto on vastaan- otettu muuttumattomana tai
                  viivytyksettä.
                </p>
                <b>Palvelun tuotantoon yhteiskäytössä käytettyjen
                  tietojärjestelmien käyttö</b>
                <p><b>34.</b> Palveluntuotannossa käytetyn sovellusohjelmiston käyttöoikeus voi olla a) asiakkaan tilitoimistolle antama
                  käyttöoikeus asiakkaan omaan tai sen hankkimaan ohjelmistoon
                  tai b) tili- toimiston myös osittain asiakkaalle antama
                  rajoitettu etäkäyt- töoikeus tilitoimiston
                  käyttöoikeudella hankkimaan tai sen omaan ohjelmistoon.
                  Tilanteen a) ehdot on kuvattu kohdassa 34a. Tilanteen b) ehdot
                  on kuvattu kohdassa 34b.
                </p>
                <p>
                  <b>34a.</b> Jos tilitoimisto tuottaa osan tai kaikki sovitut
                  kirjanpito-, laskenta-, tai muut Palvelut käyttäen asiakkaan
                  hankkimaa so- vellusohjelmistoa esimerkiksi tietoverkon
                  välityksellä, nouda- tetaan tähän käyttöön seuraavaa,
                  ellei muuta sovita:
                </p>
                <b>Tilitoimiston käyttöoikeus</b>
                <p>
                  Asiakas myöntää/hankkii omalla kustannuksellaan
                  tilitoimistol- le alihankkijoineen sujuvan palvelutuotannon
                  edellyttämän maksuttoman käyttöoikeuden käyttämäänsä
                  sovellusohjelmis- toon. Tilitoimiston tulee noudattaa asiakkaan
                  ja/tai kolmannen
                </p>
              </div>

              <div class="column">
                <p>
                  osapuolen, kuten ohjelmiston valmistajan, antamia ohjeita ja
                  käyttöehtoja ja käyttää käyttöoikeuttaan vain sovittuun
                  tarkoi- tukseen. Tilitoimistolla ei ole oikeutta ilman
                  asiakkaan kirjallis- ta suostumusta luovuttaa käyttöoikeutta
                  edelleen.
                </p>
                <p>
                  Asiakas toimittaa tilitoimistolle tarpeelliset
                  käyttäjätunnukset. Tilitoimiston on säilytettävä saamiaan
                  tunnuksia huolellisesti ja tilitoimiston on huolehdittava
                  siitä, että toimitettuja tunnuksia saa käyttää vain
                  tilitoimiston valtuuttama käyttäjä (tai käyt- täjät).
                  Tilitoimiston tulee käyttää asianmukaista virustorjuntaa,
                  palomuuria sekä muita tarvittavia tietoturvaratkaisuja.
                </p>
                <b>Asiakkaan vastuu</b>
                <p>
                  Sovellusohjelmisto sijaitsee asiakkaan tai käyttöoikeuden
                  asiakkaalle luovuttaneen ohjelmistotoimittajan nimeämällä
                  työasemalla tai palvelimella. Asiakas vastaa sovellusohjelmiston ylläpidosta sekä siitä, että sovellusohjelmistoon on
                  mah- dollista ottaa yhteys tavanomaisin teknisin menetelmin.
                  Asiakas vastaa tietoliikenneyhteyden rakentamisen ja tietojen
                  siirron kustannuksista pois lukien tilitoimiston oman internetyhteyden kustannukset.
                </p>
                <p>
                  Asiakas vastaa omasta ohjelmiston käytöstään ja sen avulla
                  luomansa materiaalin sisällöstä, oikeellisuudesta ja
                  asianmu- kaisuudesta. Asiakkaan näin tuottama aineisto
                  katsotaan asi- akkaan tilitoimistolle palvelun tuottamiseksi
                  luovuttamaksi kirjanpitoaineistoksi.
                </p>
                <b>Immateriaalioikeuden loukkaus</b>
                <p>
                  Asiakas vastaa siitä, että tilitoimiston ohjelmiston käyttö
                  ei loukkaa kolmannen osapuolen voimassa olevaa immateriaalioikeutta. Asiakas ei vastaa oikeudenloukkauksesta, joka johtuu siitä, että tilitoimisto ei ole noudattanut asiakkaan
                  antamia asianmukaisia ohjeita.
                </p>
                <b>Menettely immateriaalioikeuden loukkaustilanteissa</b>
                <p>
                  Jos kolmas osapuoli katsoo, että tilitoimisto loukkaa hänen
                  im- materiaalioikeuttaan ohjelmiston käytöllä, tilitoimiston
                  on ilmoi- tettava vaatimuksesta kirjallisesti asiakkaalle
                  viipymättä. Jos tilitoimisto valtuuttaa asiakkaan puolestaan
                  vastaamaan kol- mannen osapuolen vaatimuksiin ja antaa
                  asiakkaalle asian hoitamisen kannalta tarpeelliset tiedot ja
                  avun, asiakkaan tu- lee huolehtia siitä parhaaksi katsomallaan
                  tavalla omalla kus- tannuksellaan ja vastaa tilitoimiston
                  maksettavaksi mahdolli- sesti tulevista korvauksista.
                </p>
                <p>
                  Jos vaatimus on lainvoimaisesti hyväksytty tai asiakas katsoo
                  vaatimuksen oikeutetuksi, asiakkaalla on oikeus harkintansa
                  mukaan
                  - hankkia omalla kustannuksellaan oikeus käytön tai sen osan
                  jatkamiseen,
                </p>
                <p>
                  - muuttaa käyttöoikeutta niin, ettei kolmannen oikeuksia loukata tai
                  - korvata ohjelmisto toisella toiminnallisesti vastaavalla.
                </p>
                <b>Häiriöt ja muutokset sovellusohjelmiston käytettävyydessä</b>
                <p>
                  Tilitoimiston Palveluiden tuottaminen edellyttää asianmukaisen ohjelmiston käyttömahdollisuutta. Asiakas vastaa ohjelmiston käytössä ilmenneistä häiriöistä, katkoksista tai
                  tietojen menetyksestä johtuvista ylimääräisistä
                  tilitoimiston työkustan- nuksista, elleivät nämä johdu
                  tilitoimiston toiminnasta tai tilitoi- miston oman
                  internetyhteyden katkoksista. Asiakas pyrkii ratkaisemaan
                </p>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>4 (6)</div>
            </div>

          </div>
          <div class="page">
            <div class="column-box">
              <div class="column">
                <p>
                  ongelman niin pian kuin häiriön tai tilanteen laatu
                  kohtuudella edellyttää ja ratkaiseminen on mahdollista.
                </p>
                <p>
                  Tilitoimisto ei vastaa ohjelmiston käyttöoikeuden
                  mahdollises- ta keskeytymisestä, päättymisestä tai
                  viivästyksestä asiak- kaalle aiheutuvista vahingoista eikä
                  viivästyksistä.
                </p>
                <p>
                  Jos käytön tai käyttöoikeuden keskeytys, olennainen muutos,
                  lakkaaminen tai toimintamallin muutos aiheuttaa muutoksia
                  palveluntuotannon, esimerkiksi kirjanpito- tai palkanlaskentapalvelun, työmääriin ja kustannuksiin, tilitoimistolla on
                  oikeus muuttaa asiakkaalta perittäviä maksuja tätä
                  vastaavasti.
                </p>
                <b>Käyttöoikeuden voimassaolo ja päättyminen</b>
                <p>
                  Etäkäyttöoikeus on voimassa enintään vain niin kauan kuin
                  asiakkaalla on käyttöoikeus sovellusohjelmistoon.
                  Etäkäyttö- oikeus lakkaa viimeistään silloin, kun
                  toimeksiantosopimus Palveluista päättyy.
                </p>
                <p>
                  <b>34b.</b> Jos on sovittu siitä, että asiakas tuottaa osan
                  kirjanpito-, laskenta-, tai muusta Palveluja koskevasta
                  aineistosta etä- käyttäen tilitoimiston käyttöoikeudella
                  hankkimaan tai sen omaa sovellusohjelmistoa tietoverkon
                  välityksellä, noudate- taan tähän etäkäyttöön
                  seuraavaa, ellei muuta sovita:
                </p>
                <b>Asiakkaan käyttöoikeus</b>
                <p>
                  Tilitoimisto myöntää/hankkii eri maksusta asiakkaan
                  käytettä- väksi rajoitetun etäkäyttöoikeuden
                  tilitoimiston käyttämään sovellusohjelmistoon. Asiakkaan
                  tulee noudattaa tilitoimiston ja/tai kolmannen osapuolen, kuten
                  ohjelmiston valmistajan, antamia ohjeita ja käyttöehtoja ja
                  käyttää etäkäyttöoikeuttaan vain sovittuun tarkoitukseen.
                  Asiakkaalla ei ole oikeutta ilman tilitoimiston kirjallista
                  suostumusta luovuttaa etäkäyttöoikeutta edelleen, ellei
                  pakottavasta lainsäädännöstä muuta johdu. Tyypillisiä
                  pakottavasta lainsäädännöstä johtuvia luovutusti- lanteita
                  ovat esimerkiksi luovutus tilintarkastukseen tai lainsäädännön määräämään viranomaistarkastukseen. Asiakas
                  vastaa näistä pakottavan lainsäädännön perusteella
                  luovute- tuista etäkäyttöoikeuksista aiheutuvista
                  kustannuksista.
                </p>
                <p>
                  Palveluntarjoaja toimittaa asiakkaalle käyttäjäkohtaiset
                  käyttä- jätunnukset. Asiakkaan on säilytettävä saamiaan
                  tunnuksia huolellisesti ja jos muuta ei ole sovittu, tunnuksia
                  saa käyttää vain asiakkaan henkilöstöön kuuluva nimetty
                  käyttäjä (tai käyt- täjät). Asiakas vastaa aina
                  asiakkaalle annetuilla tunnuksilla tehdyistä toimenpiteistä.
                </p>
                <p>
                  Sovellusohjelmisto sijaitsee tilitoimiston, tilitoimiston
                  alihankki- jan tai käyttöoikeuden tilitoimistolle
                  luovuttaneen ohjelmistotoi- mittajan palvelimella. Asiakas
                  vastaa oman tietoliikenneyhtey- den hankkimisen ja tietojen
                  siirron kustannuksista sekä käyttä- miensä ohjelmistojen ja
                  pääte- ja tietokonelaitteiden sopivuu- desta, toimivuudesta
                  ja kunnossapidosta suhteessa sovellus- ohjelmistoon. Asiakkaan
                  tulee käyttää asianmukaista virustor- juntaa, palomuuria
                  sekä muita tarvittavia tietoturvaratkaisuja. Ellei muuta ole
                  erikseen sovittu, tilitoimisto ei vastaa ohjelmis- ton
                  toimivuudesta asiakkaan käyttöympäristössä eikä ohjelmistoon mahdollisesti tehtävien korjausten, päivitysten tai
                  yllä- pitotoimien aiheuttamista muutostarpeista asiakkaan
                  käyttö- ympäristössä.
                </p>
                <p>
                  Asiakas vastaa omasta ohjelmiston käytöstään ja sen avulla
                  luomansa materiaalin sisällöstä, oikeellisuudesta ja

                </p>
              </div>

              <div class="column">
                <p>
                  asianmukaisuudesta. Asiakkaan näin tuottama aineisto katsotaan
                  asiakkaan tilitoimistolle luovuttamaksi kirjanpitoaineistoksi.
                </p>
                <b>Immateriaalioikeuden loukkaus</b>
                <p>
                  Tilitoimisto vastaa siitä, että etäyhteyskäyttö ei
                  sopimusta sol- mittaessa loukkaa kolmannen osapuolen Suomessa
                  voimas- sa olevaa immateriaalioikeutta. Tilitoimisto ei vastaa
                  oikeu- denloukkauksesta, joka johtuu siitä, että asiakas ei
                  ole etä- käytössä noudattanut sille annettuja ohjeita tai
                  siitä, että asia- kas on käyttänyt ohjelmistosovellusta
                  muita kuin sovittuja tar- koituksia varten.
                </p>
                <b>Menettely immateriaalioikeuden loukkaustilanteissa</b>
                <p>
                  Jos kolmas osapuoli katsoo, että asiakas loukkaa hänen immateriaalioikeuttaan etäyhteyskäytöllä, asiakkaan on
                  ilmoitet- tava vaatimuksesta kirjallisesti tilitoimistolle
                  viipymättä. Jos asiakas valtuuttaa tilitoimiston puolestaan
                  vastaamaan kolmannen osapuolen vaatimuksiin ja antaa
                  tilitoimistolle asian hoitamisen kannalta tarpeelliset tiedot
                  ja avun, tilitoimis- to huolehtii siitä parhaaksi katsomallaan
                  tavalla omalla kustan- nuksellaan ja vastaa asiakkaan
                  maksettavaksi mahdollisesti tulevista korvauksista. Korvausten
                  maksaminen edellyttää, et- tä vastuu oikeudenloukkauksesta
                  kuuluu näiden sopimusehto- jen mukaisesti tilitoimistolle.
                </p>
                <p>
                  Jos vaatimus on lainvoimaisesti hyväksytty tai tilitoimisto
                  kat- soo vaatimuksen oikeutetuksi, tilitoimistolla on oikeus
                  harkin- tansa mukaan
                  - hankkia omalla kustannuksellaan oikeus käytön tai sen osan
                  jatkamiseen,
                </p>
                <p>
                  - muuttaa etäkäyttöoikeutta niin, ettei kolmannen oikeuksia
                  loukata,
                  - korvata etäkäyttöoikeus toisella toiminnallisesti
                  vastaavaan ohjelmistoon annettavalla etäkäyttöoikeudella tai
                </p>
                <p>
                  - lopettaa etäkäyttöoikeus ilman irtisanomisaikaa.
                </p>
                <p>
                  Oikeudenloukkausta ei katsota tilitoimiston virheeksi tai
                  viiväs- tykseksi. Tilitoimiston vastuu oikeudenloukkauksesta
                  rajoittuu tämän kohdan mukaisiin toimenpiteisiin.
                </p>

                <b>Häiriöt ja muutokset sovellusohjelmiston käytettävyydessä</b>
                <p>
                  Tilitoimisto ei takaa sovellusohjelmiston virheetöntä tai
                  jatku- vaa toimintaa, ellei erikseen ole muuta sovittu.
                  Tilitoimisto ei vastaa häiriöistä, katkoksista tai tietojen
                  menetyksestä johtu- vista vahingoista. Asiakkaan tulee
                  ilmoittaa tilitoimistolle häiri- öistä yksilöidysti ja
                  tilitoimisto pyrkii ratkaisemaan ongelman niin pian kuin
                  häiriön laatu kohtuudella edellyttää ja ratkaise- minen on
                  mahdollista. Korjaus voidaan tehdä esimerkiksi an- tamalla
                  ohjeet ongelman kiertämiseksi. Jos häiriö johtuu muusta kuin
                  tilitoimiston vastattavasta seikasta, tilitoimistolla on oikeus
                  saada selvitystyöstä eri korvaus.
                </p>
                <b>Etäkäyttöoikeuden voimassaolo ja päättyminen</b>
                <p>
                  Etäkäyttöoikeus on voimassa enintään vain niin kauan kuin
                  tili- toimistolla on käyttöoikeus sovellusohjelmistoon.
                  Etäkäyttöoi- keus lakkaa viimeistään silloin, kun
                  toimeksiantosopimus Pal- veluista päättyy.
                </p>
                <b>Muutokset</b>
                <p>
                  Jos etäkäytön lakkaaminen tai toimintamallin muutos aiheuttaa muutoksia palveluntuotannon, esimerkiksi kirjanpito- tai
                </p>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>5 (6)</div>
            </div>

          </div>
          <div class="page">
            <div class="column-box">
              <div class="column">
                <p>
                  palkanlaskentapalvelun työmääriin, tilitoimistolla on oikeus
                  muuttaa asiakkaalta veloitettavia maksuja.
                </p>
                <b>Rekrytointirajoitus</b>
                <p>
                  <b>35.</b> Kumpikaan osapuoli ei saa ilman toisen osapuolen suostumusta ottaa palvelukseensa toisen palveluksessa olevaa
                  henkilöä tai ollutta henkilöä, joka on tehnyt keskeisiä
                  Palvelun tarkoittamia tehtäviä eikä sopia muustakaan
                  järjestelystä tällaisen henkilön työpanoksen
                  hankkimiseksi, ennen kuin on kulunut kuusi kuukautta
                  Palveluista tehdyn sopimuksen päät- tymisestä.
                </p>
                <p>
                  Ellei muuta ole sovittu, rekrytointirajoitusta rikkoneen
                  osapuo- len on maksettava toiselle osapuolelle kyseisen
                  henkilön kuu- den kuukauden bruttopalkan suuruinen korvaus.
                </p>
                <p>
                  Rekrytointirajoitus ei ole voimassa, jos työsuhde on
                  päättynyt työnantajasta johtuvasta syystä.
                </p>
                <b>Sopimuksen siirto</b>
                <p>
                  36. Sopimuksen siirto on sallittu vain toisen osapuolen
                  kirjalli- sella suostumuksella. Tilitoimistolla on kuitenkin
                  siirtäessään liiketoimintansa, johon Palvelut kuuluvat,
                  oikeus siirtää sopi- mus oikeuksineen ja velvoitteineen
                  liiketoiminnan luovutuk- sensaajalle, ellei pakottavasta
                  lainsäädännöstä muuta johdu.
                </p>
                <b>Sopimuksen muuttaminen</b>
                <p>
                  <b>37.</b> Muut kuin 16.-19. ja 34a ja 34b kohdissa mainitut muutokset sopimukseen on tehtävä yhteisellä päätöksellä
                  kirjallisesti.
                </p>
                <b>Aiemmat sopimukset</b>
                <p>
                  <b>38. Sopimus, joka sisältää nämä sopimusehdot,</b>
                </p>
              </div>

              <div class="column">
                <p>
                  <b>syrjäyttää kaikki aikaisemmat</b> osapuolten välillä tehdyt sopimuksen
                  tarkoittamia Palveluja koskevat sopimukset sekä suulliset ja
                  kirjalliset ilmoitukset.
                </p>
                <b>Erimielisyyksien ratkaiseminen</b>
                <p>
                  <b>39.</b> Osapuolet pyrkivät ratkaisemaan erimielisyydet neuvotteluin. Osapuolet saattavat tästä sopimuksesta aiheutuvat
                  riitai- suudet ensisijaisesti ratkaistavaksi sovinnollisesti
                  Suomen Asianajajaliiton sovintomenettelysääntöjen mukaisessa
                  sovin- tomenettelyssä taikka tuomioistuimen
                  sovintomenettelyssä. Tarvittaessa kumpi tahansa osapuoli saa
                  viedä sovinnon väli- miehen vahvistettavaksi. Muutoin
                  riitaisuudet ratkaistaan vastaajan kotipaikan
                  käräjäoikeudessa.
                </p>
                <b>Muut ehdot</b>
                <p>
                  <b>40.</b> Sopimukseen sovelletaan näitä yleisiä ehtoja ja muita
                  sopimuksessa mainittuja asiakirjoja Suomen lain mukaan.
                  Tilitoi- miston esitteissä, hinnastoissa ja muussa aineistossa
                  olevat tiedot ovat sopimuksen osa vain, jos tarjouksessa,
                  tilausvahvistuksessa tai sopimuksessa on niin erikseen
                  mainittu.
                </p>
                <b>Soveltamisjärjestys</b>
                <p>
                  <b>41.</b> Mikäli sopimusasiakirjat ovat sisällöltään
                  ristiriitaiset, asia- kirjoja sovelletaan seuraavassa
                  etusijajärjestyksessä:
                  <br />
                  1. Toimeksiantosopimus
                  <br />
                  2. Palveluerittely- ja muut
                  rojärjestyksessä
                  <br />
                  3. Nämä yleiset sopimusehdot.
                </p>
              </div>
            </div>

            <div class="foot">
              <div>© Taloushallintoliitto 1.2.2018</div>
              <div>6 (6)</div>
            </div>
          </div>
        </div>
      </ng-template>

    </mat-tab>

    <mat-tab label="{{'sopimus-ja-ehdot.sopimus-henkilotietojen' | translate | async}} TAL 2018">

      <div *ngIf="valittuKieli.kieli === 'en'; else shktalFi" class="text-box">
        <div class="shktal-section">
          <h2>
            Personal data processing agreement TAL 2018
          </h2>
          <p>
            The accounting firm and the client have entered
            into an accounting services contract under which
            the client acquires the services described in the accounting services contract from the accounting
            firm. The accounting firm processes personal data
            in connection with the services and in this personal
            data processing agreement the parties agree upon
            the terms under which the accounting firm
            processes the client's personal data. The processing activities are described in more detail
            in attachment 1-A, which the parties may update if
            necessary during the course of the agreement.
          </p>
          <p>
            “Personal data” means any information relating to
            an identified or identifiable natural person (hereinafter the “data subject”); an identifiable
            natural person is one who can be identified,
            directly or indirectly, in particular by reference to
            an identifier such as a name, an identification
            number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural
            or social identity of that natural person.
          </p>
          <p>
            ”Processing of personal data” means any
            operation or set of operations which is performed
            on personal data or on sets of personal data, whether or not by automated means, such as
            collection, recording, organisation, structuring,
            storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available,
            alignment or combination, restriction, erasure or destruction.
          </p>
          <p>
            When processing personal data, the client is a
            controller that determines the purposes and means of the processing and the accounting firm
            is a processor that processor personal data on
            behalf of the controller.
          </p>
          <p>
            This contract encompasses the following attachments that are applied in the following order:
          </p>
          <p>
            1-A Record of processing activities
            <br />
            1-B Security of personal data at the accounting firm
          </p>
          <br />
          <h3>
            General rights and obligations
          </h3>
          <b>The client's general rights and obligations as controller</b>
          <br />
          <p>
            The client
          </p>
          <p>
            a) is responsible for collecting the personal data;
          </p>
          <p>
            b) processes personal data legally, carefully and
            in accordance with good data processing practices and otherwise in a way that the data subjects' right to respect for private life or other civil rights protecting privacy are not restricted without a reason prescribed by law;
          </p>
          <p>
            c) determines the purposes and means of the processing of personal data and provides the accounting firm written instructions regarding the processing of personal data. The purposes shall describe in what sort of tasks personal data is processed (inter alia calculation of pay);
          </p>
          <p>
            d) shall provide data subjects with all relevant notifications and information regarding the processing of their personal data as required by law;
          </p>
          <p>
            e) shall be responsible for the fulfilment of the data subjects' rights
          </p>
          <p>
            f) shall ensure that the transfer of personal data to the accounting firm and the processing of personal data under this agreement complies with applicable law during the entire duration of this agreement;
          </p>
          <p>
            g) assures that if it represents its group of companies or other third parties in this agreement, it has the right to commit to this agreement and give the accounting firm the right to process personal data under this agreement and the accounting services contract;
          </p>
          <p>
            h) confirms and ensures that processing of personal data under this agreement complies with applicable law, including data security requirements;
          </p>
          <p>
            i) confirms that it has given the accounting firm all relevant information required by the accounting firm to fulfil its obligations under this agreement and the accounting services contract in accordance with its obligations under data protection legislation;
          </p>
          <p>
            j) or its authorized external auditor may execute audits regarding processing of personal data by the accounting firm or its subcontractors under this agreement;
          </p>
          <p>
            k) shall provide any corrections, deletions and changes to the personal data to the accounting firm without delay; and
          </p>
          <p>
            l) shall reserve all ownership, immaterial property and other rights to its personal data.
          </p>
          <p>
            If the parties have agreed that, the accounting firm shall assist the client in drafting statutory records, the client shall provide the accounting firm with the information it needs to do so. The accounting firm provides the records only to the client.
          </p>
          <b>
            The accounting firm's general rights and obligations as processor
          </b>
          <p>
            The accounting firm
          </p>
          <p>
            a) shall process personal data only for the
            purposes set out in the accounting services contract and this contract and only to the extent necessary to provide the services commissioned by the client, unless otherwise required by compulsory law. The accounting firm shall not have the right to use personal data received or accessed during the course of the assignment in its own activities, or transfer, process or combine them with
            any other material in its possession, other than to the extent required by the accounting services contract and to fulfil its tasks under the accounting services contract;
          </p>
          <p>
            b) processes personal data legally, carefully and in accordance with good data processing practices and otherwise in a way that the data subjects' right to respect for private life or other civil rights protecting privacy are not restricted without a reason prescribed by law;
          </p>
          <p>
            c) shall process, and shall ensure that persons working under its supervision that have access to personal data process, personal data only in accordance with the client's documented, legal and reasonable written instructions, unless otherwise required by applicable law. In such cases, the accounting firm shall immediately notify the client of such legal requirements, unless applicable
            law prohibits such notification;
          </p>
          <p>
            d) shall ensure that personal data is only processed by persons whose duties require the processing of personal data, and that such persons have committed themselves to confidentiality or are under an appropriate statutory obligation of confidentiality;
          </p>
          <p>
            e) shall execute all security measures required by processors under applicable law as further described in this agreement;
          </p>
          <p>
            f) shall, insofar as possible and taking into account the nature of the processing, assist the client by appropriate technical and organisational measures in the fulfilment of the client's obligation to respond to data subjects' requests to exercise their rights;
          </p>
          <p>
            g) shall, taking into account the nature of processing and the information available to the accounting firm, assist the client in ensuring compliance with the client's legal obligations under applicable data protection law, including but not limited to data security, impact assessments and prior consultation obligations. The accounting firm is only required to assist the client to the
            extent required of the accounting firm as a processor under applicable law;
          </p>
          <p>
            h) shall take into account any amendments, removals and changes provided by the client in its data processing activities without undue delay;
          </p>
          <p>
            i) shall, at the client's choice and instructions, during or after the end of this agreement, delete or return all personal data to the client and delete existing copies unless compulsory law require otherwise. The parties can further agree upon the rules regarding the deletion or return of data separately;
          </p>
          <p>
            j) shall maintain necessary records/books and make available to the client all information necessary to demonstrate the accounting firm's compliance with its obligations under this agreement and applicable law;
          </p>
          <p>
            k) shall allow for and contribute to audits, including inspections, conducted by the client or any auditor mandated by the client as further agreed under this contract;
          </p>
          <p>
            l) shall, if the accounting firm considers the client's instructions to be in breach of applicable law, inform the client thereof;
          </p>
          <p>
            m) shall, if the accounting firm considers there to be deficiencies in the client's methods, inform the client thereof and, if necessary, assist the client in correcting such methods.
          </p>
          <p>
            The accounting firm has the right to invoice separately for the work and costs related to the above-mentioned provision of support, execution of rectifications, answering of requests, audit support and work consequential to changes due to the client's instructions.
          </p>
          <b>Data security</b>
          <p>
            The accounting firm shall implement and maintain appropriate technical and organisational measures to ensure a sufficient level of protection of the personal data and to protect the personal data from unauthorized and unlawful processing as well as unintentional loss, destruction, damage, change and disclosure, taking into account the state of the art, the costs of implementation, the
            nature, scope, context and purposes of processing as well as risk of varying probability and severity directed at the rights of natural persons.
          </p>
          <p>
            The data security principles executed by the accounting firm due to this agreement are further described in attachment 1-B.
          </p>
          <p>The client shall ensure that the accounting firm is informed of all circumstances related to the personal data provided by the client (such as risk assessments or processing of special categories of data) that affect the technical and organizational measures under this contract.</p>
          <p>The data security measures are evaluated, reviewed and updated regularly.</p>
          <b>
            Sub-contractors
          </b>
          <p>
            The accounting firm may use sub-contractors when processing personal data under this agreement. “Sub-contractor” means a processor contracted by the accounting firm to perform processing hereunder, in part or in whole, on the accounting firm's behalf and mandate. The client will be informed of any sub-contractors used at the start of this contract upon request. The accounting firm will
            inform the client of any intended changes concerning the addition or replacement of sub- contractors. If the client does not approve of the intended change, the client and the accounting firm have the right to terminate the accounting services contract – to the extent the change of subcontractor affects a service that includes processing of personal data – within 30 days from the
            accounting firm informing the client of the change, so that it ends at the end of said 30 day period. If a change of sub- contractor that the client does not approve of and that the accounting firm cannot impact, prohibits or significantly hinders the provision of services, the accounting firm is not obliged to provide such service.
          </p>
          <p>
            The accounting firm shall enter into a written processing agreement with the sub-contractors and shall require all sub-contractors to comply with data protection obligations imposed on the accounting firm in this agreement or data protecting obligations of corresponding level. The accounting firm shall be liable for its sub-contractors' actions as for its own.
          </p>
          <b>Transfer of personal data</b>
          <p>
            The accounting firm may transfer data outside the European Union, the European Economic Area or other countries that the European Commission has found to have an adequate level of data protection, only with the prior written consent of the client. If data is transferred outside said areas, the accounting firm shall enter into a personal data transfer agreement with all necessary
            parties. The personal data transfer agreement shall be drafted in accordance with standard contractual clauses approved by the European Commission. As an alternative to such standard contractual clauses, the transfer can take place in accordance with other transfer grounds approved by applicable law.
          </p>
          <p>
            If there is a contradiction between the standard contractual clauses or another legal transfer ground and this agreement, the standard contractual clauses and alternative transfer grounds will always receive priority in relation to the accounting services contract and this agreement.
          </p>

          <b>Notification of personal data breach</b>
          <p>
            ”Personal data breach” means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data transmitted, stored or otherwise processed.
          </p>
          <p>The accounting firm shall without undue delay inform the client of a personal data breach after the accounting firm or its sub-contractor has become aware thereof. Unless the parties agree otherwise, the notification shall be provided to the contact person assigned by the client.</p>
          <p>The accounting firm shall without undue delay inform the client of the circumstances giving rise to the personal data breach, and any other related information reasonably requested by the client and available to the accounting firm.</p>
          <p>To the extent available, the accounting firm shall provide the client with at least the following information:</p>
          <p>
            a) a description of the nature of the personal data breach, including, where possible, the categories and approximate number of data subjects concerned, and the categories and approximate number of personal data records concerned;
          </p>
          <p>
            b) the name and contact details of the person that is responsible for the accounting firm's data protection matters;
          </p>
          <p>
            c) a description of the likely consequences of the personal data breach; and
          </p>
          <p>
            d) a description of the measures taken or proposed to be taken by the accounting firm to address the personal data breach, including, where appropriate, measures to mitigate its possible adverse effects.
          </p>
          <p>
            If and to the extend the aforementioned information cannot be provided at the same time, the information may be provided in phases.
          </p>
          <b>Audit</b>
          <p>
            The client shall be entitled to audit the accounting firm's performance of its processing obligations under this agreement. The client is obligated to use external auditors who are not competitors of the accounting firm, to conduct such an audit. The parties shall agree well in advance on the time and other details relating to the conduct of such audits. The audit shall be conducted in
            such a manner that the accounting firm's undertakings towards third parties are in no way jeopardized. All the accounting firm's representatives or external auditors participating in the audit shall execute customary confidentiality undertakings towards the accounting firm.
          </p>
          <p>
            The client shall be responsible for all costs related to the audit. The accounting firm may also invoice the client for assisting in the audit and for other extra work related to the audit.
          </p>
          <b>Confidentiality</b>
          <p>
            The accounting firm shall:
            <br />
            a) keep any personal data received from the client
            confidential;
          </p>
          <p>
            b) ensure that persons authorized to process the
            personal data have committed themselves to
            confidentiality; and
          </p>
          <p>
            c) ensure that personal data is not
            disclosed/transferred to third parties without the client's prior written consent, unless the accounting firm is obliged by mandatory law or decree to disclose such information.
          </p>
          <p>
            In case data subjects or governmental authorities make a request concerning personal data, the accounting firm shall, as soon as reasonably possible, inform the client about such request before providing any response or taking other action concerning the personal data. In case any applicable authority demands an immediate response to a disclosure request, the accounting firm shall
            inform the client of the request as soon as reasonably possible after answering it, unless the accounting firm is prohibited by mandatory law or authority order to disclose such information.
          </p>
          <b>Limitation of liability</b>
          <p>
            The terms of limitation of liability in the accounting services contract are applied also to this agreement. If limitation of liability has not been agreed upon in the accounting services agreement, the following shall be applied:
            The accounting firm is only liable for direct damages arising from its negligence.
          </p>
          <p>
            The accounting firm is not liable for indirect damages such as loss of income, revenue or markets, interruption of production or service, loss of profit or other similar damage.
          </p>
          <p>
            If a third party makes a claim to one of the parties based on processing of personal data, the other party must be informed without delay. If the accounting firm is liable to pay damages to a third party, the client shall compensate the accounting firm for the loss to the extent it is not due to the accounting firm's mistake or neglect in following the contract terms.
          </p>
          <p>
            Notwithstanding the above, the accounting firm's liability is always maximum 10,000 euros per damage event and maximum 20,000 euros for damage events during the same accounting period, unless otherwise agreed in the accounting services contract. A damage is considered one damage event, even if affected by the recurrence of the same error and even if it affects several accounting
            periods. The damage is considered taking place in full during the accounting period the most essential parts took place, although some part of the damage took place during another accounting period. A breach of contract, error or neglect does not cause the accounting firm any other consequences than what has been described above.
          </p>
          <p>
            The demands to the accounting firm shall be made in writing without delay. If an error or deficiency is detected or can be detected immediately, the notification shall be made immediately or by the latest within fourteen (14) days. If an itemized demand has not been made to the accounting firm within six (6) months from detecting the damage, no compensation will be paid. In addition,
            no compensation will be paid if the demand is made after three years from the processing event in question.
          </p>
          <p>

            In all situations, each party is responsible for any administrate sanctions imposed on them by the supervisory authority or competent court that according to the decision of the supervisory authority or court in question are a consequence for the party in question not following its obligations under data protection legislation.
          </p>
          <b>Term</b>
          <p>
            The term of this agreement is tied to the term of the accounting services contract and will end automatically when the accounting services contract ends for whatever reason.
          </p>
          <p>

            If the client is in breach of this agreement, the accounting firm has the right to cancel the accounting services contract and this agreement, unless the client has corrected its actions and taken all measures to avoid, remedy and compensate for any consequences from the breach within seven (7) days from receiving the accounting firm's demand.
          </p>
          <p>
            Obligations which, by their nature, are intended to remain in force irrespective of the termination of this agreement, shall remain in force irrespective of the termination of this agreement.
          </p>
        </div>

        <div class="shktal-section">
          <b>Attachment 1-A Record of processing activities</b>
          <p>
            Tilitoimiston seloste henkilötietojen
            käsittelytoimista
          </p>
          <b>Alihankkijat</b>
          <p>
            Asiakas on antanut yleisen suostumuksen alihankkijoiden
            käyttöön. Tilitoimisto toimittaa pyydettäessä
            luettelon alihankkijoista
          </p>
          <p>
            Rekisteröityjen ryhmät sekä henkilötietojen
            käsittelyn tarkoitus ja luonne Tilitoimisto
            käsittelee seuraavien rekisteröityjen tietoja
            seuraaviin tarkoituksiin:
          </p>
          <p>
            - Asiakkaan palkan- ja palkkionsaajat palkka- ja
            henkilöstöhallinnon toteuttamiseksi - Asiakkaan
            henkilöasiakkaat laskutuksen ja saatavien seuraamista
            varten
            <br />
            - Yhdistyksen jäsenet yhdistyksen jäsenhallintaa ja
            laskutusta varten
            <br />
            - Asunto-osakeyhtiön osakkaat asunto-osakeyhtiön
            hallintoa varten
          </p>
          <p>
            - Osakasrekisteri osakeyhtiölain edellyttämällä
            tavalla Käsittelyiden kohde ja ryhmät sekä
            henkilötietojen tyyppi
          </p>
          <p>
            Tilitoimisto käsittelee seuraavia
            henkilötietoryhmiä:
          </p>
          <p>
            - Nimi ja yhteystiedot
            <br />
            - Henkilötunnus
            <br />
            - Henkilön perustiedot kuten syntymäaika, sukupuoli
            ja koulutustiedot,
          </p>
          <p>
            Palkanlaskennassa tarvittavat tiedot kuten
            ennakonpidätystiedot, sairauspoissaoloja koskevat tiedot
          </p>
          <p>- Palkanlaskennassa tarvittavat tietosuoja-asetuksen
            tarkoittamat erityiset henkilötietoryhmät sairauspoissaolot/terveystiedot ja
            ammattiyhdistysjäsenyystiedot
            <br />
            - Palkanlaskennan
            perusteella syntyneet palkka-, eläke-, ja
            verotustiedot sekä muut vastaavat tiedot
            <br />
            - Laskutusta ja perintää varten tarvittavat laskutus-
            ja perintätiedot
            <br />
            - Osakeyhtiön tai asunto-osakeyhtiön hallinnointia
            varten tarvittavat osakas- ja osakkuustiedot
          </p>
          <p>
            Henkilötietojen käsittelyn kesto Elleivät osapuolet
            ole toisin sopineet, henkilötietoja käsitellään
            niin pitkään kuin palveluita toimitetaan
            toimeksiantosopimuksen mukaisesti tai lainsäädäntö
            edellyttää tietojen säilyttämistä.
          </p>
          <p>
            Henkilötietojen maantieteellinen sijainti
          </p>
          <p>
            - Suomi ja muut ETA-maat
            <br />
            - USA
          </p>
        </div>
        <div class="shktal-section">
          <b>Attachment 1-B Security of personal data at the accounting firm</b>
          <p>
            Tietoturvaa ja henkilötietojen lainmukaista
            käsittelyä varmentavat toimet.
          </p>
          <p>
            Tilitoimisto: Tilitoimisto Lemon Tree Oy Laatija,
            päivittäjä: Valtteri Virtanen Päiväys,
            muutospäiväys: 18.05.2018
          </p>
          <b>
            Hallinto
          </b>
          <p>
            - Tietoturva sekä henkilötietojen lainmukainen
            käsittely ovat keskeinen osa tilitoimiston
            toimintaperiaatteita.
            <br />
            - Tietoturvaan ja henkilötietojen käsittelyyn
            liittyvät roolit ja vastuut on nimetty
            henkilötasolla.
            <br />
            - Tietoturvapolitiikka ja siihen liittyvät
            käytännöt on määritelty. Tietoturvapolitiikka ja
            tietoturvakäytännöt on auditoitu ulkopuolisen
            asiantuntijan toimesta ja ne katselmoidaan
            säännöllisesti.
          </p>
          <b>
            Henkilöstö
          </b>
          <p>
            - Henkilöstön roolit, työtehtävät ja vastuut on
            määritetty selkeästi.
            <br />
            - Työntekijöiden kanssa on laadittu sopimus liike- ja
            ammattisalaisuuksien salassapidosta
            <br />
            - Työsuhteiden päättymisen varalle on luotu
            toimintamalli, jossa on huomioitu käyttöoikeuksien
            poistaminen ja työntekijän hallussa mahdollisesti
            olevien aineistojen palauttaminen.
            <br />
            - Henkilöstö on perehdytetty tietoturvapolitiikkaan
            ja -käytäntöihin ja perehdytys kuuluu osana uusien
            työntekijöiden koulutusohjelmaa.
            <br />
            - Olennaisten tietoturvaan liittyvien vaaratilanteiden
            raportointiin ja käsittelyyn on toimintamalli.
          </p>
          <b>
            Toimintamallit
          </b>
          <p>
            - Suojattavan tiedon käsittely erilaisissa
            viestintäjärjestelmissä, kuten sähköpostissa tai
            pikaviestimissä on määritelty ja internetin ja
            sosiaalisen median käytölle tilitoimiston
            tietoverkossa luotu hyväksyttävän käytön
            pelisäännöt.
            <br />
            - Ulkopuolisten pilvitallennuspalveluiden käyttö
            tapahtuu ainoastaan yrityksen johdon määrittämissä
            tilanteissa hyväksymillä palveluntarjoajilla.
          </p>
          <p>
            - Etätyöskentelylle on luotu tietoturvaan liittyvät
            ohjeet.
          </p>
          <b>
            Toimitilaturvallisuus
          </b>
          <p>
            - Tilitoimiston tiloissa on turvalukitus
            <br />
            - Tilitoimistolla on ajantasainen rekisteri
            toimitilojen ja muiden suojattavien kohteiden avaimista
            sekä kulkutunnisteista.
            <br />
            - Asiakkaiden ja kolmansien osapuolten pääsy
            työpisteisiin sekä suojattaviin kohteisiin ja
            tietoihin on estetty.
          </p>
          <b>
            Asiakkaan tunnistaminen ja aineistojen luovutukset
          </b>
          <p>
            - Asiakkaiden edustajat tunnistetaan ennen
            asiakassuhteen alkamista ja tunnistetiedot tallennetaan
            rahanpesulain edellyttämällä tavalla.
            <br />
            - Asiakkaan aineistojen luovutustilanteessa noudatetaan
            hyvän tilitoimistotavan edellyttämiä sekä asiakkaan
            kanssa sovittuja tunnistus- ja
            luovutuskuittauskäytäntöjä. - Jos tilitoimisto
            hallinnoi sopimuksen mukaan asiakkaan puolesta
            asiakkaan käyttäjien pääsyä tietojärjestelmiin,
            käyttäjähallinnointi tapahtuu asiakkaan nimettyjen
            henkilöiden kanssa, sovittuja tunnustamistapoja
            hyödyntäen sekä huolehtien tunnusten ja salasanojen
            tietoturvallisista toimitustavoista.
          </p>
          <b>
            Käyttövaltuushallinta ja salasanapolitiikka
          </b>
          <p>
            - Tietojärjestelmissä käytetään vain yksilöityjä
            nimetyille henkilöille osoitettuja käyttäjätunnus-
            tai salasanapareja. Poikkeuksena ovat tilanteet, joissa
            tilitoimiston johto on arvioinut riskin
            epäolennaiseksi.
            <br />
            - Henkilöstön käyttäjätunnuksista ja
            käyttöoikeuksista tilitoimiston ulkopuolisiin
            tietojärjestelmiin pidetään kirjaa.
          </p>
          <p>
            - Työntekijöiden käyttöoikeuksien tarpeellisuutta
            tarkastellaan työtehtävien olennaisesti muuttuessa.
            <br />
            - Salasanat, PIN-koodit ja käyttäjähallintaan
            tarkoitetut koodit säilytetään tarkoitukseen
            soveltuvassa turvallisessa
            tietojärjestelmässä/tiedostossa.
          </p>
          <p>
            - Kaikissa luottamuksellista tietoa sisältävissä
            tietojärjestelmissä on käytössä salasanaan tai
            vastaavaan menettelyyn perustuva pääsynhallinta.
            <br />
            - Tietojärjestelmien pääkäyttäjätunnusten
            oletussalasanat on vaihdettu ja tietojärjestelmien
            salasanat vaihdetaan säännöllisesti.
          </p>
          <b>
            Ulkopuoliset toimijat
          </b>
          <p>
            Ulkopuolisia toimijoita ovat esimerkiksi
            siivousliikkeet, vartiointiliikkeet,
            kiinteistönhoitoyritykset, isännöintiliikkeet ja
            muut yhteistyökumppanit, joilla on pääsy
            organisaation toimitiloihin tai suojattaviin tietoihin.
          </p>
          <p>
            - Tilitoimiston yhteistyökumppaneiden kanssa on
            laadittu kirjallinen sopimus luottamuksellisen tiedon
            salassapidosta ja yhteistyökumppanit ovat tietoisia
            tilitoimiston tietoturvakäytännöistä ja
            suojattavista kohteista sekä tietosuoja- asetuksen
            vaatimuksista.
          </p>
          <p>
            - Toimitiloissa säännöllisesti työskentelevät
            ulkopuolisten toimijoiden työntekijät perehdytetään
            tarvittavissa määrin tilitoimiston
            tietoturvakäytäntöihin.
          </p>
          <b>
            Ulkoistetut ICT-palvelut
          </b>
          <p>
            Ulkoistetuilla ICT-palveluilla tarkoitetaan tässä
            kohdassa tilitoimiston ulkopuolisia yrityksiä, jotka
            tuottavat tilitoimistolle esimerkiksi palvelimien ja
            työasemien ylläpitopalvelua, tallennus- sekä
            varmistuspalveluita, tietoturvan ylläpitopalvelua tai
            tietoliikenneyhteyksien ylläpitopalvelua.
          </p>
          <p>
            - Ulkopuolisista ICT-palveluista on laadittu
            kirjalliset palvelusopimukset sekä
          </p>
          <p>
            kirjallinen sopimus luottamuksellisen tiedon
            salassapidosta.
            <br />
            - Tilitoimiston ja palveluntarjoajan välinen
            vastuunjako on dokumentoitu kirjallisesti ja
            palveluntarjoaja on tietoinen tilitoimiston
            tietoturvakäytännöistä ja suojattavista kohteista.
            <br />
            - Tilitoimiston ja ulkoistettujen ICT-palveluiden
            toiminnan ylläpidosta ja kehittämisestä
            keskustellaan määräajoin palveluntarjoajan kanssa.
          </p>
          <b>
            Suojattavien kohteiden ja tiedon hallinta
          </b>
          <p>
            Suojattavia kohteita ovat esimerkiksi työasemat,
            kannettavat tietokoneet, palvelimet ja mobiililaitteet.
          </p>
          <p>
            - Suojattaville kohteille määritelty hyväksyttävän
            käytön pelisäännöt.
            <br />
            - Asiakkaan kirjanpitoaineistolle, henkilötiedoille ja
            muille tiedoille on laadittu käsittelyohjeet. Sekä
            digitaalisen tiedon että tulosteiden tuhoamiselle on
            laadittu tietoturvallisen tuhoamisen menettelyohjeet.
            <br />
            - Käytössä on asianmukaiset tietosuojaroskasäiliöt
            tai asiakirjasilppuri luokitellun tiedon tuhoamista
            varten.
          </p>
          <b>
            Tietokoneiden ja mobiililaitteiden tietoturva
          </b>
          <p>
            - Tilitoimiston käytössä olevat työasemat,
            kannettavat tietokoneet, mobiililaitteet ja muut
            päätelaitteet on rekisteröity ja dokumentoitu
            asianmukaisesti.
            <br />
            - Koneiden säännöllisistä tietoturvapäivityksistä
            on huolehdittu asianmukaisesti ja päivityksiä
            valvotaan. Työntekijöiden oikeutta asentaa
            ohjelmistoja työasemille on rajattu ja asennuksia
            valvotaan.
          </p>
          <p>
            - Asianmukainen virus- ja haittaohjelmien
            torjuntaohjelmisto on käytössä.
            <br />
            - Tietoverkko ja tietokoneet on suojattu palomuurilla.
            <br />
            - Työntekijöiden henkilökohtaisten tietokoneiden ja
            mobiililaitteiden käyttö henkilötietojen
            käsittelyyn on kielletty.
          </p>
          <b>
            Siirrettävät tietovälineet
          </b>
          <p>
            Siirrettäviä tietovälineitä ovat esimerkiksi
            USB-muistitikut, USB-massamuistit, CD/DVD-levyt ja muut
            vastaavat muistilla tai tallennustilalla varustetut
            laitteet, jotka voidaan kytkeä tietokoneeseen.
          </p>
          <p>
            - Tilitoimistossa ei käytetä siirrettäviä
            tietovälineitä työtehtävien hoitamiseen tai
            suojattavan tiedon käsittelyyn lukuun ottamatta
            erikseen sovittuja tilanteita kuten aineiston luovutus
            tilintarkastajalle tai aineiston luovutus tai
            vastaanotto asiakkaan nimetyn yhteyshenkilön kanssa.
          </p>
          <p>
            - Käytettäessä siirrettäviä tietovälineitä
            edellä mainittuihin tarkoituksiin on niiden sisältö
            suojattu salasanalla.
          </p>
          <b>
            Palvelin- ja tietoliikenneturvallisuus
          </b>
          <p>
            - Toimitilojen palvelintilat ja tietoliikenneyhteyksien
            edellyttämät tilat pidetään lukittuina.
          </p>
          <p>
            - Langattomien verkkojen tietoliikenne on salattu.
            <br />
            - Vierasverkot on eriytetty tilitoimiston sisäisestä
            tietoverkosta luotettavalla menetelmällä.
            <br />
            - Palvelinkäyttöjärjestelmät päivitetään
            säännöllisesti.
            <br />
            - Palvelinjärjestelmä on rakennettu vikasietoiseksi
            tai kahdennettuksi siten, että tietojärjestelmien
            toiminta ei keskeydy yksittäisestä laiterikosta.
          </p>
          <b>
            Taloushallinnon pilvipalvelut
          </b>
          <p>
            Taloushallinnon pilvipalvelulla tarkoitetaan tässä
            kohdassa SaaS- tai ASP-palveluna toimitettavia
            taloushallinnon tietojärjestelmiä, joita organisaatio
            käyttää taloushallinnon palveluidensa tuottamiseen
            omille asiakkailleen.
          </p>
          <p>
            - Sopimuksiimme taloushallinnon pilvipalvelun
            käytöstä sisältyy kirjallinen palvelutasosopimus.
            <br />
            - Tilitoimiston ja palveluntarjoajan välinen
            vastuunjako on dokumentoitu kirjallisesti.
            <br />
            - Tilitoimisto on saanut palveluntarjoajalta selvitykset,
            jotka todentavat että palvelua tuotetaan
            tietosuoja-asetuksen sekä kirjanpitolain asettamat
            aineiston säilytysvaatimukset huomioiden.
          </p>
        </div>
      </div>

      <ng-template #shktalFi class="text-box">
        <div class="shktal-section">
          <h2>
            Sopimus henkilötietojen käsittelystä TAL 2018
          </h2>
          <p>
            Tilitoimisto ja asiakas ovat tehneet
            toimeksiantosopimuksen, jolla asiakas hankkii
            toimeksiantosopimuksessa kuvatut palvelut
            tilitoimistolta. Tilitoimisto käsittelee
            henkilötietoja palvelujen yhteydessä ja tässä
            sopimuksessa henkilötietojen käsittelystä sovitaan
            ehdoista, joiden mukaisesti tilitoimisto käsittelee
            asiakkaan henkilötietoja. Käsittelytoimet kuvataan
            yksityiskohtaisemmin liitteessä 1-A, jota osapuolet
            tarvittaessa päivittävät sopimuksen
            voimassaoloaikana.
          </p>
          <p>
            ”Henkilötiedolla” tarkoitetaan kaikkia tunnistettuun
            tai tunnistettavissa olevaan luonnolliseen henkilöön
            (jäljempänä ”rekisteröity”) liittyviä tietoja;
            tunnistettavissa olevana pidetään luonnollista
            henkilöä, joka voidaan suoraan tai epäsuorasti
            tunnistaa erityisesti tunnistetietojen, kuten nimen,
            henkilötunnuksen, sijaintitiedon,
            verkkotunnistetietojen taikka yhden tai useamman
            hänelle tunnusomaisen fyysisen, fysiologisen,
            geneettisen, psyykkisen, taloudellisen, kulttuurillisen
            tai sosiaalisen tekijän perusteella.
          </p>
          <p>
            ”Henkilötietojen käsittelyllä” tarkoitetaan
            toimintoa tai toimintoja, joita kohdistetaan
            henkilötietoihin tai henkilötietoja sisältäviin
            tietojoukkoihin joko automaattista tietojenkäsittelyä
            käyttäen tai manuaalisesti, kuten tietojen
            keräämistä, tallentamista, järjestämistä,
            jäsentämistä, säilyttämistä, muokkaamista tai
            muuttamista, hakua, kyselyä, käyttöä, tietojen
            luovuttamista siirtämällä, levittämällä tai
            asettamalla ne muutoin saataville, tietojen
            yhteensovittamista tai yhdistämistä, rajoittamista,
            poistamista tai tuhoamista.
          </p>
          <p>
            Käsiteltäessä henkilötietoja asiakas on
            rekisterinpitäjä, joka määrittelee henkilötietojen
            käsittelyn tarkoitukset ja keinot, ja tilitoimisto on
            käsittelijä, joka käsittelee henkilötietoja
            rekisterinpitäjän lukuun.
          </p>
          <p>
            Tämä sopimus sisältää seuraavat liitteet, joita
            sovelletaan seuraavassa järjestyksessä:
          </p>
          <p>
            1-A Seloste käsittelytoimista
            <br />
            1-B Henkilötietojen tietoturva tilitoimistossa
          </p>
          <br />
          <h3>
            Yleiset oikeudet ja velvollisuudet
          </h3>
          <b>Asiakkaan yleiset oikeudet ja velvollisuudet
            rekisterinpitäjänä</b>
          <br />
          <p>
            Asiakas
          </p>
          <p>
            a) vastaa henkilötietojen keräämisestä;
          </p>
          <p>
            b) käsittelee henkilötietoja laillisesti,
            huolellisesti ja hyvää tietojenkäsittelytapaa
            noudattaen sekä toimii muutoinkin niin, ettei
            rekisteröityjen yksityiselämän suojaa ja muita
            yksityisyyden suojan turvaavia perusoikeuksia rajoiteta
            ilman laissa säädettyä perustetta;
          </p>
          <p>
            c) määrittelee henkilötietojen käsittelyn
            tarkoitukset ja keinot sekä antaa tilitoimistolle
            kirjalliset ohjeet henkilötietojen käsittelystä.
            Henkilötietojen käsittelyn tarkoituksesta tulee
            ilmetä, minkälaisissa tehtävissä (mm.
            palkanlaskenta) henkilötietoja käsitellään;
          </p>
          <p>
            d) vastaa siitä, että rekisteröidyille toimitetaan
            kaikki lainsäädännön edellyttämät
            henkilötietojen käsittelyä koskevat ilmoitukset ja
            tiedot;
          </p>
          <p>
            e) vastaa rekisteröityjen oikeuksien toteutumisesta;
          </p>
          <p>
            f) varmistaa, että henkilötietojen siirtäminen
            tilitoimistolle sekä henkilötietojen käsittely
            tämän sopimuksen mukaisesti on lainmukaista koko
            sopimuksen voimassaolon ajan;
          </p>
          <p>
            g) vakuuttaa, että jos se edustaa tässä sopimuksessa
            konserniyhtiöitään tai kolmansia osapuolia, sillä
            on oikeus sitoutua tähän sopimukseen ja antaa
            tilitoimistolle oikeus käsitellä henkilötietoja
            tämän sopimuksen ja toimeksiantosopimuksen
            mukaisesti;
          </p>
          <p>
            h) vahvistaa ja vastaa siitä, että tämän sopimuksen
            mukainen henkilötietojen käsittely on
            lainsäädännössä asetettujen vaatimusten mukaista,
            mukaan lukien tietoturvavaatimukset;
          </p>
          <p>
            i) vahvistaa, että se on antanut tilitoimistolle
            kaikki tarvittavat tiedot, jotta tilitoimisto voi
            täyttää tässä sopimuksessa ja
            toimeksiantosopimuksessa sille asetetut velvoitteet
            tietosuojalainsäädännön vaatimusten mukaisesti;
          </p>
          <p>
            j) tai sen valtuuttama ulkopuolinen tarkastaja voi
            auditoida tilitoimiston tai tämän alihankkijoiden
            tämän sopimuksen alaista toimintaa;
          </p>
          <p>
            k) vastaa siitä, että korjaukset, poistot ja
            muutokset henkilötietoihin toimitetaan viivytyksettä
            tilitoimistolle; ja
          </p>
          <p>
            l) pidättää itsellään kaikki omistusoikeudet,
            immateriaalioikeudet ja muut oikeudet henkilötietoihin.
          </p>
          <p>
            Jos osapuolet ovat sopineet, että tilitoimisto avustaa
            asiakasta tämän lainmukaisten selosteiden
            laatimisessa, asiakkaan tulee antaa tilitoimistolle
            tämän sitä varten tarvitsemat tiedot. Tilitoimisto
            toimittaa selosteet vain asiakkaalle.
          </p>
          <b>
            Tilitoimiston yleiset oikeudet ja velvollisuudet
            käsittelijänä
          </b>
          <p>
            Tilitoimisto
          </p>
          <p>
            a) käsittelee henkilötietoja ainoastaan
            toimeksiantosopimuksessa ja tässä sopimuksessa
            määriteltyihin tarkoituksiin, vain siinä laajuudessa
            kuin on tarpeen asiakkaan toimeksiannosta tapahtuvaa
            palvelua varten, ja ainoastaan tämän sopimuksen
            voimassaoloajan, ellei pakottavasta
            lainsäädännöstä muuta johdu. Tilitoimistolla ei
            ole oikeutta käyttää toimeksiantosuhteessa saamiaan
            henkilötietoja omassa toiminnassaan, luovuttaa niitä,
            käsitellä niitä, eikä yhdistää tietoja muuhun
            hallussaan olevaan aineistoon muutoin kuin
            toimeksiantosopimuksen tarkoittamassa laajuudessa ja
            sen mukaista tehtävää hoitaessaan;
          </p>
          <p>
            b) käsittelee henkilötietoja laillisesti,
            huolellisesti ja hyvää tietojenkäsittelytapaa
            noudattaen sekä toimii muutoinkin niin, ettei
            rekisteröityjen yksityiselämän suojaa ja muita
            yksityisyyden suojan turvaavia perusoikeuksia rajoiteta
            ilman laissa säädettyä perustetta;
          </p>
          <p>
            c) käsittelee ja varmistaa alaisuudessaan toimivan
            henkilön, jolla on pääsy henkilötietoihin,
            käsittelevän henkilötietoja ainoastaan asiakkaan
            antamien dokumentoitujen, lainmukaisten ja
            kohtuullisten ohjeiden mukaisesti, paitsi jos
            sovellettavassa laissa toisin vaaditaan. Siinä
            tilanteessa tilitoimisto informoi asiakasta
            välittömästi tästä oikeudellisesta vaatimuksesta,
            edellyttäen, ettei sovellettava lainsäädäntö
            kiellä sellaista informointia;
          </p>
          <p>
            d) varmistaa, että henkilötietoja käsittelevät vain
            ne henkilöt, joiden työtehtävien hoitaminen sitä
            edellyttää ja, että kyseiset henkilöt ovat
            sitoutuneet noudattamaan salassapitovelvollisuutta tai
            heitä sitoo asianmukainen lakisääteinen
            salassapitovelvollisuus;
          </p>
          <p>
            e) toteuttaa kaikki lainsäädännön henkilötietojen
            käsittelijöiltä edellyttämät
            turvallisuustoimenpiteet siten kuin tässä
            sopimuksessa on tarkemmin sovittu;
          </p>
          <p>
            f) avustaa mahdollisuuksien mukaan ja käsittelyn
            luonteen huomioon ottaen asiakasta asianmukaisilla
            teknisillä ja organisatorisilla toimenpiteillä
            täyttämään asiakkaan velvollisuuden vastata
            pyyntöihin, jotka koskevat rekisteröityjen oikeuksien
            käyttämistä;
          </p>
          <p>
            g) avustaa käsittelyn luonteen ja tilitoimiston
            saatavilla olevat tiedot huomioon ottaen asiakasta
            varmistamaan, että asiakkaalle laissa asetettuja
            velvollisuuksia, kuten
          </p>
          <p>
            turvatoimia, vaikutusten arviointia ja
            ennakkokuulemista, noudatetaan. Tilitoimisto on
            velvollinen avustamaan asiakasta vain sovellettavan
            lainsäädännön tilitoimistolle käsittelijänä
            asettamien velvoitteiden mukaisessa laajuudessa;
          </p>
          <p>
            h) huomioi asiakkaan toimittamat tietojen korjaukset,
            poistot ja muutokset ilman aiheetonta viivytystä
            henkilötietojen käsittelyssä;
          </p>
          <p>
            i) tämän sopimuksen aikana tai sen päätyttyä joko
            tuhoaa tai palauttaa asiakkaalle asiakkaan valinnan ja
            ohjeiden mukaisesti kaikki henkilötiedot ja poistaa
            olemassa olevat jäljennökset, ellei pakottavasta
            lainsäädännöstä muuta johdu. Tuhoamista ja
            palauttamista koskevista käytännöistä voidaan sopia
            tarkemmin erikseen osapuolten kesken;
          </p>
          <p>
            j) ylläpitää tarvittavia selosteita/kirjanpitoa
            käsittelytoimista ja saattaa asiakkaan saataville
            kaikki sellaiset tiedot, jotka osoittavat, että
            tilitoimisto noudattaa sille tässä sopimuksessa ja
            sovellettavassa lainsäädännössä säädettyjä
            velvollisuuksia;
          </p>
          <p>
            k) sallii asiakkaan tai asiakkaan valtuuttaman
            auditoijan suorittamat auditoinnit ja osallistuu niihin
            siten kuin tässä sopimuksessa on tarkemmin sovittu;
          </p>
          <p>
            l) ilmoittaa asiakkaalle, jos tilitoimisto katsoo,
            että asiakkaan antama ohjeistus rikkoo sovellettavaa
            lainsäädäntöä;
          </p>
          <p>
            m) ilmoittaa asiakkaalle, jos tilitoimisto katsoo,
            että asiakkaan toimintatavoissa on puutteita, ja
            avustaa tarvittaessa asiakasta toimintatapojen
            korjaamisessa.
          </p>
          <p>
            Tilitoimistolla on oikeus laskuttaa yllä kuvatuista
            avustamis-, korjaamis- ja pyyntöihin
            vastaamistoimista, auditoinnin tuesta sekä asiakkaan
            ohjeistuksen muutoksista johtuvista toimista ja
            kustannuksistaan erikseen.
          </p>
          <b>Tietoturva</b>
          <p>
            Tilitoimisto toteuttaa ja ylläpitää asianmukaiset
            tekniset ja organisatoriset toimenpiteet, joilla
            varmistetaan henkilötietojen käsittelyn riittävä
            turvallisuustaso ja joilla suojellaan henkilötietoja
            luvattomalta ja laittomalta käsittelyltä sekä
            tahattomalta menettämiseltä, tuhoamiselta,
            vahingolta, muutokselta tai luovuttamiselta, ottaen
            huomioon erityisesti uusin tekniikka ja
            toteuttamiskustannukset, käsittelyn luonne, laajuus,
            asiayhteys ja tarkoitukset sekä luonnollisten
            henkilöiden oikeuksiin ja vapauksiin kohdistuvat,
            todennäköisyydeltään ja vakavuudeltaan vaihtelevat
            riskit.
          </p>
          <p>
            Tilitoimiston tämän sopimuksen johdosta toteuttamat
            käsittelyn tietoturvaperiaatteet on kuvattu tarkemmin
            tämän sopimuksen liitteessä 1-B. Asiakas on
            velvollinen varmistamaan, että tilitoimistoa
            informoidaan kaikista niistä asiakkaan toimittamiin
            henkilötietoihin liittyvistä seikoista (kuten
            riskiarvioinneista sekä erityisten
            henkilötietoryhmien käsittelystä), jotka vaikuttavat
            tämän sopimuksen mukaisiin teknisiin ja
            organisatorisiin toimenpiteisiin.
            Tietoturvajärjestelyjä arvioidaan, tarkistetaan ja
            päivitetään säännöllisesti.
          </p>
          <b>
            Alihankkijat
          </b>
          <p>
            Tilitoimisto saa käyttää alihankkijoita
            henkilötietojen käsittelyssä tämän sopimuksen
            perusteella. ”Alihankkijalla” tarkoitetaan
            käsittelijää, joka käsittelee henkilötietoja
            tämän sopimuksen mukaisesti, kokonaan tai osittain,
            käsittelijän lukuun ja tämän toimeksiannosta.
            Käsittelyssä käytettävät alihankkijat sopimuksen
            alkaessa ilmoitetaan asiakkaan pyynnöstä.
            Tilitoimisto tiedottaa asiakkaalle ennalta
            suunnitelluista muutoksista, joilla alihankkijoita
            lisätään tai vaihdetaan. Mikäli asiakas ei hyväksy
            suunniteltua muutosta, asiakkaalla ja tilitoimistolla
            on oikeus kolmenkymmenen (30) päivän ajan muutoksen
            tiedottamisesta irtisanoa toimeksiantosopimus
            päättymään kyseisen kolmenkymmenen (30) päivän
            jakson päättyessä sen palvelun osalta, jonka
            tuottamiseen alihankkijan muutos vaikuttaa ja jossa
            henkilötietoja käsitellään. Mikäli
            alihankkijavaihdos, jota asiakas ei hyväksy ja johon
            tilitoimisto ei voi vaikuttaa, estää tai olennaisesti
            vaikeuttaa palveluntuottamista, tilitoimistolla ei ole
            velvollisuutta tuottaa sellaista palvelua.
          </p>
          <p>
            Tilitoimisto solmii kirjallisen käsittelysopimuksen
            alihankkijan kanssa ja edellyttää kaikkien
            alihankkijoiden noudattavan tilitoimistolle tässä
            sopimuksessa asetettuja tietosuojavelvoitteita tai
            vastaavan tietosuojan tason takaavia velvoitteita.
            Alihankkija käsittelee henkilötietoja vain
            kirjallisen sopimuksen mukaisesti. Tilitoimisto vastaa
            käyttämiensä alihankkijoiden toimista kuin omistaan.
          </p>
          <b>Henkilötietojen siirto</b>
          <p>
            Tilitoimisto voi siirtää henkilötietoja Euroopan
            unionin, Euroopan talousalueen tai muiden maiden,
            joiden Euroopan komissio on todennut takaavan
            riittävän tietosuojan tason, ulkopuolelle ainoastaan
            asiakkaan etukäteisellä kirjallisella suostumuksella.
            Mikäli siirretään tietoja edellä mainittujen
            alueiden ulkopuolelle, tilitoimisto solmii
            sovellettavan lain edellytysten mukaisen sopimuksen
            henkilötietojen siirrosta tarvittavien osapuolten
            kanssa. Sopimus henkilötietojen siirrosta laaditaan
            Euroopan komission hyväksymien
            mallisopimuslausekkeiden mukaisesti. Vaihtoehtona
            mallisopimuslausekkeiden käytölle siirto voi tapahtua
            muita soveltuvan lainsäädännön hyväksymiä
            siirtoperusteita käyttäen/hyödyntäen. Mikäli
            mallisopimuslausekkeiden tai minkä tahansa muun
            lainmukaisen siirtoperusteen ja tämän sopimuksen
            välillä on ristiriita, mallisopimuslausekkeet ja
            vaihtoehtoiset siirtoperusteet saavat
            soveltamisjärjestyksessä aina etusijan suhteessa
            toimeksiantosopimukseen ja tähän sopimukseen.
          </p>
          <b>Henkilötietojen tietoturvaloukkauksista ilmoittaminen</b>
          <p>
            ”Henkilötietojen tietoturvaloukkauksella” tarkoitetaan
            tietoturvaloukkausta, jonka seurauksena on
            siirrettyjen, tallennettujen tai muuten käsiteltyjen
            henkilötietojen vahingossa tapahtuva tai lainvastainen
            tuhoaminen, häviäminen, muuttaminen, luvaton
            luovuttaminen taikka pääsy tietoihin.
          </p>
          <p>
            Tilitoimiston on ilmoitettava henkilötietojen
            tietoturvaloukkauksesta asiakkaalle ilman aiheetonta
            viivytystä siitä, kun tilitoimisto tai sen
            käyttämä alihankkija on saanut loukkauksen
            tietoonsa. Elleivät osapuolet ole toisin sopineet,
            ilmoitus tulee tehdä asiakkaan ilmoittamalle
            yhteyshenkilölle.
          </p>
          <p>
            Tilitoimiston on ilman aiheetonta viivytystä
            toimitettava asiakkaalle tieto henkilötietojen
            tietoturvaloukkaukseen johtaneista olosuhteista sekä
            muista siihen liittyvistä tilitoimiston saatavilla
            olevista seikoista asiakkaan kohtuullisten pyyntöjen
            mukaisesti.
          </p>
          <p>
            Siltä osin kuin kyseinen tieto on tilitoimiston
            saatavilla, on asiakkaalle tehtävässä ilmoituksessa
            kuvattava ainakin:
          </p>
          <p>
            a) kuvaus henkilötietojen tietoturvaloukkauksesta,
            mukaan lukien mahdollisuuksien mukaan asianomaisten
            rekisteröityjen ryhmät ja arvioidut lukumäärät
            sekä henkilötietotyyppien ryhmät ja arvioidut
            lukumäärät;
          </p>
          <p>
            b) sen henkilön nimi ja yhteystiedot, joka vastaa
            käsittelijän tietosuoja-asioista;
          </p>
          <p>
            c) kuvaus tietoturvaloukkauksen todennäköisistä
            seurauksista; sekä
          </p>
          <p>
            d) kuvaus niistä toimenpiteistä, jotka tilitoimisto
            ehdottaa toteutettaviksi ja/tai on toteuttanut
            henkilötietojen tietoturvaloukkauksen johdosta, mukaan
            lukien tarvittaessa myös toimenpiteet mahdollisten
            haittavaikutusten lieventämiseksi.
          </p>
          <p>
            Jos ja siltä osin kuin edellä listattuja tietoja ei
            ole mahdollista toimittaa samanaikaisesti, tiedot
            voidaan toimittaa vaiheittain ilman aiheetonta
            viivytystä.
          </p>
          <b>Auditointi</b>
          <p>
            Asiakkaalla on oikeus auditoida käsittelijän tämän
            sopimuksen alainen tietojenkäsittelytoiminta. Asiakas
            on velvollinen käyttämään auditoinnissa vain
            sellaisia ulkopuolisia tarkastajia, jotka eivät ole
            tilitoimiston kilpailijoita. Osapuolet sopivat
            auditoinnin ajankohdasta ja muista yksityiskohdista
            hyvissä ajoin ennen sen suorittamista. Auditointi
            tulee suorittaa tavalla, joka ei haittaa tilitoimiston
            sitoumuksia kolmansiin osapuoliin nähden. Kaikkien
            asiakkaan edustajien ja auditointiin osallistuvien
            ulkopuolisten tarkastajien tulee allekirjoittaa
            tavanomainen salassapitositoumus tilitoimiston
            hyväksi.
          </p>
          <p>
            Asiakas vastaa kaikista auditoinnista aiheutuvista
            kustannuksista. Tilitoimistolla on myös oikeus
            laskuttaa avustamisesta auditoinnissa ja muusta
            auditoinnista johtuvasta lisätyöstä.
          </p>
          <b>Salassapito</b>
          <p>
            Tilitoimisto sitoutuu
            <br />
            a) pitämään luottamuksellisena kaikki asiakkaalta
            vastaanottamansa henkilötiedot,
          </p>
          <p>
            b) varmistamaan, että henkilöt, joilla on oikeus
            käsitellä henkilötietoja, ovat sitoutuneet
            noudattamaan salassapitovelvollisuutta, sekä
          </p>
          <p>
            c) varmistamaan, että henkilötietoja ei
            siirretä/luovuteta kolmansille osapuolille ilman
            asiakkaan etukäteistä kirjallista suostumusta, ellei
            käsittelijä ole velvollinen ilmaisemaan tietoja
            pakottavan lainsäädännön tai viranomaisen
            määräyksen perusteella.
          </p>
          <p>
            Mikäli rekisteröity tai viranomainen tekee
            henkilötietoja koskevan pyynnön, tilitoimiston tulee,
            niin pian kuin on kohtuullisesti mahdollista, ilmoittaa
            asiakkaalle tällaisesta pyynnöstä ennen pyyntöön
            vastaamista tai muiden henkilötietoja koskevien
            toimenpiteiden suorittamista. Mikäli toimivaltainen
            viranomainen vaatii välitöntä vastausta, ilmoittaa
            tilitoimisto asiakkaalle pyynnöstä niin pian kuin on
            mahdollista pyyntöön vastaamisen jälkeen, ellei
            pakottavasta laista muuta johdu.
          </p>
          <b>Vastuunrajoitus</b>
          <p>
            Toimeksiantosopimuksen mukaisia vastuunrajoituksia
            sovelletaan myös tähän sopimukseen. Jos
            vastuunrajoituksista ei ole toimeksiantosopimuksessa
            sovittu, noudatetaan seuraavaa: Tilitoimisto vastaa
            vain huolimattomuudestaan johtuvista välittömistä
            vahingoista.
          </p>
          <p>
            Tilitoimisto ei vastaa välillisistä vahingoista,
            kuten tulon, liikevaihdon tai markkinoiden
            menetyksestä, tuotannon tai palvelun keskeytymisestä,
            saamatta jääneestä voitosta taikka muusta niihin
            verrattavasta vahingosta.
          </p>
          <p>
            Jos kolmas osapuoli tekee osapuolelle
            henkilötietojenkäsittelyn perusteella
            korvausvaatimuksen, siitä on ilmoitettava toiselle
            osapuolelle viipymättä. Jos tilitoimisto joutuu
            maksamaan kolmannelle osapuolelle vahingonkorvausta,
            asiakkaan on hyvitettävä tilitoimistolle tästä
            aiheutunut menetys sikäli kuin se ei johdu
            tilitoimiston virheestä tai laiminlyönnistä
            sopimusehtojen noudattamisessa.
          </p>
          <p>
            Tilitoimiston vastuun enimmäismäärä on kuitenkin
            aina enintään 10.000 euroa yhdessä
            vahinkotapahtumassa ja saman tilikauden aikana
            ilmenneistä vahinkotapahtumista yhteensä enintään
            20.000 euroa, ellei muusta enimmäismäärästä ole
            nimenomaisesti sopimuksessa sovittu. Vahinko katsotaan
            yhdeksi vahinkotapahtumaksi, vaikka siihen olisi
            vaikuttanut saman virheen toistuminen ja vaikka se
            vaikuttaisi useampaan tilikauteen. Vahingon katsotaan
            ilmenneen kokonaan sen tilikauden aikana, jolloin se
            olennaisilta osiltaan ilmeni, vaikka jokin vahingon osa
            ilmenisi muuna tilikautena. Sopimusrikkomus, virhe tai
            laiminlyönti eivät aiheuta tilitoimistolle muuta
            seuraamusta kuin mitä edellä on todettu.
          </p>
          <p>
            Vaatimukset tilitoimistolle on tehtävä kirjallisesti
            viipymättä. Jos virhe tai puute havaitaan tai on
            havaittavissa välittömästi, huomautus on tehtävä
            heti ja viimeistään neljäntoista (14) päivän
            kuluessa. Jos eriteltyä vaatimusta ei ole tehty
            tilitoimistolle kuuden (6) kuukauden kuluessa vahingon
            toteamisesta, ei korvausta makseta. Korvausta ei
            myöskään makseta, jos vaatimus tehdään, kun on
            kulunut yli kolme (3) vuotta kyseisestä
            käsittelytapahtumasta.
          </p>
          <p>
            Kaikissa tapauksissa kumpikin osapuoli vastaa itse
            valvontaviranomaisen tai toimivaltaisen tuomioistuimen
            sille määräämistä hallinnollisista sanktioista,
            jotka ovat ko. valvontaviranomaisen tai tuomioistuimen
            päätöksen mukaisesti seurausta siitä, että
            kyseinen osapuoli ei ole noudattanut sille
            tietosuojalainsäädännössä asetettuja vaatimuksia
            tai velvoitteita.
          </p>
          <b>Voimassaolo</b>
          <p>
            Tämän sopimuksen voimassaolo on sidottu
            toimeksiantosopimuksen voimassaoloon ja päättyy
            automaattisesti toimeksiantosopimuksen päättyessä
            mistä tahansa syystä.
          </p>
          <p>
            Mikäli asiakas rikkoo tätä sopimusta,
            tilitoimistolla on oikeus purkaa toimeksiantosopimus ja
            tämä sopimus, mikäli asiakas ei seitsemän (7)
            päivän kuluessa tilitoimiston lähettämästä
            kehotuksesta ole korjannut menettelyään ja
            huolehtinut kaikkiin toimiin ryhtymisestä
            rikkomuksesta johtuvien seurausten välttämiseksi,
            korjaamiseksi ja korvaamiseksi.
          </p>
          <p>
            Velvoitteet, joiden on niiden luonteen vuoksi tarkoitus
            säilyä voimassa tämän sopimuksen voimassaolon
            päättymisestä riippumatta, jäävät voimaan tämän
            sopimuksen päättymisestä riippumatta.
          </p>
        </div>

        <div class="shktal-section">
          <b>Liite 1-A Seloste käsittelytoimista</b>
          <p>
            Tilitoimiston seloste henkilötietojen
            käsittelytoimista
          </p>
          <b>Alihankkijat</b>
          <p>
            Asiakas on antanut yleisen suostumuksen alihankkijoiden
            käyttöön. Tilitoimisto toimittaa pyydettäessä
            luettelon alihankkijoista
          </p>
          <p>
            Rekisteröityjen ryhmät sekä henkilötietojen
            käsittelyn tarkoitus ja luonne Tilitoimisto
            käsittelee seuraavien rekisteröityjen tietoja
            seuraaviin tarkoituksiin:
          </p>
          <p>
            - Asiakkaan palkan- ja palkkionsaajat palkka- ja
            henkilöstöhallinnon toteuttamiseksi - Asiakkaan
            henkilöasiakkaat laskutuksen ja saatavien seuraamista
            varten
            <br />
            - Yhdistyksen jäsenet yhdistyksen jäsenhallintaa ja
            laskutusta varten
            <br />
            - Asunto-osakeyhtiön osakkaat asunto-osakeyhtiön
            hallintoa varten
          </p>
          <p>
            - Osakasrekisteri osakeyhtiölain edellyttämällä
            tavalla Käsittelyiden kohde ja ryhmät sekä
            henkilötietojen tyyppi
          </p>
          <p>
            Tilitoimisto käsittelee seuraavia
            henkilötietoryhmiä:
          </p>
          <p>
            - Nimi ja yhteystiedot
            <br />
            - Henkilötunnus
            <br />
            - Henkilön perustiedot kuten syntymäaika, sukupuoli
            ja koulutustiedot,
          </p>
          <p>
            Palkanlaskennassa tarvittavat tiedot kuten
            ennakonpidätystiedot, sairauspoissaoloja koskevat tiedot
          </p>
          <p>- Palkanlaskennassa tarvittavat tietosuoja-asetuksen
            tarkoittamat erityiset henkilötietoryhmät sairauspoissaolot/terveystiedot ja
            ammattiyhdistysjäsenyystiedot
            <br />
            - Palkanlaskennan
            perusteella syntyneet palkka-, eläke-, ja
            verotustiedot sekä muut vastaavat tiedot
            <br />
            - Laskutusta ja perintää varten tarvittavat laskutus-
            ja perintätiedot
            <br />
            - Osakeyhtiön tai asunto-osakeyhtiön hallinnointia
            varten tarvittavat osakas- ja osakkuustiedot
          </p>
          <p>
            Henkilötietojen käsittelyn kesto Elleivät osapuolet
            ole toisin sopineet, henkilötietoja käsitellään
            niin pitkään kuin palveluita toimitetaan
            toimeksiantosopimuksen mukaisesti tai lainsäädäntö
            edellyttää tietojen säilyttämistä.
          </p>
          <p>
            Henkilötietojen maantieteellinen sijainti
          </p>
          <p>
            - Suomi ja muut ETA-maat
            <br />
            - USA
          </p>
        </div>
        <div class="shktal-section">
          <b>Liite 1-B Henkilötietojen tietoturva tilitoimistossa</b>
          <p>
            Tietoturvaa ja henkilötietojen lainmukaista
            käsittelyä varmentavat toimet.
          </p>
          <p>
            Tilitoimisto: Tilitoimisto Lemon Tree Oy Laatija,
            päivittäjä: Valtteri Virtanen Päiväys,
            muutospäiväys: 18.05.2018
          </p>
          <b>
            Hallinto
          </b>
          <p>
            - Tietoturva sekä henkilötietojen lainmukainen
            käsittely ovat keskeinen osa tilitoimiston
            toimintaperiaatteita.
            <br />
            - Tietoturvaan ja henkilötietojen käsittelyyn
            liittyvät roolit ja vastuut on nimetty
            henkilötasolla.
            <br />
            - Tietoturvapolitiikka ja siihen liittyvät
            käytännöt on määritelty. Tietoturvapolitiikka ja
            tietoturvakäytännöt on auditoitu ulkopuolisen
            asiantuntijan toimesta ja ne katselmoidaan
            säännöllisesti.
          </p>
          <b>
            Henkilöstö
          </b>
          <p>
            - Henkilöstön roolit, työtehtävät ja vastuut on
            määritetty selkeästi.
            <br />
            - Työntekijöiden kanssa on laadittu sopimus liike- ja
            ammattisalaisuuksien salassapidosta
            <br />
            - Työsuhteiden päättymisen varalle on luotu
            toimintamalli, jossa on huomioitu käyttöoikeuksien
            poistaminen ja työntekijän hallussa mahdollisesti
            olevien aineistojen palauttaminen.
            <br />
            - Henkilöstö on perehdytetty tietoturvapolitiikkaan
            ja -käytäntöihin ja perehdytys kuuluu osana uusien
            työntekijöiden koulutusohjelmaa.
            <br />
            - Olennaisten tietoturvaan liittyvien vaaratilanteiden
            raportointiin ja käsittelyyn on toimintamalli.
          </p>
          <b>
            Toimintamallit
          </b>
          <p>
            - Suojattavan tiedon käsittely erilaisissa
            viestintäjärjestelmissä, kuten sähköpostissa tai
            pikaviestimissä on määritelty ja internetin ja
            sosiaalisen median käytölle tilitoimiston
            tietoverkossa luotu hyväksyttävän käytön
            pelisäännöt.
            <br />
            - Ulkopuolisten pilvitallennuspalveluiden käyttö
            tapahtuu ainoastaan yrityksen johdon määrittämissä
            tilanteissa hyväksymillä palveluntarjoajilla.
          </p>
          <p>
            - Etätyöskentelylle on luotu tietoturvaan liittyvät
            ohjeet.
          </p>
          <b>
            Toimitilaturvallisuus
          </b>
          <p>
            - Tilitoimiston tiloissa on turvalukitus
            <br />
            - Tilitoimistolla on ajantasainen rekisteri
            toimitilojen ja muiden suojattavien kohteiden avaimista
            sekä kulkutunnisteista.
            <br />
            - Asiakkaiden ja kolmansien osapuolten pääsy
            työpisteisiin sekä suojattaviin kohteisiin ja
            tietoihin on estetty.
          </p>
          <b>
            Asiakkaan tunnistaminen ja aineistojen luovutukset
          </b>
          <p>
            - Asiakkaiden edustajat tunnistetaan ennen
            asiakassuhteen alkamista ja tunnistetiedot tallennetaan
            rahanpesulain edellyttämällä tavalla.
            <br />
            - Asiakkaan aineistojen luovutustilanteessa noudatetaan
            hyvän tilitoimistotavan edellyttämiä sekä asiakkaan
            kanssa sovittuja tunnistus- ja
            luovutuskuittauskäytäntöjä. - Jos tilitoimisto
            hallinnoi sopimuksen mukaan asiakkaan puolesta
            asiakkaan käyttäjien pääsyä tietojärjestelmiin,
            käyttäjähallinnointi tapahtuu asiakkaan nimettyjen
            henkilöiden kanssa, sovittuja tunnustamistapoja
            hyödyntäen sekä huolehtien tunnusten ja salasanojen
            tietoturvallisista toimitustavoista.
          </p>
          <b>
            Käyttövaltuushallinta ja salasanapolitiikka
          </b>
          <p>
            - Tietojärjestelmissä käytetään vain yksilöityjä
            nimetyille henkilöille osoitettuja käyttäjätunnus-
            tai salasanapareja. Poikkeuksena ovat tilanteet, joissa
            tilitoimiston johto on arvioinut riskin
            epäolennaiseksi.
            <br />
            - Henkilöstön käyttäjätunnuksista ja
            käyttöoikeuksista tilitoimiston ulkopuolisiin
            tietojärjestelmiin pidetään kirjaa.
          </p>
          <p>
            - Työntekijöiden käyttöoikeuksien tarpeellisuutta
            tarkastellaan työtehtävien olennaisesti muuttuessa.
            <br />
            - Salasanat, PIN-koodit ja käyttäjähallintaan
            tarkoitetut koodit säilytetään tarkoitukseen
            soveltuvassa turvallisessa
            tietojärjestelmässä/tiedostossa.
          </p>
          <p>
            - Kaikissa luottamuksellista tietoa sisältävissä
            tietojärjestelmissä on käytössä salasanaan tai
            vastaavaan menettelyyn perustuva pääsynhallinta.
            <br />
            - Tietojärjestelmien pääkäyttäjätunnusten
            oletussalasanat on vaihdettu ja tietojärjestelmien
            salasanat vaihdetaan säännöllisesti.
          </p>
          <b>
            Ulkopuoliset toimijat
          </b>
          <p>
            Ulkopuolisia toimijoita ovat esimerkiksi
            siivousliikkeet, vartiointiliikkeet,
            kiinteistönhoitoyritykset, isännöintiliikkeet ja
            muut yhteistyökumppanit, joilla on pääsy
            organisaation toimitiloihin tai suojattaviin tietoihin.
          </p>
          <p>
            - Tilitoimiston yhteistyökumppaneiden kanssa on
            laadittu kirjallinen sopimus luottamuksellisen tiedon
            salassapidosta ja yhteistyökumppanit ovat tietoisia
            tilitoimiston tietoturvakäytännöistä ja
            suojattavista kohteista sekä tietosuoja- asetuksen
            vaatimuksista.
          </p>
          <p>
            - Toimitiloissa säännöllisesti työskentelevät
            ulkopuolisten toimijoiden työntekijät perehdytetään
            tarvittavissa määrin tilitoimiston
            tietoturvakäytäntöihin.
          </p>
          <b>
            Ulkoistetut ICT-palvelut
          </b>
          <p>
            Ulkoistetuilla ICT-palveluilla tarkoitetaan tässä
            kohdassa tilitoimiston ulkopuolisia yrityksiä, jotka
            tuottavat tilitoimistolle esimerkiksi palvelimien ja
            työasemien ylläpitopalvelua, tallennus- sekä
            varmistuspalveluita, tietoturvan ylläpitopalvelua tai
            tietoliikenneyhteyksien ylläpitopalvelua.
          </p>
          <p>
            - Ulkopuolisista ICT-palveluista on laadittu
            kirjalliset palvelusopimukset sekä
          </p>
          <p>
            kirjallinen sopimus luottamuksellisen tiedon
            salassapidosta.
            <br />
            - Tilitoimiston ja palveluntarjoajan välinen
            vastuunjako on dokumentoitu kirjallisesti ja
            palveluntarjoaja on tietoinen tilitoimiston
            tietoturvakäytännöistä ja suojattavista kohteista.
            <br />
            - Tilitoimiston ja ulkoistettujen ICT-palveluiden
            toiminnan ylläpidosta ja kehittämisestä
            keskustellaan määräajoin palveluntarjoajan kanssa.
          </p>
          <b>
            Suojattavien kohteiden ja tiedon hallinta
          </b>
          <p>
            Suojattavia kohteita ovat esimerkiksi työasemat,
            kannettavat tietokoneet, palvelimet ja mobiililaitteet.
          </p>
          <p>
            - Suojattaville kohteille määritelty hyväksyttävän
            käytön pelisäännöt.
            <br />
            - Asiakkaan kirjanpitoaineistolle, henkilötiedoille ja
            muille tiedoille on laadittu käsittelyohjeet. Sekä
            digitaalisen tiedon että tulosteiden tuhoamiselle on
            laadittu tietoturvallisen tuhoamisen menettelyohjeet.
            <br />
            - Käytössä on asianmukaiset tietosuojaroskasäiliöt
            tai asiakirjasilppuri luokitellun tiedon tuhoamista
            varten.
          </p>
          <b>
            Tietokoneiden ja mobiililaitteiden tietoturva
          </b>
          <p>
            - Tilitoimiston käytössä olevat työasemat,
            kannettavat tietokoneet, mobiililaitteet ja muut
            päätelaitteet on rekisteröity ja dokumentoitu
            asianmukaisesti.
            <br />
            - Koneiden säännöllisistä tietoturvapäivityksistä
            on huolehdittu asianmukaisesti ja päivityksiä
            valvotaan. Työntekijöiden oikeutta asentaa
            ohjelmistoja työasemille on rajattu ja asennuksia
            valvotaan.
          </p>
          <p>
            - Asianmukainen virus- ja haittaohjelmien
            torjuntaohjelmisto on käytössä.
            <br />
            - Tietoverkko ja tietokoneet on suojattu palomuurilla.
            <br />
            - Työntekijöiden henkilökohtaisten tietokoneiden ja
            mobiililaitteiden käyttö henkilötietojen
            käsittelyyn on kielletty.
          </p>
          <b>
            Siirrettävät tietovälineet
          </b>
          <p>
            Siirrettäviä tietovälineitä ovat esimerkiksi
            USB-muistitikut, USB-massamuistit, CD/DVD-levyt ja muut
            vastaavat muistilla tai tallennustilalla varustetut
            laitteet, jotka voidaan kytkeä tietokoneeseen.
          </p>
          <p>
            - Tilitoimistossa ei käytetä siirrettäviä
            tietovälineitä työtehtävien hoitamiseen tai
            suojattavan tiedon käsittelyyn lukuun ottamatta
            erikseen sovittuja tilanteita kuten aineiston luovutus
            tilintarkastajalle tai aineiston luovutus tai
            vastaanotto asiakkaan nimetyn yhteyshenkilön kanssa.
          </p>
          <p>
            - Käytettäessä siirrettäviä tietovälineitä
            edellä mainittuihin tarkoituksiin on niiden sisältö
            suojattu salasanalla.
          </p>
          <b>
            Palvelin- ja tietoliikenneturvallisuus
          </b>
          <p>
            - Toimitilojen palvelintilat ja tietoliikenneyhteyksien
            edellyttämät tilat pidetään lukittuina.
          </p>
          <p>
            - Langattomien verkkojen tietoliikenne on salattu.
            <br />
            - Vierasverkot on eriytetty tilitoimiston sisäisestä
            tietoverkosta luotettavalla menetelmällä.
            <br />
            - Palvelinkäyttöjärjestelmät päivitetään
            säännöllisesti.
            <br />
            - Palvelinjärjestelmä on rakennettu vikasietoiseksi
            tai kahdennettuksi siten, että tietojärjestelmien
            toiminta ei keskeydy yksittäisestä laiterikosta.
          </p>
          <b>
            Taloushallinnon pilvipalvelut
          </b>
          <p>
            Taloushallinnon pilvipalvelulla tarkoitetaan tässä
            kohdassa SaaS- tai ASP-palveluna toimitettavia
            taloushallinnon tietojärjestelmiä, joita organisaatio
            käyttää taloushallinnon palveluidensa tuottamiseen
            omille asiakkailleen.
          </p>
          <p>
            - Sopimuksiimme taloushallinnon pilvipalvelun
            käytöstä sisältyy kirjallinen palvelutasosopimus.
            <br />
            - Tilitoimiston ja palveluntarjoajan välinen
            vastuunjako on dokumentoitu kirjallisesti.
            <br />
            - Tilitoimisto on saanut palveluntarjoajalta selvitykset,
            jotka todentavat että palvelua tuotetaan
            tietosuoja-asetuksen sekä kirjanpitolain asettamat
            aineiston säilytysvaatimukset huomioiden.
          </p>
        </div>
      </ng-template>

    </mat-tab>

  </mat-tab-group>

</div>