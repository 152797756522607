import { Component, OnDestroy, ViewChild, ErrorHandler, Renderer2, ViewEncapsulation, ApplicationRef } from '@angular/core'
import { trigger, transition, style, animate, state } from '@angular/animations'
import { Router, RouterOutlet, NavigationStart } from '@angular/router'

import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MatSidenav } from '@angular/material/sidenav'

import { KayttajaService } from './_angular/service/kayttaja.service'
import { LaskuService } from './_angular/service/lasku/lasku.service'
import { VasenValikkoService } from './_jaettu-angular/service/vasen-valikko.service'
import { WindowSizeService } from './_jaettu-angular/service/window.service'
import { LadataanService } from './_jaettu-angular/service/ladataan.service'
import { LemonTranslationService } from './_jaettu-angular/service/lemon-translation.service'
import { LemonHttpService } from './_angular/service/lemon-http.service'
import { DragAndDropService } from './_jaettu-angular/service/drag-and-drop.service'
import { LoadingConfig } from './_jaettu-angular/_components/loading.component'
import { AiiaDialogService } from './_angular/service/aiia-dialog.service'

import { Laskuasetukset } from './_jaettu/model/lasku'
import { Kayttaja, Yritysmuoto, Asiakas, KnowYourCustomer, Roolit, NewFeature } from './_jaettu/model/kayttaja'

import { Subject, combineLatest, Observable, BehaviorSubject, of as observableOf, from } from 'rxjs'
import { takeUntil, filter, startWith, map, distinctUntilChanged, delay, tap, switchMap } from 'rxjs/operators'

import { environment } from 'environments/environment'
import { EnvironmentType } from './app.environment'
import { TervetuloaSopimusDialog, TervetuloaSopimusDialogData } from './tervetuloa/tervetuloa-sopimus.dialog'
import { UusiSopimusDialog, UusiSopimusDialogData } from './sopimukset/uusi-sopimus.dialog'
import { ViiaLoginRequiredDialog, AiiaDialogData } from './banks/viia/viia-login-required.dialog'
import { SopimuksenHintaJaTekstiResponse, SopimuksenHintaJaTekstiRequest } from './_jaettu/model/sopimus'
// import { ViiaAsiakkaanAutentikointitiedot, ViiaHaeIbanitLemonaidResponse } from './_jaettu/model/aiia'
import { DateService } from './_shared-core/service/date.service'
import { AllowedDialogsService } from './_angular/service/allowed-dialogs.service'
import { TunnistautuminenNetsDialog, TunnistautuminenNetsDialogData } from './tunnistautuminen/nets/tunnistautuminen-nets.dialog'
import { TunnistautuminenService } from './_angular/service/tunnistautuminen.service'
import { KycUriService } from './_jaettu/service/kyc-uri.service'
import { TuntemistiedotDialogService } from './_angular/service/tuntemistiedot-dialog.service'
import { UusiSopimusDialogService } from './_angular/service/uusi-sopimus-dialog.service'
import { TilinpaatosUsersService } from './_angular/service/tilinpaatos-users.service'
import { FirebaseLemonaid } from './_angular/service/firebase-lemonaid.service'
import { UudetTuntemistiedotDialog, UudetTuntemistiedotDialogData } from './tervetuloa/uudet-tuntemistiedot.dialog'
import { HolviLoginRequiredDialog, HolviLoginRequiredDialogData } from './banks/holvi/holvi-login-required.dialog'
import { BankCustomerConsent, BankDialogType } from './_jaettu/model/banks'
import { BankConsentUriService } from './_jaettu/service/bank-consent-uri.service'
import { GeneralBankSelectionDialogData } from './banks/general-bank-selection.dialog'
import { HolviDialogService } from './_angular/service/holvi-dialog.service'
import { MaksutMainosLaskujenVastaanottoDialog, MaksutMainosLaskujenVastaanottoDialogData } from './maksut/maksut-mainos-laskujen-vastaanotto.dialog'
import { UserSettingsService } from './_angular/service/user-settings.service'
import { ApixReceivedInvoiceConfig } from './_jaettu/model/apix'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('sisaltoAuki', [
      // eslint-disable-next-line @typescript-eslint/naming-convention
      state('vasen', style({ 'margin-left': '0' })),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      state('oikea', style({ 'margin-left': '220px' })),
      transition('* <=> *', animate('400ms 0ms cubic-bezier(0.25, 0.8, 0.25, 1)'))
    ]),
    trigger('valikkoAuki', [
      // eslint-disable-next-line @typescript-eslint/naming-convention
      state('vasen, vasenpika', style({ transform: 'translate3d(-100%, 0, 0)', 'box-shadow': 'none', 'display': 'none;' })),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      state('oikea', style({ transform: 'none', 'box-shadow': '0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)' })),
      transition('vasen <=> oikea', animate('400ms 0ms cubic-bezier(0.25, 0.8, 0.25, 1)')),
      transition('vasenpika <=> *', animate('0ms')),
      transition('* <=> vasenpika', animate('0ms'))
    ]),
    trigger('tummaTausta', [
      // eslint-disable-next-line @typescript-eslint/naming-convention
      state('poissa', style({ 'background-color': 'transparent' })),
      // eslint-disable-next-line @typescript-eslint/naming-convention
      state('nakyvissa', style({ 'background-color': 'rgba(0, 0, 0, 0.6)' })),
      transition('* <=> *', animate('400ms 0ms cubic-bezier(.25,.8,.25,1)'))
    ])
  ]
})
export class AppComponent implements OnDestroy {

  @ViewChild('outletti', { read: RouterOutlet, static: true }) routerOutlet: RouterOutlet
  @ViewChild('nav', { read: MatSidenav }) nav: MatSidenav

  private avattuNappulasta: BehaviorSubject<boolean> = new BehaviorSubject(false)

  ladataanObservable: Observable<boolean>
  urlObservable: Observable<string>
  kirjautunutKayttajaObservable: Observable<Kayttaja>
  asetuksetObservable: Observable<Laskuasetukset>

  private _vasenValikkoAukiObservable: Observable<boolean>
  private _onkoOverTilaObservable: Observable<boolean>
  vasemmanValikonTilaObservable: Observable<'side' | 'over'>
  onkoSisaltoKapeaObservable: Observable<boolean>
  vasemmanValikonAnimointiTila: Observable<'vasenpika' | 'vasen' | 'oikea'>
  sisallonAnimointitilaObservable: Observable<'vasenpika' | 'vasen' | 'oikea'>
  onkoValikonVerhoNakyvissaObservable: Observable<boolean>
  valikonAvaajaNakyvissaObservable: Observable<boolean>

  showNewFeatureSignAtMaksutLaskujenVastaanottoObservable: Observable<boolean>
  showAdvertiseObservable: Observable<boolean>
  combinedObservable: Observable<boolean>

  private _ngUnsubscribe: Subject<void> = new Subject<void>()

  private _vasemmanValikonVaihtuminen = 801
  private _initiatedInsideDocument = false

  ladataanOverlayConfig: LoadingConfig = {
    fullScreenBackdrop: true,
    backdropBackgroundColour: 'rgba(0, 0, 0, 0.15)',
    backdropBorderRadius: '0',
    primaryColour: '#ffffff',
    secondaryColour: '#ffffff',
    tertiaryColour: '#ffffff'
  }

  naytaBeta = environment.environment === EnvironmentType.BETA
  naytaInternetYhteysOngelmaObservable: Observable<boolean>
  showReportsIconObservable: Observable<boolean>



  constructor(
    private _applicationRef: ApplicationRef,
    private _errorHandler: ErrorHandler,
    private _kayttajaService: KayttajaService,
    private _router: Router,
    private _dialog: MatDialog,
    private _vasenValikkoService: VasenValikkoService,
    private _laskuService: LaskuService,
    private _windowSizeService: WindowSizeService,
    private _ladataanService: LadataanService,
    private _translationService: LemonTranslationService,
    private _http: LemonHttpService,
    private _dndService: DragAndDropService,
    private _renderer: Renderer2,
    private _allowedDialogsService: AllowedDialogsService,
    private _aiiaDialogService: AiiaDialogService,
    private _holviDialogService: HolviDialogService,
    private _dateService: DateService,
    private _tunnistautuminenService: TunnistautuminenService,
    private _kycUriService: KycUriService,
    private _tuntemistiedotDialogService: TuntemistiedotDialogService,
    private _uusiSopimusDialogService: UusiSopimusDialogService,
    private _tilinpaatosUsersService: TilinpaatosUsersService,
    private _firebaseLemonaid: FirebaseLemonaid,
    private _bankConsentUriService: BankConsentUriService,
    private _userSettingsService: UserSettingsService
  ) {

    this.vasemmanValikonTilaObservable = this._windowSizeService.sizeObservable.pipe(
      map(() => {
        const leveys = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth
        return leveys <= this._vasemmanValikonVaihtuminen ? 'over' : 'side'
      }),
      distinctUntilChanged<'over' | 'side'>()
    )

    this._vasenValikkoAukiObservable = combineLatest([this._vasenValikkoService.aukiObservable, this._kayttajaService.kayttajanTiedotObservable, this.vasemmanValikonTilaObservable, this.avattuNappulasta]).pipe(
      map(([servicenPuolestaAuki, kayttajanTiedot, leveydenPuolestaAuki, avattuNappulasta]) => {
        const kayttajanPuolestaAuki = !!kayttajanTiedot

        if (avattuNappulasta) {
          return true
        }

        // console.log('Vasen valikko auki')
        return kayttajanPuolestaAuki && servicenPuolestaAuki && leveydenPuolestaAuki === 'side'
      })
    )

    this.onkoSisaltoKapeaObservable = combineLatest([this.vasemmanValikonTilaObservable, this._vasenValikkoAukiObservable]).pipe(
      map(([valikonTila, valikkoOnAuki]) => {
        // console.log('Vasen valikko auki')
        return valikonTila === 'over' || (valikonTila === 'side' && !valikkoOnAuki)
      })
    )

    // const kayttoehtojenHyvaksynnatObservable: Observable<KayttoehtojenHyvaksynnat> = kayttajaService.kayttajanTiedotObservable.pipe(
    //   switchMap(kayttajanTiedot => {
    //     if (kayttajanTiedot) {
    //       return this.lemonaidDatabase.object(this.kayttoehdotUriService.getKayttoehdotRealtimeApprovalsCollectionUri(kayttajanTiedot.uid)).valueChanges().pipe(
    //         map(approvals => {
    //           if (approvals) {
    //             return approvals as KayttoehtojenHyvaksynnat
    //           }
    //         })
    //       )
    //     }
    //     return observableOf<KayttoehtojenHyvaksynnat>(null)
    //   }),
    // )
    // const nykyistenKayttoehtojenAvainObservable: Observable<string> = kayttajaService.kayttajanTiedotObservable.pipe(
    //   switchMap(kayttajanTiedot => {
    //       if (kayttajanTiedot) {
    //         return this.lemonaidDatabase.object(this.kayttoehdotUriService.getKayttoehdotRealtimeLatestKeyUri()).valueChanges().pipe(
    //           map(data => {
    //             return data as string}))
    //         }
    //       return observableOf<string>(null)
    //   })
    // )
    // combineLatest(kayttoehtojenHyvaksynnatObservable,
    //               nykyistenKayttoehtojenAvainObservable,
    //               kayttajaService.kayttajanTiedotObservable,
    //               translationService.currentLanguageObservable)
    //               .pipe(
    //   switchMap(([hyvaksynnat, kayttoehtojenAvain, kayttajaTiedot, valittuKieli]) => {
    //     if (!kayttoehtojenAvain) {
    //       return observableOf<KayttoehtojenDialogData>(null)
    //     }
    //     const findApproval = hyvaksynnat ? hyvaksynnat[kayttoehtojenAvain] : null
    //     if (!findApproval || !hyvaksynnat) {
    //       return this.lemonaidDatabase.object(this.kayttoehdotUriService.getKayttoehdotRealtimeLatestTextUri(valittuKieli)).valueChanges().pipe(
    //         map(async text => {
    //           const kayttoehtojenText: string = text as string
    //           const kayttoehtojenDialogData: KayttoehtojenDialogData = {
    //             uid: kayttajaTiedot.uid,
    //             kayttoehtojenAvain: kayttoehtojenAvain,
    //             text: kayttoehtojenText.replace(kayttoehtojenAvain, '')
    //           }
    //           return kayttoehtojenDialogData
    //         })
    //       )
    //     }
    //     return observableOf<KayttoehtojenDialogData>(null)
    //   }),
    //   takeUntil(this._ngUnsubscribe)
    // ).subscribe(async data => {
    //   // Sulje dialogi, jos sellainen oli
    //   if ( this.uudetKayttoehdotDialogi ) {
    //     this.uudetKayttoehdotDialogi.close()
    //     this.uudetKayttoehdotDialogi = null
    //   }
    //   const output: KayttoehtojenDialogData = await data
    //   if (output) {
    //     this.naytaUudetKayttoehdotDialogi(output)
    //   }
    // })

    const alkuperainenTila = this._vasenValikkoService.annaAlkuperainenTila(this._vasemmanValikonVaihtuminen)
    this.sisallonAnimointitilaObservable = this.onkoSisaltoKapeaObservable.pipe(
      map(kapea => {
        return kapea ? 'vasen' : 'oikea'
      }),
      startWith<'vasen' | 'oikea' | 'vasenpika'>(alkuperainenTila)
    )

    this.vasemmanValikonAnimointiTila = this._vasenValikkoAukiObservable.pipe(
      map(auki => {
        return auki ? 'oikea' : 'vasen'
      }),
      startWith<'vasen' | 'oikea' | 'vasenpika'>(alkuperainenTila === 'vasen' ? 'vasenpika' : 'oikea')
    )

    this.urlObservable = this._router.events.pipe(
      filter(event => { return event instanceof NavigationStart }),
      map((event: NavigationStart) => { return event.url }),
      distinctUntilChanged(),
      delay(1),
      takeUntil(this._ngUnsubscribe)
    )
    this.kirjautunutKayttajaObservable = this._kayttajaService.kayttajaObservable
    this.ladataanObservable = this._ladataanService.ladataanObservable.pipe(
      startWith(false),
      tap(() => {
        setTimeout(() => { this._applicationRef.tick() }, 250)
      })
    )
    this.asetuksetObservable = this._laskuService.asetuksetObservable

    this._renderer.listen('document', 'dragstart', () => {
      // console.log('RENDERER START DRAG')
      this._initiatedInsideDocument = true
    })

    this._renderer.listen('document', 'dragend', () => {
      // console.log('RENDERER END DRAG')
      this._initiatedInsideDocument = false
    })

    this._renderer.listen('document', 'drop', () => {
      // console.log('RENDERER DROP')
      this._initiatedInsideDocument = false
      this._dndService.setDragging(false)
    })

    this._renderer.listen('document', 'dragover', () => {
      // console.log('RENDERER DRAGOVER')
      if (!this._initiatedInsideDocument) {
        this._dndService.setDragging(true)
      }
    })

    this._renderer.listen('document', 'dragenter', () => {
      // console.log('RENDERER DRAGENTER')
      if (!this._initiatedInsideDocument) {
        this._dndService.setDragging(true)
      }
    })

    this._renderer.listen('document', 'dragleave', () => {
      // console.log('RENDERER DRAG LEAVE')
      this._dndService.setDragging(false)
    })

    this._onkoOverTilaObservable = this.vasemmanValikonTilaObservable.pipe(
      map(tila => {
        // console.log('Overtila')
        return tila === 'over'
      })
    )

    this.onkoValikonVerhoNakyvissaObservable = combineLatest([this._onkoOverTilaObservable, this._vasenValikkoAukiObservable]).pipe(
      map(([overTila, valikkoAuki]) => {
        // console.log('Vasen valikko onkoValikonVerhoNakyvissaObservable')
        return overTila && valikkoAuki
      }),
      tap(nakyvissa => {
        if (nakyvissa) {
          this._renderer.addClass(document.body, 'noscroll')
          this._renderer.addClass(document.documentElement, 'noscroll')
        } else {
          this._renderer.removeClass(document.body, 'noscroll')
          this._renderer.removeClass(document.documentElement, 'noscroll')
        }
      })
    )

    this.valikonAvaajaNakyvissaObservable = combineLatest([this._onkoOverTilaObservable, this._vasenValikkoService.aukiObservable, this.kirjautunutKayttajaObservable]).pipe(
      map(([overTila, valikkoAuki, kirjautunutKayttaja]) => {
        return overTila && valikkoAuki && !!kirjautunutKayttaja
      }),
      delay(10)
    )

    this.showReportsIconObservable = combineLatest([this._tilinpaatosUsersService.tilinpaatosUsersObservable, this._kayttajaService.kayttajanTiedotObservable]).pipe(
      map(([tilinpaatosUsers, kayttaja]) => {
        const userAsShareholder = tilinpaatosUsers?.osakkaatUsers?.find(shareholder => shareholder.lemonaidUid === kayttaja.uid)
        if (userAsShareholder) {
          return true
        }
        const userAsBoardMember = tilinpaatosUsers?.hallitusUsers?.find(boardMember => boardMember.lemonaidUid === kayttaja.uid)
        if (
          userAsBoardMember
          // &&
          // (
          //   userAsBoardMember.role === 'toimitusjohtaja' ||
          //   userAsBoardMember.role === 'jasen' ||
          //   userAsBoardMember.role === 'puheenjohtaja'
          // )
        ) {
          return true
        }
      })
    )

    // const isAiiaLoginRequiredObservable = combineLatest([this._kayttajaService.kayttajaObservable, this._kayttajaService.nykyinenAsiakasObservable]).pipe(
    //   switchMap(([kayttaja, asiakas]) => {

    //     if (this._router.url.startsWith('/ajopaivakirja')) {
    //       return observableOf<boolean>(false)
    //     }

    //     if (this._aiiaDialogService.aiiaFlowCompletedRecently()) {
    //       return observableOf<boolean>(false)
    //     }

    //     if (!asiakas || !kayttaja || kayttaja.roolit.HALLINTO_VIIA_VALTUUTUKSEN_ANTAJA !== true) {
    //       return observableOf<boolean>(false)
    //     }
    //     // Has the customer been marked as needing a Viia login?
    //     return this._isViiaLoginRequired(asiakas)

    //   }),
    //   switchMap(isRequired => {
    //     if (!isRequired) {
    //       return observableOf<ViiaHaeIbanitLemonaidResponse>(null)
    //     }
    //     // Create observable that fetches the ibans we have access to.
    //     return this._firebaseLemonaid.functionsCall<string, ViiaHaeIbanitLemonaidResponse>('viiaGetUserIbansLemonaidUusiProd', '').then(ibanResponse => {
    //       if (!ibanResponse) { return null }
    //       if (ibanResponse.e) {
    //         this._errorHandler.handleError(new Error('Error while fetching viia ibans: ' + ibanResponse.e))
    //         return null
    //       }
    //       return ibanResponse
    //     })
    //   }),
    //   map(ibanResponse => {
    //     if (ibanResponse?.ibanitJaProviderIdt) {
    //       const aiiaDialogConfig: MatDialogConfig<AiiaDialogData> = {
    //         data: {
    //           // ibanitJaProviderIdt: ibanResponse.ibanitJaProviderIdt, // Will be deprecated
    //           dialogToShowMetadata: null // New field, replaces ibanitJaProviderIdt
    //         }
    //       }
    //       return aiiaDialogConfig
    //     }
    //     return null
    //   })
    // )

    this.kirjautunutKayttajaObservable.pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(kayttaja => {
      // Aseta kieli, jos meillä sellainen on
      if (kayttaja && kayttaja.asiointikieli) {
        this._translationService.vaihdaKieli(kayttaja.asiointikieli)
      }
    })

    combineLatest([this._kayttajaService.kayttajanTiedotObservable, this.kirjautunutKayttajaObservable]).pipe(
      takeUntil(this._ngUnsubscribe)
    ).subscribe(([tiedot, kayttaja]) => {
      if (tiedot && kayttaja && environment.environment === EnvironmentType.BETA) {
        if (!tiedot.euid && !kayttaja.email.endsWith('@lemontree.fi')) {
          this._kayttajaService.logout()
        }
      }
    })

    this.naytaInternetYhteysOngelmaObservable = this._http.internetYhteysObservable.pipe(
      map(online => { return !online })
    )

    const naytaTuntemistiedotDialogObservable = combineLatest([this._kayttajaService.nykyinenAsiakasObservable, this._kayttajaService.kayttajaObservable]).pipe(
      map(([asiakas, kayttaja]) => {
        if (asiakas && kayttaja) {
          if (this._tuntemistiedotDialogService.postponedRecently()) {
            return null
          }
          if (this._naytaTuntemistiedotDialogi(asiakas, kayttaja)) {
            return asiakas
          }
        }
        return null
      }),
      switchMap(asiakas => {
        if (asiakas) {
          return this._firebaseLemonaid.firestoreDoc<KnowYourCustomer>(this._kycUriService.getCustomerKycDocUri(asiakas.asiakasAvain)).listen().pipe(
            map(kyc => {
              const config: MatDialogConfig<UudetTuntemistiedotDialogData> = {
                data: {
                  kyc: kyc || null
                }
              }
              return config
            })
          )
        }
        return observableOf<MatDialogConfig<UudetTuntemistiedotDialogData>>(null)
      })
    )

    const naytaTervetuloaDialogObservable = combineLatest([this._kayttajaService.nykyinenAsiakasObservable, this._kayttajaService.kayttajaObservable]).pipe(
      switchMap(([asiakas, kayttaja]) => {

        const naytaTuntemistiedot = this._naytaTuntemistiedotDialogi(asiakas, kayttaja)
        const naytaTunnistaminen = this._naytaTunnistamisDialog(kayttaja)
        const naytaSopimus = this._naytaSopimusDialog(kayttaja)

        if (kayttaja && !kayttaja.tervetuloaDialogiNaytetty && naytaSopimus) {
          const reqData: SopimuksenHintaJaTekstiRequest = { kieli: this._translationService.nykyinenKieli || 'fi' }
          const hintaJaTekstiPromise = this._firebaseLemonaid.functionsCall<SopimuksenHintaJaTekstiRequest, SopimuksenHintaJaTekstiResponse>('sopimusAnnaKayttajanTekstiJaHinta', reqData).then(resp => {
            if (!resp || (resp && resp.e)) {
              this._errorHandler.handleError(new Error('Sopimus text and price fetch failed!' + (resp?.e ?? 'unknown-error')))
              return null
            }
            return resp
          }).catch(err => {
            this._errorHandler.handleError(err)
            return null
          })
          return from(hintaJaTekstiPromise).pipe(
            map((lastPriceAndSopimus: SopimuksenHintaJaTekstiResponse) => {

              if (!lastPriceAndSopimus) {
                return null
              }

              const kaytettavaLeveys = window.innerWidth
              const kaytettavaKorkeus = window.innerHeight
              const leveys = kaytettavaLeveys > 900 ? '90vw' : '98vw'
              // const korkeus = kaytettavaKorkeus > 1100 ? '80vh' : kaytettavaKorkeus > 768 ? '85vh' : '95vh'

              const settings: MatDialogConfig<TervetuloaSopimusDialogData> = {
                maxWidth: '1000px',
                width: leveys,
                // maxHeight: '800px',
                disableClose: true,
                data: {
                  naytaTunnistaminen: naytaTunnistaminen,
                  naytaSopimus: naytaSopimus,
                  naytaTuntemistiedot: naytaTuntemistiedot,
                  sopimuksenAvain: lastPriceAndSopimus?.sopimusAvain,
                  hinta: lastPriceAndSopimus?.hinta,
                  mobiili: kaytettavaLeveys < 670 || kaytettavaKorkeus < 670,
                  kayttajaJoTunnistettu: kayttaja.kayttajaTunnistettu === 'tunnistettu-nets',
                  pepMissing: !kayttaja.pepTiedotAnnettu
                }
              }

              return settings

            })
          )
        }
        return observableOf<MatDialogConfig<TervetuloaSopimusDialogData>>(null)
      })
    )

    const naytaNetsTunnistautuminenDialogObservable = this._kayttajaService.kayttajaObservable.pipe(
      map(kayttaja => {
        if (this._naytaTunnistamisDialog(kayttaja)) {
          const settings: MatDialogConfig<TunnistautuminenNetsDialogData> = {
            data: {
              kayttajaJoTunnistettu: kayttaja.kayttajaTunnistettu === 'tunnistettu-nets',
              pepMissing: !kayttaja.pepTiedotAnnettu
            }
          }
          return settings
        }
        return null
      })
    )

    const naytaSopimusDialogObservable = this._kayttajaService.kayttajaObservable.pipe(
      switchMap(kayttaja => {

        if (!this._naytaSopimusDialog(kayttaja) || this._uusiSopimusDialogService.postponedRecently()) {
          return observableOf(null)
        }
        const lang = this._translationService.nykyinenKieli
        const reqData: SopimuksenHintaJaTekstiRequest = {
          kieli: lang || 'fi'
        }

        const hintaJaTekstiPromise: Promise<SopimuksenHintaJaTekstiResponse> = this._firebaseLemonaid.functionsCall<SopimuksenHintaJaTekstiRequest, SopimuksenHintaJaTekstiResponse>('sopimusAnnaKayttajanTekstiJaHinta', reqData)
          .then(resp => {
            if (!resp || (resp && resp.e)) {
              this._errorHandler.handleError(new Error('Sopimus text and price fetch failed!' + (resp?.e ?? 'unknown-error')))
              return null
            }
            return resp
          }).catch(err => {
            this._errorHandler.handleError(err)
            return null
          })

        return from(hintaJaTekstiPromise).pipe(
          map((lastPriceAndSopimus: SopimuksenHintaJaTekstiResponse) => {
            // If no data from CF, stop
            if (!lastPriceAndSopimus) {
              return null
            }
            const kaytettavaLeveys = window.innerWidth
            // const kaytettavaKorkeus = window.innerHeight
            const leveys = kaytettavaLeveys > 900 ? '90vw' : '98vw'
            // const korkeus = kaytettavaKorkeus > 1100 ? '80vh' : kaytettavaKorkeus > 768 ? '85vh' : '95vh'

            const settings: MatDialogConfig<UusiSopimusDialogData> = {
              maxWidth: '1000px',
              width: leveys,
              // maxHeight: '800px',
              disableClose: true,
              data: {
                sopimuksenAvain: lastPriceAndSopimus.sopimusAvain,
                sopimusPaattyy: this._dateService.lisaaPaiviaPaikallinen(this._dateService.currentLocalDate(), 14),
                kuukausiHinta: lastPriceAndSopimus.hinta?.hinta,
                hintaMuuttuu: false,
                kieli: lang
              }
            }
            return settings
          })
        )
      })
    )

    const _bankCustomerConsentObservable: Observable<BankCustomerConsent> = this._kayttajaService.kayttajaObservable.pipe(
      switchMap(kayttaja => {
        if (!kayttaja) {
          return observableOf<BankCustomerConsent>(null)
        }
        if (!kayttaja.roolit['HALLINTO_VIIA_VALTUUTUKSEN_ANTAJA']) {
          return observableOf<BankCustomerConsent>(null)
        }
        return this._firebaseLemonaid.firestoreDoc<BankCustomerConsent>(this._bankConsentUriService.getBankConsentUri(kayttaja.asiakasAvain)).listen()
      })
    )

    const showGeneralBankSelectionDialogObservable: Observable<MatDialogConfig<GeneralBankSelectionDialogData>> = _bankCustomerConsentObservable.pipe(
      map(consent => {

        if (!consent) {
          return null
        }

        if (consent.dialogCompletePreventReShow?.findIndex(prevented => prevented === BankDialogType.GENERAL) > -1) {
          return null
        }
        const generalRequired = consent.dialogsToShow?.findIndex(dialog => dialog.dialogToShow === BankDialogType.GENERAL) > -1

        if (!generalRequired) {
          return null
        }

        const settings: MatDialogConfig<GeneralBankSelectionDialogData> = {
          maxWidth: '1000px',
          // maxHeight: '800px',
          disableClose: true,
          data: {
            includeHolvi: true
          }
        }
        return settings
      })
    )
    const showGeneralWithoutHolviConnectionDialogObservable: Observable<MatDialogConfig<GeneralBankSelectionDialogData>> = _bankCustomerConsentObservable.pipe(
      map(consent => {

        if (!consent) {
          return null
        }

        if (consent.dialogCompletePreventReShow?.findIndex(prevented => prevented === BankDialogType.GENERAL_WO_HOLVI) > -1) {
          return null
        }
        const settings: MatDialogConfig<GeneralBankSelectionDialogData> = {
          maxWidth: '1000px',
          // maxHeight: '800px',
          disableClose: true,
          data: {
            includeHolvi: false
          }
        }

        if (consent.forceDialogToShow === BankDialogType.GENERAL_WO_HOLVI) {
          return settings
        }

        const generalRequired = consent.dialogsToShow?.findIndex(dialog => dialog.dialogToShow === BankDialogType.GENERAL_WO_HOLVI) > -1
        if (!generalRequired) {
          return null
        }

        return settings
      })
    )


    const showHolviConnectionDialogObservable: Observable<MatDialogConfig<HolviLoginRequiredDialogData>> = _bankCustomerConsentObservable.pipe(
      map(consent => {

        if (!consent) {
          return null
        }

        if (consent.dialogCompletePreventReShow?.findIndex(prevented => prevented === BankDialogType.HOLVI_RENEW) > -1) {
          return null
        }
        const holviRequired = consent.dialogsToShow?.findIndex(dialog => dialog.dialogToShow === BankDialogType.HOLVI_RENEW) > -1

        if (!holviRequired) {
          return null
        }

        if (this._holviDialogService.holviCompletedRecently()) {
          return null
        }

        if (this._holviDialogService.postponedRecently()) {
          return null
        }

        const settings: MatDialogConfig<HolviLoginRequiredDialogData> = {
          maxWidth: '1000px',
          disableClose: true,
          data: {
          }
        }

        return settings
      })
    )

    const showAiiaConnectionDialogObservable: Observable<MatDialogConfig<AiiaDialogData>> = _bankCustomerConsentObservable.pipe(
      map(consent => {

        if (!consent) {
          return null
        }

        if (this._aiiaDialogService.aiiaFlowCompletedRecently()) {
          return null
        }

        if (this._aiiaDialogService.postponedRecently()) {
          return null
        }

        if (consent.dialogCompletePreventReShow?.findIndex(prevented => prevented === BankDialogType.PSD2_RENEW) > -1) {
          return null
        }

        const psd2Dialog = consent.dialogsToShow?.find(dialog => dialog.dialogToShow === BankDialogType.PSD2_RENEW || // Intentionally finds first - the dialog loop will be done multiple times if there are accounts from more than one bank.
          dialog.dialogToShow === BankDialogType.GENERAL || // TODO! Remove once general dialog is ready for release!
          dialog.dialogToShow === BankDialogType.GENERAL_WO_HOLVI // TODO! Remove once general dialog is ready for release!
        )
        if (!psd2Dialog) {
          return null
        }

        const settings: MatDialogConfig<AiiaDialogData> = {
          data: {
            // ibanitJaProviderIdt: null,
            dialogToShowMetadata: psd2Dialog ?? null
          }
        }
        return settings
      })
    )

    // Pop-up mainos
    const einvoiceApprovingActivatedObservable = this._kayttajaService.kayttajaObservable.pipe(
      switchMap(kayttaja => {
        if (!kayttaja || !kayttaja.roolit[Roolit.SAHKOISTEN_LASKUJEN_VASTAANOTTO]) {
          return observableOf<boolean>(false)
        }
        return this._firebaseLemonaid.firestoreDoc<ApixReceivedInvoiceConfig>('customers/' + kayttaja.asiakasAvain + '/apix-received-invoice-config/' + kayttaja.asiakasAvain).listen().pipe(
          map(config => !!config && config.receiveActive)
        )
      })
    )

    // Pop-up mainos
    const naytaLaskujenVastaanottoMainosDialogObservable = combineLatest([
      this._kayttajaService.kayttajanTiedotObservable,
      this._userSettingsService.userSettingsObservable,
      einvoiceApprovingActivatedObservable
    ]).pipe(
      map(([kayttajanTiedot, userSettings, einvoiceApprovingActivated]) => {

        if (einvoiceApprovingActivated) {
          return null
        }

        if (userSettings?.seen?.[NewFeature.MAKSUT_LASKUJEN_VASTAANOTTO_MAINOS]) {
          return null
        }

        const dialogData: MaksutMainosLaskujenVastaanottoDialogData = {
          kayttajaUid: kayttajanTiedot.uid,
          kayttajaAsiakasAvain: kayttajanTiedot.asiakasAvain,
        }
        const dialogConfig: MatDialogConfig<MaksutMainosLaskujenVastaanottoDialogData> = {
          data: dialogData
        }

        return dialogConfig
      })
    )

    // Configure dialogs

    this._allowedDialogsService.registerDialog<TervetuloaSopimusDialog, TervetuloaSopimusDialogData>(
      TervetuloaSopimusDialog,
      'tervetuloa',
      9000,
      naytaTervetuloaDialogObservable
    )
    this._allowedDialogsService.registerDialog<TunnistautuminenNetsDialog, TunnistautuminenNetsDialogData>(
      TunnistautuminenNetsDialog,
      'tunnistautuminen-nets',
      8000,
      naytaNetsTunnistautuminenDialogObservable
    )
    this._allowedDialogsService.registerDialog<UusiSopimusDialog, UusiSopimusDialogData>(
      UusiSopimusDialog,
      'uusi-sopimus',
      3000,
      naytaSopimusDialogObservable
    )
    // this._allowedDialogsService.registerDialog<ViiaLoginRequiredDialog, AiiaDialogData>(
    //   ViiaLoginRequiredDialog,
    //   BankDialogTypes.PSD2_RENEW,
    //   2000,
    //   isAiiaLoginRequiredObservable // TODO! Start checking this from BankConsentDialog, not Aiia-specific consent
    // )
    // // GENERAL
    // this._allowedDialogsService.registerDialog<GeneralBankSelectionDialogData, GeneralBankSelectionDialogData>(
    //   GeneralBankSelectionDialog,
    //   BankDialogTypes.GENERAL,
    //   1999,
    //   showGeneralBankSelectionDialogObservable
    // )
    // // GENERAL WITHOUT HOLVI
    // this._allowedDialogsService.registerDialog<GeneralBankSelectionDialog, GeneralBankSelectionDialogData>(
    //   GeneralBankSelectionDialog,
    //   BankDialogTypes.GENERAL_WO_HOLVI,
    //   1998,
    //   showGeneralWithoutHolviConnectionDialogObservable
    // )
    // HOLVI
    this._allowedDialogsService.registerDialog<HolviLoginRequiredDialog, HolviLoginRequiredDialogData>(
      HolviLoginRequiredDialog,
      BankDialogType.HOLVI_RENEW,
      1997,
      showHolviConnectionDialogObservable
    )
    // AIIA
    this._allowedDialogsService.registerDialog<ViiaLoginRequiredDialog, AiiaDialogData>(
      ViiaLoginRequiredDialog,
      BankDialogType.PSD2_RENEW,
      1996,
      showAiiaConnectionDialogObservable
    )

    this._allowedDialogsService.registerDialog<UudetTuntemistiedotDialog, UudetTuntemistiedotDialogData>(
      UudetTuntemistiedotDialog,
      'uudet-tuntemistiedot',
      1000,
      naytaTuntemistiedotDialogObservable
    )

    this._allowedDialogsService.registerDialog<MaksutMainosLaskujenVastaanottoDialog, MaksutMainosLaskujenVastaanottoDialogData>(
      MaksutMainosLaskujenVastaanottoDialog,
      'mainos-vastaanota-laskuja',
      500,
      naytaLaskujenVastaanottoMainosDialogObservable
    )

    this._allowedDialogsService.startShowingDialogs()
  }

  private _naytaTunnistamisDialog(kayttaja: Kayttaja): boolean {
    if (!kayttaja) { return false }
    const tunnistetaanKoskaOllaanPalaamassaTunnistautumisesta = this._tunnistautuminenService.getTunnistautuminenAction() === 'continue-standalone-dialog' && !!this._tunnistautuminenService.getTunnistautuminenState() && kayttaja.roolit.TUNNISTETAAN
    const tunnistetaanKoskaPyydettyEikaVielaVahva = (kayttaja.kayttajaTunnistettu === 'ei-tunnistettu' || kayttaja.kayttajaTunnistettu === 'tunnistettu-hetulla') && kayttaja.roolit.TUNNISTETAAN
    const tunnistettuMuttaKayttajanPepTiedotEiAnnettu = kayttaja.roolit.TUNNISTETAAN && kayttaja.kayttajaTunnistettu === 'tunnistettu-nets' && !kayttaja.pepTiedotAnnettu
    return tunnistetaanKoskaPyydettyEikaVielaVahva || tunnistetaanKoskaOllaanPalaamassaTunnistautumisesta || tunnistettuMuttaKayttajanPepTiedotEiAnnettu
  }

  private _naytaSopimusDialog(kayttaja: Kayttaja): boolean {
    if (!kayttaja) { return false }
    return kayttaja.roolit.HALLINTO_SOPIMUKSEN_HYVAKSYJA && kayttaja.sopimuksenHyvaksyntaStatus === 'ei-hyvaksytty'
  }

  private _naytaTuntemistiedotDialogi(asiakas: Asiakas, kayttaja: Kayttaja): boolean {
    if (
      kayttaja &&
      asiakas &&
      kayttaja.roolit.HALLINTO_TUNTEMISTIETOJEN_ANTAJA &&
      (
        asiakas.tuntemistietojenStatus === 'vaatii-paivityksen' ||
        asiakas.tuntemistietojenStatus === 'ei-annettu'
      ) &&
      (
        asiakas.yritysmuoto === Yritysmuoto.OSAKEYHTIO ||
        asiakas.yritysmuoto === Yritysmuoto.OSUUSKUNTA
      )
    ) {
      return true
    }
    return false
  }

  muutaNayta() {
    this.avattuNappulasta.next(!this.avattuNappulasta.value)
  }

  signalChange() {
    this._windowSizeService.publishChange()
  }

  suljeVasenValikko() {
    this.avattuNappulasta.next(false)
  }

  ngOnDestroy() {
    this._ngUnsubscribe.next()
    this._ngUnsubscribe.complete()
  }

  // private _isViiaLoginRequired(asiakas: Asiakas): Observable<boolean> {
  //   return this._firebaseLemonaid.firestoreDoc<ViiaAsiakkaanAutentikointitiedot>(this._viiaUriService.annaAsiakkaanAutentikointitietojenUri(asiakas.asiakasAvain))
  //     .listen().pipe(
  //       map(consent => {
  //         if (!consent) {
  //           return false // Consent is created when the first viia role user is added
  //         }
  //         // Don't require login if dialog has been postponed
  //         if (consent.postponedUntil && this._dateService.compareLocalDates(consent.postponedUntil, '>', this._dateService.currentLocalDate())) {
  //           return false
  //         }
  //         // Has Viia login been required by backend?
  //         return this._dateService.compareLocalDates(consent.loginRequiredFrom, '<=', this._dateService.currentLocalDate())
  //       })
  //     )
  // }

}
